import {baseURL} from "../baseurl";

export interface InformationAnamnesis {
  inc_id?: number;
  id?: string;
  patient: string;
  disease_type: TypeInformationAnamnesis;
  disease_desc: string;
}

export enum TypeInformationAnamnesis {
  MEDICINE_ALLERGY = "Alergi Obat",
  FOOD_ALLERGY = "Alergi Makanan",
  OLD_DISEASE = "Penyakit Lama",
  FAMILY_ILLNESS = "Penyakit Keluarga",
}

const informationClient = baseURL;

const useInformation = () => ({
  async create(
    information: InformationAnamnesis
  ): Promise<InformationAnamnesis> {
    const {data} = await informationClient.post(
      `checkup/anamnesis/support_information`,
      information
    );
    return data;
  },

  async get(idPatient: string) {
    const {data} = await informationClient.get(
      `checkup/anamnesis/support_information/patient/${idPatient}`
    );
    return data;
  },
});

export default useInformation;
