import React from 'react'
import { Link } from 'react-router-dom'
import { PreviousIconButton } from '../form/icon-button/PreviousIconButton'

export const DataQueueNumber = () => {
    return (
        <div style={{ background: '#219BD4', height: '100vh', position: 'relative' }}>
            <div>
                <h1 style={{ fontSize: '3rem', fontWeight: 'bold', textShadow: '1px 1px 10px #000000', color: 'white', textAlign: 'center', marginBottom: '5%' }}>Prakter Dokter Mandiri Pringgondani</h1>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', height: '75%' }}>
                <p style={{ fontSize: '2em', color: 'white' }}>Nomor Antrian</p>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <p style={{ fontSize: '5em', color: 'white', padding: 0, margin: 0 }}>A022</p>
                    <p style={{ fontSize: '2em', fontWeight: 'bolder', color: 'white' }}>Faris Naufal Musthofa Kamal</p>
                </div>
                <p style={{ fontSize: '2em', color: 'white', fontWeight: 'lighter' }}>Poli Umum</p>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: '1.5em', color: 'white', padding: 0, margin: 0 }}>11 November 2021</p>
                    <p style={{ fontSize: '1.4em', color: 'white' }}>09:45:30</p>
                </div>
            </div>

            <Link to="/dashboard"><PreviousIconButton style={{ width: '50px', height: '50px', background: 'white', borderRadius: '100%', position: 'absolute', right: '1%', bottom: '5%', boxShadow: '2px 2px 10px rgb(0 0 0 / 25%)', cursor: 'pointer' }} /></Link>

        </div>
    )
}
