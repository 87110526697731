import React, {useEffect, useState} from "react";
import Title from "../../layout/title";
import Card from "../../layout/card";
import {DataTable, limit} from "../../layout/dataTable/dataTable";
import Text from "antd/lib/typography/Text";
import {EnterIconButton} from "../../form/icon-button/EnterIconButton";
import {useHistory} from "react-router";
import moment from "moment";
import {PaginationData} from "../../../services/utility";
import useQueue, {Queue} from "../../../services/queue";
import {handleQueueNumberHelper} from "../../../helper";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import {triggerActiveRoom} from "../../layout/mainLayout";

export const CheckUpData: React.FC = () => {
  const pathname = (idRoom: string) =>
    window.location.pathname + `/step1/${idRoom}`;
  const history = useHistory();
  const queueService = useQueue();

  const [sizePerPage, setSizePerPage] = useState(limit[0].value);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [waitingList, setWaitingList] = useState<PaginationData<Queue[]>>({
    data: [],
    pagination: {},
  });

  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);

  const datas = [
    {
      date: "02/01/2021",
      nomor: "A001",
      service: "POLI GIGI",
      name: "Sonia Nevermind",
      phone_number: "08771137556",
      address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
      priority: "1",
      status: "Reservasi",
    },
    {
      date: "02/01/2021",
      nomor: "A002",
      service: "Poli Kecantikan",
      name: "Sonia Nevermind",
      phone_number: "08771137556",
      address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
      priority: "1",
      status: "Reservasi",
    },
    {
      date: "02/01/2021",
      nomor: "A004",
      service: "Laboratorium",
      name: "Hajime Hinata",
      phone_number: "08771137556",
      address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
      priority: "1",
      status: "Reservasi",
    },
    {
      date: "02/01/2021",
      nomor: "A007",
      service: "Poli Khusus",
      name: "Karma Akabane",
      phone_number: "08771137556",
      address: "Kp. Jepang 5/2 Ciloto Cipanas KAB. CIANJUR",
      priority: "1",
      status: "Reservasi",
    },
  ];

  const fetchQueueEffect = () => {
    queueService
      .fetch(searchQuery, "PELAYANAN", sizePerPage, page)
      // .then((res) => console.log(res))
      .then((res) => setWaitingList(res))
      .catch((err) => console.error(err));
  };

  useEffect(fetchQueueEffect, [sizePerPage, page, sizePerPage, totalPage]);

  const handleEnterButton = (idRoom: string) => {
    queueService.enableQueueRoom(idRoom).then(() => {
      queueService.getQueueRoomDetail(idRoom).then((res: any) => {
        let queueNum = handleQueueNumberHelper(
          res.queue.shift.clinic_poly.code,
          res.queue.number,
          3
        );
        let activeQueue = {
          idRoom: res.id,
          idQueue: res.queue.id,
          queueNum: queueNum,
        };
        localStorage.setItem("activeQueue", JSON.stringify(activeQueue));
        setTriggerQueueNum(!triggerQueueNum);
        history.push(pathname(idRoom));
      });
    }).catch(err => console.log(err, 'from enable queue room'));
  };

  const columns = [
    {
      name: "Tanggal",
      cell: (q: any) => (
        <Text style={{color: "#21D47E", padding: 0, margin: 0}} strong>
          {" "}
          {moment(q.created).format("DD-MM-YYYY")}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "Nomor",
      cell: (q: any) => (
        <Text strong>
          {handleQueueNumberHelper(
            q.queue.shift.clinic_poly.code,
            q.queue.number,
            3
          )}
        </Text>
      ),
      width: "5%",
    },
    {
      name: "Layanan",
      cell: (q: any) => (
        <>
          {" "}
          <Text style={{padding: 0, margin: "0 5px 0 0"}} strong>
            {q.queue.shift.clinic_poly.poly.name}
          </Text>{" "}
          <Text
            style={{color: "#21D47E", padding: 0, margin: "0 0 0 5px"}}
            strong
          >
            {q.bpjs}
          </Text>
        </>
      ),
      width: "10%",
    },
    {
      name: "Nama",
      cell: (q: any) => (
        <Link to={`patients/${1}`}>
          {" "}
          {/* Data belum dapat ditampilkan */}
          <Text strong>{q.queue.anamnesis.patient.fullname}</Text>
        </Link>
      ),
      width: "20%",
    },
    {
      name: "No Telp",
      cell: (q: any) => (
        <Text strong>
          {/* Data belum dapat ditampilkan */}
          {q.queue.anamnesis.patient.phone_number}
        </Text>
      ),
    },
    {
      name: "Alamat",
      cell: (q: any) => (
        <Text strong>
          {/* Data belum dapat ditampilkan */}
          {q.queue.anamnesis.patient.address}{" "}
          {q.queue.anamnesis.patient.village.name}{" "}
          {q.queue.anamnesis.patient.village.district.name}{" "}
          {q.queue.anamnesis.patient.village.district.city.name}
          {q.queue.anamnesis.patient.village.district.city.province.name}
        </Text>
      ),
      width: "25%",
    },
    {
      name: "Prioritas",
      cell: (q: any) => (
        <Text
          style={{
            backgroundColor: "#21D47E",
            color: "#FFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            marginTop: "10px",
          }}
          strong
        >
          {q.queue.priority ? 2 : 1}
        </Text>
      ),
      width: "5%",
    },
    {
      name: "Status",
      cell: (q: any) => (
        <Text style={{color: "#0A7BCD", marginTop: "10px"}} strong>
          {q.queue.status}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "",
      cell: (q: any) => (
        <EnterIconButton
          onClick={()=>handleEnterButton(q.id)}
          style={{cursor: "pointer"}}
        />
      ),
      width: "5%",
    },
  ];

  return (
    <>
      <Title
        title="Daftar Tunggu Pemeriksaan Dokter"
        description="list antrian daftar tunggu pasien"
      />
      <Card title="Data Pasien" search={true}>
        <DataTable
          datas={waitingList.data}
          columns={columns}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          limit={limit}
        />
      </Card>
    </>
  );
};
