import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useMedicine, { Medicine } from '../../../services/pharmacy/medicine';
import {Notification, successNotification} from "../../layout/notification";
import DataTable from 'react-data-table-component';
import {FilterComponent} from '../../layout/fiterDataTable'
import { count } from 'console';
import { GlobalStatus } from '../../../context/context';


const { confirm } = Modal

const DataMedicine: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadMedicine();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Medicine, setMedicine] = useState<PaginationData<Medicine[]>>({data: [], pagination: {}})
    const filteredItems = Medicine.data.filter(item => 
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) || 
        item.active_substance && item.active_substance.toLowerCase().includes(filterText.toLowerCase())
    );

    const [loader, setLoader] = useState<boolean>(false)
    const MedicineService = useMedicine()
    const history = useHistory()
    const params = useParams() as any

    const loadMedicine = () => {
        setLoader(true)
        MedicineService
        .fetch('', perPage, page)
        .then(Medicine => {
            setMedicine(Medicine)
            console.log(Medicine)
            let total = (Medicine.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        MedicineService
            .fetch(searching, perPage, 1)
            .then(Medicine => {
                setMedicine(Medicine)
                let total = (Medicine.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        MedicineService
            .fetch('', perPage, pages)
            .then(Medicine => {
                setMedicine(Medicine)
                let total =  (Medicine.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        MedicineService
            .fetch('', newPerPage, pages)
            .then(Medicine => {
                setMedicine(Medicine)
                let total = (Medicine.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }


    const deleteMedicine = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              setLoader(true)
              MedicineService
                  .delete(id)
                  .then(() => {
                      loadMedicine()
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
        })
    }

    useEffect(loadMedicine, [])

    const columns = [
        {
            name: <Typography.Text strong>kode</Typography.Text>,
            cell: (p: Medicine) => p.code,
            
        },
        {
            name: <Typography.Text strong>nama</Typography.Text>,
            cell: (p: Medicine) => p.name,
            
        },
       
        {
            name: <Typography.Text strong>bpjs</Typography.Text>,
            cell: (p: Medicine) => GlobalStatus(p.bpjs as any)
        },

        {
            name: <Typography.Text strong>obat racikan ?</Typography.Text>,
            cell: (p: Medicine) => GlobalStatus(p.mix as any)
        },
        {
            name: <Typography.Text strong>status</Typography.Text>,
            cell: (p: Medicine) => GlobalStatus(p.status as any)
        },
        {
            name: <Typography.Text strong>zat aktif</Typography.Text>,
            cell: (p: Medicine) => p.active_substance
        },

        {
            name: 'Aksi',
            cell: (c: Medicine) => {
                return ( 
                    <>
                        <Button type="text" title="Update" onClick={ () => history.push(`/medicine/${c.id}`) }><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={ () => deleteMedicine(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Obat & Alkes" description="data semua obat-obatan dan alat kesehatan" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }

                    <Card title="Data Obat & Alkes" addButton={true}>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        striped={true}
                        highlightOnHover={true}
                        className='data-table'
                        noHeader={true}
                        
                    />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataMedicine