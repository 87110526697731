
import { baseURL } from "../baseurl";
import { City } from "../global/cities";
import { PaginationData } from "../utility";

export interface Hospital {
    id? : string
    name: string
    email?: string
    phone: string
    status: string | number | boolean
    address: string
    city: City
}


const HospitalClient = baseURL

const useHospital = () => ({
    async create(Hospital: Hospital): Promise<Hospital>{
        const {data} = await HospitalClient.post(`/config/hospitals`, Hospital)
        return data
    },
    async update(id: string, Hospital: Hospital): Promise<Hospital>{
        await HospitalClient.patch(`/config/hospitals/${id}`, Hospital)
        return Hospital
    },
    async delete(id: string): Promise<void>{
        const {data} = await HospitalClient.delete(`/config/hospitals/${id}`)
        return data
    },
    async get(id:string): Promise<Hospital>{
        const {data} = await HospitalClient.get(`/config/hospitals/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Hospital[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await HospitalClient.get(`/config/hospitals/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useHospital