import { FormPatient } from "../../components/administration/patient/_form";
import MainLayout from "../../components/layout/mainLayout";
import HistoryVisitPage from "../../pages/administration/visit.history";
import { CheckupPage } from "../../pages/doctor/checkup";
import FourthStepPage from "../../pages/doctor/checkup/fourth.step.form";
import QueueDoctorPage from "../../pages/doctor/checkup/queue";
import ResultPage from "../../pages/doctor/checkup/result";
import FirstStepPage from "../../pages/doctor/checkup/_first.step.form";
import SecondStepPage from "../../pages/doctor/checkup/_second.step.form";
import ThirdStepPage from "../../pages/doctor/checkup/_third.step.form";
import PackagePage from "../../pages/doctor/config/package";
import PackageMedicineDetailPage from "../../pages/doctor/config/_detail";
import DashboardDoctorrPage from "../../pages/doctor/dashboard";
import VisitDoctorPage from "../../pages/doctor/visit";
import ResultVisitDoctorPage from "../../pages/doctor/visit/result";
import { RouterInterface } from "../router.interface";

export const DoctorRoute: RouterInterface[] = [
  {
    component: DashboardDoctorrPage,
    layout: MainLayout,
    path: '/',
    exact: true
  },
  {
    component: CheckupPage,
    layout: MainLayout,
    path: '/checkup',
    exact: true
  },
  {
    component: FirstStepPage,
    layout: MainLayout,
    path: '/checkup/step1/:id',
    exact: true
  },
  {
    component: SecondStepPage,
    layout: MainLayout,
    path: '/checkup/step2/:id',
    exact: true
  },
  {
    component: ThirdStepPage,
    layout: MainLayout,
    path: '/checkup/step3/:id',
    exact: true
  },
  {
    component: FourthStepPage,
    layout: MainLayout,
    path: '/checkup/step4/:id',
    exact: true
  },
  {
    component: ResultPage,
    layout: MainLayout,
    path: '/checkup/result/:id',
    exact: true
  },
  {
    component: QueueDoctorPage,
    layout: MainLayout,
    path: '/queue-list',
    exact: true
  },
  {
    component: ResultVisitDoctorPage,
    layout: MainLayout,
    path: '/visit/:id',
    exact: true
  },
  {
    component: VisitDoctorPage,
    layout: MainLayout,
    path: '/visit',
    exact: true
  },
  {
    component: HistoryVisitPage,
    layout: MainLayout,
    path: '/list-checkup',
    exact: true
  },
  {
    component: FormPatient,
    layout: MainLayout,
    path: '/patients/:id',
    exact: true
  },
  {
    component: PackageMedicineDetailPage,
    layout: MainLayout,
    path: '/package-medicine/:id',
    exact: true
  },
  {
    component: PackagePage,
    layout: MainLayout,
    path: '/package-medicine',
    exact: true
  },
]