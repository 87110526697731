
import React, { FC } from 'react'
import DataQueue from '../../components/pharmacy/queue'

const QueuePharmacy: FC = () => {
    return <div>
        <DataQueue/>
    </div>
}

export default QueuePharmacy