
import React, { FC } from 'react'
import DataChest from '../../components/pharmacy/chest'

const Chest: FC = () => {
    return <div>
        <DataChest/>
    </div>
}

export default Chest