
import { baseURL, id } from "../baseurl";
import { PaginationData } from "../utility";

export interface Group {
    inc_id? : number
    id? : string
    name: string
}


const GroupClient = baseURL
const clinicId = id

const useGroup = () => ({
    async create(Group: Group): Promise<Group>{
        const {data} = await GroupClient.post(`/medicine/group/`, Group)
        return data
    },
    async update(id: string, Group: Group): Promise<Group>{
        await GroupClient.patch(`/medicine/group/${id}`, Group)
        return Group
    },
    async delete(id: string): Promise<void>{
        const {data} = await GroupClient.delete(`/medicine/group/${id}`)
        return data
    },
    async get(id:string): Promise<Group>{
        const {data} = await GroupClient.get(`/medicine/group/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Group[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await GroupClient.get(`/medicine/group/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useGroup