import { FC } from "react";
import InventoryPharmacy from "../../components/pharmacy/invetory";

const InventoryPharmacyPage: FC = () => {
    return (
        <div>
            <InventoryPharmacy/>
        </div>
    )
}

export default InventoryPharmacyPage