import { Col, Row, Card, Typography } from "antd"
const { Title } = Typography

const DashboardCashier = () => {
    return <>
         
        <Row justify="space-around">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row style={{ marginBottom: 10}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">
                                    <Title level={5} disabled>pendaftaran hari ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>4.000</Typography.Text>
                                        <span className="mock-block"> orang</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">
                                    <Title level={5} disabled>kunjungan hari ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>4.000</Typography.Text>
                                        <span className="mock-block"> orang</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">
                                    <Title level={5} disabled>pendapatan hari ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>1.000.000</Typography.Text>
                                        <span className="mock-block"> IDR</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">

                                    <Title level={5} disabled>pendapatan bulan ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>50.000.000</Typography.Text>
                                        <span className="mock-block"> IDR</span>
                                    </div>
                                </Card>
                            </Col> 

                            
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card className="card-display" style={{ minHeight: '450px', marginBottom: 10 }}>
                            <Title level={5} disabled>Kunjungan Pasien</Title>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="card-display" style={{ marginBottom: 20, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>pasien umum</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>4.000</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Orang</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="card-display" style={{ marginBottom: 20, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>pasien bpjs</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>2.000</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Orang</span>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>pasien asuransi</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>10</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Orang</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                        <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>rata-rata waktu tunggu kasir</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>8</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Menit</span>
                                </div>
                            </div>
                        </Card>
                    </Col>


                </Row>
                
            </Col>
        </Row>
    </>
}

export default DashboardCashier