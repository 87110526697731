import { FC,  useState } from 'react'
import { Button, Col,  Form,  Input,  Modal,  Row,  Space,  Typography} from 'antd';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import useOrder from '../../../services/pharmacy/order';
import { orderDetail } from '../../../services/pharmacy/order.detail';
import ModalDetailMedicine from '../modal/_detail_medicine';
import { MedicineDetail } from '../../../services/pharmacy/medicine.detail';
import { successNotification } from '../../layout/notification';
import { DeleteIconButton } from '../../form/icon-button/DeleteIconButton';

const { Text, Link } = Typography;
const { confirm } = Modal

const ViewReleasePharmacy: FC = () => { 
    // Data Table
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [medicineModal, setMedicineModal] = useState(false)
    const orderServices = useOrder()
    const [obat, setObat] = useState<MedicineDetail>()

    const [form, setForm] = useState<orderDetail>({
        qty: 0,
        detail_medicine : {
            inc_id : 0 
        } as MedicineDetail
        
    } as any)

    const data = [
        {
            id: 1,
            barcode: 'ADFadfa12312',
            name: 'Paracetamol',
            unit: 'Box',
            qty: '4',
        }
    ]


    const columns = [
        {
            name: 'no',
            width: '10%',
            selector: (row: any) =>  <Text strong> {row.id} </Text>
        },
        {
            name: 'barcode',
            width: '30%',
           selector: (row: any) =>  <Text strong> { row.barcode} </Text>
        },
        {
            name: 'nama obat',
            width: '40%',
           selector: (row: any) =>  <Text strong> { row.name} </Text>
        },
        {
            name: 'qty pengeluaran',
            width: '20%',
            selector: (row: any) =>  <Text strong> {row.qty} {row.unit}</Text>
        }
    ]

    const onChangeObat = (m: MedicineDetail) => {
        setObat(m)

        setForm({
            ...form,
            detail_medicine : {
                inc_id : m.inc_id
            } as MedicineDetail
        })

        setMedicineModal(false)
    }

    const submit = () => {
        console.log(form)
    }

    const deleteMedicine = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              setLoader(true)
              orderServices
                  .delete(id)
                  .then(() => {
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
        })
    }

    return <div>
        <Title title="Pengeluaran Obat" description="detail pengisian pengeluaran obat" />
        <Row>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>

                <Card title="Daftar Detail Pengeluaran Obat">
                    <DataTable
                        columns={columns}
                        data={data}
                        pagination={false}
                        className="data-table"
                    />
                </Card>
                   
            </Col>
        </Row>
    </div>
}

export default ViewReleasePharmacy