
import  { FC } from 'react'
import FormHospital from '../../../components/management/hospital/_form'

const HospitalFormPage: FC = () => {
    return <div>
        <FormHospital/>
    </div>
}

export default HospitalFormPage