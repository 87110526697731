import React, { FC, useEffect, useState } from 'react'
import { Button, Col,  Row, Tabs, Typography, Table, Divider, Space, Dropdown, Menu} from 'antd';

import Card from '../../layout/card'
import Title from "../../layout/title";
import ResultProfile from '../../pharmacy/results/_profile';
import ResultPayment from './_payment';

const { Text } = Typography;
const { TabPane } = Tabs;


const DetailVisit: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)

    const columns = [
        {
          title: 'Name',
          dataIndex: 'medicine',
        },
        {
            title: 'Keterangan',
            dataIndex: 'info',
          },
        {
          title: 'Total',
          dataIndex: 'total',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
            title: 'Total Harga',
            dataIndex: 'total_price',
        },
        {
            title: '',
            dataIndex: ''
        }
      ];
      
      const data = [
        {
          key: '1',
          name: 'John Brown',
          borrow: 10,
          repayment: 33,
        }
      ];
    
    const letterButton = (
      <Menu>
        <Menu.Item key="1">Keterangan Berobat</Menu.Item>
        <Menu.Item key="2">Surat Sakit</Menu.Item>
      </Menu>
    )
  

    return <div>
        <Title title="Kunjungan Pasien" description="detail data kunjungan pasien" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                    {/* Data Pasien */}
                    <Card title="Data Pemeriksaan Pasien">
                        <Row>
                            <Typography.Title level={5} >1. Profil Pasien</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/>
                            
                            <ResultProfile/>
                         
                            <Divider dashed style={{ marginTop: '0'}}/>

                            <ResultPayment/>

                        </Row>
                    </Card>

                 
            </Col>
        </Row>
    </div>
}

export default DetailVisit