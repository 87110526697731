import {
  Col,
  Row,
  Card,
  Typography
} from "antd";
import { useState } from "react";
import { DoctorRoomModal } from "../form/modal/DoctorRoomModal";
const { Title } = Typography;

const DashboardDokter = () => {
  const [showRoom, setShowRoom] = useState(true);

  return (
    <>
      <Row justify="space-around">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Row justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row justify={"space-between"}>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card className="card-display">
                    <Title level={5} disabled>
                      kunjungan hari ini
                    </Title>
                    <div style={{ margin: 10, textAlign: "right" }}>
                      <Typography.Text style={{ fontSize: "2.4em" }} strong>
                        4000
                      </Typography.Text>
                      <span className="mock-block"> orang</span>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card className="card-display">
                    <Title level={5} disabled>
                      kunjungan bulan ini
                    </Title>
                    <div style={{ margin: 10, textAlign: "right" }}>
                      <Typography.Text style={{ fontSize: "2.4em" }} strong>
                        4000
                      </Typography.Text>
                      <span className="mock-block"> orang</span>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card className="card-display">
                    <Title level={5} disabled>
                      rata-rata waktu periksa
                    </Title>
                    <div style={{ margin: 10, textAlign: "right" }}>
                      <Typography.Text style={{ fontSize: "2.4em" }} strong>
                        8
                      </Typography.Text>
                      <span className="mock-block"> menit</span>
                    </div>
                  </Card>
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Card className="card-display">
                    <Title level={5} disabled>
                      rata-rata waktu tunggu
                    </Title>
                    <div style={{ margin: 10, textAlign: "right" }}>
                      <Typography.Text style={{ fontSize: "2.4em" }} strong>
                        10
                      </Typography.Text>
                      <span className="mock-block"> menit</span>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Card
                className="card-display"
                style={{ minHeight: "450px", marginBottom: 10 }}
              >
                <Title level={5} disabled>
                  kunjungan pasien
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <Card
                className="card-display"
                style={{ minHeight: "450px", marginBottom: 10 }}
              >
                <Title level={5} disabled>
                  penilaian dokter
                </Title>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <Card className="card-display" style={{ minHeight: "450px" }}>
                <Col span={20} offset={2}>
                  <Title level={5} disabled>
                    10 penyakit paling banyak
                  </Title>
                </Col>
                <div style={{ width: "100%" }}></div>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12}></Col>
      </Row>
      <DoctorRoomModal visible={showRoom} setVisible={setShowRoom} />
    </>
  );
};

export default DashboardDokter;
