import { Col, Row, Table, Typography } from "antd"
import { FC } from "react"
import DataTable from "react-data-table-component";

const { Text } = Typography


const ResultPayment: FC = () => {
    const customStyles = {
      rows : {
          style : {
              border: '1px solid #edf0f2'
          }
      },
      headCells: {
          style: {
              backgroundColor: '#089ED4',
              color: '#fff',
              border: '1px solid #edf0f2'

          },
      },
      cells : {
          style : {
              border: '0px solid #edf0f2'
          }
      }
  };

  // const columns = [
  //     {
  //       name: 'nama pembayaran',
  //       selector: (row: any) => <Text strong>{row.name}</Text>,
  //     }, 
  //     {
  //       name: 'qty',
  //       width: '10%',
  //       selector: (row: any) => <Text strong style={{ textAlign: 'center' }}>{row.qty}</Text>,
  //     }, 
  //     {
  //       name: 'harga satuan',
  //       selector: (row: any) => <Text strong style={{ textAlign: 'right' }}>{row.price}</Text>,
  //     }, 
  //     {
  //       name: 'total',
  //       selector: (row: any) => <Text strong style={{ textAlign: 'right' }}>{row.qty * row.price}</Text>,
  //     }
  //   ]

  const columns = [
    {
      title: 'nama pembayaran',
      dataIndex: 'name',
    },
    {
      title: 'qty',
      dataIndex: 'qty',
    },
    {
      title: 'harga satuan',
      dataIndex: 'price',
    }
  ]
  const data = [
    {
      key: '1',
      name: 'Administrasi',
      qty : 1,
      price: 15000
    },
    {
      key: '2',
      name: 'Konsultasi Dokter',
      qty : 1,
      price: 25000
    },
    {
      key: '3',
      name: 'Tindakan - Inj Ranitidine',
      qty : 2,
      price: 35000
    },
    {
      key: '4',
      name: 'Tindakan - Inj Vit C',
      qty : 1,
      price: 45000
    }
  ];

    
    return <>
         
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 10}}>
            <Table
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                  style={{ marginTop: '-4em'}}
              />
          </Col>
    </>
}

export default ResultPayment