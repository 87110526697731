import { Tooltip } from "antd";
import React from "react";
import { IconButton } from "../../../services/iconButton";

export const VisibilityIconButton: React.FC<IconButton> = ({
  onClick,
  style,
  color,
}) => {
  return (
    <Tooltip placement="topLeft" title={"Detail"}>
      <svg
        onClick={onClick}
        style={style}
        width="65"
        height="38"
        viewBox="0 0 65 38"
        fill={color ? color : "#27AAE1"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M64.1678 21.1081C58.4798 8.85909 46.0788 0.944092 32.5718 0.944092C19.0648 0.944092 6.66279 8.85909 0.973785 21.1081C0.952785 21.1521 0.946788 21.1981 0.926788 21.2421C0.868788 21.3811 0.822792 21.5221 0.780792 21.6671C0.747792 21.7811 0.714796 21.8941 0.690796 22.0081C0.664796 22.1391 0.650773 22.2721 0.635773 22.4061C0.623773 22.5331 0.612793 22.6601 0.612793 22.7871C0.612793 22.9121 0.624796 23.0361 0.634796 23.1611C0.648796 23.2971 0.661789 23.4321 0.689789 23.5661C0.710789 23.6781 0.744794 23.7891 0.776794 23.9001C0.819794 24.0471 0.866774 24.1911 0.924774 24.3331C0.943774 24.3761 0.949788 24.4211 0.969788 24.4641C2.78579 28.4011 5.30179 31.9221 8.44879 34.9291C9.22179 35.6701 10.2188 36.0381 11.2118 36.0381C12.2668 36.0381 13.3178 35.6241 14.1058 34.8021C15.6308 33.2051 15.5758 30.6731 13.9778 29.1471C12.0158 27.2721 10.3928 25.1261 9.09778 22.7741C12.7848 16.1321 19.1358 11.4051 26.4208 9.67609C21.4958 11.9961 18.0708 16.9931 18.0708 22.7881C18.0708 30.7831 24.5748 37.2881 32.5698 37.2881C40.5658 37.2881 47.0708 30.7831 47.0708 22.7881C47.0708 16.9931 43.6448 11.9951 38.7178 9.67609C46.6128 11.5491 53.4108 16.9431 56.9088 24.4771C57.5858 25.9351 59.0308 26.7931 60.5398 26.7931C61.1028 26.7931 61.6768 26.6731 62.2218 26.4201C64.2288 25.4911 65.0988 23.1121 64.1678 21.1081ZM32.5708 29.2891C28.9868 29.2891 26.0718 26.3731 26.0718 22.7891C26.0718 19.2051 28.9868 16.2891 32.5708 16.2891C36.1558 16.2891 39.0718 19.2051 39.0718 22.7891C39.0718 26.3731 36.1558 29.2891 32.5708 29.2891Z"
          fill={color ? color : "#219BD4"}
        />
      </svg>
    </Tooltip>
  );
};
