import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Row, Tag, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Icon, { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useQueue, { Queue } from '../../../services/queue';
import { GlobalStatus } from '../../../context/context';
import { Notification } from "../../layout/notification";
import DataTable from 'react-data-table-component';
import { FilterComponent, FilterComponent2 } from '../../layout/fiterDataTable'
import { NextButton } from '../../icons/nextButton';

const { Text } = Typography;


const RegisBPJS: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                loadQueue();
            }
        };

        return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Queue, setQueue] = useState<PaginationData<Queue[]>>({ data: [], pagination: {} })
    const filteredItems = Queue.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const QueueService = useQueue()
    const history = useHistory()
    const params = useParams() as any

    const loadQueue = () => {
        setLoader(true)
        QueueService
            .fetch('', '', perPage, page)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        QueueService
            .fetch(searching, '', perPage, 1)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const handlePageChange = (pages: number) => {
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', perPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', newPerPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }


    const deleteQueue = (id: string) => {
        const c = window.confirm(`Are You Sure Delete ?`)
        if (c) {
            setLoader(true)
            QueueService
                .delete(id)
                .then(() => {
                    loadQueue()
                    Notification('Hapus Data Berhasil')
                })
                .catch(e => console.log(e))
                .finally(() => setLoader(false))
        }
    }

    useEffect(loadQueue, [])

    const columnss = [
        {
            name: 'Tanggal',
            cell: (p: Queue) => p.date
        },
        {
            name: 'No',
            cell: (p: Queue) => p.nomor
        },
        {
            name: 'Layanan',
            cell: (p: Queue) => p.poly
        },

        {
            name: 'Nama',
            cell: (p: Queue) => p.name
        },


        {
            name: 'Status',
            cell: (p: Queue) => GlobalStatus(parseInt(p.status as any))
        },
        {
            name: 'No. Hp',
            cell: (p: Queue) => p.handphone
        },
        {
            name: 'Alamat',
            cell: (p: Queue) => p.address
        },

        {
            name: 'Prioritas',
            cell: (p: Queue) => p.priority
        },
        {
            name: 'Aksi',
            cell: (c: Queue) => {
                return (
                    <>
                        <Button type="text" title="Update" onClick={() => history.push(`/Queue/${c.id}`)}><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={() => deleteQueue(c.id as string)}><DeleteOutlined /></Button>
                    </>
                )
            }
        }
    ]

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            nomor: 'A2',
            bpjs: '312321312312351',
            name: 'Jajang Dadas Bengeut',
            status: 'Terintegrasi',
            address: 'Kp Warung Bawang 4/4 Kab. Cianjur, Kec. Cianjur, Ds. Sirnagalih ',
            jk: 'L'
        }
    ]


    const columns = [
        {
            name: <Text strong> # </Text>,
            width: '5%',
            selector: (row: any) => <Text strong> {row.id} </Text>
        },
        {
            name: <Text strong> tanggal </Text>,
            width: '10%',
            selector: (row: any) => <Text strong> {row.date} </Text>
        },
        {
            name: <Text strong> nomor bpjs </Text>,
            width: '15%',
            selector: (row: any) => <Text strong> <span >{row.bpjs}</span></Text>
        },
        {
            name: <Text strong> nomor </Text>,
            width: '5%',
            selector: (row: any) => <Text strong> {row.nomor} </Text>
        },
        {
            name: <Text strong> nama </Text>,
            width: '20%',
            selector: (row: any) => <Text strong> {row.name} </Text>
        },

        {
            name: <Text strong> jk </Text>,
            width: '4%',
            selector: (row: any) => <Text strong> {row.jk} </Text>
        },

        {
            name: <Text strong> status </Text>,
            width: '13%',
            selector: (row: any) => <Text strong> <Tag color="#108ee9">{row.status}</Tag></Text>
        },
        {
            name: <Text strong> alamat </Text>,
            width: '20%',
            wrap: true,
            selector: (row: any) => <Text strong> {row.address} </Text>
        },
        {
            name: '',
            width: '8%',
            selector: (row: any) => <Button type="text" size="large" title="Update" onClick={() => history.push(`/queue/${row.id}`)}><NextButton /> </Button>
        }
    ]

    return <div>
        <Title title="Pendaftaran BPJS" description="data semua pendaftaran pasien bpjs yang terekap dalam sistem" />
        <Row>

            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }

                <Card title="Data Pasien BPJS">
                    <DataTable
                        columns={columns}
                        data={data}
                        className="data-table"
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noHeader={true}
                        striped={true}
                    />
                </Card>

            </Col>
        </Row>
    </div>
}

export default RegisBPJS