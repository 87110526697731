import { Tooltip } from "antd";
import React from "react";

export const MessageMenuIcon = () => {
  return (
    <Tooltip placement="right" title={"Surat"}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#F19000"
        />
        <path
          d="M24.1716 14H20C18.1144 14 17.1716 14 16.5858 14.5858C16 15.1716 16 16.1144 16 18V28C16 29.8856 16 30.8284 16.5858 31.4142C17.1716 32 18.1144 32 20 32H26C27.8856 32 28.8284 32 29.4142 31.4142C30 30.8284 30 29.8856 30 28V19.8284C30 19.4197 30 19.2153 29.9239 19.0315C29.8478 18.8478 29.7032 18.7032 29.4142 18.4142L25.5858 14.5858C25.2968 14.2968 25.1522 14.1522 24.9685 14.0761C24.7847 14 24.5803 14 24.1716 14Z"
          stroke="white"
          stroke-width="2"
        />
        <path
          d="M20 24L26 24"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M20 28L24 28"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M24 14V18C24 18.9428 24 19.4142 24.2929 19.7071C24.5858 20 25.0572 20 26 20H30"
          stroke="white"
          stroke-width="2"
        />
      </svg>
    </Tooltip>
  );
};
