import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavExtras: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/reg-queue')}>
                    <RouterLink to="/reg-queue" key="1">Display Antrian</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/tv-display')}>
                    <RouterLink to="/tv-display" key="2" >Display TV</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}