import { baseURL } from "../baseurl";
import { PublicHealth } from "./public.health";
import { PaginationData } from "../utility";
import { Village } from "../global/village";

const phVillageClient = baseURL

export interface PHVillage 
{
    id?: string
    inc_id?: number
    public_health: PublicHealth
    village: Village
}


const usePHVillage = () => ({
    async create(PHVillage: PHVillage, publicHealth: string): Promise<PHVillage>{
        const {data} = await phVillageClient.post(`/public-healths/${publicHealth}/villages`, PHVillage)
        return data
    },
    async update(id: string, publicHealth: string, PHVillage: PHVillage): Promise<PHVillage>{
        const {data} = await phVillageClient.patch(`/public-healths/${publicHealth}/villages/${id}`, PHVillage)
        return data
    },
    async delete(id: string, publicHealth: string): Promise<void>{
        const {data} = await phVillageClient.delete(`/public-healths/${publicHealth}/villages/${id}`)
        return data
    },
    async get(id:string, publicHealth: string): Promise<PHVillage>{
        const {data} = await phVillageClient.get(`/public-healths/${publicHealth}/villages/${id}`)
        return data
    },
    async fetch(publicHealth: string, search: string, size?: number, row?: number): Promise<PaginationData<PHVillage[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const { data } = await phVillageClient.get(`/public-healths/${publicHealth}/villages/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default usePHVillage