import { FC } from "react";
import { DetailInventory } from "../../components/pharmacy/invetory/_detail";

const InventoryDetailPharmacyPage: FC = () => {
    return (
        <div>
            <DetailInventory/>
        </div>
    )
}

export default InventoryDetailPharmacyPage