import { Button, Modal, Form, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { IModal } from "./PatientMedicalRecord";
import Select, { SelectItem } from "../../form/Select";

interface IDoctorRoomModal extends IModal {}

const ROOM: SelectItem[] = [
  {
    display: "1",
    value: "1",
  },
  {
    display: "2",
    value: "2",
  },
];

export const DoctorRoomModal: React.FC<IDoctorRoomModal> = ({
  visible,
  setVisible,
}) => {
  const handleSubmit = () => {
    console.log("anda mensubmit modal ini");
  };
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="submit" className="btn-primary" onClick={handleSubmit}>
            Ya
          </Button>,
        ]}
      >
        <h3 style={{ fontWeight: "bold" }}>Pilih ruangan</h3>
        <div style={{ margin: "1%" }}>
          <Row align="middle">
            <Col sm={24} md={24} lg={24} xl={24}>
              <Select name="room" label="" items={ROOM} />
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
