
import React, { FC } from 'react'
import DataTypeDisease from '../../../components/management/type_disease'

const ICDPage: FC = () => {
    return <div>
        <DataTypeDisease />
    </div>
}

export default ICDPage