import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import {  successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import useUnit, { Unit } from '../../../services/pharmacy/units';

import { Medicine } from '../../../services/pharmacy/medicine';
import {  FilterComponent2 } from '../../layout/fiterDataTable';


const { confirm } = Modal


const DataUnit = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadUnits();
      }
    };

    return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()
  const params = useParams<any>()
  const [Units, setUnits] = useState<PaginationData<Unit[]>>({data: [], pagination: {}})
  const filteredItems = Units.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const UnitServices = useUnit()
  const [loader, setLoader] = useState<boolean>(false)

  //Form Variabel
  const [ form, setForm ] = useState<Unit>({
    name: '', 
    medicine: {
      inc_id: 0
    } as Medicine,
    fill: 0,
  })



  const resetField = () => {
    setForm({
      name: '', 
      medicine: {
        inc_id: 0
      } as Medicine,
      fill: 0,
    } as any)
  }
 
  const columns = [
    {
      name: 'Nama',
      selector: 'name',
      sortable: true,
    },
    {
      name: '',
      button: true,
      cell: (row: any) => {
          return ( 
              <>
                  <Button id="unit-edit" type="text" onClick={ () => { history.push(`/unit/${row.id}`);  window.location.reload() } }><FormOutlined /></Button>
                  <Button id="unit-delete" danger type="text" onClick={ () => deleteData(row.id) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getUnits = () => {
    setLoader(true)
    UnitServices
        .get(params.id)
        .then(form => {
            setForm(form)
            console.log(form)
        }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
}


  const loadUnits = () => {
    setLoader(true)
    UnitServices
        .fetch('', perPage, page)
        .then(Unit => {
            setUnits(Unit)
            let total = (Unit.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    UnitServices
        .fetch(searhing, perPage, 1)
        .then(Unit => {
            setUnits(Unit)
            let total = (Unit.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    UnitServices
        .fetch('', perPage, pages)
        .then(Unit => {
            setUnits(Unit)
            let total =  (Unit.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    UnitServices
        .fetch('', newPerPage, pages)
        .then(Unit => {
            setUnits(Unit)
            let total = (Unit.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      content: `Data dengan ID : ${id}`,
      onOk() {
        setLoader(true)
        UnitServices
            .delete(id)
            .then(() => {
                loadUnits()
                successNotification('hapus')
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  }

  const submit = () => {
    // console.log( !!params.id)
    // console.log(form)
          
      !!params.id === false  && UnitServices
      .create(form)
      .then(() => { 
          loadUnits() 
          successNotification('tambah')
          resetField()
      })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      !!params.id === true && UnitServices
          .update(params.id, form)
          .then(() => { 
              loadUnits() 
              successNotification('perbaharui')
              window.location.href = "/unit"
          })
          .catch(e => console.log(e))
          .finally(()=> setLoader(false))
  }


    useEffect(() => {
        loadUnits();
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getUnits()
    }, [])


  return (<>
    <Title title="Satuan" description="daftar data satuan" />
    <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="tambah satuan" >
                    <Form layout="vertical">
                        <Form.Item label="nama satuan" className='lable-form-input'>
                            <Input data-cy="name_unit" value={form.name}  onChange={({target : {value: name}}) => setForm({...form, name})} className='form-control'/>
                        </Form.Item>
                        
                    </Form>
                    <Form.Item>
                        <Button className='btn_submit' type="primary" onClick={submit}>
                            Simpan
                        </Button>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="data satuan">
                  <DataTable
                       columns={columns}
                       data={filteredItems}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       onChangeRowsPerPage={handlePerRowsChange}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                       subHeader
                       subHeaderComponent={subHeaderComponentMemo}
                       striped={true}
                       highlightOnHover={true}
                       noHeader={true}
                       className='data-table'
                  />
                </Card>
            </Col>
        </Row>
    </>
  );
};

export default DataUnit 