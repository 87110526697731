
import React, { FC } from 'react'
import DataSupplier from '../../components/pharmacy/supplier'

const Supplier: FC = () => {
    return <div>
        <DataSupplier/>
    </div>
}

export default Supplier