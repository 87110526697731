import { Col, Row, Form, Input, Select,  Button, notification } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from "../../layout/title";
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Notification, successNotification } from '../../layout/notification'
import usePartnerCompany, { PartnerCompany } from '../../../services/configurations/partner.company'
import { id as clinicId } from '../../../services/baseurl';


export interface onPartnerChangeFunction {
    (partner: PartnerCompany): void
}

export interface partnerProps {
    onPartnerChanged : onPartnerChangeFunction
}

const FormPartner: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const partnerService = usePartnerCompany()
    const history = useHistory()
    const params = useParams() as any
    const [disabled, setDisabled] = useState<boolean>(false)

    const [partner, setPartner] = useState<PartnerCompany>({
        name: '',
        email: '',
        pic: '',
        phone: '',
        address: ''
    })

    const resetField = () => {
        setPartner({
            name: '',
            email: '',
            pic: '',
            phone: '',
            address: ''
        })
    }
    
  
    const submit = () => {
        params.id == 'new' && partnerService
            .create(partner)
            .then(partner => {
                    console.log(partner)
                    successNotification('tambah')
                    resetField()
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== 'new' && partnerService
            .update(params.id, partner)
            .then(partner => {
                setPartner(partner)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadpartner = () => {
        setLoader(true)
        partnerService
            .get(params.id)
            .then(partner => setPartner({
                name: partner.name,
                email: partner.email,
                pic: partner.pic,
                phone: partner.phone,
                address: partner.address
            }))
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
       if(params.id !== 'new'){ 
           setPartner({
               ...partner, 
               id: params.id
           })
           setDisabled(true) 
       }
        params.id !== 'new' && loadpartner()
    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Perusahaan Mitra" description="form pengisian data Perusahaan" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card title="Pendaftaran Perusahaan">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="nama perusahaan" className='lable-form-input'>
                                <Input data-cy="name"  value={partner.name} onChange={({target : {value: name}}) => setPartner({...partner, name})}  className='form-control'/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="penanggungjawab" className='lable-form-input'>
                                <Input data-cy="pic" value={partner.pic}  onChange={({target : {value: pic}}) => setPartner({...partner, pic})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                    
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="email" className='lable-form-input'>
                                <Input data-cy="email" value={partner.email}  onChange={({target : {value: email}}) => setPartner({...partner, email})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="telepon" className='lable-form-input'>
                                <Input data-cy="phone" value={partner.phone}  onChange={({target : {value: phone}}) => setPartner({...partner, phone})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="alamat" className='lable-form-input'>
                                <TextArea data-cy="address"  value={partner.address}  onChange={({target : {value: address}}) => setPartner({...partner, address})} className='form-control' />
                            </Form.Item>
                        </Col>

                        <Form.Item >
                            <Button type="primary" onClick={submit} style={{  marginRight:'8px' }}>
                                Simpan
                            </Button>

                            <Button type="default" title="Kembali" onClick={ () => history.push(`/partnerships`) }>Kembali</Button> 
                        </Form.Item>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormPartner