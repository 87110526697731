import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input , Modal, Row } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import { successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import useGroup, { Group } from '../../../services/pharmacy/group';

import { id as clinicId } from '../../../services/baseurl';
import { FilterComponent2 } from '../../layout/fiterDataTable';

const { confirm } = Modal


const DataGroup = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadGroup();
      }
    };

    return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()

  const params = useParams<any>()
  const [Group, setGroup] = useState<PaginationData<Group[]>>({data: [], pagination: {}})
  const filteredItems = Group.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const Groupervices = useGroup()
  const [loader, setLoader] = useState<boolean>(false)

  //Form Variabel
  const [ form, setForm ] = useState<Group>({
    name: ''
  })

  const resetField = () => {
    setForm({
      name: ''
    } as any)
  }


 
  const columns = [
    {
      name: 'nama',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'aksi',
      button: true,
      cell: (row: any) => {
          return ( 
              <>
                  <Button id='group-edit' type="text" onClick={ () => { history.push(`/group/${row.id}`);  window.location.reload() } }><FormOutlined /></Button>
                  <Button id='group-delete' danger type="text" onClick={ () => deleteData(row.id) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getGroup = () => {
    setLoader(true)
    Groupervices
        .get(params.id)
        .then(form => {
            setForm({
              name: form.name
            })
            console.log(form)
        }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
}


  const loadGroup = () => {
    setLoader(true)
    Groupervices
        .fetch('', perPage, page)
        .then(Group => {
            setGroup(Group)
            let total = (Group.pagination.total_page as number)
            setTotalRows(total)
           
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    Groupervices
        .fetch(searhing, perPage, 1)
        .then(Group => {
            setGroup(Group)
            let total = (Group.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    Groupervices
        .fetch('', perPage, pages)
        .then(Group => {
            setGroup(Group)
            let total =  (Group.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    Groupervices
        .fetch('', newPerPage, pages)
        .then(Group => {
            setGroup(Group)
            let total = (Group.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      content: `Data dengan ID : ${id}`,
      onOk() {
        setLoader(true)
        Groupervices
            .delete(id)
            .then(() => {
                loadGroup()
                successNotification('hapus')
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  }

  const submit = () => {
    // console.log( !!params.id)
    // console.log(form)
          
      !!params.id === false  && Groupervices
      .create(form)
      .then(() => { 
          loadGroup() 
          successNotification('tambah')
          resetField()
      })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      !!params.id === true && Groupervices
          .update(params.id, form)
          .then(() => { 
              loadGroup() 
              successNotification('perbaharui')
              resetField()
              window.location.href = "/group"
          })
          .catch(e => console.log(e))
          .finally(()=> {
            setLoader(false)
          })
  }


    useEffect(() => {
        loadGroup();
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getGroup()
    }, [])


  return (<>
    <Title title="Golongan" description="daftar data golongan" />
    <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="tambah golongan" >
                    <Form layout="vertical" id="form-input">
                        <Form.Item label="nama" className='lable-form-input'>
                            <Input data-cy="name_group" value={form.name}  onChange={({target : {value: name}}) => setForm({...form, name})} className='form-control'/>
                        </Form.Item>
                    </Form>
                    <Form.Item>
                        <Button className='btn_submit' type="primary" onClick={submit}> Simpan</Button>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="data golongan">
                  <DataTable
                       columns={columns}
                       data={filteredItems}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       onChangeRowsPerPage={handlePerRowsChange}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                       subHeader
                       subHeaderComponent={subHeaderComponentMemo}
                       striped={true}
                       highlightOnHover={true}
                       noHeader={true}
                       className='data-table'
                  />
                </Card>
            </Col>
        </Row>
    </>
  );
};

export default DataGroup 