import { Col, Row, Form, Button, Steps, Input, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from "../../layout/title";
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Notification, successNotification } from '../../layout/notification'
import Select, { SelectItem } from '../../layout/form/select';
import _, { values } from 'lodash'
import useMedicine, { Medicine } from '../../../services/pharmacy/medicine';
import useCategory from '../../../services/pharmacy/category';
import useGroup from '../../../services/pharmacy/group';


const {Step} = Steps

const FormMedicine: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const medicineServices = useMedicine()
    const history = useHistory()
    const params = useParams() as any
    const [disabled, setDisabled] = useState<boolean>(false)

    const [selectedStatus, setSelectedStatus] = useState("AKTIF");

    const [selectedMix, setSelectedMix] = useState("TIDAK");
    const [selectedBPJS, setSelectedBPJS] = useState("TIDAK");

   
    const [medicine, setMedicine] = useState<Medicine>({
            code : '',
            barcode: '',
            name: '',
            category: {
                id: ''
            } as any,
            group: {
                id: ''
            } as any,
            mix: false,
            bpjs: false,
            status: true,
            active_substance: '',
        } as any
    )

    const STATUSOPTION1: SelectItem[] = [
        {
            display: "AKTIF",
            value: "true"
        },
        {
            display: "TIDAK AKTIF",
            value: "false"
        }
    ]


    const STATUSOPTION2: SelectItem[] = [
        {
            display: "YA",
            value: "true"
        },
        {
            display: "TIDAK",
            value: "false"
        }
    ]


    const [category, setCategory] = useState<SelectItem[]>([])
    const categoryService = useCategory()

    const getCategory = async (value: string) => {
        const category:SelectItem[] = []
        const result = await categoryService.fetch(value).then(res => res.data)
        
        await Promise.all(_.map(result, async (val) => 
            await category.push({
                display: val.name,
                value: val.id as any
            })

        ));

        setCategory(category)
    }

    const [group, setGroup] = useState<SelectItem[]>([])
    const groupService = useGroup()

    const getGroup = async (value: string) => {
        const group:SelectItem[] = []
        const result = await groupService.fetch(value).then(res => res.data)

        console.log(result)
        
        await Promise.all(_.map(result, async (val) => 
            await group.push({
                display: val.name,
                value: val.id as any
            })
        ));

        setGroup(group)
    }
  
    const submit = () => {
        console.log(medicine)
        
       
        params.id == "new" && medicineServices
            .create(medicine)
            .then(medicine => {
                    console.log(medicine)
                    successNotification('tambahkan')
                    
                    history.push(`/medicine/${medicine.id}/detail`)
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== "new" && medicineServices
            .update(params.id, medicine)
            .then(medicine => {
                console.log(medicine)
                setMedicine(medicine)
                successNotification('perbarui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadmedicine = () => {
        setLoader(true)
        medicineServices
            .get(params.id)
            .then(medicine => {
                console.log(medicine)
                setMedicine({
                    code : medicine.code,
                    barcode: medicine.barcode,
                    name: medicine.name,
                    category: {
                        id: medicine.category.id
                    } as any,
                    group: {
                        id: medicine.group.id
                    } as any,
                    mix: medicine.mix,
                    bpjs: medicine.bpjs,
                    status: medicine.status,
                    active_substance: medicine.active_substance,
                })
               
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

 
    useEffect(() => {
       if(params.id !== 'new'){ 
           setMedicine({
               ...medicine, 
               id: params.id
           })
           setDisabled(true) 
           loadmedicine()
        }
       

    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Obat" description="form pengisian data medicine" />
        <Row>

            <Col xs={12} sm={12} md={12} lg={8} xl={8} offset={14} style={{ marginBottom: 40}}>
            <Steps>
                <Step status="process" key={1} title={"Pendataan Obat"} />
                <Step status="process" key={2} title={"Detail Obat"} />
            </Steps>
            </Col>
        </Row>
        <Card>
           
        
       
            <div className="steps-content" style={{ marginTop: 20}}>
            
                <Form layout="vertical">
                    <Row>
                        <Col xs={18} sm={18} md={18} lg={18} xl={18} offset={3}>
                            
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row>
                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                        <Form.Item label="kode" className="lable-form-input">
                                            <Input  value={medicine.code}  onChange={({target : {value: code}}) => setMedicine({...medicine, code})} className="form-control" disabled={disabled} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={2} lg={2} xl={2}></Col>
                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                        <Form.Item label="barcode" className="lable-form-input">
                                            <Input  value={medicine.barcode} onChange={({target : {value: barcode}}) => setMedicine({...medicine, barcode})} className="form-control" disabled={disabled}/>
                                        </Form.Item>
                                    </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item label="nama" className="lable-form-input">
                                        <Input  value={medicine.name} onChange={({target : {value: name}}) => setMedicine({...medicine, name})} className="form-control"  />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row>
                                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                            <Select name="category" label="kategori obat"  onValueChange={(category) => { setMedicine({...medicine, category:{ id: category} as any})}} 
                                            items={ category } showSearch filterOption={false} onSearch={ getCategory } />

                                        </Col>

                                        <Col xs={24} sm={24} md={2} lg={2} xl={2}>
                                        
                                        </Col>

                                        <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                        <Select name="group" label="golongan obat"  onValueChange={(group) => { setMedicine({...medicine, group:{ id: group} as any})}} 
                                            items={ group } showSearch filterOption={false} onSearch={ getGroup } />

                                        </Col>

                                    </Row>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Row>
                                        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                            <Select 
                                                name='mix'
                                                label="termasuk racikan?"
                                                items={STATUSOPTION2}
                                                value={selectedMix}
                                                onValueChange={(v) => setSelectedMix(v as string)}
                                            />
                                        </Col>

                                        <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                        
                                        </Col>

                                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                            <Select 
                                                name="status" 
                                                label="status obat"
                                                items={STATUSOPTION1}
                                                value={selectedStatus}
                                                onValueChange={(v) => setSelectedStatus(v as string)}/>
                                        </Col>

                                        <Col xs={24} sm={24} md={1} lg={1} xl={1}>
                                        
                                        </Col>

                                        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                                            <Select 
                                                name="bpjs" 
                                                label="obat bpjs?"
                                                items={STATUSOPTION2}
                                                value={selectedBPJS}
                                                onValueChange={(v) => setSelectedBPJS(v as string)}/>
                                        </Col>

                                    </Row>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Form.Item label="komposisi / zat aktif" style={{ fontWeight: 'bold'}}>
                                        <TextArea rows={6}  value={medicine.active_substance} onChange={({target : {value: active_substance}}) => setMedicine({...medicine, active_substance})} className="form-control"  />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                    <Space>
                                        <Button type="default" block  title="Kembali" onClick={ () => history.push(`/medicine`) }>Kembali</Button> 
                                       
                                        <Button type="primary"  block onClick={submit}>
                                            Selanjutnya
                                        </Button>
                                    </Space>
                                </Col>
                            
                        </Col>

                    </Row>
                </Form>
            </div>
        </Card> 

        </>
    )
}

export default FormMedicine