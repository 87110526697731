import { notification, Spin } from "antd";
import { debounce } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import useClinic, { Clinic } from "../../../services/configuration/clinic";
import usePoly, { Poly } from "../../../services/configuration/poly";
import useQueue, { Queue } from "../../../services/queue";
import { PaginationData } from "../../../services/utility";
import styles from "./index.module.css";

export const DataRegQueue: React.FC = () => {
  const [polies, setPolies] = useState<PaginationData<Poly[]>>({
    data: [],
    pagination: {},
  });
  const card = useRef<any>();
  const [availablePolies, setavailablePolies] = useState<any>();
  const [clinics, setClinics] = useState<Clinic>();
  // const [dataQueue, setDataQueue] = useState<any>()
  const [isLoading, setIsLoading] = useState(false);

  const polyService = usePoly();
  const clinicService = useClinic();
  const queueService = useQueue();

  const createQueue = debounce(async (clinicId: string | number) => {
    const dataQueue: Queue = {
      clinic_poly: clinicId as string,
      priority: false,
      status: "OFFLINE",
      bookdated: moment().format("YYYY-MM-DD HH:mm:ss"),
      // bookdated: "2021-12-08 09:50:00",
    };

    await queueService
      .create(dataQueue)
      .then()
      .catch((err) => console.log(err));
  }, 1000);

  useEffect(() => {
    clinicService
      .get()
      .then((res) => setClinics(res))
      .catch((err) => console.error(err.response, "Clinic gagal di get"))
      .finally(() => setIsLoading(false));

    setIsLoading(true);

    polyService
      .fetch("", "", "")
      .then((res) => setPolies(res))
      .catch((err) => console.error(err, "Poly gagal di get"))
      .finally(() => setIsLoading(false));
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      filterDulu(polies.data);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [polies]);

  const filterDulu = (poli: Poly[]) => {
    console.log(poli)
    const data = (
      <div className={styles.layout__content}>
        {poli.map((p) => {
          const isInTime = p.shift.filter((shft) => {
            const [startHours, startMinutes, startSeconds] =
              shft.startTime.split(":");

            const [endHours, endMinutes, endSeconds] = shft.endTime.split(":");

            return moment().isBetween(
              moment()
                .set("hours", Number(startHours))
                .set("minutes", Number(startMinutes))
                .set("seconds", Number(startSeconds)),
              moment()
                .set("hours", Number(endHours))
                .set("minutes", Number(endMinutes))
                .set("seconds", Number(endSeconds))
            );
          });
          if (isInTime.length)
            return (
              <div
                key={p.id}
                className={styles.layout__content__poly}
                onClick={() => createQueue(p?.id!)}
              >
                {p.poly!.name}
              </div>
            );
        })}
      </div>
    );
    setavailablePolies(data);
  };

  return (
    <Spin size="large" spinning={isLoading}>
      <div className={styles.layout}>
        <h1
          style={{
            fontSize: "3rem",
            fontWeight: "bold",
            textShadow: "2px 2px 4px #000000",
            color: "white",
            textAlign: "center",
            marginBottom: "5%",
            margin: 0,
            padding: 0,
          }}
        >
          {clinics?.name}
        </h1>
        {availablePolies}
      </div>
      <div ref={card}></div>
    </Spin>
  );
};
