
import React, { FC } from 'react'
import DataCategory from '../../components/pharmacy/category'

const Category: FC = () => {
    return <div>
        <DataCategory/>
    </div>
}

export default Category