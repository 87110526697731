import {Modal, Tag, Typography} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import DataTable from "react-data-table-component";
import useCheckup, {
  IDirectMedicine,
  ISelectedMedicine,
} from "../../../services/doctor/checkup";
import {PaginationData} from "../../../services/utility";
import {FilterComponent} from "../../layout/fiterDataTable";
import {AddIconButton} from "../icon-button/AddIconButton";
import {IModal} from "./PatientMedicalRecord";

interface IListMedicine extends IModal {
  setSelectedMedicine: React.Dispatch<
    React.SetStateAction<ISelectedMedicine | undefined>
  >;
}

// const checkupService = useCheckup();

export const ListMedicineModal: React.FC<IListMedicine> = ({
  visible,
  setVisible,
  setSelectedMedicine,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (searchQuery) {
        setResetPaginationToggle(!resetPaginationToggle);
        setSearchQuery("");
        loadMedicines();
      }
    };
    return (
      <FilterComponent
        onFilter={(e: any) => filterData(e.target.value)}
        onClear={handleClear}
        filterText={searchQuery}
      />
    );
  }, [searchQuery, resetPaginationToggle]);
  const [medicines, setMedicines] = useState<
    PaginationData<ISelectedMedicine[]>
  >({
    data: [],
    pagination: {},
  });

  const filteredData = medicines.data.filter((medicine) => {
    (medicine.medicine.name &&
      medicine.medicine.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())) ||
      (medicine.medicine.active_substance &&
        medicine.medicine.active_substance
          .toLowerCase()
          .includes(searchQuery.toLowerCase()));
    // (medicine.medicine.group.name &&
    //   medicine.medicine.group.name
    //     .toLowerCase()
    //     .includes(searchQuery.toLowerCase())) ||
    // (medicine.medicine.category.name &&
    //   medicine.category.name
    //     .toLowerCase()
    //     .includes(searchQuery.toLowerCase()));
  });

  const checkupService = useCheckup();

  const loadMedicines = () => {
    setIsLoading(true);
    checkupService
      .fetchMedicine(searchQuery, sizePerPage, currentPage)
      .then((res) => {
        setMedicines(res);
        setTotalPage(res.pagination.total_page as number);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const filterData = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    loadMedicines();
  };

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    loadMedicines();
  };

  const handlePerRowsChange = (size: number, pages: number) => {
    setSizePerPage(size);
    setCurrentPage(pages);
    loadMedicines();
  };

  useEffect(loadMedicines, []);

  const onAddIconButtonClick = (id: string) => {
    setSelectedMedicine(medicines.data.find((medicine) => medicine.id === id));
    setVisible(false);
  };

  const columns = [
    {
      name: <Typography.Text strong>Nama Obat</Typography.Text>,
      cell: (medicine: ISelectedMedicine) => medicine.medicine.name,
      sortable: true,
    },
    {
      name: <Typography.Text strong>Golongan</Typography.Text>,
      // cell: (medicine: ISelectedMedicine) => medicine.medicine.group.name,
      cell: (medicine: ISelectedMedicine) => (
        <p>Data belum dapat ditampilkan...</p>
      ),
      sortable: true,
    },
    {
      name: <Typography.Text strong>Zat Aktif</Typography.Text>,
      cell: (medicine: ISelectedMedicine) => medicine.medicine.active_substance,
      sortable: true,
    },
    {
      name: <Typography.Text strong>Kategori</Typography.Text>,
      // cell: (medicine: ISelectedMedicine) => medicine.medicine.category.name,
      cell: (medicine: ISelectedMedicine) => (
        <p>Data belum dapat ditampilkan...</p>
      ),
      sortable: true,
    },
    {
      name: <Typography.Text strong>BPJS</Typography.Text>,
      cell: (medicine: ISelectedMedicine) =>
        medicine.medicine.bpjs ? (
          <Tag color="green">OBAT BPJS</Tag>
        ) : (
          <Tag color="blue">OBAT UMUM</Tag>
        ),
      sortable: true,
    },
    {
      name: <Typography.Text strong>Stok</Typography.Text>,
      cell: (medicine: ISelectedMedicine) => (
        <p>Data belum dapat ditampilkan...</p>
      ),
      sortable: true,
    },
    {
      name: <Typography.Text strong>Harga</Typography.Text>,
      cell: (medicine: ISelectedMedicine) => (
        <p>Data belum dapat ditampilkan...</p>
      ),
      sortable: true,
    },
    {
      name: <Typography.Text strong></Typography.Text>,
      cell: (medicine: ISelectedMedicine) => (
        <AddIconButton onClick={() => onAddIconButtonClick(medicine.id)} />
      ),
      sortable: false,
    },
  ];
  return (
    <Modal
      title="Daftar List Obat"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
    >
      {isLoading && <div>Loading...</div>}
      <DataTable
        columns={columns}
        data={medicines.data}
        pagination
        paginationServer
        paginationTotalRows={totalPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        striped={true}
        highlightOnHover={true}
        noHeader={true}
        className="data-table"
      />
    </Modal>
  );
};
