import { Col, Row, Form,  Button, Input, Space, Select as SelectTheme } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Title from "../../layout/title";
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { successNotification } from '../../layout/notification'
import useSupplier, {StatusOption, Supplier} from '../../../services/pharmacy/supplier';
import Card from '../../layout/card';
import Select, { SelectItem } from '../../layout/select';

const FormSupplier: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const SupplierService = useSupplier()
    const history = useHistory()
    const params = useParams() as any
    const [selectedStatus, setSelectedStatus] = useState("AKTIF");

    const STATUS: SelectItem[] = [
        {
            display: "AKTIF",
            value: "true"
        },
        {
            display: "TIDAK AKTIF",
            value: "false"
        }
    ]

    const [supplier, setSupplier] = useState<Supplier>(
        {
            code: '',
            name: '',
            phone: '',
            pic: '',
            pic_phone: '',
            address: '',
            top: 1,
            status: true
        } as any
    )

  
    const submit = () => {
        
        console.log(supplier)
        params.id == 'new' && SupplierService
            .create(supplier)
            .then(Supplier => {
                    console.log(Supplier)
                    successNotification('tambah')
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))

        params.id !== 'new' && SupplierService
            .update(params.id, supplier)
            .then(Supplier => {
                setSupplier(Supplier)
                successNotification('edit')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadSupplier = () => {
        setLoader(true)
        SupplierService
            .get(params.id)
            .then(async supplier => {
                setSupplier({
                    ...supplier,
                    code: supplier.code,
                    name: supplier.name,
                    phone: supplier.phone,
                    pic: supplier.pic,
                    pic_phone: supplier.pic_phone,
                    address: supplier.address,
                    top: supplier.top,
                    status: supplier.status
                })
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
       if(params.id !== 'new'){ 
           
           setSupplier({
               ...supplier, 
           }) 

       }
        params.id !== 'new' && loadSupplier()

    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Supplier/PBF" description="form pengisian data supplier/pbf" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card title="tambah supplier/pbf" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="kode supplier/pbf" className='lable-form-input'>
                                <Input  data-cy="code"  value={supplier.code} onChange={({target : {value: code}}) => setSupplier({...supplier, code})} className="form-control" />
                            </Form.Item>
                            <Form.Item label="nama supplier/pbf" className='lable-form-input'>
                                <Input  data-cy="name"  value={supplier.name} onChange={({target : {value: name}}) => setSupplier({...supplier, name})} className="form-control" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="penanggung jawab (pic)"  className="lable-form-input">
                                <Input  data-cy="pic" value={supplier.pic}  onChange={({target : {value: pic}}) => setSupplier({...supplier, pic})} className="form-control" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="telepon pic" className="lable-form-input">
                                <Input  data-cy="pic_phone" value={supplier.pic_phone}  onChange={({target : {value: pic_phone}}) => setSupplier({...supplier, pic_phone})} className="form-control" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="telepon perusahaan" className="lable-form-input">
                                <Input  data-cy="phone" value={supplier.phone}  onChange={({target : {value: phone}}) => setSupplier({...supplier, phone})} className="form-control" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="term of payment (top)" className="lable-form-input">
                                <Input  data-cy="top" type={'number'} value={supplier.top}  onChange={({target : {value: top}}) => setSupplier({...supplier, top} as any)} className="form-control" addonAfter="hari"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Select  
                                name="status"
                                label="Status"
                                items={STATUS}
                                value={selectedStatus}
                                onValueChange={(v) => setSelectedStatus(v as string)}
                            />

                        </Col>
 
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="alamat" className="lable-form-input">
                                <TextArea data-cy='address' value={supplier.address}  onChange={({target : {value: address}}) => setSupplier({...supplier, address})} className="form-control" rows={4}/>
                            </Form.Item>
                        </Col>

                        <Form.Item>
                            <Space>
                                <Button className='btn_submit' type="primary" onClick={submit}>
                                    Simpan
                                </Button>

                                <Button type="default" title="Kembali" onClick={ () => history.push(`/supplier`) }>Kembali</Button> 
                            </Space>
                        </Form.Item>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormSupplier