import React, { FC } from 'react'
import { Input as InputForm, Form } from 'antd'

export interface OnInputValueChange {
    (value: string): void
}

export interface IStyles {
    name: string
}
export interface InputProps {
    name: string
    label: string
    value?: string
    onValueChange?: OnInputValueChange
    className?: string
    placeholder?: string
    onClick?: any
    disabled?: boolean
}

const FloatingTextInput: FC<InputProps> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onValueChange && props.onValueChange(e.target.value)
    }

    return (
        <Form.Item label={props.label} name={props.name} style={{ fontWeight: 'bold', fontSize: '1em', marginBottom: 10 }}>
            <InputForm onClick={props.onClick} name={props.name} disabled={!props.disabled ? false : true} placeholder={props.placeholder} style={{ border: `${props.disabled ? '1px solid #d9d9d9' : ''}`, borderRadius: 7, boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)' }} value={props.value} onChange={onChange} className={props.className} />
        </Form.Item>
    )
}

export default FloatingTextInput