
import { baseURL } from "../baseurl";
import { District } from "./district";
import { PaginationData } from "../utility";

export interface Village {
  id: string;
  name: string;
  district: District;
}

const VillageClient = baseURL

const useVillage = () => ({
    async create(Village: Village): Promise<Village> {
        const { data } = await VillageClient.post(`/config/locations/village`, Village)
        return data
    },
    async update(id: string, Village: Village): Promise<Village> {
        const { data } = await VillageClient.patch(`/config/locations/village/${id}`, Village)
        return data
    },
    async delete(id: string): Promise<void> {
        const { data } = await VillageClient.delete(`/config/locations/village/${id}`)
        return data
    },
    async get(id: string): Promise<Village> {
        const { data } = await VillageClient.get(`/config/locations/village/${id}`)
        return data
    },
    async fetch(search: string, districtId?: number | string, size?: number, row?: number): Promise<PaginationData<Village[]>> {
      let searching = search ? `name=${search}` : "";
      let district = districtId ? `districtId=${districtId}` : "";
      let sizes = size ? `size=${size}` : ''
      let rows = row ? `page=${row}` : ''
      const { data } = await VillageClient.get(
        `/config/locations/village/?${searching}&${district}&${sizes}&${rows}`
      );
      return data;
    }
})

export default useVillage;
