import React from 'react'
import { DataPatient } from '../../../components/administration/patient'

export const Patient: React.FC = () => {
    return (
        <div>
            <DataPatient />
        </div>
    )
}
