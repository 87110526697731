import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavConfigGlobalClinic: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/icd')}>
                    <RouterLink to="/icd" key="1" >ICD 10</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/bridging-pcare')}>
                    <RouterLink to="/bridging-pcare" key="2" >Bridging Pcare</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/insurance')}>
                    <RouterLink to="/insurance" key="3" >Asuransi</RouterLink>
                </Menu.Item>
                <Menu.Item key="4" disabled={location.pathname.includes('/public-healts')}>
                    <RouterLink to="/public-health" key="4" >Puskesmas</RouterLink>
                </Menu.Item>
                <Menu.Item key="5" disabled={location.pathname.includes('/partnerships')}>
                    <RouterLink to="/partnerships" key="5" >Mitra Klinik</RouterLink>
                </Menu.Item>
                <Menu.Item key="6" disabled={location.pathname.includes('/prb')}>
                    <RouterLink to="/prb" key="6" >PRB</RouterLink>
                </Menu.Item>
                <Menu.Item key="7" disabled={location.pathname.includes('/hospital')}>
                    <RouterLink to="/hospital" key="7" >Rumah Sakit</RouterLink>
                </Menu.Item>
                <Menu.Item key="8" disabled={location.pathname.includes('/location')}>
                    <RouterLink to="/location" key="8" >Lokasi</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}