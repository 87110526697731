import React from 'react'
import { useHistory } from 'react-router'
import { NavLink } from 'react-router-dom'

export interface ISidebar {
    id?: string
    icon?: JSX.Element
    title?: string
    path: string
    exact?: boolean
}
export const MobileSidebar: React.FC<ISidebar> = ({ id, icon, title, path }) => {
    return (
        <NavLink to={path} className="data_sidebar_mobile_container" activeClassName="data_sidebar_mobile_container_active">
            <div onClick={() => console.log('kamu klik menu dingan id ', id)} style={{ display: 'flex', alignItems: 'center', margin: '5%', cursor: 'pointer' }}>
                {icon}
                <strong style={{ paddingLeft: '10%' }}>{title}</strong>
            </div>
        </NavLink>
    )
}
