import { baseURL } from "../baseurl";
import { District } from "../global/district";
import { PaginationData } from "../utility";

const publicHealthClient = baseURL

export interface PublicHealth 
{
    id?: string
    name: string
    email: string
    phone: string
    pic: string
    district: District
    address: string
}


const usePublicHealth = () => ({
    async create(PublicHealth: PublicHealth): Promise<PublicHealth>{
        const {data} = await publicHealthClient.post(`/config/public-healths`, PublicHealth)
        return data
    },
    async update(id: string, PublicHealth: PublicHealth): Promise<PublicHealth>{
        const {data} = await publicHealthClient.patch(`/config/public-healths/${id}`, PublicHealth)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await publicHealthClient.delete(`/config/public-healths/${id}`)
        return data
    },
    async get(id:string): Promise<PublicHealth>{
        const {data} = await publicHealthClient.get(`/config/public-healths/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<PublicHealth[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const { data } = await publicHealthClient.get(`/config/public-healths/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default usePublicHealth