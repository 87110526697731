import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import Title from "../../layout/title";
import Card from "../../layout/card";
import { PaginationData } from "../../../services/utility";
import { Notification, successNotification } from "../../layout/notification";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import DataTable from "react-data-table-component";

import { id as clinicId } from "../../../services/baseurl";
import useLocation, { Location } from "../../../services/pharmacy/location";
import useCity from "../../../services/global/cities";
import Select, { SelectItem } from "../../layout/form/select";
import _ from "lodash";
import { FilterComponent2 } from "../../layout/fiterDataTable";

const { confirm } = Modal;
const { TextArea } = Input;

const DataLocation = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
        loadLocation();
      }
    };

    return (
      <FilterComponent2
        onFilter={(e: any) => filterData(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  //Global Variable
  const history = useHistory();

  const params = useParams<any>();
  const [Location, setLocation] = useState<PaginationData<Location[]>>({
    data: [],
    pagination: {},
  });
  const filteredItems = Location.data.filter(
    (item) =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );
  const LocationServices = useLocation();
  const [loader, setLoader] = useState<boolean>(false);

  //Form Variabel
  const [form, setForm] = useState<Location>({
    name: "",
    info: ""
  });

  const resetField = () => {
    setForm({
      name: "",
      info: ""
    } as any);
  };

  const columns = [
    {
      name: "name",
      selector: "name",
      sortable: true,
      cell: (p:Location) => p.name
    },
    {
      name: "info",
      cell: (p: Location) => p.info,
      sortable: true,
    },
    {
      name: "",
      button: true,
      cell: (row: any) => {
        return (
          <>
            <Button
              id="location-edit"
              type="text"
              onClick={() => {
                history.push(`/location/${row.id}`);
                window.location.reload();
              }}
            >
              <FormOutlined />
            </Button>
            <Button id="location-delete" danger type="text" onClick={() => deleteData(row.id)} >
              <DeleteOutlined />
            </Button>
          </>
        );
      },
    },
  ];

  const getLocation = () => {
    setLoader(true);
    LocationServices.get(params.id)
      .then((form) => {
        setForm({
          name: form.name,
          info: form.info
        });
        console.log(form);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const loadLocation = () => {
    setLoader(true);
    LocationServices.fetch("", perPage, page)
      .then((Location) => {
        setLocation(Location);
        let total = Location.pagination.total_page as number;
        setTotalRows(total);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const filterData = (searhing: string) => {
    setLoader(false);
    setFilterText(searhing);
    LocationServices.fetch(searhing, perPage, 1)
      .then((Location) => {
        setLocation(Location);
        let total = Location.pagination.total_page as number;
        setTotalRows(total);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const handlePageChange = (pages: number) => {
    setPage(pages);
    setLoader(true);
    LocationServices.fetch("", perPage, pages)
      .then((Location) => {
        setLocation(Location);
        let total = Location.pagination.total_page as number;
        setTotalRows(total);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage);
    setPage(pages);
    setLoader(true);
    LocationServices.fetch("", newPerPage, pages)
      .then((Location) => {
        setLocation(Location);
        let total = Location.pagination.total_page as number;
        setTotalRows(total);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  const deleteData = (id: string) => {
    confirm({
      title: "Serius Ingin Hapus Data ini?",
      icon: <ExclamationCircleOutlined />,
      content: `Data dengan ID : ${id}`,
      onOk() {
        setLoader(true);
        LocationServices.delete(id)
          .then(() => {
            loadLocation();
            successNotification("hapus");
          })
          .catch((e) => console.log(e))
          .finally(() => setLoader(false));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };


  const submit = () => {
    // console.log( !!params.id)
    console.log(form);

    !!params.id === false &&
      LocationServices.create(form)
        .then(() => {
          loadLocation();
          successNotification("tambah");
          resetField();
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(false));

    !!params.id === true &&
      LocationServices.update(params.id, form)
        .then(() => {
          loadLocation();
          successNotification("perbaharui");
          window.location.href = "/location";
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(false));
  };

  useEffect(() => {
    loadLocation();
    !!params.id === true && setForm(form);
    !!params.id === true && getLocation();
  }, []);

  return (
    <>
      <Title
        title="Lokasi Obat"
        description="daftar data lokasi obat (gudang / apotek)"
      />
      <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Card title="tambah lokasi">
            <Form layout="vertical">
              <Form.Item label="nama lokasi" className="lable-form-input">
                <Input
                  data-cy="location_name"
                  value={form.name}
                  onChange={({ target: { value: name } }) =>
                    setForm({ ...form, name })
                  }
                  className="form-control"
                />
              </Form.Item>

              <Form.Item label="info" className="lable-form-input">
                <TextArea
                  data-cy="location_info"
                  value={form.info}
                  onChange={({ target: { value: info } }) =>
                    setForm({ ...form, info })
                  }
                  className="form-control"
                />
              </Form.Item>
            </Form>
            <Form.Item>
              <Button
                type="primary"
                onClick={submit}
                data-cy="btn-submit"
                style={{ marginRight: "8px" }}
              >
                Simpan
              </Button>
            </Form.Item>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1}></Col>
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
          {loader && <div>Loading .... </div>}
          <Card title="data lokasi">
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
              subHeader
              subHeaderComponent={subHeaderComponentMemo}
              striped={true}
              noHeader={true}
              highlightOnHover={true}
              className="data-table"
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DataLocation;
