import { Tooltip } from "antd";
import React from "react";
import { IconButton } from "../../../services/iconButton";

export const DeleteIconButton: React.FC<IconButton> = ({
  onClick,
  style,
  color,
}) => {
  return (
    <Tooltip placement="topLeft" title={"Hapus"}>
      <svg
        onClick={onClick}
        style={style}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill={color ? color : "transparent"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.0001 21L21 16M20.9999 21L16 16"
          stroke="#CD160A"
          strokeWidth="2.5"
        />
        <path
          d="M21 13V10C21 7.17157 21 5.75736 20.1213 4.87868C19.2426 4 17.8284 4 15 4H9C6.17157 4 4.75736 4 3.87868 4.87868C3 5.75736 3 7.17157 3 10V14C3 16.8284 3 18.2426 3.87868 19.1213C4.75736 20 6.17157 20 9 20H13"
          stroke="#CD160A"
          strokeWidth="2.5"
        />
        <path
          d="M8 4V4C8.73962 4 9.10944 4 9.43492 4.11591C9.7015 4.21085 9.9449 4.36128 10.149 4.55726C10.3983 4.79654 10.5637 5.12731 10.8944 5.78885L11.8944 7.78885C12.4323 8.86469 12.7013 9.4026 13.1846 9.7013C13.6679 10 14.2693 10 15.4721 10H18C18.9319 10 19.3978 10 19.7654 10.1522C20.2554 10.3552 20.6448 10.7446 20.8478 11.2346C21 11.6022 21 12.0681 21 13V13"
          stroke="#CD160A"
          strokeWidth="2.5"
        />
      </svg>
    </Tooltip>
  );
};
