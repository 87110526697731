import {  Modal, notification } from 'antd'
import { CheckOutlined } from '@ant-design/icons';

export const Notification = (type: string) => {
    notification.open({
        message: type,
        icon: <CheckOutlined style={{ color: '#49E379' }} />
      });
}


export const successNotification = (content: string) =>  {
  Modal.success({
    content: `Data berhasil di ${content} !`,
  });
  setTimeout(()=>{
    Modal.destroyAll()
  }, 1000)

}
