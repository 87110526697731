import { Button, Divider, Dropdown, Menu, Row, Space, Typography } from 'antd'
import React from 'react'
import {  NavLink, Route,  useHistory } from 'react-router-dom'
import { EditButton } from '../../form/EditButton'
import Card from '../../layout/card'
import ResultDiagnosis from '../result/diagnosis'
import ResultFirstCheck from '../result/first_check'
import ResultPhysicalCheck from '../result/physical_check'
import ResultProfile from '../result/profile'
import ResultRecipe from '../result/recipe'
import ResultTreatment from '../result/treatment'

export const ResultVisit = () => {
    const history = useHistory()

    const menu = (
        <Menu>
          <Menu.Item key="1">Rekam Medis</Menu.Item>
          <Menu.Item key="2">Surat Sakit</Menu.Item>
          <Menu.Item key="3">Keterangan Berobat</Menu.Item>
        </Menu>
      );
      
    return (
        <Card title="Data Pemeriksaan Pasien">
            <Row align="middle" style={{ padding: '2%' }}>
                <Typography.Title level={5} style={{ padding: 0, margin: 0 }} >1. Profile Pasien</Typography.Title> 

                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultProfile />

                {/* Pemeriksaan Awal */}

                <Space><Typography.Title level={5} style={{ padding: 0, margin: 0 }}>2. Pemeriksaan awal</Typography.Title> <NavLink to="/checkup/1"><EditButton>Edit</EditButton></NavLink></Space>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultFirstCheck />

                <Space><Typography.Title level={5} style={{ padding: 0, margin: 0 }}>3. Pemeriksaan fisik & penunjang</Typography.Title> <NavLink to="/checkup/1"><EditButton>Edit</EditButton></NavLink></Space>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultPhysicalCheck />

                <Space><Typography.Title level={5} style={{ padding: 0, margin: 0 }}>4. Diagnosa</Typography.Title><NavLink to="/checkup/1"><EditButton>Edit</EditButton></NavLink></Space>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultDiagnosis />

                <Space><Typography.Title level={5} style={{ padding: 0, margin: 0 }}>5. Tindakan & Perlengkapan</Typography.Title> <NavLink to="/checkup/1"><EditButton>Edit</EditButton></NavLink></Space>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultTreatment />


                <Space><Typography.Title level={5} style={{ padding: 0, margin: 0 }}>6. Resep Obat</Typography.Title> <NavLink to="/checkup/1"><EditButton>Edit</EditButton></NavLink></Space>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultRecipe />

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>7. Keterangan</Typography.Title>

                <Divider></Divider>
                <Space>
                    <Button type='primary'>Tambah Pemeriksaan</Button>
                    <Dropdown.Button overlay={menu}>Cetak Dokumen</Dropdown.Button>
                </Space>

            </Row>
        </Card>
    )
}
