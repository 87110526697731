
import React, { FC } from 'react'
import DataHospital from '../../../components/management/hospital'

const HospitalPage: FC = () => {
    return <div>
        <DataHospital/>
    </div>
}

export default HospitalPage