import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import { Notification, successNotification } from '../../layout/notification'

import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import Select, { SelectItem } from '../../layout/select';
import _ from 'lodash'
import usePHVillage, { PHVillage } from '../../../services/configurations/public.health.private';
import useProvince from '../../../services/global/province';
import useCity from '../../../services/global/cities';
import useVillage from '../../../services/global/village';
import useDistrict from '../../../services/global/district';

const { Search } = Input
const { confirm } = Modal

const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
  <>
    {/* <TextField id="search" type="text" placeholder="Filter By Name" aria-label="Search Input" value={filterText} onChange={onFilter} style={{ marginTop: '-30%'}} />
    <ClearButton type="primary" onClick={onClear}  style={{ marginTop: '-30%'}}><ReloadOutlined /></ClearButton> */}
    <Search placeholder="input search text" value={filterText} onChange={onFilter} onSearch={onClear} enterButton style={{position: 'absolute', bottom: 130, height: 32, width: 250, }}/>
  </>
  );

const DataPrivatePublicHealth:FC = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadPhVillage();
      }
    };

    return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()

  const params = useParams<any>()
  const [phVillage, setPhVillage] = useState<PaginationData<PHVillage[]>>({data: [], pagination: {}})
  const filteredItems = phVillage.data.filter(item => item.village.name && item.village.name.toLowerCase().includes(filterText.toLowerCase()));
  const phVillageServices = usePHVillage()
  const [loader, setLoader] = useState<boolean>(false)
 


  //Form Variabel
  const [ form, setForm ] = useState<PHVillage>({
    public_health: {
      id: params.publicHealth
    }as any,
    village: {
      id: 0
    } as any
  })  


  const provinceService = useProvince()
  const cityService = useCity()
  const villageService = useVillage()
  const districtService = useDistrict()
  const [city, setCity] = useState<SelectItem[]>([])
  const [district, setDistrict] = useState<SelectItem[]>([])
  const [province, setProvince] = useState<SelectItem[]>([])
  const [village, setVillage] = useState<SelectItem[]>([])

  const getProvince = async (value: string, idCountry: number) => {
      const province:SelectItem[] = []
      const result = await provinceService.fetch(value, idCountry).then(res => res.data)
      
      await Promise.all(_.map(result, async (val) => 
          await province.push({
              display: val.name,
              value: val.id
          })
      ));

      setProvince(province)
  }
  

  const getCity = async (value: string) => {
      const city:SelectItem[] = []
      const result = await cityService.fetch(value).then(res => res.data)
      await Promise.all(_.map(result, async (val) => 
          await city.push({
              display: val.name,
              value: val.id
          })
      ));

      setCity(city)
  }

  const getDistrict = async (value: string) => {
      const district:SelectItem[] = []
      const result = await districtService.fetch(value).then(res => res.data)
      await Promise.all(_.map(result, async (val) => 
          await district.push({
              display: val.name,
              value: val.id
          })
      ));

      setDistrict(district)
  }   

  const getVillage = async (value: string) => {
      const village:SelectItem[] = []
      const result = await villageService.fetch(value).then(res => res.data)
      await Promise.all(_.map(result, async (val) => 
          await village.push({
              display: val.name,
              value: val.id
          })
      ));

      setVillage(village)
  }

 
  const columns = [
    {
      name: 'Nama',
      cell: (p: PHVillage) => p.village.name,
      sortable: true,
    },
    {
      name: 'Aksi',
      cell: (c: PHVillage) => {
          return ( 
              <>
                  <Button type="text" onClick={ () => { history.push(`/public-health/private/${c.id}`);  window.location.reload() } }><FormOutlined /></Button>
                  <Button danger type="text" onClick={ () => deleteData(c.id as string) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getPhVillage = () => {
    setLoader(true)
    phVillageServices
        .get(params.id, params.publicHealth)
        .then(form => {
            setForm(form);
            }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
}


  const loadPhVillage = () => {
    setLoader(true)
    phVillageServices
        .fetch(params.publicHealth,'', perPage, page)
        .then(typeIdentites => {
            setPhVillage(typeIdentites)
            let total = (typeIdentites.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    phVillageServices
        .fetch(params.publicHealth,searhing, perPage, 1)
        .then(typeIdentites => {
            setPhVillage(typeIdentites)
            let total = (typeIdentites.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    phVillageServices
        .fetch(params.publicHealth,'', perPage, pages)
        .then(typeIdentites => {
            setPhVillage(typeIdentites)
            let total =  (typeIdentites.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    phVillageServices
        .fetch(params.publicHealth,'', newPerPage, pages)
        .then(typeIdentites => {
            setPhVillage(typeIdentites)
            let total = (typeIdentites.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      content: `Data dengan ID : ${id}`,
      onOk() {
        setLoader(true)
        phVillageServices
            .delete(id, params.publicHealth)
            .then(() => {
                loadPhVillage()
                
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
}

    const submit = () => {
        console.log(form)  
        !!params.id === false  && phVillageServices
        .create(form, params.publicHealth)
        .then(() => { 
            loadPhVillage() 
            successNotification('tambah')
        })
        .catch(e => console.log(e))
        .finally(()=> setLoader(false))
        
        !!params.id === true && phVillageServices
            .update(params.id, params.publicHealth, form)
            .then(() => { 
                loadPhVillage() 
                Notification('Transaksi Update Berhasil')
                window.location.href = "/type-identity"
            })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
    }


    useEffect(() => {
        loadPhVillage();
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getPhVillage()
    }, [])


  return (<>
    <Title title="Puskesmas Desa" description="daftar data desa yang termasuk dalam puskesmas" />
    <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="Form Tambah Desa" >
                    <Form layout="vertical">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: '1%' }}>
                          <Select name="province" label="Provinsi" value={'Jawa Barat'}
                            items={ province } showSearch filterOption={false} onSearch={(q) => getProvince(q, 1)} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: '1%' }}>
                          <Select name="city" label="Kota / Kabupaten" value={'Cianjur'}
                            items={ city } showSearch filterOption={false} onSearch={ getCity } />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: '1%' }}>
                          <Select name="district" label="Kecematan" 
                            items={ district } showSearch filterOption={false} onSearch={ getDistrict } />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ marginLeft: '1%' }}>
                          <Select name="village" label="Desa"  onValueChange={(village) => { setForm({...form, village:{ id: village} as any})}} 
                            items={ village } showSearch filterOption={false} onSearch={ getVillage } />
                        </Col>
                    </Form>
                    <Form.Item>
                        <Button type="primary" onClick={submit} style={{  marginRight:'8px' }}>
                            Simpan
                        </Button>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="Data Desa">
                  <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      style={{marginTop: '-100px'}}
                  />
                </Card>
            </Col>
        </Row>
    </>
  );
};

export default DataPrivatePublicHealth 