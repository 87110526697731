import { Col, Row } from "antd";
import { FC } from "react";
import DataTable from "react-data-table-component";
import Card from "../../layout/card";
import Title from "../../layout/title";

const MobileSchedule: FC = () => {
    return <>
        <Title title={"Jadwal Dokter Mobile"} description="mengatur jadwal dokter"></Title>
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Card title={"daftar dokter dan bidan"}>
                    <DataTable columns={[]} data={[]}/>
                </Card>
            </Col>
        </Row>
    </>
}

export default MobileSchedule