
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface PartnerCompany {
    id?: string
    name: string
    email: string
    pic: string
    phone: string
    address: string
}

export interface LogoPartner {
    logo: string
}

const PartnerCompanyClient = baseURL

const usePartnerCompany = () => ({
    async create(PartnerCompany: PartnerCompany): Promise<PartnerCompany>{
        const {data} = await PartnerCompanyClient.post(`/config/partnerships`, PartnerCompany)
        return data
    },
    async update(id: string, PartnerCompany: PartnerCompany): Promise<PartnerCompany>{
        await PartnerCompanyClient.patch(`/config/partnerships/${id}`, PartnerCompany)
        return PartnerCompany
    },
    async delete(id: string): Promise<void>{
        const {data} = await PartnerCompanyClient.delete(`/config/partnerships/${id}`)
        return data
    },
    async get(id:string): Promise<PartnerCompany>{
        const {data} = await PartnerCompanyClient.get(`/config/partnerships/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<PartnerCompany[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await PartnerCompanyClient.get(`/config/partnerships/?${searching}&${sizes}&${rows}`)
        return data
    },
    async getLogo(id:string): Promise<PartnerCompany>{
        const {data} = await PartnerCompanyClient.get(`/config/partnerships/${id}/logo`)
        return data
    },
    async saveLogo(id:string, logo: Buffer): Promise<PartnerCompany>{
        const {data} = await PartnerCompanyClient.put(`/config/partnerships/${id}/logo`, logo)
        return data
    }
})

export default usePartnerCompany