
import React, { FC } from 'react'
import DataGroup from '../../components/pharmacy/group'

const Group: FC = () => {
    return <div>
        <DataGroup/>
    </div>
}

export default Group