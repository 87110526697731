import { SecondStepForm } from "../../../components/doctor/checkup/secondStepForm";

const SecondStepPage = () => {
  return (
    <>
      <SecondStepForm />
    </>
  );
};

export default SecondStepPage;
