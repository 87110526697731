import React, { FC, useEffect, useState } from 'react'
import { Badge, Button, Col, Row, Tag, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Icon, { DeleteOutlined, FormOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useQueue, { Queue } from '../../../services/queue';
import { GlobalStatus } from '../../../context/context';
import { Notification } from "../../layout/notification";
import DataTable from 'react-data-table-component';
import { FilterComponent, FilterComponent2 } from '../../layout/fiterDataTable'
import { NextButton } from '../../icons/nextButton';

const { Text, Link } = Typography;


const DataQueue: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                loadQueue();
            }
        };

        return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Queue, setQueue] = useState<PaginationData<Queue[]>>({ data: [], pagination: {} })
    const filteredItems = Queue.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const QueueService = useQueue()
    const history = useHistory()
    const params = useParams() as any

    const loadQueue = () => {
        setLoader(true)
        QueueService
            .fetch('', '', perPage, page)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        QueueService
            .fetch(searching, '', perPage, 1)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const handlePageChange = (pages: number) => {
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', perPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', newPerPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }


    const deleteQueue = (id: string) => {
        const c = window.confirm(`Are You Sure Delete ?`)
        if (c) {
            setLoader(true)
            QueueService
                .delete(id)
                .then(() => {
                    loadQueue()
                    Notification('Hapus Data Berhasil')
                })
                .catch(e => console.log(e))
                .finally(() => setLoader(false))
        }
    }

    useEffect(loadQueue, [])

    const columnss = [
        {
            name: 'Tanggal',
            cell: (p: Queue) => p.date
        },
        {
            name: 'No',
            cell: (p: Queue) => p.nomor
        },
        {
            name: 'Layanan',
            cell: (p: Queue) => p.poly
        },

        {
            name: 'Nama',
            cell: (p: Queue) => p.name
        },


        {
            name: 'Status',
            cell: (p: Queue) => GlobalStatus(parseInt(p.status as any))
        },
        {
            name: 'No. Hp',
            cell: (p: Queue) => p.handphone
        },
        {
            name: 'Alamat',
            cell: (p: Queue) => p.address
        },


        {
            name: 'Prioritas',
            cell: (p: Queue) => p.priority
        },
        {
            name: 'Aksi',
            cell: (c: Queue) => {
                return (
                    <>
                        <Button type="text" title="Update" onClick={() => history.push(`/Queue/${c.id}`)}><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={() => deleteQueue(c.id as string)}><DeleteOutlined /></Button>
                    </>
                )
            }
        }
    ]

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            nomor: 'A002',
            poly: 'Poli Kecantikan',
            name: 'Jajang Dadas Bengeut',
            status: 'BPJS',
            handphone: '0192830123213',
            address: 'Kampung Cigombong No, RT.01/RW.09, Ciherang, Kec. Pacet, Kabupaten Cianjur, Jawa Barat 43253',
            priority: '2'
        }
    ]


    const columns = [
        {
            name: 'tanggal',
            wrap: true,
            selector: (row: any) => <Text strong type='success'> {row.date} </Text>
        },
        {
            name: 'nomor',
            selector: (row: any) => <Text strong> {row.nomor} </Text>
        },
        {
            name: 'layanan',
            wrap: true,
            selector: (row: any) => <Text strong> <span >{row.poly} - <Text type="success">{row.status}</Text></span></Text>
        },

        {
            name: 'nama',
            wrap: true,
            width: '15%',
            selector: (row: any) => <Text strong> <a onClick={() => history.push(`/queue/${row.id}`)}>{row.name}</a> </Text>
        },

        {
            name: 'no. hp',
            wrap: true,
            selector: (row: any) => <Text strong> {row.handphone} </Text>
        },
        {
            name: 'alamat',
            wrap: true,
            selector: (row: any) => <Text strong> {row.address} </Text>
        },

        {
            name: 'prioritas',
            selector: (row: any) => <Tag style={{ backgroundColor: '#E9E9DF', color: '#000', fontWeight: 'bold' }}>{ row.priority }</Tag>
        },
        {
            name: 'aksi',
            width: '9%',
            selector: (row: any) => <Button type="text" size="middle" title="Update" onClick={() => history.push(`/queue/${row.id}`)}><NextButton /> </Button>
        }
    ]

    return <div>
        <Title title="Antrian" description="data antrian pasien" />
        <Row>

            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }

                <Card title="Daftar Antrian">
                    <DataTable
                        columns={columns}
                        data={data}
                        className="data-table"
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noHeader={true}

                        // columns={columns}
                        // data={filteredItems}
                        // pagination
                        // paginationServer
                        // paginationTotalRows={totalRows}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // onChangePage={handlePageChange}
                        // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        // subHeader
                        // subHeaderComponent={subHeaderComponentMemo}
                        // striped={true}
                        // noHeader={true}
                        // highlightOnHover={true}
                        // className="data-table"
                    />
                </Card>

            </Col>
        </Row>
    </div>
}

export default DataQueue