import { Col, Row, Form, Input, Button, Space } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from '../../layout/title';
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import {  successNotification } from '../../layout/notification'
import usePublicHealth, { PublicHealth } from '../../../services/configurations/public.health';
import _ from 'lodash'
import Select, { SelectItem } from '../../layout/select';
import useDistrict, { District } from '../../../services/global/district';


const FormPublicHealth: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const publicHealthService = usePublicHealth()
    const history = useHistory()
    const params = useParams() as any
    const [district, setDistrict] = useState<SelectItem[]>([])
    const districtService = useDistrict()
    
    const [publicHealth, setPublicHealth] = useState<PublicHealth>({
        name: '',
        email: '',
        pic: '',
        phone: '',
        address: '',
        district : {
            id: '',
        } as District
    })

    
    const getDistrict = async (value: string, cityId : number | string) => {
        const district:SelectItem[] = []
        const result = await districtService.fetch(value, cityId).then(res => res.data)
        await Promise.all(_.map(result, async (val) => 
            await district.push({
                display: val.name,
                value: val.id
            })
        ));

        setDistrict(district)
    }   
    
  
    const submit = () => {
        params.id == 'new' && publicHealthService
            .create(publicHealth)
            .then(publicHealth => {
                    setPublicHealth(publicHealth)
                    successNotification('tambah')
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== 'new' && publicHealthService
            .update(params.id, publicHealth)
            .then(publicHealth => {
                // console.log(publicHealth)
                setPublicHealth(publicHealth)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadpublicHealth = () => {
        setLoader(true)
        publicHealthService
            .get(params.id)
            .then(async publicHealth => {

                    await setDistrict([
                        {
                            value: publicHealth.district.id,
                            display: publicHealth.district.name,
                        }
                    ])

                    setPublicHealth({
                        name: publicHealth.name,
                        email: publicHealth.email,
                        pic: publicHealth.pic,
                        phone: publicHealth.phone,
                        address: publicHealth.address,
                        district : {
                            id: publicHealth.district.id,
                        } as District
                    })
                }
            )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    useEffect(() => {
        console.log(params.id)
       if(params.id !== 'new'){ 
           setPublicHealth({
               ...publicHealth,
               id: params.id
           })
       }
        params.id !== 'new' && loadpublicHealth()
    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Puskesmas" description="form pengisian data puskesmas" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card title="Pendaftaran Puskesmas" >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="nama puskesmas" className='lable-form-input'>
                                <Input data-cy="name"  value={publicHealth.name} onChange={({target : {value: name}}) => setPublicHealth({...publicHealth, name})}  className='form-control'/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="penanggungjawab" className='lable-form-input'>
                                <Input data-cy="pic" value={publicHealth.pic}  onChange={({target : {value: pic}}) => setPublicHealth({...publicHealth, pic})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                    
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="email"  className='lable-form-input'>
                                <Input data-cy="email" value={publicHealth.email}  onChange={({target : {value: email}}) => setPublicHealth({...publicHealth, email})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="telepon"  className='lable-form-input'>
                                <Input data-cy="phone" value={publicHealth.phone}  onChange={({target : {value: phone}}) => setPublicHealth({...publicHealth, phone})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>    
                            <Select name="district" label="kecamatan"  onValueChange={(district) => { setPublicHealth({...publicHealth, district:{ id: district} as District})}} 
                            items={ district } showSearch filterOption={false}  onSearch={(q) => getDistrict(q, 163)} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="alamat"  className='lable-form-input'>
                                <TextArea data-cy="address"  value={publicHealth.address}  onChange={({target : {value: address}}) => setPublicHealth({...publicHealth, address})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                        
                    
                        <Space>
                            <Button type="primary" onClick={submit} data-cy="btn-submit">
                                Simpan
                            </Button>

                            <Button type="default" title="Kembali" onClick={ () => history.push(`/public-health`) }>Kembali</Button> 
                        </Space>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormPublicHealth