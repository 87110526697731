import { MedisyIcon } from '../form/icon-button/MedisyIcon'
import { NotFoundIcon } from '../form/icon-button/NotFoundIcon'

export const Data404 = () => {
    return (
        <>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '10vh', marginRight: '1%' }}>
                <MedisyIcon style={{ width: '150px' }} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh' }}>
                <NotFoundIcon />
            </div>
        </>
    )
}
