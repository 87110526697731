import moment from "moment";
import React from "react";

export const DataCoba: React.FC = () => {
  // const data = [
  //   {
  //     id: "368482a3-e18d-499f-a432-fd97196b08ce",
  //     order: "1",
  //     status: false,
  //     code: "A",
  //     max_online_registration: 0,
  //     created: "2021-12-07T09:08:18.000Z",
  //     updated: "2021-12-07T09:08:18.000Z",
  //     poly: {
  //       name: "POLI UMUM",
  //       code_bpjs: "bpj5",
  //     },
  //     shift: [
  //       {
  //         name: "Pagi",
  //         startTime: "08:00:00",
  //         endTime: "11:20:00",
  //       },
  //     ],
  //   },
  //   {
  //     id: "ee0b25ba-8fa3-4c17-aee6-ebe86a41f21e",
  //     order: "2",
  //     status: false,
  //     code: "B",
  //     max_online_registration: 0,
  //     created: "2021-12-07T09:13:45.000Z",
  //     updated: "2021-12-07T09:13:45.000Z",
  //     poly: {
  //       name: "POLI KEGANTENGAN",
  //       code_bpjs: "bpj5",
  //     },
  //     shift: [
  //       {
  //         name: "Pagi",
  //         startTime: "08:00:00",
  //         endTime: "11:20:00",
  //       },
  //     ],
  //   },
  //   {
  //     id: "a2d6ccd5-1440-4ad4-b2c1-0f42d1e857e4",
  //     order: "3",
  //     status: false,
  //     code: "C",
  //     max_online_registration: 0,
  //     created: "2021-12-07T09:14:43.000Z",
  //     updated: "2021-12-07T09:14:43.000Z",
  //     poly: {
  //       name: "POLI KECANTIKAN",
  //       code_bpjs: "bpj5",
  //     },
  //     shift: [
  //       {
  //         name: "Siang",
  //         startTime: "12:45:00",
  //         endTime: "15:00:00",
  //       },
  //     ],
  //   },
  //   {
  //     id: "1664e64e-b7f4-4617-b19f-3305bfc674b8",
  //     order: "4",
  //     status: true,
  //     code: "I",
  //     max_online_registration: 0,
  //     created: "2021-12-16T08:12:28.000Z",
  //     updated: "2021-12-16T08:12:28.000Z",
  //     poly: {
  //       name: "POLI KIA",
  //       code_bpjs: "bpj5",
  //     },
  //     shift: [
  //       {
  //         name: "Siang",
  //         startTime: "12:45:00",
  //         endTime: "24:00:00",
  //       },
  //     ],
  //   },
  // ];

  const format = "hh:mm:ss";
  console.log(moment(format));
  return (
    <div>
      {moment().isBetween(
        moment().set("hours", 21).set("minute", 0o0),
        moment().set("hours", 23).set("minute", 15)
      )
        ? "Between"
        : "NOOO"}
    </div>
  );
};
