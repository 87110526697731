import React, {useEffect, useState} from "react";

export const CurrentTime = () => {
  const [dateTime, setDateTime] = useState("");
  const [time, setTime] = useState("");
  const currentDateTimeEffect = () => {
    const dateInterval: any = setInterval(() => {
      const date = new Date();
      setDateTime(
        `${date.getDay()} ${date.toLocaleDateString("id-ID", {
          month: "long",
        })} ${date.getFullYear()}`
      );
      setTime(
        `${date.toLocaleString("id-ID", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        })}`
      );

      return () => clearInterval(dateInterval);
    }, 1000);
  };
  useEffect(currentDateTimeEffect, []);
  //   console.log(dateTime);
  return (
    <>
      <h3>{dateTime ? dateTime : "01 Januari 2022"}</h3>
      <span>{time ? time.replaceAll(".", ":") : "00:00:00"}</span>
      {/* <span>09:40:04</span> */}
    </>
  );
};
