import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useHistory} from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from '../../layout/title';
import { PaginationData } from '../../../services/utility';
import { Notification, successNotification } from '../../layout/notification'
import { GlobalStatus } from '../../../context/context';

import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';
import useICDInterface, { ICDInterface } from '../../../services/configurations/type.diseases';
import { wrap } from 'module';
const { confirm } = Modal

const DataTypeDisease: FC = () => {
     // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadtypeDisease();
      }
    };

    return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  

  const history = useHistory()
  const [typeDisease, setTypeDisease] = useState<PaginationData<ICDInterface[]>>({data: [], pagination: {}})
  const filteredItems = typeDisease.data.filter(item => 
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) || 
        item.code && item.code.toLowerCase().includes(filterText.toLowerCase()) ||
        item.general_name && item.general_name.toLowerCase().includes(filterText.toLowerCase()) 
        )
        ;
  const typeDiseaseService = useICDInterface()
  const [loader, setLoader] = useState<boolean>(false)

 
  const loadtypeDisease = () => {
    setLoader(true)
    typeDiseaseService
        .fetch('', perPage, page)
        .then(typeDisease => {
            setTypeDisease(typeDisease)
            let total = (typeDisease.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  const deletetypeDisease = (id: string) => {
      confirm({
        title: 'Serius Ingin Hapus Data ini?',
        icon: <ExclamationCircleOutlined />,
        content: `Data akan hilang permanent`,
        onOk() {
          setLoader(true)
          typeDiseaseService
              .delete(id)
              .then(() => {
                  loadtypeDisease()
                  successNotification('hapus')
                })
              .catch(e => console.log(e))
              .finally(()=> setLoader(false))
        },
        onCancel() {
          console.log('Cancel');
        },
      });
  }
  
  const filterData = (searching: string) => {
    
    setFilterText(searching)
    typeDiseaseService
        .fetch(searching, perPage, 1)
        .then(typeDisease => {
            setTypeDisease(typeDisease)
            let total = (typeDisease.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    typeDiseaseService
        .fetch('', perPage, pages)
        .then(typeDisease => {
            setTypeDisease(typeDisease)
            let total =  (typeDisease.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    typeDiseaseService
        .fetch('', newPerPage, pages)
        .then(typeDisease => {
            setTypeDisease(typeDisease)
            let total = (typeDisease.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

    
const columns = [
    {
        name: <Typography.Text strong>kode</Typography.Text>,
        selector: 'code',
        sortable: true,
        minWidth: '10%',
        wrap: true
    },

    {
        name: <Typography.Text strong>nama</Typography.Text>,
        selector: 'name',
        sortable: true,
        width: '30%',
        wrap: true
    },
    
    {
        name: <Typography.Text strong>nama umum</Typography.Text>,
        selector: 'general_name',
        sortable: true,
        width: '25%',
        wrap: true
    },

    {
        name: <Typography.Text strong>keterangan</Typography.Text>,
        selector: 'keterangan', 
        width: '20%',
        wrap: true
    },
    {
        name: <Typography.Text strong>aksi</Typography.Text>,
        width: '10%',
        cell: (row: any) => {
            return ( 
                <>
                    <Button type="text" onClick={ () => history.push(`/icd/${row.id}`) }><FormOutlined /></Button>
                    <Button danger type="text" onClick={ () => deletetypeDisease(row.id as string) }><DeleteOutlined /></Button>
                </>
             )
        }
    },
];

    useEffect(loadtypeDisease, [])

   
    

    return <div>
        <Title title="ICD 10" description="data semua ICD 10" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                
                <Card title="Data Penyakit (ICD 10)" addButton={true}>
                <DataTable
                     columns={columns}
                     data={filteredItems}
                     pagination
                     paginationServer
                     paginationTotalRows={totalRows}
                     onChangeRowsPerPage={handlePerRowsChange}
                     onChangePage={handlePageChange}
                     paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                     subHeader
                     subHeaderComponent={subHeaderComponentMemo}
                     striped={true}
                     highlightOnHover={true}
                     noHeader={true}
                     className='data-table'
                />
                </Card>
            </Col>
        </Row>
    </div>
}

export default DataTypeDisease

