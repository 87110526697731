
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NextIconButton } from "../../form/icon-button/NextIconButton";
import { PreviousIconButton } from "../../form/icon-button/PreviousIconButton";
import styles from "./dataTable.module.css";
interface Column {
  name: string;
  cell: (p: any) => any;
  width?: string;
}

interface IProps {
  datas: { [key: string]: any };
  columns: Column[];
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  sizePerPage?: number;
  setSizePerPage?: React.Dispatch<React.SetStateAction<number>>;
  setQuery?: React.Dispatch<React.SetStateAction<string>>;
  limit?: any;
  totalPage?: number | undefined;
}

export const limit = [
  {
    label: "5",
    value: 5,
  },
  {
    label: "25",
    value: 25,
  },
  {
    label: "50",
    value: 50,
  },
  {
    label: "100",
    value: 100,
  },
  {
    label: "250",
    value: 250,
  },
];

/**
 *   Data Table:
 **  datas => data yang akan ditampilkan didatable, berbentuk array/list of object
 **   columns => column mana saja yang ingin ditampilkan pada datatable, berbentuk list of object
 **  setPage = untuk mengeset nilai page yang saat ini kita sedang berada (berbentuk setState)
 **  sizePerPage =  untuk mengeset nilai limit data yang ditampilkan, nilainya berasal dari limit
 **  setSizePerPage = untuk mengeset nilai size per page (berapa data yang ingin ditampilkan dalam 1 page)
 **  limit  = limit untuk kita masukan kedalam selection
 */

export const DataTable: React.FC<IProps> = ({
  datas,
  columns,
  setPage,
  sizePerPage,
  setSizePerPage,
  limit,
  totalPage,
  setQuery,
}) => {
  const keys = datas[0] && Object.keys(datas[0]);

  const [limitValue, setLimitValue] = useState<number>(sizePerPage!);
  const handleOnPageChange = (data: any) => {
    setPage!(data.selected + 1);
  };
  useEffect(() => {
    setSizePerPage!(limitValue);
  }, [limitValue]);

  return (
    <div className={styles.table_container}>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.select_limit}>
          <p>Showing: </p>
          <select
            value={limitValue}
            style={{ margin: "0 5%" }}
            onChange={(e: any) => setLimitValue(e.currentTarget.value)}
          >
            {limit.map((lim: any, index: number) => (
              <option key={index} value={lim.value}>
                {lim.label}
              </option>
            ))}
          </select>
          <p>entries</p>
        </div>
        {/* <div style={{ position: 'relative' }} className={styles.form_control}>
                    <div className={styles.form_control__icon}>
                        <SearchIconButton style={{ cursor: 'pointer' }} />
                    </div>
                    <Input className={styles.form_control__search_input} placeholder="Search" />
                </div> */}
      </div>
      <table cellPadding={5} cellSpacing={5}>
        <thead>
          <tr>
            {columns.map((val, index) => (
              <td key={index}>
                <div>{val.name}</div>
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {datas[0] &&
            datas.map((data: any, index: number) => (
              <tr className={styles.tr_body_style} key={index}>
                {columns.map((val, index) => (
                  <td key={index} width={val.width}>
                    <div className={styles.td_body_style}>{val.cell(data)}</div>
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <div className={styles.footer_container}>
        <div style={{ display: "flex" }}>Showing 1 to 10 of 82 entries</div>
        <div style={{ display: "flex" }}>
          <ReactPaginate
            previousLabel={<PreviousIconButton style={{ marginTop: "40%" }} />}
            breakLabel={"..."}
            nextLabel={<NextIconButton style={{ marginTop: "40%" }} />}
            pageCount={totalPage!}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handleOnPageChange}
            containerClassName={styles.container_pagination}
            activeClassName={styles.container_pagination_active}
          />
        </div>
      </div>
    </div>
  );
};
