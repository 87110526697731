import { Button, Col, DatePicker, Form, Row, Space, TimePicker } from "antd";
import { FC } from "react";
import DataTable from "react-data-table-component";
import Card from "../../layout/card";
import Select from "../../layout/form/select";
import Title from "../../layout/title";

const MobileClose: FC = () => {
    return <div>
        <Title title={"Tutup Layanan Mobile"} description="pengaturan untuk menutup layanan"></Title>
        <Row justify="space-around">
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Card title="form tutup layanan">
                    <Form layout="vertical">
                        <Select label={"layanan"} name={"poli"} items={[]}></Select>
                        <Form.Item label={"tanggal"} style={{ fontWeight: 'bold'}}>
                            <DatePicker className="form-control" style={{ width: '100%'}}/>
                        </Form.Item>
                        <Form.Item label={"jam mulai"} style={{ fontWeight: 'bold'}}>
                            <TimePicker className="form-control" style={{ width: '100%'}}/>
                        </Form.Item>
                        <Form.Item label={"jam selesai"} style={{ fontWeight: 'bold'}}>
                            <TimePicker className="form-control" style={{ width: '100%'}}/>
                        </Form.Item>
                        <Form.Item>
                            <Space>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button htmlType="button">
                                Reset
                            </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
            <Col xs={24} sm={12} md={15} lg={15} xl={15}>
                <Card title="data tutup layanan">
                    <DataTable columns={[]} data={[]}/>

                </Card>
            </Col>
        </Row>
    </div>
}

export default MobileClose