import { FC } from "react";
import Reservation from "../../../components/administration/reservation";

const ReservationPage: FC = () => {
  return (
    <div>
      <Reservation />
    </div>
  );
};

export default ReservationPage;
