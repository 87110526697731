import React, { FC, useState } from 'react'
import { Col,  Row, Tag, Typography, Card as CardTheme, Space, Menu, Modal, Tooltip} from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined , DeleteOutlined, PrinterOutlined, UserAddOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import { Order } from '../../../services/pharmacy/order';
import useInventory, { InventoryLocation } from '../../../services/pharmacy/inventory.location';
import { SelectItem } from '../../layout/form/select';
import _ from 'lodash'
import { NextButton } from '../../icons/nextButton';
import {  FilterComponent2 } from '../../layout/fiterDataTable';
const { Text } = Typography;
const { confirm } = Modal


const InventoryPharmacy: FC = () => { 
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const history = useHistory()
    const [loader, setLoader] = useState(false)

    const [form, setForm] = useState<Order>({
        code: 0,
        date: 0,
        inventory_location: {
            inc_id : 0
        } as InventoryLocation,
    } as any)

    const data = [
        {
            id: 1,
            barcode: 'ADSFDA001',
            name : 'Kursi Roda',
            group: 'ALKES',
            category: 'OTC',
            stock: 100,
            unit: 'pcs',
            status: 'lebih'
        },
        {
            id: 2,
            barcode: 'ADSFDA002',
            name : 'Hufadextamin',
            group: 'Obat',
            category: 'Dowa',
            stock: 100,
            unit: 'tablet',
            status: 'kurang'
        },
        {
            id: 3,
            barcode: 'ADSFDA003',
            name : 'Tongkat',
            group: 'ALKES',
            category: 'OTC',
            stock: 20,
            unit: 'pcs',
            status: 'lebih'
        }
    ]

    

    const [inventoryLocation, setInventoryLocation] = useState<SelectItem[]>([])
    const inventoryService = useInventory()

    const getInventory = async (value: string) => {
        const inventoryLocation:SelectItem[] = []
        const result = await inventoryService.fetch(value).then(res => res.data)
        
        await Promise.all(_.map(result, async (val) => 
            await inventoryLocation.push({
                display: val.location.name,
                value: val.inc_id as any
            })

        ));

        setInventoryLocation(inventoryLocation)
    }

    const columns = [
        {
            name: 'no',
            width: '5%',
            selector: (row: any) => row.id
        },
        {
            name: 'barcode',
            selector: (row: any) =>  <Tooltip title="detail obat"><a onClick={()=> history.push(`/inventory-stock/${row.id}`)} style={{ fontWeight: 'bold'}} > { row.barcode} </a></Tooltip>
        },
        {
            name: 'nama obat',
            width: '25%',
           selector: (row: any) =>  <Text strong> {row.name} </Text>
        },
        {
            name: 'golongan',
            selector: (row: any) =>  <Text strong> {row.group} </Text>
        },
        {
            name: 'kategori',
           selector: (row: any) => <Text strong> { row.category} </Text>
        },
        {
            name: 'stok',
           selector: (row: any) => <Text strong> {row.stock} {row.unit} </Text>
        },
        
        {
            name: 'status',
           selector: (row: any) => {
               return (
                   row.status == "kurang"
                   ? <Tag color="#F3D40C"><Text strong style={{ color : '#ffffff'}}>{row.status}</Text></Tag>
                   : <Tag color="#30d95d"><Text strong style={{ color : '#ffffff'}}>lebih</Text></Tag>
               )
           }
     
        },
        {
            name: '',
            selector: (row: any) =>  (<>
                <Space>
                    <a onClick={()=> history.push(`/inventory-stock/${row.id}`)}> <NextButton/> </a>
                </Space>

                </>
            )
        }
    ]


    const submit = () => {
        console.log(form)
    }

    return <div>
        <Title title="Penyimpanan Obat" description="data stok obat" />
        <Row>
            
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row justify='space-between' align='middle'>
                    <Col xs={24} sm={24} md={12} lg={10} xl={7}>
                        <CardTheme style={{ boxShadow: '0px 3px 10px #b6b6b6', borderRadius: 15, marginBottom: 10, minHeight: '80px' }}>
                            <Typography.Title style={{ textAlign: 'right' }} level={2}>Rp. 10.000.000,-</Typography.Title>
                            <Typography.Title level={5}  style={{ textAlign: 'right', marginTop: '-10px' }} >Total Uang</Typography.Title>
                        </CardTheme>
                    </Col>

                </Row>
               
                {
                    loader && <div>Loading .... </div>
                }
                    <Card title="list penyimpanan obat">
                        <DataTable
                            columns={columns}
                            data={data}
                            className="data-table"
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            noHeader={true}
                            striped={true}
                        />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default InventoryPharmacy

function filterData(value: any) {
    throw new Error('Function not implemented.');
}
