import { Button, Col, Descriptions, Form, Row, Select, Space } from "antd";
import { FC, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Input from "../../form/Input";
import Card from "../../layout/card";
import Title from "../../layout/title";
import Recipe from "../../pharmacy/form/recipe";

import useRecipe, { RecipeMedicine } from '../../../services/pharmacy/recipe';

export const PackageMedicineDetail: FC = () => {
    const history = useHistory()
    const [RecipeMedicine, setRecipeMedicine] = useState<RecipeMedicine[]>([])
    const rows = [
        {
            id: 1,
            name: 'Demam',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        },
        {
            id: 2,
            name: 'Covid',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        },
        {
            id: 3,
            name: 'Maag',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        }
    ]

    const columns = [
        {
            name: 'nama obat',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'penggunaan',
            selector: 'type'
        },
        {
            name: 'catatan',
            selector: 'look_other'
        },
        {
          name: 'Aksi',
          button: true,
          cell: (row: any) => {
              return ( 
                  <>
                      <Button type="primary" danger onClick={ () => { history.push(`/package-medicine/${row.id}`);  window.location.reload() } }>Hapus</Button>
                  </>
              )
          }
        },
      ];
    return (
        <>
            <Title title={"Konfigurasi Paket Obat"} description="konfigurasi detail isi paket obat"></Title>
            <Row justify="space-around">
                <Col xs={24} sm={24} md={24}>
                    <Card>
                        <Descriptions className="cus-description" bordered column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
                            <Descriptions.Item label="Nama Paket">Demam</Descriptions.Item>
                            <Descriptions.Item label="Jenis Paket">Paket Biasa</Descriptions.Item>
                            <Descriptions.Item label="Dokter">dr. Yusuf Wibisono</Descriptions.Item>
                            <Descriptions.Item label="Tampilkan di dokter lain">Tidak</Descriptions.Item>
                        </Descriptions>
                        <Space>
                            <Button type="primary">Edit Paket</Button>
                            <Button type="default" onClick={()=> history.push(`/package-medicine`) }>Kembali</Button>
                        </Space>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card title="tambah obat">
                        <Form layout="vertical">
                            <Recipe setRecipe={setRecipeMedicine!} />
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Card title="data paket obat">
                        <DataTable
                            columns={columns}
                            data={rows}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            // subHeaderComponent={subHeaderComponentMemo}
                            striped={true}
                            highlightOnHover={true}
                            noHeader={true}
                            className="data-table"                   
                            
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}