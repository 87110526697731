import { FourthStepForm } from "../../../components/doctor/checkup/fourthStepForm";

const FourthStepPage = () => {
  return (
    <>
      <FourthStepForm />
    </>
  );
};

export default FourthStepPage;
