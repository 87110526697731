import { Button, Col, Radio, Row, Typography } from "antd";
import { FC } from "react";
import Card from "../../layout/card";
import Title from "../../layout/title"

const MobilePolies: FC = () => {
    const columns = [
        {
            
        }
    ]
    return <>
    <Title title={"Layanan Mobile"} description="pengaturan untuk mengaktifkan layanan di mobile app"/>
    <Row>
        <Col xs={24} sm={24} md={16} lg={16} xl={16} >
            <Card title="aktifkan poli">
                <Row justify="space-around"  style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Text>pelayanan</Typography.Text>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Typography.Text>diperlukan dokter?</Typography.Text>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Typography.Text>status aktif?</Typography.Text>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Typography.Text></Typography.Text>
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>poli umum</Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="1" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Button>atur jadwal buka</Button>
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>poli gigi</Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>kia / bidan</Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>usg</Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>kecantikan  </Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        
                    </Col>
                </Row>
                <Row justify="space-around" style={{ marginBottom : 10 }}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                        <Typography.Title level={5}>lab</Typography.Title>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="0" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Radio.Group defaultValue="1" buttonStyle="solid">
                            <Radio.Button value="1">Ya</Radio.Button>
                            <Radio.Button value="0">Tidak</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5} xl={5} >
                        <Button>atur jadwal buka</Button>
                    </Col>
                </Row>
            </Card>
        </Col>
    </Row>
    </>
}

export default MobilePolies