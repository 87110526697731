import React, {useEffect, useState} from "react";
import Title from "../../layout/title";
import {message} from "antd";
import Card from "../../layout/card";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import {PaginationData} from "../../../services/utility";
import usePatient, {Patient} from "../../../services/Registration/Patients";
import {EnterIconButton} from "../../form/icon-button/EnterIconButton";
import {useHistory} from "react-router";
import {DataTable, limit} from "../../layout/dataTable/dataTable";
import {SelectItem} from "../../form/Select";
import {IdentityInsurance} from "../../../services/global/Identity";
import {VisibilityIconButton} from "../../form/icon-button/VisibilityIconButton";
import {PreAnamnesisModal} from "../../form/modal/PreAnamnesisModal";
import useInsurance, {
  Insurance,
} from "../../../services/configuration/Insurance";
import {useRecoilValue} from "recoil";
import {triggerActiveRoom} from "../../layout/mainLayout";
import _ from "lodash";
import {honorifics} from "../../../helper";

const INSURANCE: SelectItem[] = [
  {
    display: "BPJS",
    value: IdentityInsurance.BPJS,
  },
  {
    display: "UMUM",
    value: IdentityInsurance.GENERAL,
  },
  {
    display: "ASURANSI",
    value: IdentityInsurance.INSURANCE,
  },
];

export const DataPatient: React.FC = () => {
  const pathNewPatient = window.location.pathname + "/new";
  const pathEditPatient = (id: string) => {
    return window.location.pathname + `/${id}`;
  };
  const history = useHistory();
  const [totalPage, setTotalPage] = useState<any>(0);

  const [visible, setVisible] = useState(false);

  const [patients, setPatients] = useState<PaginationData<Patient[]>>({
    data: [],
    pagination: {},
  });
  const [insurance, setInsurance] = useState<SelectItem[]>([]);

  const [sizePerPage, setSizePerPage] = useState(limit[2].value);
  const [page, setPage] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState("");
  const [selectedPatient, setSelectedPatient] = useState<Patient>();

  const triggerQueueNum = useRecoilValue(triggerActiveRoom);
  const patientService = usePatient();
  const insuranceService = useInsurance();

  const loadPatient = () => {
    setLoader(true);
    patientService
      .fetch("", sizePerPage, page)
      .then((patient) => {
        setPatients(patient);
        setTotalPage(patient.pagination.total_page);
      })
      .catch((e) => message.error(e))
      .finally(() => setLoader(false));

    // getInsurance();
  };

  // const getInsurance = async () => {
  //   const insurance: SelectItem[] = [];
  //   const result = await insuranceService.fetch().then((res) => res.data);

  //   await Promise.all(
  //     _.map(
  //       result,
  //       async (val) =>
  //         await insurance.push({
  //           display: val.name as string,
  //           value: val.id!,
  //         })
  //     )
  //   );

  //   setInsurance(insurance);
  // };

  const handleEnterButton = (id: string) => {
    handleSelectedPatient(id);
    setVisible(true);
  };

  const handleSelectedPatient = (id: string) => {
    patientService
      .get(id)
      .then((res) => setSelectedPatient(res))
      .catch((err) => message.error(err));
  };

  const column = [
    {
      name: "Tanggal",
      cell: (p: any) => (
        <Text strong> {moment(p.created).format("DD-MM-YYYY")}</Text>
      ),
      width: "10%",
    },
    {
      name: "Nomor",
      cell: (p: any) => <Text strong>{p.no_registration}</Text>,
      width: "10%",
    },
    {
      name: "Nama",
      cell: (p: any) => (
        <Text strong>{honorifics(p.fullname, p.marital_status, p.gender)}</Text>
      ),
      width: "27%",
    },
    {
      name: "Jenis Kelamin",
      cell: (p: any) => <Text strong>{p.gender}</Text>,
      width: "10%",
    },
    {
      name: "Alamat",
      cell: (p: any) => (
        <Text strong>
          {p.address} {p.village.name} {p.village.district.name}{" "}
          {p.village.district.city.name} {p.village.district.city.province.name}
        </Text>
      ),
      width: "30%",
    },
    {
      name: "",
      cell: (p: any) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {localStorage.getItem("activeQueue") && (
            <EnterIconButton
              onClick={() => handleEnterButton(p.id)}
              style={{cursor: "pointer"}}
            />
          )}
          <VisibilityIconButton
            onClick={() => history.push(pathEditPatient(p.id))}
            style={{width: "25px", marginLeft: "5%", cursor: "pointer"}}
          />
        </div>
      ),
      width: "7%",
    },
  ];

  useEffect(() => {
    loadPatient();
  }, [triggerQueueNum, page, sizePerPage]);

  return (
    <>
      <Title
        title="Pendaftaran Pasien"
        description="data semua pasien yang terekap dalam sistem"
      />
      <Card title="Data Pasien" addButton={true} search={true}>
        {/* {JSON.stringify(selectedPatient)} */}
        <DataTable
          datas={patients.data}
          columns={column}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          limit={limit}
          totalPage={totalPage}
        />
      </Card>
      <PreAnamnesisModal
        visible={visible}
        setVisible={setVisible}
        visitType={INSURANCE}
        // insuranceType={insurance}
        selectedPatient={selectedPatient}
      />
    </>
  );
};
