
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";
import { Medicine } from "./medicine";

export interface Unit {
    id? : string
    inc_id?: number
    name: string
    medicine: Medicine
    fill: number
}


const UnitClient = baseURL

const useUnit = () => ({
    async create(Unit: Unit): Promise<Unit>{
        const {data} = await UnitClient.post(`/medicine/unit/`, Unit)
        return data
    },
    async update(id: string, Unit: Unit): Promise<Unit>{
        const {data} = await UnitClient.patch(`/medicine/unit/${id}`, Unit)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await UnitClient.delete(`/medicine/unit/${id}`)
        return data
    },
    async get(id:string): Promise<Unit>{
        const {data} = await UnitClient.get(`/medicine/unit/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Unit[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await UnitClient.get(`/medicine/unit/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useUnit