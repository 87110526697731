import { Col, Row, Table, Typography } from "antd"
import { FC } from "react"
import DataTable from "react-data-table-component";
const { Text } = Typography

const ResultRecipe: FC = () => {
  const customStyles = {
    rows : {
        style : {
            border: '1px solid #edf0f2'
        }
    },
    headCells: {
        style: {
            backgroundColor: '#108ee9',
            color: '#fff',
            border: '1px solid #edf0f2'

        },
    },
    cells : {
        style : {
            border: '1px solid #edf0f2'
        }
    }
};

  const columns = [
      {
        name: 'Nama Obat',
        selector: (row: any) => <Text strong>{row.name}</Text>,
      }, 
      {
        name: 'Penggunaan',
        selector: (row: any) => <Text strong>{row.used}</Text>
      }, 
      {
        name: 'note',
        selector: (row: any) => <Text strong>{row.note}</Text>
      }
    ]
  const data = [
    {
      key: '1',
      name: 'PCT',
      used : '3x1',
      note: 'Setelah Makan'
      
    }
  ];

    
    return <>


                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 10}}>
                  <DataTable
                        data={data}
                        columns={columns}
                        pagination={false}
                        style={{ marginTop: '-4em'}}
                        customStyles={customStyles}
                    />
                </Col>

    </>
}

export default ResultRecipe