
import { FC } from 'react'
import DataSelling from '../../components/cashier/selling'

const ListSellingCashierPage: FC = () => {
    return <div>
        <DataSelling/>
    </div>
}

export default ListSellingCashierPage