import { FC,  useState } from 'react'
import { Button, Col,  Form,  Input,  Modal,  Row,  Space,  Typography} from 'antd';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import useOrder from '../../../services/pharmacy/order';
import { orderDetail } from '../../../services/pharmacy/order.detail';
import ModalDetailMedicine from '../modal/_detail_medicine';
import { MedicineDetail } from '../../../services/pharmacy/medicine.detail';
import { successNotification } from '../../layout/notification';
import { DeleteIconButton } from '../../form/icon-button/DeleteIconButton';

const { Text, Link } = Typography;
const { confirm } = Modal

const DetailReleasePharmacy: FC = () => { 
    // Data Table
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [medicineModal, setMedicineModal] = useState(false)
    const orderServices = useOrder()
    const [obat, setObat] = useState<MedicineDetail>()

    const [form, setForm] = useState<orderDetail>({
        qty: 0,
        detail_medicine : {
            inc_id : 0 
        } as MedicineDetail
        
    } as any)

    const data = [
        {
            id: 1,
            name: 'Paracetamol',
            unit: 'Box',
            qty: '4',
        }
    ]


    const columns = [
        {
            name: 'No',
            width: '10%',
            selector: (row: any) =>  <Text strong> {row.id} </Text>
        },
        {
            name: 'Nama Obat',
            width: '40%',
           selector: (row: any) =>  <Text strong> { row.name} </Text>
        },
        {
            name: 'Qty',
            width: '30%',
            selector: (row: any) =>  <Text strong> {row.qty} {row.unit}</Text>
        },
        {
            name: '',
            width: '10%',
            selector: (row: any) => <a title="hapus obat" onClick={() => deleteMedicine(row.id as any)} style={{ color: '#FFF' }}> <DeleteIconButton style={{ width: 20}}/></a>  
        }
    ]

    const onChangeObat = (m: MedicineDetail) => {
        setObat(m)

        setForm({
            ...form,
            detail_medicine : {
                inc_id : m.inc_id
            } as MedicineDetail
        })

        setMedicineModal(false)
    }

    const submit = () => {
        console.log(form)
    }

    const deleteMedicine = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              setLoader(true)
              orderServices
                  .delete(id)
                  .then(() => {
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
        })
    }

    return <div>
        <Title title="Pengeluaran Obat" description="detail pengisian pengeluaran obat" />
        <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="Input Obat" >
                    <Form layout="vertical">
                        <Form.Item label="obat" className='lable-form-input'>
                            <Input onClick={()=> setMedicineModal(true)}  className="form-control" value={obat?.unit.medicine.name}  onChange={({target : {value: detail_medicine}}) => setForm({...form, detail_medicine: {inc_id: detail_medicine} as any})}/>
                        </Form.Item>
                        <Form.Item label="qty obat" className='lable-form-input'>
                            <Input onChange={({target : {value: qty}}) => setForm({...form, qty} as any)} className='form-control'/>
                        </Form.Item>
                        
                    </Form>
                    <Form.Item>
                        <Space>

                        <Button type="default" onClick={()=> history.push(`/release`)} >Kembali</Button>
                        <Button type="primary" onClick={submit} >Simpan</Button>
                        </Space>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }

                    <Card title="Daftar Pengeluaran Obat">
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination={false}
                            className="data-table"
                        />
                        <Modal
                            title="Data Obat"
                            style={{ top: 20 }}
                            visible={medicineModal}
                            onOk={() => setMedicineModal(false)}
                            onCancel={() => setMedicineModal(false)}
                            width={'50%'}
                        >
                            <ModalDetailMedicine 
                                onSelectedObat={onChangeObat} />
                        </Modal>
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DetailReleasePharmacy