import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row, Space, Steps, Typography } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import {  successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import useUnit, { Unit } from '../../../services/pharmacy/units';
import useMedicineDetail, { MedicineDetail } from '../../../services/pharmacy/medicine.detail';
import Select, { SelectItem } from '../../layout/select';
import _ from 'lodash'
import {  FilterComponent2 } from '../../layout/fiterDataTable';
import NumberFormat from 'react-number-format';
import { GlobalCurrency } from '../../../context/context';

const { Search } = Input

const {Step} = Steps

const { confirm } = Modal


const FormMedicineDetail = () => {
 
  //Global Variable
  const history = useHistory()
  const params = useParams<any>()
  const MedicineDetailService = useMedicineDetail(params.id)
  const [loader, setLoader] = useState<boolean>(false)


  //Form Variabel

  const [form, setForm]  = useState<MedicineDetail>({
    unit: {
      id: ''
    } as any,
    smallest_unit: '' as any,
    primary: true,
    price_selling: '' as any,
    price_purchase: '' as any
  
  })

  const resetField = () => {
    setForm(
      {
        unit: {
          id: ''
        } as any,
        smallest_unit: '' as any,
        primary: true,
        price_selling: '' as any,
        price_purchase: '' as any
      }
    )
  }


  const [unit, setUnit] = useState<SelectItem[]>([])
  const unitServices = useUnit()

  const getUnits = async (value: string) => {
      const unit:SelectItem[] = []
      const result = await unitServices.fetch(value).then(res => res.data)
      
      await Promise.all(_.map(result, async (val) => 
          await unit.push({
              display: val.name,
              value: val.id as any
          })

      ));

      setUnit(unit)
  }

  //Start Datatable Medicine Detail
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadMedicine();
      }
    };

    return <FilterComponent2  onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);
  const [medicineDetail, setMedicineDetail] = useState<PaginationData<MedicineDetail[]>>({data: [], pagination: {}})
  const filteredItems = medicineDetail.data.filter(item => 
      // item.unit.medicine.name && item.unit.medicine.name.toLowerCase().includes(filterText.toLowerCase())
      item.price_purchase || 
      item.price_selling
  );

  const loadMedicine = () => {
      setLoader(true)
      MedicineDetailService
      .fetch('', perPage, page)
        .then(medicineDetail => {
            setMedicineDetail(medicineDetail)
            console.log(medicineDetail)
            let total = (medicineDetail.pagination.total_page as number)
              setTotalRows(total)
        }
        )
      .catch(e => console.log(e))   
      .finally(() => setLoader(false))
      }
  
  const filterData = (searching: string) => {
      setLoader(false)
      setFilterText(searching)
      MedicineDetailService
          .fetch(searching, perPage, 1)
          .then(medicineDetail => {
              setMedicineDetail(medicineDetail)
              let total = (medicineDetail.pagination.total_page as number)
              setTotalRows(total)
              }
              )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
      setPage(pages)
      setLoader(true)
      MedicineDetailService
          .fetch('', perPage, pages)
          .then(medicineDetail => {
              setMedicineDetail(medicineDetail)
              let total =  (medicineDetail.pagination.total_page as number)
              setTotalRows(total)
              }
              )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
  };
  
  const handlePerRowsChange = (newPerPage: number, pages: number) => {
      setPerPage(newPerPage)
      setPage(pages)
      setLoader(true)
      MedicineDetailService
          .fetch('', newPerPage, pages)
          .then(medicineDetail => {
              setMedicineDetail(medicineDetail)
              let total = (medicineDetail.pagination.total_page as number)
              setTotalRows(total)
              }
              )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
  }

  useEffect(loadMedicine, [])

  const columns = [
    {
      name: <Typography.Text strong>satuan</Typography.Text>,
      cell: (p: MedicineDetail) => p.unit.name,
      
    },
    {
        name: <Typography.Text strong>satuan terkecil</Typography.Text>,
        cell: (p: MedicineDetail) => p.smallest_unit,
        
    },
    {
        name: <Typography.Text strong>harga beli</Typography.Text>,
        cell: (p: MedicineDetail) => GlobalCurrency(p.price_purchase),
        
    },
    {
        name: <Typography.Text strong>harga jual</Typography.Text>,
        cell: (p: MedicineDetail) => GlobalCurrency(p.price_selling),
        
    }
     
  ]


  const submit = () => {
    // console.log( !!params.id)
    console.log(params.id)
          
      params.id !== null && 
      MedicineDetailService
        .create(params.id, form)
        .then(() => { 
            successNotification('tambah')
            loadMedicine()
            resetField()
        })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      // !!params.id === true && UnitServices
      //     .update(params.id, form)
      //     .then(() => { 
      //         loadUnits() 
      //         successNotification('perbaharui')
      //         window.location.href = "/unit"
      //     })
      //     .catch(e => console.log(e))
      //     .finally(()=> setLoader(false))
  }


    useEffect(() => {
      loadMedicine()
    }, [])


  return (<>
    <Title title="Obat" description="form pengisian data satuan obat" />
      <Row>

        <Col xs={8} sm={8} md={8} lg={8} xl={8} offset={16} style={{ marginBottom: 40}}>
            <Steps>
                <Step status="finish" key={1} title={"Pendataan Obat"} />
                <Step status="process" key={2} title={"Detail Obat"} />
            </Steps>
        </Col>
      </Row>
    
        <div className="steps-content" style={{ marginTop: 20}}>
          <Row>
              <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                  <Card title="detail satuan obat" >
                    
                      <Form layout="vertical">

                          <Select name="unit" label="satuan obat"  onValueChange={(unit) => { setForm({...form, unit:{ id: unit} as any})}} items={ unit } showSearch filterOption={false} onSearch={ getUnits } />

                          <Form.Item label="isi satuan / satuan terkecil"  className="lable-form-input">
                              <InputNumber  value={form.smallest_unit} min={0} onChange={(smallest_unit: number) => setForm({...form, smallest_unit} as any)} className="form-control" style={{ width: '100%'}}/>
                          </Form.Item>
                          <Form.Item label="harga beli"  className="lable-form-input">
                            <NumberFormat thousandSeparator={true}  value={form.price_purchase}  onValueChange={({value: price_purchase}) => setForm({...form, price_purchase} as any)} className='form-control-currency' />
                          </Form.Item>


                          <Form.Item label="harga jual"  className="lable-form-input">
                            <NumberFormat thousandSeparator={true}  value={form.price_selling}  onValueChange={({value: price_selling}) => setForm({...form, price_selling} as any)} className='form-control-currency' />
                          </Form.Item>

                      </Form>
                      <Form.Item>
                          <Space>
                            
                            <Button type="default" onClick={ () => history.push(`/medicine/${params.id}`) }>
                                Kembali
                            </Button>
                            <Button type="primary" onClick={submit} >
                                Simpan
                            </Button>
                          </Space>
                      </Form.Item>
                  </Card>
              </Col>
              <Col xs={24} sm={24} md={1} lg={1} xl={1}>
              </Col>
              <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                  {
                      loader && <div>Loading .... </div>
                  }
                  <Card title="detail obat">
                    <DataTable
                       columns={columns}
                       data={filteredItems}
                       pagination
                       paginationServer
                       paginationTotalRows={totalRows}
                       onChangeRowsPerPage={handlePerRowsChange}
                       onChangePage={handlePageChange}
                       paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                       subHeader
                       subHeaderComponent={subHeaderComponentMemo}
                       striped={true}
                       highlightOnHover={true}
                       className='data-table'
                       noHeader={true}
                    />
                  </Card>
              </Col>
          </Row>
        </div>
    
    </>
  );
};

export default FormMedicineDetail 
