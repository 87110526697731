import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Row, Typography, Card as CardTheme, Select, DatePicker } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Icon, { DeleteOutlined, FormOutlined, LoginOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useQueue, { Queue } from '../../../services/queue';
import { GlobalStatus } from '../../../context/context';
import { Notification } from "../../layout/notification";
import DataTable from 'react-data-table-component';
import { FilterComponent2 } from '../../layout/fiterDataTable'
import { NextButton } from '../../icons/nextButton';

const { Text, Link } = Typography;


const DataVisit: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                loadQueue();
            }
        };

        return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Queue, setQueue] = useState<PaginationData<Queue[]>>({ data: [], pagination: {} })
    const filteredItems = Queue.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const QueueService = useQueue()
    const history = useHistory()
    const params = useParams() as any

    const loadQueue = () => {
        setLoader(true)
        QueueService
            .fetch('', '', perPage, page)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        QueueService
            .fetch(searching, '', perPage, 1)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const handlePageChange = (pages: number) => {
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', perPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', newPerPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }


    const deleteQueue = (id: string) => {
        const c = window.confirm(`Are You Sure Delete ?`)
        if (c) {
            setLoader(true)
            QueueService
                .delete(id)
                .then(() => {
                    loadQueue()
                    Notification('Hapus Data Berhasil')
                })
                .catch(e => console.log(e))
                .finally(() => setLoader(false))
        }
    }

    useEffect(loadQueue, [])

    const columnss = [
        {
            name: 'Tanggal',
            cell: (p: Queue) => p.date
        },
        {
            name: 'No',
            cell: (p: Queue) => p.nomor
        },
        {
            name: 'Layanan',
            cell: (p: Queue) => p.poly
        },

        {
            name: 'Nama',
            cell: (p: Queue) => p.name
        },


        {
            name: 'Status',
            cell: (p: Queue) => GlobalStatus(parseInt(p.status as any))
        },
        {
            name: 'No. Hp',
            cell: (p: Queue) => p.handphone
        },
        {
            name: 'Alamat',
            cell: (p: Queue) => p.address
        },


        {
            name: 'Prioritas',
            cell: (p: Queue) => p.priority
        },
        {
            name: 'Aksi',
            cell: (c: Queue) => {
                return (
                    <>
                        <Button type="text" title="Update" onClick={() => history.push(`/Queue/${c.id}`)}><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={() => deleteQueue(c.id as string)}><DeleteOutlined /></Button>
                    </>
                )
            }
        }
    ]

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            nomor: 'A002',
            poly: 'Poli Kecantikan',
            name: 'Jajang Dadas Bengeut',
            status: <Text type="success">BPJS</Text>,
            handphone: '0192830123213',
            address: 'dfakdfhal;jdfha',
            priority: '-'
        }
    ]


    const columns = [
        {
            name: 'tanggal',
            wrap: true,
            selector: (row: any) => <Text strong>  {row.date} </Text>
        },
        {
            name: 'antrian',
            selector: (row: any) => <Text strong>  {row.nomor} </Text>
        },
        {
            name: 'layanan',
            wrap: true,
            selector: (row: any) => <Text strong>  <span >{row.poly} - {row.status}</span></Text>
        },

        {
            name: 'nama',
            width: '20%',
            wrap: true,
            selector: (row: any) => <a onClick={()=> history.push(`/visit/${row.id}`)} style={{ fontWeight: 'bold'}} >{row.name} </a>
        },

        {
            name: 'no. hp',
            wrap: true,
            selector: (row: any) => <Text strong>  {row.handphone} </Text>
        },
        {
            name: 'alamat',
            wrap: true,
            selector: (row: any) => <Text strong>  {row.address} </Text>
        },
        {
            name: '',
            width: '12%',
            selector: (row: any) => <Button type="text" size="middle" title="Detail Kunjungan" onClick={() => history.push(`/visit/${row.id}`)}><NextButton /></Button>
        }
    ]

    return <div>
        <Title title="Kunjungan" description="data kunjungan pasien" />
        <Row>

            <Col span={24}>
                <CardTheme style={{ boxShadow: '0px 3px 10px #b6b6b6', borderRadius: 15, marginBottom: 10, minHeight: '80px' }}>
                    <Row>
                        <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ borderRight: '3px solid #ccc', marginRight: 40, marginTop: 10 }}>
                            <Typography.Title level={3} style={{ marginBottom: 0, fontWeight: 'bold', color: '#1890ff' }}>Pencarian </Typography.Title>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                            <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                        </Col>
                        <Col span={1} />
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                            <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                        </Col>

                        <Col span={1} />
                        <Col xs={24} sm={24} md={2} lg={2} xl={2} style={{ marginTop: 10}}>
                            <Button type="primary">Cari Data</Button>
                        </Col>
                    </Row>
                </CardTheme>
                {
                    loader && <div>Loading .... </div>
                }

                <Card title="Daftar Kunjungan">
                    <DataTable
                        columns={columns}
                        data={data}
                        className="data-table"
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        noHeader={true}
                    />
                </Card>

            </Col>
        </Row>
    </div>
}

export default DataVisit