import {Menu} from "antd";
import {FC, useContext} from "react";
import "./header.css";
import {DataSidebar} from "../layout/dataSidebar";
import {UserMenuIcon} from "./icons/UserMenuIcon";
import {AuthContext} from "../../context/AuthContext";
import {Link, useHistory} from "react-router-dom";
interface IProps {
  dataSidebar: {
    id: string;
    icon: JSX.Element;
    title: string;
    path: string;
  }[];
}

const HeaderLayout: FC<IProps> = ({dataSidebar}) => {
  const {dispatch} = useContext(AuthContext);
  const history = useHistory();
  return (
    <>
      <Menu theme="light" defaultSelectedKeys={["1"]} mode="inline">
        {dataSidebar.map((data: any) => (
          <DataSidebar
            id={data.id}
            icon={data.icon}
            title={data.title}
            path={data.path}
            exact={data.exact}
          />
        ))}
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            bottom: "5%",
            left: "5%",
          }}
          onClick={() => {
            dispatch({
              type: "LOGOUT",
            });
            history.replace("/");
          }}
        >
          {/* marginTop: "200px" */}
          <div
            style={{
              position: "fixed",
              left: 15,
              right: 0,
              bottom: 10,
              marginTop: 10,
            }}
          >
            {/* <Link to={`/logout`}><UserMenuIcon/></Link> */}
            <UserMenuIcon />
          </div>
        </div>
      </Menu>
    </>
  );
};

export default HeaderLayout;
