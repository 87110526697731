import {Modal} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import useICDInterface, {
  ICDInterface,
} from "../../../services/configurations/type.diseases";
import {PaginationData} from "../../../services/utility";
import {IModal} from "./PatientMedicalRecord";
import Text from "antd/lib/typography/Text";
import {AddIconButton} from "../icon-button/AddIconButton";
import {FilterComponent} from "../../layout/fiterDataTable";
import DataTable from "react-data-table-component";
import TreatmentFormPage from "../../../pages/management/treatment/_form";

interface IDiseaseCategoryList extends IModal {
  setSelectedDiseaseType: React.Dispatch<React.SetStateAction<ICDInterface>>;
}

export const DiseaseCategoryList: React.FC<IDiseaseCategoryList> = ({
  visible,
  setVisible,
  setSelectedDiseaseType,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (searchQuery) {
        setResetPaginationToggle(!resetPaginationToggle);
        setSearchQuery("");
        loadTypeDiseases();
      }
    };
    return (
      <FilterComponent
        onFilter={(e: any) => filterData(e.target.value)}
        onClear={handleClear}
        filterText={searchQuery}
      />
    );
  }, [searchQuery, resetPaginationToggle]);
  const [typeDiseases, setTypeDiseases] = useState<
    PaginationData<ICDInterface[]>
  >({data: [], pagination: {}});
  const filteredData = typeDiseases.data.filter((typeDisease) => {
    (typeDisease.code &&
      typeDisease.code.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (typeDisease.name &&
        typeDisease.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (typeDisease.general_name &&
        typeDisease.general_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase()));
  });

  const typeDiseaseService = useICDInterface();

  const filterData = (searchQuery: string) => {
    setSearchQuery(searchQuery);
    loadTypeDiseases();
  };

  const loadTypeDiseases = () => {
    setIsLoading(true);
    typeDiseaseService
      .fetch(searchQuery, sizePerPage, currentPage)
      .then((typeDisease) => {
        setTypeDiseases(typeDisease);
        let total = typeDisease.pagination.total_page as number;
        setTotalPage(total);
        console.log(typeDisease);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    loadTypeDiseases();
  };

  const handlePerRowsChange = (size: number, pages: number) => {
    setSizePerPage(size);
    setCurrentPage(pages);
    loadTypeDiseases();
  };

  useEffect(loadTypeDiseases, []);

  const onAddButtonClick = (idDisease: string) => {
    setSelectedDiseaseType(typeDiseases.data.find((d) => d.id === idDisease)!);
    setVisible(false);
  };

  const columns = [
    {
      name: "Kode",
      cell: (p: any) => (
        <Text strong style={{color: "#27AAE1"}}>
          {p.code}
        </Text>
      ),
    },
    {
      name: "Nama ICD 10",
      cell: (p: any) => <Text strong>{p.name}</Text>,
    },
    {
      name: "Nama Umum",
      cell: (p: any) => <Text strong>{p.general_name}</Text>,
    },
    {
      name: "Keterangan",
      cell: (p: any) => <Text strong>{p.info}</Text>,
      width: "",
    },
    {
      name: "",
      cell: (p: any) => (
        <AddIconButton
          style={{cursor: "pointer"}}
          onClick={() => onAddButtonClick(p.id)}
        />
      ),
      width: "",
    },
  ];
  return (
    <Modal
      title="DAFTAR LIST KATEGORI PENYAKIT"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
    >
      {/* {isLoading && <div>Loading...</div>} */}
      <DataTable
        columns={columns}
        data={typeDiseases.data}
        pagination
        paginationServer
        paginationTotalRows={totalPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        striped={true}
        highlightOnHover={true}
        noHeader={true}
        className="data-table"
      />
    </Modal>
  );
};
