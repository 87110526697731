import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";
import { InventoryLocation } from "./inventory.location";
export interface Anamnesis { 
    inc_id?: number
    id?: string
}

export interface RecipeMedicine {
    inc_id?: number
    id?: string
    visit: Anamnesis
    inventory_location: InventoryLocation
    used: number
    time: number
    day: number
    total: number
    note: string
    info: string
}

export enum StatusUsed { 
    AFTER = "SETELAH MAKAN",
    BEFORE  = "SEBELUM MAKAN"
}

export enum Day {
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    week = 7,
    month = 30
}



const RecipeClient = baseURL

const useRecipe = () => ({
    async create(Recipe: RecipeMedicine): Promise<RecipeMedicine>{
        const {data} = await RecipeClient.post(`/pharmacy/recipes/`, Recipe)
        return data
    },
    async update(id: string, Recipe: RecipeMedicine): Promise<RecipeMedicine>{
        const {data} = await RecipeClient.patch(`/pharmacy/recipes/${id}`, Recipe)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await RecipeClient.delete(`/pharmacy/recipes/${id}`)
        return data
    },
    async get(id:string): Promise<RecipeMedicine[]>{
        const {data} = await RecipeClient.get(`/pharmacy/recipes/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<RecipeMedicine[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await RecipeClient.get(`/pharmacy/recipes/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useRecipe