import {baseURL, id} from "../baseurl";
import {Clinic} from "./clinic";
import {PaginationData} from "../utility";
import {Moment} from "moment";

export interface ClinicPoly {
  inc_id?: number;
  id?: string;
  poly: {
    inc_id: number;
    id: string;
    name: string;
    code_bpjs: string | null;
    created: string;
    updated: string;
  };
  order: string | number;
  status: boolean;
  code: string;
  is_doctor: boolean;
  max_online_registration: number;
  shift?: {
    name: string;
    startTime: string;
    endTime: string;
  };
}

export enum StatusClinicPoly {
  TRUE = "AKTIF",
  FALSE = "TIDAK AKTIF",
}

export enum Shift {
  PAGI = "PAGI",
  SIANG = "SIANG",
}

const ClinicPolyClient = baseURL;
const clinicId = id;

const useClinicPoly = () => ({
  async create(ClinicPoly: ClinicPoly): Promise<ClinicPoly> {
    const {data} = await ClinicPolyClient.post(
      `/config/poly/clinic`,
      ClinicPoly
    );
    return data;
  },
  async update(id: string, ClinicPoly: ClinicPoly): Promise<ClinicPoly> {
    await ClinicPolyClient.patch(`/config/poly/clinic/${id}`, ClinicPoly);
    return ClinicPoly;
  },
  async delete(id: string): Promise<void> {
    const {data} = await ClinicPolyClient.delete(`/config/poly/clinic/${id}`);
    return data;
  },
  async get(id: string): Promise<ClinicPoly> {
    const {data} = await ClinicPolyClient.get(`/config/poly/clinic/${id}`);
    return data;
  },
  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<ClinicPoly[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";
    const {data} = await ClinicPolyClient.get(
      `/config/poly/clinic/?${searching}&${sizes}&${rows}`
    );
    return data;
  },
});

export default useClinicPoly;
