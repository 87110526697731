import { FC, useState } from 'react'
import {  Row,  Typography, Divider} from 'antd';

import { MedisyIcon } from '../../form/icon-button/MedisyIcon';
import ResultProfile from '../result/profile';
import ResultFirstCheck from '../result/first_check';
import ResultPhysicalCheck from '../result/physical_check';
import ResultDiagnosis from '../result/diagnosis';
import ResultTreatment from '../result/treatment';
import ResultRecipe from '../result/recipe';


const PrintMedicalRecord: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)

    const columns = [
        {
          title: 'Name',
          dataIndex: 'medicine',
        },
        {
            title: 'Keterangan',
            dataIndex: 'info',
          },
        {
          title: 'Total',
          dataIndex: 'total',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
            title: 'Total Harga',
            dataIndex: 'total_price',
        },
        {
            title: '',
            dataIndex: ''
        }
      ];
      
      const data = [
        {
          key: '1',
          name: 'John Brown',
          borrow: 10,
          repayment: 33,
        }
      ];

  

    return <> 
            <div style={{ padding: 10}}>
        
            <table style={{ borderBottom: '1px solid #000', width: '100%', marginTop: 10 }}>
                  <td width={80} style={{ padding: 10}} align="center" valign="middle"><MedisyIcon/></td>
                  <td align="center" valign="middle">
                      <Typography.Title level={4}>Klinik Keluarga</Typography.Title>
                      <Typography.Text style={{ fontSize: '11px', marginTop: '-10px', position: 'unset'}}>Kp. Cigombong No. 64 RT.01/09 Desa Ciherang, Kec. Pacet - Cianjur 43253
                          <br/>Telp. (0263) 513513 Email : info@klinikkeluarga.com
                          <br/>Website : www.klinikkeluarga.com
                      </Typography.Text>
                  </td>
                  <td width={80}  align="center" valign="middle" style={{ padding : 10}}><MedisyIcon/></td>
              </table>

            <p style={{ textAlign: 'center', fontWeight: 'bold', margin: 10}}>Rekam Medis Pasien</p>
                  
            <Row style={{ marginTop: 30}}>   
                <Typography.Title level={5} >1. Profil Pasien</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>
                
                <ResultProfile/>

                {/* Pemeriksaan Awal */}

                <Typography.Title level={5} style={{ marginTop: 20}}>2. Pemeriksaan awal</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

                <ResultFirstCheck />

                <Typography.Title level={5} style={{ marginTop: 20}}>3. Pemeriksaan fisik & penunjang</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

                <ResultPhysicalCheck />

                <Typography.Title level={5} style={{ marginTop: 20}}>4. Diagnosa</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

                <ResultDiagnosis/>

                <Typography.Title level={5} style={{ marginTop: 20}}>5. Tindakan & Perlengkapan</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

                <ResultTreatment/>


                <Typography.Title level={5} style={{ marginTop: 20}}>6. Resep Obat</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

                <ResultRecipe/>

                <Typography.Title level={5} style={{ marginTop: 20}}>7. Keterangan</Typography.Title>
                <Divider dashed style={{ marginTop: '0'}}/>

            </Row>


        </div>
    </>
}

export default PrintMedicalRecord