import { Col, Row, Card, Typography } from "antd"
const { Title } = Typography

const DashboardManagement = () => {
    return <>
         
        <Row justify="space-around">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row justify="space-between">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Row justify={'space-between'}>
                        
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Card className="card-display">

                                <Title level={5} disabled>total karyawan</Title>
                                <div style={{ margin: 10, textAlign: 'right'}}>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>4000</Typography.Text>
                                    <span className="mock-block"> orang</span>
                                </div>
                            </Card>
                        </Col> 
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Card className="card-display">

                                <Title level={5} disabled>total dokter</Title>
                                <div style={{ margin: 10, textAlign: 'right'}}>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>4000</Typography.Text>
                                    <span className="mock-block"> orang</span>
                                </div>
                            </Card>
                        </Col> 
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Card className="card-display">

                                <Title level={5} disabled>total perawat</Title>
                                <div style={{ margin: 10, textAlign: 'right'}}>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>8</Typography.Text>
                                    <span className="mock-block"> orang</span>
                                </div>
                            </Card>
                        </Col> 
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Card className="card-display">

                                <Title level={5} disabled>total perusahaan mitra</Title>
                                <div style={{ margin: 10, textAlign: 'right'}}>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>10</Typography.Text>
                                    <span className="mock-block"> orang</span>
                                </div>
                            </Card>
                        </Col> 

                        
                    </Row>

                </Col>
                </Row>
                <Row justify="space-around">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card className="card-display" style={{ minHeight: '450px', marginBottom: 10 }}>
                            <Title level={5} disabled>kunjungan pasien</Title>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
               
                
            </Col>

           
        </Row>
    </>
}

export default DashboardManagement