import  { FC, useEffect, useState } from 'react'
import { Col, Row, Form, Space, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../layout/card'

import Title from "../../layout/title";
import useClinicBpjs, { BridgingClinicInterface } from '../../../services/configurations/bpjs';
import { useForm } from 'antd/lib/form/Form';
import Input from '../../layout/form/input';


export const BridgingBpjs: FC = () => {
    const [loader, setLoader] = useState<boolean>(false)
    const bpjsService = useClinicBpjs()
    const history = useHistory()
    const params = useParams() as any
    const [form] = useForm()
    const [username, setUsername] =  useState('')
    const [password, setPassword] =  useState('')
    const [bridging, setBridging] = useState<BridgingClinicInterface>()


    const loadClinic = () => {
        setLoader(true)
        bpjsService
            .get(params.clinicId)
            .then(clinicBpjs => {
                setBridging(clinicBpjs)
                console.log(params.clinicId)
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const onFinish = (val: any) => {
        const datas: BridgingClinicInterface = {
            username: val.username,
            password: val.password,
            provider_code: val.provider_code
        } 

        console.log(datas)
        // bpjsService
        // .create(params.clinicId, clinicBpjs)
        // .then(clinicBpjs => {
        //             setClinicBpjs(clinicBpjs)
        //             Notification('Transaksi Berhasil')
        //         }
        //     )
        //     .catch(e => console.log(e))   
        //     .finally(() => setLoader(false))

        // loadClinic()
    }

    useEffect(() =>  {
        loadClinic()
    }, [])


    return <div>
       <Title title="Bridging BPJS" description="form untuk bridging bpjs" />
        <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="Bridging Bpjs" >
                    {params.clinicId}
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                        <Input name={'username'} label={'username'} value={username}/>
                        <Input name={'password'} label={'password'} value={''}/>
                        <Input name={'provider_code'} label={'kode provider'} value={''}/>
                        <Space>
                            <Button type="primary" htmlType='submit'>Simpan</Button>
                        </Space>
                    </Form>
                </Card>
            </Col>
            
        </Row>
    </div>
}