import { Modal } from "antd"
import { IModal } from "./PatientMedicalRecord"

export const PatientHistory: React.FC<IModal> = ({ visible, setVisible }) => {
    return (
        <Modal
            title="Riwayat Pasien"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={1000}
        >
            Data Belum diinputkan...
        </Modal>
    )
}