import { Col, Row, Form, Input,   Button, Space, Select as SelectTheme } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from "../../layout/title";
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { successNotification } from '../../layout/notification'
import useHospital, { Hospital } from '../../../services/configurations/hospital';
import Select, { SelectItem } from '../../layout/select';
import _ from 'lodash'
import useCity from '../../../services/global/cities';

const { Option } = SelectTheme

const FormHospital: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const hospitalService = useHospital()
    const history = useHistory()
    const params = useParams() as any


    const cityService = useCity()
    const [city, setCity] = useState<SelectItem[]>([])

    const [hospital, setHospital] = useState<Hospital>(
        {
            name: '',
            email: '',
            phone: '',
            address: '',
            status: '',
            city: {
                id: ''
            } as any
        } 
    )

    const resetField = () => {
        setHospital({
            name: '',
            email: '',
            phone: '',
            address: '',
            status: '',
            city: {
                id: ''
            } as any
        })
    }



    const getCity = async (value: string, provinceId : number | string) => {
        const city:SelectItem[] = []
        const result = await cityService.fetch(value, provinceId).then(res => res.data)
       
        await Promise.all(_.map(result, async (val) => 
            await city.push({
                display: val.name,
                value: val.id
            })
        ));

        setCity(city)
    }
    
  
    const submit = () => {
        const body = {
            ...hospital,
            status: hospital.status === 1
        }

        console.log(body)
        
        // console.log(hospital)
        params.id == 'new' && hospitalService
            .create(body)
            .then(hospital => {
                    console.log(hospital)
                    successNotification('tambah')
                    resetField()
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))

        params.id !== 'new' && hospitalService
            .update(params.id, body)
            .then(hospital => {
                setHospital(hospital)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadHospital = () => {
        setLoader(true)
        hospitalService
            .get(params.id)
            .then(async hospital => {
                console.log(hospital)
                await setCity([
                    {
                        value: hospital.city.id,
                        display: hospital.city.name,
                    }
                ])

                setHospital({
                    name: hospital.name,
                    email: hospital.email,
                    phone: hospital.phone,
                    address: hospital.address,
                    status: hospital.status,
                    city: {
                        id: hospital.city.id
                    } as any
                })
                
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
       if(params.id !== 'new'){ 
           
           setHospital({
               ...hospital, 
               id: params.id
           }) 

       }
        params.id !== 'new' && loadHospital()

    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Tempat Rujukan" description="form pengisian data rumah sakit / tempat rujukan" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card title="penambahan tempat rujukan">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="nama" className='lable-form-input'>
                                <Input data-cy="name"  value={hospital.name} onChange={({target : {value: name}}) => setHospital({...hospital, name})}  className='form-control'/>
                            </Form.Item>
                        </Col>
                    
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="email" className='lable-form-input'>
                                <Input data-cy="email" value={hospital.email}  onChange={({target : {value: email}}) => setHospital({...hospital, email})} className='form-control' />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="telepon" className='lable-form-input'>
                                <Input data-cy="phone" value={hospital.phone}  onChange={({target : {value: phone}}) => setHospital({...hospital, phone})} className='form-control' />
                            </Form.Item>
                        </Col>
 
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Form.Item label="status" className='lable-form-input'>
                            <div id='status-select'>
                                <SelectTheme data-cy="status" onChange={(value: any) => setHospital({...hospital, status : value}) } className="cy-status form-control" defaultValue={1}>
                                    <Option value={1}>AKTIF</Option>
                                    <Option value={0}>TIDAK AKTIF</Option>
                                </SelectTheme>
                            </div>
                        </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <div id="city-select">
                                <Select data-cy="city" name="city" label="kota / kabupaten" value={hospital.city.id} items={city} onValueChange={ (city) => setHospital({ ...hospital, city:{ id: city} as any })} showSearch filterOption={false} onSearch={(q) => getCity(q, 12)}/>
                            </div>
                        </Col>

                        
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="alamat" className='lable-form-input'>
                                <TextArea data-cy="address" value={hospital.address}  onChange={({target : {value: address}}) => setHospital({...hospital, address})} className='form-control' />
                            </Form.Item>
                        </Col>

                        <Space>
                            <Button type="primary" onClick={submit} data-cy="btn-submit">Simpan</Button>
                            <Button type="default" title="Kembali" data-cy="btn-back" onClick={ () => history.push(`/hospital`) }>Kembali</Button> 
                        </Space>

                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormHospital