import React, { FC, useEffect, useRef, useState } from 'react'
import { Button, Input, Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { PaginationData } from '../../../services/utility';
import DataTable from 'react-data-table-component';
import useMedicineDetail, { MedicineDetail } from '../../../services/pharmacy/medicine.detail';
import { FilterComponent3 } from '../../layout/fiterDataTable';

const { Text } = Typography

interface DataMedicineDetailInterface {
    onSelectedObat: (m: MedicineDetail) => void
}

const ModalDetailMedicine: FC<DataMedicineDetailInterface> = ({ onSelectedObat }) => { 

    
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadMedicine();
        }
      };
  
      return <FilterComponent3 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [MedicineDetail, setMedicineDetail] = useState<PaginationData<MedicineDetail[]>>({data: [], pagination: {}})
    const filteredItems = MedicineDetail.data.filter(item => item.unit.medicine.name && item.unit.medicine.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const MedicineDetailService = useMedicineDetail()

    const loadMedicine = () => {
        setLoader(true)
        MedicineDetailService
        .fetch('', perPage, page)
        .then(MedicineDetail => {
            setMedicineDetail(MedicineDetail)
            let total = (MedicineDetail.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        MedicineDetailService
            .fetch(searching, perPage, 1)
            .then(MedicineDetail => {
                setMedicineDetail(MedicineDetail)
                let total = (MedicineDetail.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        MedicineDetailService
            .fetch('', perPage, pages)
            .then(MedicineDetail => {
                setMedicineDetail(MedicineDetail)
                let total =  (MedicineDetail.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        MedicineDetailService
            .fetch('', newPerPage, pages)
            .then(MedicineDetail => {
                setMedicineDetail(MedicineDetail)
                let total = (MedicineDetail.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    
    const getMedicineDetail = (m: MedicineDetail) => {
        onSelectedObat(m)
        
    }


    useEffect(loadMedicine, [])

    const columns = [
        {
            name: 'Nama',
            cell: (p: MedicineDetail) => <Text strong> {p.unit.medicine.name}</Text>,
            sortable: true,
            grow : 2
        },
        {
            name: 'Isi / Satuan',
            cell: (p: MedicineDetail) => <Text strong>{p.unit.fill} {p.unit.name}</Text>
        },
        {
            name: '',
            cell: (c: MedicineDetail) => {
                return ( 
                    <> 
                        <Button type="link" title="Get Data" onClick={ () => getMedicineDetail(c) }><CheckCircleFilled /></Button>
                    </>
                 )
            },
            grow: 1
        }
    ]

    

    return <div>
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            style={{ bottom: 130}}
        />
    </div>
}

export default ModalDetailMedicine