
import { FC } from 'react'
import DataUser from '../../../components/management/user'

const EmployeePage: FC = () => {
    return <div>
        <DataUser />
    </div>
}

export default EmployeePage