import { Col, Row, Form, Input,  Button } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from '../../layout/title';
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import {  successNotification } from '../../layout/notification'
import useTreatment, { StatusTreatment, Treatment } from '../../../services/configurations/treatment';
import usePoly, { ClinicPoly } from '../../../services/configurations/poly';
import Select, { SelectItem } from '../../layout/select';
import _ from 'lodash'
import NumberFormat from 'react-number-format';

const FormTreatment: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const treatmentService = useTreatment()
    const history = useHistory()
    const params = useParams() as any
    const [disabled, setDisabled] = useState<boolean>(false)


    const [treatment, setTreatment] = useState<Treatment>({
        name: '',
        price: 0,
        doctor_fee: 0,
        nurse_fee: 0,
        bhp: 0,
        cost_net: 0,
        status: StatusTreatment.TRUE, //1. Active 2. Non-active
        info: '',
        clinic_poly: {
            id: ''
        } as ClinicPoly,
        bpjs: StatusTreatment.TRUE, //1. Yes 2. No
    })

    const polyService = usePoly()
    const [poly, setPoly] = useState<SelectItem[]>([])

    const getPoly = async (value: string) => {
        const poly:SelectItem[] = []
        
        const result = await polyService.fetch(value).then(res => res.data)
        
        await Promise.all(_.map(result, async (val) => 
            await poly.push({
                display: val.poly.name,
                value: val.id as any
            })
        ));

        setPoly(poly)
    }

    const STATUS: SelectItem[] = [
        {
            display: "AKTIF",
            value: StatusTreatment.TRUE
        },
        {
            display: "TIDAK AKTIF",
            value: StatusTreatment.FALSE
        }
    ]


    const STATUSBPJS: SelectItem[] = [
        {
            display: "YA",
            value: StatusTreatment.TRUE
        },
        {
            display: "TIDAK",
            value: StatusTreatment.FALSE
        }
    ]
  
    const submit = () => {
        console.log(treatment)

        params.id == 'new' && treatmentService
            .create(treatment)
            .then(treatment => {
                    console.log(treatment)
                    successNotification('tambah')
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== 'new' && treatmentService
            .update(params.id, treatment)
            .then(treatment => {
                console.log(treatment)
                setTreatment(treatment)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const loadtreatment = () => {
        setLoader(true)
        treatmentService
            .get(params.id)
            .then( async treatment => {
                await setPoly([
                    {
                        value: treatment.clinic_poly.id as any,
                        display: treatment.clinic_poly.poly.name,
                    }
                ])

                setTreatment({
                    name: treatment.name,
                    price: treatment.price,
                    doctor_fee: treatment.doctor_fee,
                    nurse_fee: treatment.nurse_fee,
                    bhp: treatment.bhp,
                    cost_net: treatment.cost_net,
                    status: treatment.status,
                    info: treatment.info,
                    clinic_poly: {
                        id: treatment.clinic_poly.id
                    } as ClinicPoly,
                    bpjs: treatment.bpjs
                })
                }
            )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
       if(params.id !== 'new'){ 
           setTreatment({
               ...treatment, 
               id: params.id
           })
           setDisabled(true) 
       }
        params.id !== 'new' && loadtreatment()
    }, [])


    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Tindakan" description="form pengisian data tindakan" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={16} lg={14} xl={14}>
                    <Card title="Pendataan Tindakan" >
                        <Row justify='space-between'>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="nama tindakan" className='lable-form-input'>
                                <Input value={treatment.name} required  onChange={({target : {value: name}}) => setTreatment({...treatment, name})} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} />
                            </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="harga" className='lable-form-input'>
                                    <NumberFormat thousandSeparator={true} value={treatment.price} onValueChange={({value: price }) => setTreatment({...treatment, price} as any)} className='form-control-currency'/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="fee fokter" className='lable-form-input'>
                                    <NumberFormat thousandSeparator={true}  value={treatment.doctor_fee}  onValueChange={({value: doctor_fee}) => setTreatment({...treatment, doctor_fee} as any)} className='form-control-currency' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="fee perawat" className='lable-form-input'>
                                    <NumberFormat thousandSeparator={true}  value={treatment.nurse_fee}  onValueChange={({value: nurse_fee}) => setTreatment({...treatment, nurse_fee} as any)} className='form-control-currency' />
                                </Form.Item>
                            </Col>
                            
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="biaya bhp" className='lable-form-input'>
                                    <NumberFormat thousandSeparator={true}  value={treatment.bhp}  onValueChange={({value: bhp}) => setTreatment({...treatment, bhp} as any)} className='form-control-currency' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="biaya bersih" className='lable-form-input'>
                                    <NumberFormat thousandSeparator={true}  value={treatment.cost_net}  onValueChange={({value: cost_net}) => setTreatment({...treatment, cost_net} as any)} className='form-control-currency' />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Select name="clinicPoly" label="Poli"  onValueChange={(clinic_poly) => { setTreatment({...treatment, clinic_poly:{ id: clinic_poly} as any})}} 
                                items={ poly } showSearch filterOption={false} onSearch={(q) => getPoly(q)}/>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Row>
                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                        <Select name="status" value={StatusTreatment.TRUE} label="status" items={STATUS} onValueChange={ (status) => setTreatment({ ...treatment, status: status as StatusTreatment })}/>
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                        <Select name="bpjs"  value={StatusTreatment.TRUE} label="cover bpjs?" items={STATUSBPJS} onValueChange={ (bpjs) => setTreatment({ ...treatment, bpjs: bpjs as StatusTreatment })}/>
                                    </Col>
                                </Row>
                        </Col>


                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.Item label="Info" className='lable-form-input'>
                                <TextArea value={treatment.info}  onChange={({target : {value: info}}) => setTreatment({...treatment, info} as any)} />
                            </Form.Item>
                        </Col>
                        </Row>

                        

                        <Form.Item>
                            <Button type="primary" onClick={submit} style={{  marginRight:'8px' }}>
                                Simpan
                            </Button>

                            <Button type="default" title="Kembali" onClick={ () => history.push(`/treatment`) }>Kembali</Button> 
                        </Form.Item>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormTreatment