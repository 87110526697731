import React from 'react'
import { DataCheckupQueue } from '../../../components/queue/dataCheckupQueue'

export const CheckupQueue: React.FC = () => {
    return (
        <div>
            <DataCheckupQueue />
        </div>
    )
}
