import React, { FC, useEffect,  useState } from 'react'
import { Button,  Typography } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { PaginationData } from '../../../services/utility';
import DataTable from 'react-data-table-component';
import useInventory, { InventoryLocation } from '../../../services/pharmacy/inventory.location';
import { FilterComponent3 } from '../../layout/fiterDataTable';

const { Text } = Typography

interface DataInventoryInterface {
    onSelectedObat: (m: InventoryLocation) => void
}

const DataMedicine: FC<DataInventoryInterface> = ({ onSelectedObat }) => { 

    
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);

    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadMedicine();
        }
      };
  
      return <FilterComponent3 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [inventory, setInventory] = useState<PaginationData<InventoryLocation[]>>({data: [], pagination: {}})
    console.log(inventory)
    const filteredItems = inventory.data.filter(item => item.detail_medicine.unit.medicine.name && item.detail_medicine.unit.medicine.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const InventoryService = useInventory()

    const loadMedicine = () => {
        setLoader(true)
        InventoryService
        .fetch('', perPage, page)
        .then(inventory => {
            setInventory(inventory)
            let total = (inventory.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        InventoryService
            .fetch(searching, perPage, 1)
            .then(inventory => {
                setInventory(inventory)
                let total = (inventory.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        InventoryService
            .fetch('', perPage, pages)
            .then(inventory => {
                setInventory(inventory)
                let total =  (inventory.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        InventoryService
            .fetch('', newPerPage, pages)
            .then(inventory => {
                setInventory(inventory)
                let total = (inventory.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    
    const getInventory = (m: InventoryLocation) => {
        onSelectedObat(m)
        
    }


    useEffect(loadMedicine, [])

    const columns = [
        {
            name: 'Nama',
            cell: (p: InventoryLocation) => <Text strong> {p.detail_medicine.unit.medicine.name}</Text>,
            sortable: true,
            grow : 2
        },
        {
            name: 'Group',
            cell: (p: InventoryLocation) => <Text strong >{p.detail_medicine.unit.medicine.group.name}</Text>,
            sortable: true
        },
        {
            name: 'Zat Aktif',
            cell: (p: InventoryLocation) => <Text strong >{ p.detail_medicine.unit.medicine.active_substance}</Text>,
            grow: 4
        },

        {
            name: 'Kategori',
            cell: (p: InventoryLocation) => <Text strong >{p.detail_medicine.unit.medicine.category.name}</Text>
        },

        {
            name: 'BPJS',
            cell: (p: InventoryLocation) => <Text strong >{p.detail_medicine.unit.medicine.bpjs}</Text> 
        },

        {
            name: 'Stock',
            cell: (p: InventoryLocation) => <Text strong>{p.stock} {p.detail_medicine.unit.name}</Text>
        },
        {
            name: '',
            cell: (c: InventoryLocation) => {
                return ( 
                    <> 
                        <Button type="link" title="Get Data" onClick={ () => getInventory(c) }><CheckCircleFilled /></Button>
                    </>
                 )
            },
            grow: 1
        }
    ]

    

    return <div>
        <DataTable
            columns={columns}
            data={filteredItems}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            style={{ bottom: 130}}
            striped={true}
            highlightOnHover={true}
        />
    </div>
}

export default DataMedicine