import { baseURL } from "../baseurl"
import { PaginationData } from "../utility"

export interface Supplier {
    inc_id? : number
    id? : string
    code: string
    name: string
    phone: string
    pic : string
    pic_phone: string
    address: string
    top: number
    status: number | string | boolean
}

export enum StatusOption {
    TRUE = "AKTIF",
    FALSE = "TIDAK AKTIF"
}

const SupplierClient = baseURL

const useSupplier = () => ({
    async create(Supplier: Supplier): Promise<Supplier>{
        const {data} = await SupplierClient.post(`/medicine/suppliers/`, Supplier)
        return data
    },
    async update(id: string, Supplier: Supplier): Promise<Supplier>{
        const {data} = await SupplierClient.patch(`/medicine/suppliers/${id}`, Supplier)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await SupplierClient.delete(`/medicine/suppliers/${id}`)
        return data
    },
    async get(id:string): Promise<Supplier>{
        const {data} = await SupplierClient.get(`/medicine/suppliers/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Supplier[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await SupplierClient.get(`/medicine/suppliers/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useSupplier