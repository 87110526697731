const Constant = {
    GLOBAL_LOADER: 'GLOBAL_LOADER'
}
const initialStates:object = {
}
const DispachtStates:any = {}
const USERID = 1
export {
    Constant,
    initialStates,
    DispachtStates,
    USERID
}