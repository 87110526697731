
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface PRBType {
  inc_id: number;
  id: string;
  name: string;
}

const prbClient = baseURL;

const usePRBType = () => ({
  async fetch(search: string): Promise<PaginationData<PRBType[]>> {
    let params = search ? `?name=${search}` : "";
    const { data } = await prbClient.get(`/config/prbs${params}`);

    return data;
  },
  async get(id: number): Promise<PRBType> {
    const { data } = await prbClient.get(`/config/prbs/${id}`);

    return data;
  },
  async create(prbType: PRBType): Promise<PRBType> {
    const { data } = await prbClient.post(`/config/prbs`, prbType);

    return data;
  },
  async delete(id: number): Promise<void> {
    const { data } = await prbClient.delete(`/config/prbs/${id}`);

    return data;
  },
});

export default usePRBType;
