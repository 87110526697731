import { Button, Col, Form, Input, InputNumber, Modal, Row, Select as SelectTheme } from "antd"
import { Dispatch, FC, SetStateAction, useState } from "react"
import { useParams } from "react-router"
import { Medicine } from "../../../services/pharmacy/medicine"
import useRecipe, { Day, RecipeMedicine, StatusUsed } from "../../../services/pharmacy/recipe"
import Select, { SelectItem }  from "../../layout/form/select"
import { Notification } from "../../layout/notification"
import DataMedicine from "./medicine"
import { MedicineDetail } from "../../../services/pharmacy/medicine.detail"
import { Unit } from "../../../services/pharmacy/units"
import { InventoryLocation } from "../../../services/pharmacy/inventory.location"

const { Option } = SelectTheme

interface RecipeProps {
    setRecipe : Dispatch<SetStateAction<RecipeMedicine[]>>
}

const Recipe: FC<RecipeProps> = ({setRecipe}) => { 
    const params = useParams() as any
    const recipeServices = useRecipe()
    const [RecipeMedicine, setRecipeMedicine] = useState<RecipeMedicine[]>()
    const [recipeModal, setRecipeModal] = useState(false)
    const [loader, setLoader] = useState(false)
    const [obat, setObat] = useState<InventoryLocation>()
    const [form, setForm ] = useState<RecipeMedicine> ({
        visit: {
            inc_id: params.id
        } as any,
        inventory_location: {
            inc_id: 0
        } as InventoryLocation,
        used: 1,
        time: 1,
        day: 1,
        total: 1,
        note: '',
        info: '',
    })

    const onChangeObat = (m: InventoryLocation) => {
        setObat(m)

        setForm({
            ...form,
            inventory_location: {
                inc_id: m.inc_id
            } as InventoryLocation
        })

        setRecipeModal(false)
    }


    const STATUSUSED: SelectItem[] = [
        {
            display: "Setelah Makan",
            value: StatusUsed.AFTER
        },
        {
            display: "Sebelum Makan",
            value: StatusUsed.BEFORE
        }
    ]

    const DAYUSED: SelectItem[] = [
        {
            display: "1 Hari",
            value: Day.one
        },
        {
            display: "2 Hari",
            value: Day.two
        },
        {
            display: "3 Hari",
            value: Day.three
        },
        {
            display: "4 Hari",
            value: Day.four
        },
        {
            display: "7 Hari",
            value: Day.week
        },
        {
            display: "30 Hari",
            value: Day.month
        }
    ]


    
    const submit = () => {
        console.log(form)
        recipeServices
            .create(form)
            .then(RecipeMedicine => {
                    console.log(RecipeMedicine)
                    Notification('Transaksi Input Berhasil')
                    setRecipe(prev => {
                        
                        const data = prev 
                        data.push(form)
                        console.log(data)
                        return data
                    })
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    return<>
        <Form layout="vertical" id="obat">
            <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Form.Item label="nama obat" style={{ fontWeight: 'bold' }}>
                        <Input type="text" onClick={()=> setRecipeModal(true)} className="form-control" value={obat?.detail_medicine.unit.medicine.name}  onChange={({target : {value: inventory_location}}) => setForm({...form, inventory_location: {inc_id: inventory_location} as any})}  style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}/>
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item label="dosis" style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control"  value={form.used}  onChange={({target : {value: used}}) => setForm({...form, used} as any)}  style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} />
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item label="waktu"  style={{ fontWeight: 'bold' }}>
                        <InputNumber min={1} type="text" className="form-control"  value={form.time}  onChange={(time) => setForm({...form, time} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%'}} />
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Select name="day" label="Hari" value={form.note} items={DAYUSED} onValueChange={ (day) => setForm({ ...form, day: day as Day })}/>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item label="Total"  style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control" value={form.total}  onChange={({target : {value: total}}) => setForm({...form, total} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}/>
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <Select name="note" label="Catatan" value={form.note} items={STATUSUSED} onValueChange={ (note) => setForm({ ...form, note: note as StatusUsed })}/>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={10} lg={16} xl={16}>
                    <Form.Item label="Keterangan" style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control" value={form.info}  onChange={({target : {value: info}}) => setForm({...form, info} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={4} xl={2} style={{ marginTop: '-20px'}}>
                    <Form.Item label=" ">
                        <Button type="primary"  size="middle" onClick={submit}> Simpan</Button>
                    </Form.Item>
                </Col>
            </Row>

            <Modal
                title="Data Obat"
                style={{ top: 20 }}
                visible={recipeModal}
                onOk={() => setRecipeModal(false)}
                onCancel={() => setRecipeModal(false)}
                width={'80%'}
            >
                <DataMedicine 
                onSelectedObat={onChangeObat} />
            </Modal>
        </Form>
    </>
}


export default Recipe