import React from 'react'

export const UserMenuIcon = () => {
    return (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" fill="#21D47E" />
            <path d="M26.2353 20.6349C25.6476 20.3241 24.9192 20.5479 24.6083 21.1353C24.1424 22.0156 23.2342 22.5621 22.2383 22.5621C21.2424 22.5621 20.3342 22.0153 19.8682 21.1353C19.557 20.5479 18.829 20.3241 18.2413 20.6349C17.6539 20.9458 17.4297 21.6738 17.7406 22.2615C18.6247 23.9318 20.3483 24.9693 22.2379 24.9693C24.1276 24.9693 25.8511 23.9318 26.7353 22.2615C27.0468 21.6738 26.8226 20.9458 26.2353 20.6349Z" fill="white" />
            <path d="M26.8653 28.3405C29.7804 26.7118 31.7567 23.5962 31.7567 20.0264C31.7567 14.778 27.4867 10.5083 22.2386 10.5083C16.9902 10.5083 12.7202 14.778 12.7202 20.0264C12.7202 23.5962 14.6965 26.7115 17.6116 28.3405C16.0342 29.2181 14.7086 30.54 13.8292 32.2003C13.5184 32.788 13.7422 33.5163 14.3299 33.8272C14.5095 33.9221 14.7024 33.9672 14.8922 33.9672C15.3238 33.9672 15.7409 33.7343 15.9569 33.3265C17.1918 30.9939 19.599 29.5451 22.2386 29.5451C24.8783 29.5451 27.2852 30.9939 28.5204 33.3265C28.8316 33.9142 29.56 34.1381 30.1473 33.8272C30.7347 33.516 30.9589 32.788 30.648 32.2003C29.768 30.54 28.4427 29.2184 26.8653 28.3405ZM15.1274 20.0264C15.1274 16.1054 18.3173 12.9155 22.2386 12.9155C26.1596 12.9155 29.3495 16.105 29.3495 20.0264C29.3495 23.9477 26.1596 27.1379 22.2386 27.1379C18.3173 27.1379 15.1274 23.9477 15.1274 20.0264Z" fill="white" />
        </svg>

    )
}
