
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface InsuranceInterface {
    id?:  string
    name: string
}


const InsuranceClient = baseURL

const useInsurance = () => ({
    async create(insurance: InsuranceInterface): Promise<InsuranceInterface>{
        const {data} = await InsuranceClient.post(`/config/insurance-types`, insurance)
        return data
    },
    async update(id: string, insurance: InsuranceInterface): Promise<InsuranceInterface>{
        await InsuranceClient.patch(`/config/insurance-types/${id}`, insurance)
        return insurance
    },
    async delete(id: string): Promise<void>{
        const {data} = await InsuranceClient.delete(`/config/insurance-types/${id}`)
        return data
    },
    async get(id:string): Promise<InsuranceInterface>{
        const {data} = await InsuranceClient.get(`/config/insurance-types/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<InsuranceInterface[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const { data } = await InsuranceClient.get(`/config/insurance-types/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useInsurance
