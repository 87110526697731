import React from 'react'
import { IconButton } from '../../../services/iconButton'

export const MenuIconButton: React.FC<IconButton> = ({ onClick, style, className }) => {
    return (
        <svg onClick={onClick} style={style} className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="menu_24px">
                <path id="icon/navigation/menu_24px" fillRule="evenodd" clipRule="evenodd" d="M3 8V6H21V8H3ZM3 13H21V11H3V13ZM3 18H21V16H3V18Z" fill="black" fillOpacity="0.54" />
            </g>
        </svg>

    )
}
