import React, { Dispatch, FC, SetStateAction } from "react";
import {  Card as CardDisplay, Input } from "antd";
import { Space } from "antd";

import "./main.css";
import { SearchIconButton } from "../form/icon-button/SearchIconButton";
import { AddPeopleIconButton } from "../form/icon-button/AddPeopleIconButton";
import { useHistory } from "react-router";

export interface CardProps {
  search?: boolean;
  addButton?: boolean;
  title?: string;
  customeMinHeight?: string;
  setQuery?: Dispatch<SetStateAction<string>>;
  children: React.ReactNode
}

const Card: FC<CardProps> = (props) => {
  const { children, search, addButton, title, setQuery } = props;
  const pathname = window.location.pathname + "/new";

  const history = useHistory();

  return (
    <CardDisplay
      style={{
        boxShadow: "0px 3px 10px #b6b6b6",
        borderRadius: 15,
        marginBottom: 20,
        minHeight: `${
          props.customeMinHeight ? props.customeMinHeight : "200px"
        }`,
      }}
    >
      {(title || addButton || search) && (
        <>
          <div className="d-flex justify-content-between">
            <h2 className="title-card">{title}</h2>
            {/* <div className="d-flex align-items-center"> */}
            <Space size="middle">
              {search && (
                <div style={{ position: "relative" }} className="form-control">
                  <div className="form-control__icon">
                    <SearchIconButton style={{ cursor: "pointer " }} />
                  </div>
                  <Input
                    className="form-control__search-input"
                    placeholder="Search"
                    onChange={(e: any) => setQuery && setQuery(e.target.value)}
                  />
                </div>
              )}
              {addButton && (
                <AddPeopleIconButton
                  onClick={() => history.push(pathname)}
                  style={{ cursor: "pointer " }} 
                  data-cy="btn-card-add"
                />
              )}
            </Space>
            {/* </div> */}
          </div>
          <hr className="dividing-line" />
        </>
      )}
      {children}
    </CardDisplay>
  );
};

export default Card;
