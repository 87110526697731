import React, { useCallback, useContext, useEffect, useState } from "react";
import Title from "../../layout/title";
import Card from "../../layout/card";
import styles from "./index.module.css";
import { EnterIconButton } from "../../form/icon-button/EnterIconButton";
import { useHistory } from "react-router";
import { DeleteIconButton } from "../../form/icon-button/DeleteIconButton";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import { DataTable, limit } from "../../layout/dataTable/dataTable";
import useQueue, { Queue } from "../../../services/queue";
import { PaginationData } from "../../../services/utility";
import { SocketContext } from "../../../context/SocketContext";
import { message, Popconfirm } from "antd";
import { getEnv } from "../../../config";

const datas = [
  {
    date: (
      <div>
        <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>05/01/2021</p>
        <p style={{ color: "#149BD4", padding: 0, margin: 0 }}>10:23:41</p>
      </div>
    ),
    nomor: "A034",
    service: <div style={{ display: "flex" }}>Poli Umum </div>,
    bpjs: <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>BPJS</p>,
    name: "Mahardiaz Prima Setiawan",
    phone_number: "0882275569004",
    address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
    prioritas: (
      <p
        style={{
          backgroundColor: "#21D47E",
          color: "#FFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          width: "20px",
          height: "20px",
          marginTop: "10px",
        }}
      >
        1
      </p>
    ),
    status: <p style={{ color: "#149BD4", marginTop: "10px" }}>Online</p>,
  },
  {
    date: (
      <div>
        <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>05/01/2021</p>
        <p style={{ color: "#149BD4", padding: 0, margin: 0 }}>11:23:41</p>
      </div>
    ),
    nomor: "A035",
    service: <div style={{ display: "flex" }}>Poli Umum</div>,
    bpjs: <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>BPJS</p>,
    name: "Maesa Munawaroh Sujiadi Lincoln",
    phone_number: "0882275569004",
    address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
    prioritas: (
      <p
        style={{
          backgroundColor: "#21D47E",
          color: "#FFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          width: "20px",
          height: "20px",
          marginTop: "10px",
        }}
      >
        1
      </p>
    ),
    status: <p style={{ color: "#149BD4", marginTop: "10px" }}>Online</p>,
  },
  {
    date: (
      <div>
        <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>05/01/2021</p>
        <p style={{ color: "#149BD4", padding: 0, margin: 0 }}>11:23:41</p>
      </div>
    ),
    nomor: "A035",
    service: <div style={{ display: "flex" }}>Poli Umum</div>,
    bpjs: <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>BPJS</p>,
    name: "Maesa Munawaroh Sujiadi Lincoln",
    phone_number: "0882275569004",
    address: "Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR",
    prioritas: (
      <p
        style={{
          backgroundColor: "#21D47E",
          color: "#FFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          width: "20px",
          height: "20px",
          marginTop: "10px",
        }}
      >
        1
      </p>
    ),
    status: <p style={{ color: "#149BD4", marginTop: "10px" }}>-</p>,
  },
];

// const CLINICID = process.env.REACT_APP_CLINIC_ID

interface QueueFromSocket {
  created: string;
  updated: string;
  id: string;
  position: string;
  status: boolean;
  queue: {
    id: string;
    poly: {
      name: string;
      code: string;
    };
  };
}

export const DataQueueList: React.FC = () => {
  const history = useHistory();
  const socket = useContext(SocketContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [sizePerPage, setSizePerPage] = useState(limit[2].value);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<any>(0);

  const [queue, setQueue] = useState<PaginationData<Queue[]>>({
    data: [],
    pagination: {},
  });
  const [queueFromSocket, setQueueFromSocket] = useState([
    {
      created: "",
      updated: "",
      id: "",
      position: "",
      status: false,
      queue: {
        id: "",
        poly: {
          name: "",
          code: "",
        },
      },
    },
  ]);

  const queueService = useQueue();

  const handlePressEnter = (id: string) => {
    console.log(id, "dari handlePressEnter");
    queueService
      .enableQueueRoom(id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const handleReceiveQueue = useCallback((args: any) => {
    setQueueFromSocket((prev) => [...prev, args]);
  }, []);

  const handleQueueNumber = (code: string, num: number, size: number) => {
    let s = num + "";
    while (s.length < size) s = "0" + s;
    return code + s;
  };

  useEffect(() => {
    socket.on(
      `QUEUE REGISTRATION ${getEnv("REACT_APP_CLINIC_ID")}`,
      handleReceiveQueue
    );
    return () => {
      socket.off(
        `QUEUE REGISTRATION ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );
    };
  }, []);

  useEffect(() => {
    queueService
      .fetch(searchQuery, "PENDAFTARAN", sizePerPage, page)
      .then((res) => {
        setQueue(res);
        setTotalPage(res.pagination.total_page);
      })
      .catch((err) => console.error("lhoo lho kokkk, error", err.response));
  }, [queueFromSocket, page, sizePerPage, totalPage]);

  console.log("Queue Component redered");

  const column = [
    {
      name: "Tanggal",
      cell: (q: any) => (
        <div>
          <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>
            {moment(q.created).format("DD-MM-YYYY")}
          </p>
          <p style={{ color: "#0A7BCD", padding: 0, margin: 0 }}>
            {moment(q.created).format("HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      name: "Layanan",
      cell: (q: any) => <Text strong>{q.queue.poly.name}</Text>,
    },
    {
      name: "Nomor",
      cell: (q: any) => (
        <Text strong>
          {handleQueueNumber(q.queue.poly.code, q.queue.number, 3)}
        </Text>
      ),
    },
    {
      name: "Nama",
      cell: (q: any) => <Text strong>{q.name}</Text>,
      width: "20%",
    },
    {
      name: "No Telp",
      cell: (q: any) => <Text strong>{q.phone_number}</Text>,
      width: "10%",
    },
    {
      name: "Alamat",
      cell: (q: any) => <Text strong>{q.address}</Text>,
      width: "20%",
    },
    {
      name: "Prioritas",
      cell: (q: any) => (
        <Text
          style={{
            backgroundColor: "#21D47E",
            color: "#FFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            marginTop: "10px",
          }}
          strong
        >
          {q.priority}
        </Text>
      ),
      width: "5%",
    },
    {
      name: "Status",
      cell: (q: any) => (
        <Text style={{ color: "#0A7BCD", marginTop: "10px" }} strong>
          {q.status}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "",
      cell: (q: any) => (
        <div
          className={styles.td_body_style}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <EnterIconButton
            onClick={() => handlePressEnter(q.id)}
            style={{ cursor: "pointer", width: "40px" }}
          />
          <Popconfirm
            placement="topLeft"
            title={"Apakah anda yakin ingin menghapus data ini?"}
            onConfirm={() => confirmDelete(q.queue.id)}
            okText="Ya"
            cancelText="Tidak"
          >
            <DeleteIconButton
              style={{ cursor: "pointer", width: "25px", marginLeft: "5%" }}
            />
          </Popconfirm>
        </div>
      ),
      width: "50px",
    },
  ];

  const confirmDelete = (id: string) => {
    queueService
      .delete(id)
      .then((res: any) => {
        message.success(res.message);
        setQueue({
          ...queue,
          data: queue.data.filter((q: any) => q.queue.id !== id),
        });
        // queue.data.filter((q: any) => q.queue.id !== id) )
      })
      .catch((err) => console.error(err));
  };
  return (
    <>
      {/* {
            JSON.stringify(queue)
        } */}
      <Title
        title="Daftar Antrian Pasien"
        description="list antrian daftar tunggu pasien"
      />
      <Card title="Data Pasien" search={true}>
        <DataTable
          datas={queue.data}
          columns={column}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          limit={limit}
          totalPage={totalPage}
        />
        {/* <DataTable
                    datas={data}
                    columns={column}
                    setPage={setPage}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    limit={limit} /> */}
        {/* <div className={styles.table_container}>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <td><div>#</div></td>
                                <td><div>Tanggal</div> </td>
                                <td><div>No. Antrian</div></td>
                                <td><div>Layanan</div></td>
                                <td><div>Prioritas</div></td>
                                <td><div></div></td>
                            </tr>
                        </thead>
                        <tbody>
                            {datas.map((data, index) => (
                                <tr className={styles.tr_body_style}>
                                    <td width="10%"><div className={styles.td_body_style}>{index + 1}</div></td>
                                    <td width="20%"><div className={styles.td_body_style}>{data.date}</div></td>
                                    <td width="20%"><div className={styles.td_body_style}>{data.nomor}</div></td>
                                    <td width="20%"><div className={styles.td_body_style}>{data.phone_number}</div></td>
                                    <td width="20%"><div className={styles.td_body_style}>{data.prioritas}</div></td>
                                    <td width="10%">
                                        <div className={styles.td_body_style} style={{ display: 'flex', justifyContent: 'center' }}>
                                            <EnterIconButton onClick={() => history.push('/patient')} style={{ cursor: 'pointer' }} />
                                            <DeleteIconButton onClick={() => console.log('berhasil hapus')} style={{ cursor: 'pointer', width: '25px', marginLeft: '5%' }} />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table> */}
        {/* </div> */}
      </Card>
    </>
  );
};
