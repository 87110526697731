import { Button, message, Modal } from "antd";
import React from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import useQueue from "../../../services/queue";
import { triggerActiveRoom } from "../../layout/mainLayout";
import {
  handleQueueNumber,
  newIdActiveRoomAtom,
  prevActiveRoomAtom,
} from "../../queue";
import { IModal } from "./QueueNumberModal";

interface IProps extends IModal {
  onConfirm: (id: string) => void;
}

export const RoomEntryConfirmationModal: React.FC<IProps> = ({
  visible,
  setVisible,
  onConfirm,
}) => {
  const id = useRecoilValue(newIdActiveRoomAtom);
  const prevId = useRecoilValue(prevActiveRoomAtom);
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);
  const history = useHistory();
  const queueService = useQueue();
  const onSubmit = () => {
    queueService
      .disableQueueRoom(prevId)
      .then(() => {
        localStorage.removeItem("activeQueue");
        queueService
          .enableQueueRoom(id)
          .then(() => {
            queueService
              .getQueueRoomDetail(id)
              .then((res: any) => {
                let queueNum = handleQueueNumber(
                  res.queue.shift.clinic_poly.code,
                  res.queue.number,
                  3
                );
                let activeQueue = {
                  idRoom: res.id,
                  idQueue: res.queue.id,
                  queueNum: queueNum,
                };
                localStorage.setItem(
                  "activeQueue",
                  JSON.stringify(activeQueue)
                );
                console.log("active room trigger");
                setTriggerQueueNum(!triggerQueueNum);
                history.push("/patients");
              })
              .catch((err: any) =>
                console.log(err, "ini response adalah get queue room")
              );
          })
          .catch((err) => console.log(err));
        message.success("Berhasil mengubah data antrian");
      })
      .catch((err) => console.error(err));
    setVisible(false);
  };
  return (
    <Modal
      title="Pemberitahuan"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      width={400}
      footer={[
        <Button onClick={() => setVisible(false)} className="btn-light">
          Tidak
        </Button>,
        <Button onClick={onSubmit} className="btn-primary">
          Ya
        </Button>,
      ]}
    >
      Anda sudah memasukan nomor antrian? Apakah Anda ingin mengganti?
    </Modal>
  );
};
