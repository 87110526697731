import { baseURL } from "../baseurl"
import { PaginationData } from "../utility"
import { Category } from "./category"
import { Group } from "./group"
export interface Medicine {
    inc_id? : number
    id? : string
    code: string
    name: string
    group: Group
    category: Category
    mix: number | string | boolean //1. Yes 2. No
    bpjs: number | string | boolean //1. Yes 2. No
    active_substance?: string
    barcode?: string
    status: number | string | boolean//1. Active 2. Non Active
}


const MedicineClient = baseURL

const useMedicine = () => ({
    async create(Medicine: Medicine): Promise<Medicine>{
        const {data} = await MedicineClient.post(`/medicine/get`, Medicine)
        return data
    },
    async update(id: string, Medicine: Medicine): Promise<Medicine>{
        await MedicineClient.patch(`/medicine/get/${id}`, Medicine)
        return Medicine
    },
    async delete(id: string): Promise<void>{
        const {data} = await MedicineClient.delete(`/medicine/get/${id}`)
        return data
    },
    async get(id:string): Promise<Medicine>{
        const {data} = await MedicineClient.get(`/medicine/get/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Medicine[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await MedicineClient.get(`/medicine/get/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useMedicine