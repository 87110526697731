import React, {useContext, useEffect, useState} from "react";
import {Stetoscope} from "../../assets/icons/Stetoscope";
import {Button, Form, Row, Input, Spin, message} from "antd";
import styles from "./index.module.css";
import {useForm} from "antd/lib/form/Form";
import axios from "axios";
import {AuthContext} from "../../context/AuthContext";
import {useAuth} from "../../services/auth";
import useQueue from "../../services/queue";
import {Redirect, useHistory} from "react-router";
import { useRecoilState } from "recoil";
import { triggerActiveRoom } from "../layout/mainLayout";
import { handleQueueNumber } from "../queue";

export const DataLogin: React.FC = () => {
  const history = useHistory();
  const {state, dispatch} = useContext(AuthContext);

  const [isLoading, setisLoading] = useState(false);

  const [form] = useForm();
  const auth = useAuth();
  const queue = useQueue();
  const [triggerQueueNum, setTriggerQueueNum] = useRecoilState(triggerActiveRoom);

  const onFinish = async (val: any) => {
    const reqBody = {
      username: val.username,
      password: val.password,
    };

    setisLoading(true);
    const isAuth = await auth.create(reqBody).catch((err) => {
      message.error("Username atau Password salah!");
    })
    
    if (isAuth) {
      dispatch({
        type: "LOGIN",
        payload: {
          ...state,
          username: isAuth.username,
          nickname: isAuth.nickname,
          token: isAuth.access_token,
          clinic: isAuth.clinic,
          roles: isAuth.roles,
          isAuthenticated: true,
        },
      });

      await checkQueueRoomActive()

      history.push("/");
    }
    
    setisLoading(false);
  };

  const checkQueueRoomActive = async () => {
    const isActive = await queue.getQueueRoomActive().catch(e => null)
    
    if (isActive) {
      let queueNum = handleQueueNumber(
        isActive.queue_room.queue.shift.clinic_poly.code,
        isActive.queue_room.queue.number,
        3
      );
      let activeQueue = {
        idRoom: isActive.queue_room.id,
        idQueue: isActive.queue_room.queue.id,
        queueNum: queueNum,
      };
      
      localStorage.setItem("activeQueue", JSON.stringify(activeQueue));
      
      setTriggerQueueNum(!triggerQueueNum);
    }
  }

  return (
    <Spin size="large" spinning={isLoading}>
      <div className={styles.layout}>
        <div className={styles.layout__left}>
          <Stetoscope />
        </div>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className={styles.layout__right}
        >
          <div className={styles.layout__right__container__background}>
            <Stetoscope width="100%" />
          </div>
          <div className={styles.layout__right__container}>
            <div className={styles.layout__right__container__body}>
              <h1>Sign In</h1>
              <Form.Item
                label="Username"
                name="username"
                rules={[{required: true, message: "Masukan Username"}]}
              >
                <Input
                  name="username"
                  placeholder="Username"
                  className={styles.authInput}
                />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[{required: true, message: "Masukan Password"}]}
              >
                <Input.Password
                  name="password"
                  placeholder="Password"
                  className={styles.authInput}
                />
              </Form.Item>
              {/* <Button className={styles.btnPrimary} onClick={() => history.push('/dashboard')}>Log in</Button> */}
              <Button className={styles.btnPrimary} htmlType="submit">
                Log in
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </Spin>
  );
};
