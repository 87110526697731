import { FifthStep } from "../../../components/doctor/checkup/fifth_step";

const ResultPage = () => {
  return (
    <>
      <FifthStep />
    </>
  );
};

export default ResultPage;
