
import { FC } from 'react'
import HistoryCheckUp from '../../components/administration/visit/_history_checkup'

const HistoryVisitPage: FC = () => {
    return <div>
        <HistoryCheckUp/>
    </div>
}

export default HistoryVisitPage