import  { FC } from 'react'
import { InputNumber as InputForm, Form } from 'antd'

export interface OnInputValueChange {
    (value: number): void
}

export interface InputProps {
    label: string
    value: number
    onValueChange?: OnInputValueChange
    className?: string
    placeholder?: string
}

const InputNumber: FC<InputProps> = (props) => {
    const onChange = (value: any) => {
        props.onValueChange && props.onValueChange(value)
    }

    return (
        <Form.Item label={props.label} style={{ fontWeight: 'bold', marginBottom: 10 }}>
            <InputForm placeholder={props.placeholder} style={{ width: '100%', border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} value={props.value} onChange={onChange} className={props.className} />
        </Form.Item>
    )
}

export default InputNumber
