import React, { FC, useState } from 'react'
import { Col,  Row, Tag, Typography, Card as CardTheme, DatePicker, Button, Dropdown, Space, Menu, Modal} from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowRightOutlined , DeleteOutlined, PrinterOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import { Order } from '../../../services/pharmacy/order';
import useInventory, { InventoryLocation } from '../../../services/pharmacy/inventory.location';
import { SelectItem } from '../../layout/form/select';
import _ from 'lodash'
import { NextButton } from '../../icons/nextButton';
import {  FilterComponent2 } from '../../layout/fiterDataTable';
import { DeleteIconButton } from '../../form/icon-button/DeleteIconButton';
import { VisibilityIconButton } from '../../form/icon-button/VisibilityIconButton';

const { Text, Link } = Typography;
const { confirm } = Modal


const DataReceivePharmacy: FC = () => { 
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const history = useHistory()
    const [loader, setLoader] = useState(false)

    const [form, setForm] = useState<Order>({
        code: 0,
        date: 0,
        inventory_location: {
            inc_id : 0
        } as InventoryLocation,
    } as any)

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            code: 'OOA2021-001',
            code_penerimaan: 'ROA2021-001',
            location: 'Gudang Klinik',
            status: 'proses'
        },
        {
            id: 2,
            date: '12/01/2021',
            code: 'OOA2021-001',
            code_penerimaan: 'ROA2021-003',
            location: 'Gudang Klinik',
            status: 'selesai'
        }
    ]

    

    const [inventoryLocation, setInventoryLocation] = useState<SelectItem[]>([])
    const inventoryService = useInventory()

    const getInventory = async (value: string) => {
        const inventoryLocation:SelectItem[] = []
        const result = await inventoryService.fetch(value).then(res => res.data)
        
        await Promise.all(_.map(result, async (val) => 
            await inventoryLocation.push({
                display: val.location.name,
                value: val.inc_id as any
            })

        ));

        setInventoryLocation(inventoryLocation)
    }

    const columns = [
        {
            name: 'tanggal penerimaan',
            width: '15%',
            selector: (row: any) =>  <Text strong> {row.date} </Text>
        },
        {
            name: 'kode penerimaan',
            width: '20%',
           selector: (row: any) => <a onClick={()=> history.push(`/receive/${row.id}`)} style={{ fontWeight: 'bold'}} > { row.code_penerimaan} </a>
        },
        {
            name: 'tanggal pemesanan',
            width: '15%',
            selector: (row: any) =>  <Text strong> {row.date} </Text>
        },
        {
            name: 'kode pemesanan',
            width: '20%',
           selector: (row: any) => <Text strong> { row.code} </Text>
        },
        
        {
            name: 'status',
            width: '10%',
           selector: (row: any) => {
               return (
                   row.status == 'proses'
                   ? <Tag color="#2db7f5"><Text strong style={{ color : '#ffffff'}}>{row.status}</Text></Tag>
                   : <Tag color="#30d95d"><Text strong style={{ color : '#ffffff'}}>{row.status}</Text></Tag>
               )
           }
     
        },
        {
            name: '',
            width: '20%',
            selector: (row: any) =>  {
                const renderStatus = () => {
                    if(row.status == 'proses') { 
                        return (
                            <Space>
                                <a onClick={ () => history.push(`/receive/${row.id}`) }><NextButton style={{ width: 16}}/></a>
                                <a style={{ color: 'red'}} onClick={ () => deleteData() }><DeleteIconButton style={{ width: 16 }}/></a>
                            </Space>
                        )
                    } else {
                        return (
                            <Space>
                                <a onClick={ () => history.push(`/receive/view/${row.id}`) }><VisibilityIconButton style={{ width: 16}}/></a>
                                <a onClick={ () => printData(`${row.id}`) }><PrinterOutlined/></a>
                            </Space>
                        )
                    } 
                }
                return renderStatus()
            }
        }
    ]

   const printData = (id: string) => {}

      const deleteData = () => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              setLoader(true)
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    const submit = () => {
        console.log(form)
    }

    return <div>
        <Title title="Penerimaan Obat" description="data transaksi penerimaan obat" />
        <Row>
            
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <CardTheme style={{ boxShadow: '0px 3px 10px #b6b6b6', borderRadius: 15, marginBottom: 10, minHeight: '80px' }}>
                        <Row>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ borderRight: '3px solid #ccc', marginRight: 40, marginTop: 10 }}>
                                <Typography.Title level={3} style={{ marginBottom: 0, fontWeight: 'bold', color: '#1890ff' }}>Pencarian </Typography.Title>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                                <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                            </Col>
                            <Col span={1} />
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                                <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                            </Col>

                            <Col span={1} />
                            <Col xs={24} sm={24} md={2} lg={2} xl={2} style={{ marginTop: 10}}>
                                <Button type="primary">Cari Data</Button>
                            </Col>
                        </Row>
                    </CardTheme>
                {
                    loader && <div>Loading .... </div>
                }
                    <Card title="Daftar penerimaan obat">
                        <DataTable
                            columns={columns}
                            data={data}
                            className="data-table"
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            noHeader={true}
                            striped={true}
                        />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataReceivePharmacy

function filterData(value: any) {
    throw new Error('Function not implemented.');
}
