import React, { FC, useState } from 'react'
import { Col,  Row, Typography,  Divider, Menu} from 'antd';

import Card from '../../layout/card'
import Title from "../../layout/title";
import ResultProfile from '../results/_profile';
import ResultTreatment from '../results/_treatment';
import ResultRecipe from '../results/_recipe';



const DetailVisit: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)

    const columns = [
        {
          title: 'Name',
          dataIndex: 'medicine',
        },
        {
            title: 'Keterangan',
            dataIndex: 'info',
          },
        {
          title: 'Total',
          dataIndex: 'total',
        },
        {
          title: 'Harga',
          dataIndex: 'price',
        },
        {
            title: 'Total Harga',
            dataIndex: 'total_price',
        },
        {
            title: '',
            dataIndex: ''
        }
      ];
      
      const data = [
        {
          key: '1',
          name: 'John Brown',
          borrow: 10,
          repayment: 33,
        }
      ];
    
    const letterButton = (
      <Menu>
        <Menu.Item key="1">Keterangan Berobat</Menu.Item>
        <Menu.Item key="2">Surat Sakit</Menu.Item>
      </Menu>
    )
  

    return <div>
        <Title title="Kunjungan Pasien" description="detail data kunjungan pasien" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                    {/* Data Pasien */}
                    <Card title="Data Pemeriksaan Pasien">
                        <Row>
                            {/* <Typography.Title>Data Pembayaran</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/> */}

                            {/* <Consultant/> */}

                            <Typography.Title level={5} >Profil Pasien</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/>
                            
                            <ResultProfile/>

                            {/* Pemeriksaan Awal */}

                            <Typography.Title level={5} style={{ marginTop: 20}}>Tindakan & Perlengkapan</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/>

                            <ResultTreatment/>


                            <Typography.Title level={5} style={{ marginTop: 20}}>Resep Obat</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/>

                            <ResultRecipe/>

                            <Typography.Title level={5} style={{ marginTop: 20}}>Keterangan</Typography.Title>
                            <Divider dashed style={{ marginTop: '0'}}/>
{/*                             
                            <Space>
                              <Button type='primary'>Tambah Pemeriksaan</Button>
                              <Button style={{ background: '#21D47E', color: '#fff'}}>Cetak Rekam Medis</Button>
                              <Dropdown.Button overlay={letterButton}>Tambah Surat</Dropdown.Button>
                            </Space> */}
                        </Row>
                    </Card>

                 
            </Col>
        </Row>
    </div>
}

export default DetailVisit