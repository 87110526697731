import {  Col, Descriptions, Divider, Row, Typography } from "antd";
import { FC, useState } from "react";
import { Patient } from "../../../pages/administration/patient";
import { MedisyIcon } from "../../form/icon-button/MedisyIcon";


export const PrintQueue: FC = () => {
    const [profile, setProfile] = useState({
        name: 'Jajang',
        himedVerification: 'SKS12321',
        gender: 'Laki - laki',
        job: 'Buruh Tani',
        age: '24 Tahun',
        address: 'Kp balong 02/05 desa ciharashas, cilaku, cianjur, Ciharashas, Cilaku',
        diagnosis: ['Ambient', 'Batuk']
    })
    const doctor = 'dr. Komar Komarudin Hidayatullah SpoG'
    return (
        <>
            <div id="print-faktur" style={{ padding : 10, textAlign: 'center' }}>
                {/* Header */}
               
                <p style={{ textAlign: 'center',fontSize: '0.8em' }}>Klinik Keluarga</p>
                <p style={{ fontSize: '0.5em', marginTop: '-5px', textAlign: 'center'}}>Kp. Cigombong No. 64 RT.01/09 Desa Ciherang, Kec. Pacet - Cianjur 43253
                    <br/>Telp. (0263) 513513 Email : info@klinikkeluarga.com
                    <br/>Website : www.klinikkeluarga.com
                </p>
                <Divider dashed></Divider>
                <p style={{ fontSize: '1em', marginTop: '-20px'}}>nomor antrian</p>
                <p style={{ fontSize: '2em', fontWeight: 'bold'}}>A099</p>


                <p style={{ fontSize: '0.6em', marginTop: '10px'}}>Layanan : <span style={{ fontWeight: 'bold'}}> Poli Umum</span></p>
                <p style={{ fontSize: '0.6em', marginTop: '10px'}}>Nomor antrian hanya berlaku pada hari dicetak<br/>Terima Kasih atas Kunjungan Anda</p>
                <p style={{ fontSize: '0.4em', marginTop: '10px'}}>Powered By medisy.id supported by madtive.com</p>
            </div>
        </> 
    )
}
