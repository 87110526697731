import React, { FC } from 'react'
import { Card as CardDisplay, Typography } from 'antd'

export interface CardProps {
    title: string
    description?: string
}

const Card : FC<CardProps> = (props) => {

    const { title, description } = props

    return (
        <div style={{ marginBottom: 30 }}>
            <Typography.Title level={2} style={{ marginBottom: 0, fontWeight: 'bold' }}>{ title }</Typography.Title>
            <p>{ description }</p>
        </div>
    )
}   

export default Card