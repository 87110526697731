import { createContext, ReactNode } from "react";
import io from "socket.io-client";
import { getEnv } from "../../config";

const getSocket = () => {
  return io(getEnv(`REACT_APP_SOCKET_URL`));
};

const socket = getSocket();
interface ISocketProvider {
  children: ReactNode;
}

export const SocketContext = createContext(socket);

export const SocketProvider: React.FC<ISocketProvider> = ({ children }) => {
  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
