
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";


export interface Polies {
    id?:  string
    name: string
    code_bpjs: string
    created? : Date
    updated? : Date
}


const PolyClient = baseURL

const usePolies = () => ({
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Polies[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const { data } = await PolyClient.get(`/config/poly/detail/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default usePolies


