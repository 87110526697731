
import  { FC } from 'react'
import DataVisit from '../../components/cashier/visit'

const VisitCashier: FC = () => {
    return <div>
        <DataVisit/>
    </div>
}

export default VisitCashier