import { Image, Button, Menu } from "antd"
import React, { FC } from "react"
import { Link as RouterLink, useLocation } from 'react-router-dom'


const NavRegion: FC = () => {
    const location = useLocation()

    return (<>
        <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
            <Menu.Item key="1" disabled={location.pathname.includes('/province')}>
                <RouterLink to="/province" key="1">Provinsi</RouterLink>
            </Menu.Item>
            <Menu.Item key="2" disabled={location.pathname.includes('/cities')}>
                <RouterLink to="/cities" key="2" >Kota</RouterLink>
            </Menu.Item>
            <Menu.Item key="3" disabled={location.pathname.includes('/district')}><RouterLink to="/district" key="3">Kecamatan</RouterLink></Menu.Item>
            <Menu.Item key="4" disabled={location.pathname.includes('/village')}><RouterLink to="/village" key="4">Desa</RouterLink></Menu.Item>
        </Menu>
    </>
    )
}

export default NavRegion