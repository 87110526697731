import {Moment} from "moment";
import {baseURL} from "../baseurl";
import {PaginationData} from "../utility";

export interface User {
  inc_id?: number;
  id?: string;
  username: string;
  password: string;
  identity_number: string;
  practice_number: string;
  fullname: string;
  nickname: string;
  handphone: string;
  email?: string;
  city: number;
  address?: string;
  work_entry_date: Moment;
  doctor_code?: string;
  str_number?: string;
  roles: {
    name: string;
  }[];
  created?: Date;
  updated?: Date;
}

export interface Nurse {
  id: string;
  username: string;
  identity_number: string;
  practice_number: string;
  fullname: string;
  nickname: string;
  handphone: string;
  email: string;
  address: string;
  work_entry_date: string;
  doctor_code: string;
  str_number: string;
  city: number;
  rating: string | number | null;
  info: string | number | null;
  clinic: number;
  created: string;
  updated: string;
}

export enum RolesUser {
    MANAGEMENT = "manajemen",
    ADMIN = "admin",
    NURSE = "perawat",
    MIDWIFE = "bidan",
    DOCTOR = "dokter",
    CASHIER = "Cashier",
    PHARMACY = "apotek",
    WAREHOUSE = "gudang",
    LAB = "lab",
    FINANCE = "finance"
}

const UserClient = baseURL;

const useUser = () => ({
  async create(User: User): Promise<User> {
    const {data} = await UserClient.post(`/users`, User);
    return data;
  },
  async update(id: string, User: User): Promise<User> {
    await UserClient.patch(`/users/${id}`, User);
    return User;
  },
  async delete(id: string): Promise<void> {
    const {data} = await UserClient.delete(`/users/${id}`);
    return data;
  },
  async get(id: string): Promise<User> {
    const {data} = await UserClient.get(`/users/${id}`);
    return data;
  },
  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<User[]>> {
    let searching = search ? `fullname=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";

    const {data} = await UserClient.get(
      `/users/?${searching}&${sizes}&${rows}`
    );

    return data;
  },

  async getNurse(fullname: string): Promise<PaginationData<Nurse[]>> {
    const {data} = await UserClient.get(
      `users/role/all?role=perawat&fullname=${fullname}`
    );
    return data;
  },
});

export default useUser;
