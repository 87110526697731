import { FirstStepForm } from "../../../components/doctor/checkup/firstStepForm";

const FirstStepPage = () => {
  return (
    <>
      <FirstStepForm />
    </>
  );
};

export default FirstStepPage;
