
import { baseURL, id } from "../baseurl";
import { Location } from "./location";
import { PaginationData } from "../utility";
import { MedicineDetail } from "./medicine.detail";

export interface InventoryLocation {
    inc_id?: number
    id?: string
    location: Location
    detail_medicine: MedicineDetail
    stock: number
    min: number
    max: number
}


const InventoryClient = baseURL
const clinicId = id

const useInventory = () => ({
    async create(inventory: InventoryLocation): Promise<InventoryLocation>{
        const {data} = await InventoryClient.post(`/pharmacy/clinics/${clinicId}/inventory-location/`, inventory)
        return data
    },
    async update(id: string, inventory: InventoryLocation): Promise<InventoryLocation>{
        const {data} = await InventoryClient.patch(`/pharmacy/clinics/${clinicId}/inventory-location/${id}`, inventory)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await InventoryClient.delete(`/pharmacy/clinics/${clinicId}/inventory-location/${id}`)
        return data
    },
    async get(id:string): Promise<InventoryLocation>{
        const {data} = await InventoryClient.get(`/pharmacy/clinics/${clinicId}/inventory-location/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<InventoryLocation[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await InventoryClient.get(`/pharmacy/clinics/${clinicId}/inventory-location/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useInventory