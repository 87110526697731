import MainLayout from "../../components/layout/mainLayout";
import QueueCashierPage from "../../pages/cashier/queue";
import QueueDetailCashierPage from "../../pages/cashier/queue.detail";
import SellingCashierPage from "../../pages/cashier/selling";
import ListSellingCashierPage from "../../pages/cashier/list.selling";
import DashboardCashierPage from "../../pages/cashier/dashboard";
import DetailVisit from "../../components/cashier/visit/_detail";
import VisitCashier from "../../pages/cashier/visit";
import { RouterInterface } from "../router.interface";

export const CashierRoute: RouterInterface[] = [
  {
    component: DashboardCashierPage,
    layout: MainLayout,
    path: '/',
    exact: true
  },
  {
    component: QueueDetailCashierPage,
    layout: MainLayout,
    path: '/queue/:id',
    exact: true
  },
  {
    component: QueueCashierPage,
    layout: MainLayout,
    path: '/queue',
    exact: true
  },
  {
    component: DetailVisit,
    layout: MainLayout,
    path: '/visit/:id',
    exact: true
  },
  {
    component: VisitCashier,
    layout: MainLayout,
    path: '/visit',
    exact: true
  },
  {
    component: ListSellingCashierPage,
    layout: MainLayout,
    path: '/list-selling',
    exact: true
  },
  {
    component: SellingCashierPage,
    layout: MainLayout,
    path: '/selling',
    exact: true
  },
]