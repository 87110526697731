import { FC } from "react";
import { DataQueueList } from "../../components/queue";

const QueuePage: FC = () => {
  return (
    <div>
      <DataQueueList />
    </div>
  );
};

export default QueuePage;
