import { Button, Modal } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Patient } from "../../../pages/administration/patient";
import { triggerActiveRoom } from "../../layout/mainLayout";
import { IModal } from "./QueueNumberModal";

interface ICreatePatientToAnamnesisModal extends IModal {
  patient: {
    no_registration: string;
    fullname: string;
    age: string;
    address: string;
  };
}

export const CreatePatientToAnamnesisModal: React.FC<ICreatePatientToAnamnesisModal> =
  ({ visible, setVisible, patient }) => {
    const history = useHistory();
    const [triggerQueueNum, setTriggerQueueNum] =
      useRecoilState(triggerActiveRoom);
    const handleSubmit = () => {
      localStorage.removeItem("activeQueue");
      setTriggerQueueNum(!triggerQueueNum);
      history.push("/anamnesis");
    };

    const handleCancel = () => {
      setVisible(false);
    };
    return (
      <div>
        <Modal
          visible={visible}
          onCancel={() => setVisible(false)}
          footer={[
            <Button key="submit" className="btn-primary" onClick={handleSubmit}>
              Ok
            </Button>,
            // <Button key="back" className="btn-light" onClick={handleCancel}>
            //   Tidak
            // </Button>,
          ]}
        >
          <h3>Data Pasien telah dibuat dengan data diri:</h3>
          <table style={{ margin: "5% 0" }} cellPadding={10}>
            <thead>
              <tr>
                <td width={"30%"}>
                  <strong>No Pendaftaran</strong>
                </td>
                <td>:</td>
                <td>{patient.no_registration}</td>
              </tr>
              <tr>
                <td width={"30%"}>
                  <strong>Nama Lengkap</strong>
                </td>
                <td>:</td>
                <td>{patient.fullname}</td>
              </tr>
              <tr>
                <td width={"30%"}>
                  <strong>Usia</strong>
                </td>
                <td>:</td>
                <td>{patient.age}</td>
              </tr>
              <tr>
                <td width={"30%"}>
                  <strong>Alamat</strong>
                </td>
                <td>:</td>
                <td>{patient.address}</td>
              </tr>
            </thead>
          </table>
        </Modal>
      </div>
    );
  };
