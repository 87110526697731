
import React, { FC } from 'react'
import DetailVisit from '../../components/pharmacy/visit/_detail'

const VisitDetailCashier: FC = () => {
    return <div>
        <DetailVisit/>
    </div>
}

export default VisitDetailCashier