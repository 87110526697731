import Text from 'antd/lib/typography/Text'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { EnterIconButton } from '../form/icon-button/EnterIconButton'
import { DataTable, limit } from '../layout/dataTable/dataTable'
import Title from '../layout/title'
import Card from '../layout/card';
import moment from 'moment'
import { Link } from 'react-router-dom'

export const DataCheckupQueue: React.FC = () => {
    const pathname = window.location.pathname + "/1"
    const history = useHistory()
    const [sizePerPage, setSizePerPage] = useState(limit[0].value)
    const [page, setPage] = useState<number>(0)

    const datas = [
        {
            date: '02/01/2021',
            nomor: 'A001',
            service: 'POLI GIGI',
            name: 'Sonia Nevermind',
            phone_number: '08771137556',
            address: 'Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR',
            priority: '1',
            status: 'Reservasi'
        },
        {
            date: '02/01/2021',
            nomor: 'A002',
            service: 'Poli Kecantikan',
            name: 'Sonia Nevermind',
            phone_number: '08771137556',
            address: 'Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR',
            priority: '1',
            status: 'Reservasi'
        },
        {
            date: '02/01/2021',
            nomor: 'A004',
            service: 'Laboratorium',
            name: 'Hajime Hinata',
            phone_number: '08771137556',
            address: 'Kp. Cinyawa 5/2 Ciloto Cipanas KAB. CIANJUR',
            priority: '1',
            status: 'Reservasi'
        },
        {
            date: '02/01/2021',
            nomor: 'A007',
            service: 'Poli Khusus',
            name: 'Karma Akabane',
            phone_number: '08771137556',
            address: 'Kp. Jepang 5/2 Ciloto Cipanas KAB. CIANJUR',
            priority: '1',
            status: 'Reservasi'
        }
    ]

    const columns = [
        {
            name: 'Tanggal',
            cell: (q: any) => <Text style={{ color: '#21D47E', padding: 0, margin: 0 }} strong> {moment(q.created).format('DD-MM-YYYY')}</Text>,
            width: '10%'
        },
        {
            name: 'Nomor',
            cell: (a: any) => <Text strong>{a.nomor}</Text>,
            width: '5%'
        },
        {
            name: 'Layanan',
            cell: (a: any) => <> <Text style={{ padding: 0, margin: '0 5px 0 0' }} strong>{a.service}</Text> <Text style={{ color: '#21D47E', padding: 0, margin: '0 0 0 5px' }} strong>{a.bpjs}</Text></>,
            width: '15%'
        },
        {
            name: 'Nama',
            cell: (a: any) => <Text strong>{a.name}</Text>,
            width: '20%'
        },
        {
            name: 'No Telp',
            cell: (a: any) => <Text strong>{a.phone_number}</Text>,
            width: '10%'
        },
        {
            name: 'Alamat',
            cell: (a: any) => <Text strong>{a.address}</Text>,
            width: '20%'
        },
        {
            name: 'Prioritas',
            cell: (a: any) => <Text style={{ backgroundColor: '#21D47E', color: '#FFFF', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '5px', width: '20px', height: '20px', marginTop: '10px' }} strong>{a.priority}</Text>,
            width: '5%'
        },
        {
            name: 'Status',
            cell: (a: any) => <Text style={{ color: '#0A7BCD', marginTop: '10px' }} strong>{a.status}</Text>,
            width: '10%'
        },
        {
            name: '',
            cell: (a: any) => <Link to="/anamnesis/1"><EnterIconButton style={{ cursor: 'pointer' }} /></Link>,
            width: '5%'
        },
    ]




    return (
        <>
            <Title title="Daftar Antrian Pemeriksaan Pasien" description="list antrian pemeriksaan pasien" />
            <Card title="Data Pasien" search={true}>
                <DataTable
                    datas={datas}
                    columns={columns}
                    setPage={setPage}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    limit={limit}
                />
            </Card>
        </>
    )
}

