import React from 'react'
import { IconButton } from '../../../services/iconButton'

export const SearchIconButton: React.FC<IconButton> = ({ onClick, style }) => {
    return (
        <svg onClick={onClick} style={style} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.3612 1.77342C9.20914 -0.890413 4.46431 -0.509827 1.78354 2.62265C-0.682777 5.50446 -0.553404 9.72522 1.93711 12.4563L0.238083 14.4422C-0.102756 14.8398 -0.0538329 15.4364 0.346258 15.7749C0.746349 16.1128 1.34676 16.065 1.68732 15.6671L3.38227 13.6864C6.49874 15.7228 10.738 15.1798 13.2152 12.2851C15.8963 9.15312 15.5127 4.43752 12.3612 1.77342ZM11.766 11.0604C9.76552 13.3985 6.22314 13.6829 3.87043 11.6943C1.51799 9.70604 1.23179 6.18595 3.23251 3.84814C5.23351 1.51006 8.77562 1.22537 11.1286 3.21392C13.4813 5.20221 13.767 8.72229 11.766 11.0604Z" fill="#00BBEB" />
        </svg>

    )
}
