import {  Col, Descriptions, Divider, Row, Typography } from "antd";
import { FC, useState } from "react";
import { MedisyIcon } from "../../form/icon-button/MedisyIcon";


export const PrintQueueOnline: FC = () => {
    const [profile, setProfile] = useState({
        name: 'Jajang',
        himedVerification: 'SKS12321',
        gender: 'Laki - laki',
        job: 'Buruh Tani',
        age: '24 Tahun',
        address: 'Kp balong 02/05 desa ciharashas, cilaku, cianjur, Ciharashas, Cilaku',
        diagnosis: ['Ambient', 'Batuk']
    })
    const doctor = 'dr. Komar Komarudin Hidayatullah SpoG'
    return (
        <>
            <div id="print-faktur" style={{ padding : 10, textAlign: 'center' }}>
                {/* Header */}
               
                <table style={{ borderBottom: '1px solid #000', width: '100%', marginTop: 10 }}>
                    <td width={80} style={{ padding: 10}} align="center" valign="middle"><MedisyIcon/></td>
                    <td align="center" valign="middle">
                        <Typography.Title level={2}>Klinik Keluarga</Typography.Title>
                        <Typography.Text style={{ fontSize: '1.2em', marginTop: '-10px', position: 'unset'}}>Kp. Cigombong No. 64 RT.01/09 Desa Ciherang, Kec. Pacet - Cianjur 43253
                            <br/>Telp. (0263) 513513 Email : info@klinikkeluarga.com
                            <br/>Website : www.klinikkeluarga.com
                        </Typography.Text>
                    </td>
                    <td width={80}  align="center" valign="middle" style={{ padding : 10}}><MedisyIcon/></td>
                </table>
                <p style={{ fontSize: '1.5em', marginTop: '10px'}}>nomor antrian reservasi</p>
                <p style={{ fontSize: '2.3em', fontWeight: 'bold'}}>A099</p>


                <p style={{ fontSize: '1em', marginTop: '10px'}}>Layanan : <span style={{ fontWeight: 'bold'}}> Poli Umum</span></p>
                <p style={{ fontSize: '1em', marginTop: '-5px'}}>Waktu Reservasi :  <span style={{ fontWeight: 'bold'}}> Jumat, 24 Desember 2021 - 10:53</span></p>
                <p style={{ fontSize: '1em', marginTop: '-5px'}}>Nama Pasien :  <span style={{ fontWeight: 'bold'}}> 20210900903 - SRI WANDAYATI (63 tahun)</span></p>
                <p style={{ fontSize: '1em'}}>Nomor antrian hanya berlaku pada hari dicetak<br/>Terima Kasih atas Kunjungan Anda</p>
                <p style={{ fontSize: '0.7em'}}>Powered By medisy.id supported by madtive.com</p>
            </div>
        </> 
    )
}
