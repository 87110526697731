
import { FC } from "react";
import FormPRB from "../../../components/management/prb/_form";

const PrbFormPage: FC = () => {
    return <>
        <FormPRB/>
    </>
}

export default PrbFormPage