import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Form, Input, Modal, Row, Select, Table, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useHospital, { Hospital } from '../../../services/configurations/hospital';
import { GlobalStatus } from '../../../context/context';
import {Notification, successNotification} from "../../layout/notification";
import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';

const { confirm } = Modal


const DataHospital: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadHospital();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Hospital, setHospital] = useState<PaginationData<Hospital[]>>({data: [], pagination: {}})
    const filteredItems = Hospital.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const HospitalService = useHospital()
    const history = useHistory()
    const params = useParams() as any

    const loadHospital = () => {
        setLoader(true)
        HospitalService
        .fetch('', perPage, page)
        .then(Hospital => {
            setHospital(Hospital)
            let total = (Hospital.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        HospitalService
            .fetch(searching, perPage, 1)
            .then(Hospital => {
                setHospital(Hospital)
                let total = (Hospital.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        HospitalService
            .fetch('', perPage, pages)
            .then(Hospital => {
                setHospital(Hospital)
                let total =  (Hospital.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        HospitalService
            .fetch('', newPerPage, pages)
            .then(Hospital => {
                setHospital(Hospital)
                let total = (Hospital.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }


    const deleteHospital = (id: string) => {

        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: `Data dengan ID : ${id}`,
            onOk() {
                setLoader(true)
            HospitalService
                .delete(id)
                .then(() => {
                    loadHospital()
                    successNotification('hapus')
                })
                .catch(e => console.log(e))
                .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadHospital, [])

    const columns = [
        {
            name: <Typography.Text strong>nama</Typography.Text>,
            cell: (p: Hospital) => p.name
        },
        {
            name: <Typography.Text strong>email</Typography.Text>,
            cell: (p: Hospital) => p.email
        },
        {
            name: <Typography.Text strong>telepon</Typography.Text>,
            cell: (p: Hospital) => p.phone
        },

        {
            name: <Typography.Text strong>status</Typography.Text>,
            cell: (p: Hospital) => GlobalStatus(p.status as any)
        },

        {
            name: <Typography.Text strong>alamat</Typography.Text>,
            cell: (p: Hospital) => p.address
        },
        {
            name: <Typography.Text strong>aksi</Typography.Text>,
            cell: (c: Hospital) => {
                return ( 
                    <>
                        <Button id="cy-edit" type="text" title="Update" onClick={ () => history.push(`/hospital/${c.id}`) }><FormOutlined /></Button>
                        <Button id="cy-delete" danger type="text" title="Detele" onClick={ () => deleteHospital(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Rumah Sakit" description="data semua rumah sakit rujukan klinik" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }

                    <Card title="Data Rumah Sakit" addButton={true}>
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            striped={true}
                            highlightOnHover={true}
                            noHeader={true}
                            className='data-table'
                        />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataHospital