import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import Card from '../../layout/card'
import Title from '../../layout/title';
import { PaginationData } from '../../../services/utility';
import usePRB, { PRB } from '../../../services/configurations/prb';
import { Notification, successNotification } from '../../layout/notification'

import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';

const { confirm } = Modal

const DataPRB: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadPRB();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const [prb, setPRB] = useState<PaginationData<PRB[]>>({data: [], pagination: {}})
    const filteredItems = prb.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const PRBService = usePRB()
    const history = useHistory()
    const params = useParams() as any

    const loadPRB = () => {
        setLoader(true)
        PRBService
        .fetch('', perPage, page)
        .then(prb => {
            setPRB(prb)
            let total = (prb.pagination.total_page as number)
                setTotalRows(total)
            }
        )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        PRBService
            .fetch(searching, perPage, 1)
            .then(prb => {
                setPRB(prb)
                let total = (prb.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        PRBService
            .fetch('', perPage, pages)
            .then(prb => {
                setPRB(prb)
                let total =  (prb.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        PRBService
            .fetch('', newPerPage, pages)
            .then(prb => {
                setPRB(prb)
                let total = (prb.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    

    const deletePRB = (id: string) => {

        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                 PRBService
                .delete(id)
                .then(() => {
                    loadPRB()
                    successNotification('hapus')
                })
                .catch(e => console.log(e))
                .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadPRB, [])

    const columns = [
        {
            name:  <Typography.Text strong>nama</Typography.Text>,
            cell: (p: PRB) => p.name
        },
        {
            name:  <Typography.Text strong>aksi</Typography.Text>,
            cell: (c: PRB) => {
                return ( 
                    <>
                        <Button id="prb-edit" type="text" onClick={ () => history.push(`/prb/${c.id}`) }><FormOutlined /></Button>
                        <Button id="prb-delete" danger type="text" onClick={ () => deletePRB(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
       <Title title="Pasien Rujuk Balik" description="form pengisian data pasien rujuk balik" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                
                <Card title="data jenis pasien rujuk balik" addButton={true} data-cy="card">
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    striped={true}
                    highlightOnHover={true}
                    noHeader={true}
                    className='data-table'
                />
                </Card>
            </Col>
        </Row>
    </div>
}

export default DataPRB