import { Button, Col, Descriptions, Row, Table, Typography } from "antd"
import DataTable from "react-data-table-component"
import { useHistory } from "react-router-dom"
import Card from "../../layout/card"
import Title from "../../layout/title"

export const DetailInventory = () => {
    const history = useHistory()

    const columnTransaction = [
        {
            name: 'tanggal',
            selector: (row: { date_transaction: any }) => row.date_transaction,
        }, 
        {
            name: 'status',
            selector: (row: { status: any }) => row.status,
        },
        {
            name: 'qty',
            selector: (row: any) => <Typography.Text strong>{row.qty} {row.unit}</Typography.Text>,
        },
    ];
    
    const dataTransaction = [
        {
            id: 1,
            date_transaction: '01-01-2022',
            status: 'Pemasukkan',
            qty: 150,
            unit: 'Box'
        },
        {
            id: 2,
            date_transaction: '02-01-2022',
            status: 'Pengeluaran',
            qty: 50,
            unit: 'Box'
        },
    ]


    return (<>
    <Title title="Penyimpanan Obat" description="data detail obat" />
        <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Row justify="space-between">
                    <Col xs={24} sm={7} md={7} lg={7}>
                        <Card title={'detail info obat'}>
                            <Descriptions
                                bordered
                                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                            >
                                <Descriptions.Item label="Barcode">ASD00012</Descriptions.Item>
                                <Descriptions.Item label="Nama Obat">Paracetamol</Descriptions.Item>
                                <Descriptions.Item label="Lokasi Obat">Gudang Klinik</Descriptions.Item>
                                <Descriptions.Item label="Stok Obat">100 Box</Descriptions.Item>
                                <Descriptions.Item label="Harga Beli">Rp. 100.000</Descriptions.Item>
                                <Descriptions.Item label="Harga Jual">Rp. 150.000</Descriptions.Item>
                            </Descriptions>

                            <Button type="default" onClick={() => history.push(`/inventory-stock`)} style={{ marginTop: 10}}>Kembali</Button>
                        </Card>
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={16}>
                        <Card title={'detail transaksi obat'}>
                            <DataTable 
                                columns={columnTransaction} 
                                data={dataTransaction}
                                pagination={false}
                                noHeader={true}
                                className="data-table2"
                            />
                        </Card>  
                    </Col>
                    
                </Row>
                  
            </Col>
        </Row>    
    </>)
}