import { baseURL } from "../baseurl";
import { Patient } from "../Registration/Patients";
import { PatientClient } from "../Services";
import { PaginationData } from "../utility";

export enum InsuranceInsurance {
  GENERAL = "UMUM",
  BPJS = "BPJS",
  INSURANCE = "ASURANSI",
}

export enum InsuranceParnersType {
  EMPLOYEE = "KARYAWAN",
  FAMILY = "KELUARGA KARYAWAN",
  NON = "-",
}

export enum InsuranceDecision {
  TRUE = "YA",
  FALSE = "TIDAK",
}

export interface Insurance {
  inc_id?: number;
  id?: string;
  name?: string;
  patient: Patient;
  insurance: InsuranceInsurance;
  number?: string;
  prb: InsuranceDecision;
  prb_type?: number;
  partners: InsuranceDecision;
  partners_type?: InsuranceParnersType;
  company?: number;
}

const insuranceClient = baseURL;

const useInsurance = () => ({
  async create(insurance: Insurance): Promise<Insurance> {
    const { data } = await PatientClient.post(
      `/config/insurance-types`,
      insurance
    );
    return data;
  },
  async update(id: string, insurance: Insurance): Promise<Insurance> {
    const { data } = await PatientClient.patch(`/insurance/${id}`, insurance);
    return data;
  },
  async delete(id: string): Promise<void> {
    const { data } = await insuranceClient.delete(
      `/config/insurance-types/${id}`
    );
    return data;
  },
  async get(id: string): Promise<Insurance> {
    const { data } = await insuranceClient.get(`/config/insurance-types/${id}`);
    return data;
  },
  async fetch(
    name?: string,
    page?: string | number,
    size?: number | string
  ): Promise<PaginationData<Insurance[]>> {
    let searching = name ? `name=${name}` : "";
    let rows = page ? `page=${page}` : "";
    let sizes = size ? `size=${size}` : "";
    const { data } = await insuranceClient.get(`/config/insurance-types/?${searching}&${sizes}&${rows}`)
    return data;
  },
});

export default useInsurance;
