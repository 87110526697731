import { Badge, Col, Descriptions, Row, Typography } from "antd";
import moment from "moment";
import { FC } from "react";

const { Text } = Typography

const ResultProfile: FC = () => {
    return <>
        <Descriptions className="cus-description" bordered column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
            <Descriptions.Item label="Status Berobat">BPJS</Descriptions.Item>
            <Descriptions.Item label="Tanggal">
                <div style={{ display: 'flex' }}>
                    <p style={{ color: '#219BD4', padding: '0 2% 0 0', margin: 0 }}>{moment().format('DD/MM/YYYY')}</p>
                    <p style={{ color: '#21D47E', padding: 0, margin: 0 }}>{moment().format('HH:mm')}</p>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label="Nama Lengkap">Faris Naufal Musthofa Kamal</Descriptions.Item>
            <Descriptions.Item label="Alamat">Jln. Kebun Raya Cibodas, Kp. Rarahan, Kel. Cimacan, Kab. Cianjur</Descriptions.Item>
            <Descriptions.Item label="Tanggal Lahir">24 Tahun 3 Bulan 1 hari</Descriptions.Item>
            <Descriptions.Item label="Layanan">Poli Umum</Descriptions.Item>
            <Descriptions.Item label="Dokter">dr. Yusuf Wibisono</Descriptions.Item>
            <Descriptions.Item label="Status Asuransi">BPJS - 3202123123321</Descriptions.Item>
            <Descriptions.Item label="Diagnosa">-</Descriptions.Item>
        </Descriptions>
    </>
}

export default ResultProfile