
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface Category {
    inc_id?: number
    id? : string
    name: string
    percentage: number
}


const CategoryClient = baseURL

const useCategory = () => ({
    async create(Category: Category): Promise<Category>{
        const {data} = await CategoryClient.post(`/medicine/categories/`, Category)
        return data
    },
    async update(id: string, Category: Category): Promise<Category>{
        await CategoryClient.patch(`/medicine/categories/${id}`, Category)
        return Category
    },
    async delete(id: string): Promise<void>{
        const {data} = await CategoryClient.delete(`/medicine/categories/${id}`)
        return data
    },
    async get(id:string): Promise<Category>{
        const {data} = await CategoryClient.get(`/medicine/categories/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Category[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await CategoryClient.get(`/medicine/categories/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useCategory