import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import { PaginationData } from '../../../services/utility';
import useSupplier, { Supplier } from '../../../services/pharmacy/supplier';
import DataTable from 'react-data-table-component';
import { FilterComponent} from '../../layout/fiterDataTable'
import { successNotification } from '../../layout/notification';

const { confirm } = Modal


const DataSupplier: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadSupplier();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Supplier, setSupplier] = useState<PaginationData<Supplier[]>>({data: [], pagination: {}})
    const filteredItems = Supplier.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const SupplierService = useSupplier()
    const history = useHistory()
    const params = useParams() as any

    const loadSupplier = () => {
        setLoader(true)
        SupplierService
        .fetch('', perPage, page)
        .then(Supplier => {
            setSupplier(Supplier)
            let total = (Supplier.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        SupplierService
            .fetch(searching, perPage, 1)
            .then(Supplier => {
                setSupplier(Supplier)
                let total = (Supplier.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        SupplierService
            .fetch('', perPage, pages)
            .then(Supplier => {
                setSupplier(Supplier)
                let total =  (Supplier.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        SupplierService
            .fetch('', newPerPage, pages)
            .then(Supplier => {
                setSupplier(Supplier)
                let total = (Supplier.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }


    const deleteSupplier = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              setLoader(true)
              SupplierService
                  .delete(id)
                  .then(() => {
                      loadSupplier()
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadSupplier, [])

    const columns = [
        {
            name: 'Nama',
            cell: (p: Supplier) => p.name
        },
        {
            name: 'Telepon',
            cell: (p: Supplier) => p.phone
        },
        {
            name: 'Pic',
            cell: (p: Supplier) => p.pic
        },
        {
            name: 'No Telepon PIC ',
            cell: (p: Supplier) => p.pic_phone
        },
        
        {
            name: 'Aksi',
            cell: (c: Supplier) => {
                return ( 
                    <>
                        <Button type="text" title="Update" onClick={ () => history.push(`/supplier/${c.id}`) }><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={ () => deleteSupplier(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Supplier" description="data semua supplier" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }

                    <Card title="data supplier/pbf obat" addButton={true}>
                        <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                        className="data-table"
                        striped={true}
                        noHeader={true}
                        highlightOnHover={true}
                    />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataSupplier