import React, {useEffect, useState} from "react";
import {Button, Descriptions, Form, Row, Col, Table} from "antd";
import Card from "../../layout/card";
import Input from "../../form/Input";
import Textarea from "../../form/TextArea";
import useInformation, {
  InformationAnamnesis,
} from "../../../services/Registration/InformationAnamnesis";
import {useForm} from "antd/lib/form/Form";
import Select, {SelectItem} from "../../layout/select";
import _ from "lodash";
import usePoly from "../../../services/configuration/poly";
import usePatient, {Patient} from "../../../services/Registration/Patients";
import moment from "moment";
import {PatientHistory} from "../../form/modal/PatientHistory";
import {InputWithUnit} from "../../form/InputWithUnit";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";
import {bmiHelper} from "../../../helper";
import "./Step/step.css";
import useQueue from "../../../services/queue";
import TitleWithStep from "../../layout/stepTitle";
import {StepNavigation} from "./StepNavigation";

const TYPE_DISEASE: SelectItem[] = [
  {
    display: "Alergi Obat",
    value: "Alergi Obat",
  },
  {
    display: "Alergi Makanan",
    value: "Alergi Makanan",
  },
  {
    display: "Penyakit Lama",
    value: "Penyakit Lama",
  },
  {
    display: "Penyakit Keluarga",
    value: "Penyakit Keluarga",
  },
];

export const FirstStepForm = () => {
  const [visible, setVisible] = useState(false);

  const [anamnesisDatas, setAnamnesisDatas] = useState({
    id: "",
    position: "",
    status: false,
    created: "",
    updated: "",
    queue: {
      id: "",
      priority: true,
      number: 0,
      status: "",
      bookdated: "",
      shift: {
        id: 0,
        name: "",
        startTime: "",
        endTime: "",
        clinic_poly: {
          inc_id: 0,
          id: "",
          order: 0,
          status: true,
          code: "",
          max_online_registration: 30,
          is_doctor: true,
          created: "",
          updated: "",
          poly: {
            inc_id: 1,
            id: "",
            name: "",
            code_bpjs: "",
            created: "",
            updated: "",
          },
        },
      },
      anamnesis: {
        id: "",
        patient: {
          inc_id: 0,
          id: "",
          no_registration: "",
          bpjs: "",
          type_identity: "",
          no_identity: "",
          fullname: "",
          place_of_birth: "",
          date_of_birth: "",
          gender: "",
          blood_type: null,
          last_education: null,
          marital_status: "",
          job: "",
          phone_number: "",
          email: "",
          village: {
            id: 0,
            name: "",
            district: {
              id: 0,
              name: "",
              city: {
                id: 0,
                name: "",
                province: {
                  id: 0,
                  name: "",
                  country: {
                    id: 0,
                    name: "",
                  },
                },
              },
            },
          },
          address: "",
          person_in_charge: null,
          relationship: null,
          person_in_charge_phone_number: null,
          clinic: 0,
          source: null,
          created: "",
          updated: "",
          deleted: null,
        },
        insurance_type: null,
        insurance_no: "",
        prb: null,
        partnership: null,
        partnership_info: null,
        doctor: null,
        complaint: null,
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        temperature: null,
        respiratory: null,
        heart: null,
        spo2: null,
        gdp: null,
        gds: null,
        hba1c: null,
        priority_info: null,
        created: "",
        updated: "",
      },
    },
  });

  const [age, setAge] = useState("");

  const [information, setInformation] = useState<InformationAnamnesis[]>([]);
  const [diseaseDesc, setDiseaseDesc] = useState("");
  const [typeDisease, setTypeDisease] = useState("");
  const [poly, setPoly] = useState<SelectItem[]>([]);

  const [complaint, setComplaint] = useState("");
  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [idealWeight, setIdealWeight] = useState(0);
  const [bmi, setBmi] = useState(0);
  const [bmiDesc, setBmiDesc] = useState("");
  const [sistole, setSistole] = useState(0);
  const [diastole, setDiastole] = useState(0);
  const [spo2, setSpo2] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [respiratory, setRespiratory] = useState(0);
  const [heart, setHeart] = useState(0);

  const [initalCheckupForm] = useForm();
  const [initalCheckupFormInformation] = useForm();
  const polyService = usePoly();
  const history = useHistory();
  const queueService = useQueue();
  const patientService = usePatient();
  const informationService = useInformation();
  const params = useParams<{id: string}>();

  const getSupportInformation = async (idPatient: string) => {
    const supportInformation = await informationService
      .get(idPatient)
      .then((res) => res.data);

    // supportInformation.map()
    return supportInformation;
  };

  const patientInformationEffect = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .getQueueRoomDetail(activeQueue.idRoom)
      .then((res: any) => {
        let diff = moment.duration(
          moment().diff(moment(res.queue.anamnesis.patient.date_of_birth))
        );
        setAge(
          `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
        );
        setAnamnesisDatas(res);
        console.log(getSupportInformation(res.queue.anamnesis.patient.id));
        initialField(res);
      })
      .catch((err) => console.log(err));
  };

  // console.log(res)

  const initialField = (res: any) => {
    initalCheckupForm.setFieldsValue({
      complaint: res.queue.anamnesis.complaint,
      complaint_detail: res.queue.anamnesis.complaint_detail,
    });
    setHeight(res.queue.anamnesis.height);
    setWeight(res.queue.anamnesis.weight);
    setSistole(res.queue.anamnesis.sistole);
    setDiastole(res.queue.anamnesis.diastole);
    setSpo2(res.queue.anamnesis.spo2);
    setTemperature(res.queue.anamnesis.temperature);
    setRespiratory(res.queue.anamnesis.respiratory);
    setHeart(res.queue.anamnesis.heart);
  };

  const bmiEffect = () => {
    const bmiPackage = bmiHelper(weight, height, "Laki - Laki");
    setIdealWeight(bmiPackage.idealWeight);
    setBmi(bmiPackage.bmi);
    initalCheckupForm.setFieldsValue({
      bmi_desc: bmiPackage.description,
    });
  };

  const deleteInformation = (_: number) => {
    setInformation((prev) => {
      let newArray = [...prev];
      newArray.splice(_, 1);
      return newArray;
    });
  };
  const getPoly = async (value: string) => {
    const poly: SelectItem[] = [];
    const result = await polyService.fetch(value).then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await poly.push({
            display: val.name,
            value: val.id!,
          })
      )
    );

    setPoly(poly);
  };

  const onFinish = (val: any) => {
    const datas = {
      complaint: val.complaint,
      complaint_detail: val.complaint_detail,
      diastole: diastole,
      heart: heart,
      height: height,
      weight: weight,
      respiratory: respiratory,
      sistole: sistole,
      spo2: spo2,
      temperature: temperature,
    };
    console.log(datas);
    patientService
      .updateAnamnesis(anamnesisDatas.queue.anamnesis.id, datas)
      .then((res) => {
        history.push(`/checkup/step2/${params.id}`);
      })
      .catch((err) => console.log(err));
  };

  const onFinishInformation = (val: any) => {
    const informationSupport = {
      patient: anamnesisDatas.queue.anamnesis.patient.id,
      disease_type: val.type_disease,
      disease_desc: val.disease_desc,
    };

    initalCheckupFormInformation.setFieldsValue({
      disease_desc: "",
    });

    informationService
      .create(informationSupport)
      .then((res) => console.log(res))
      .catch((res) => console.log);
  };

  useEffect(patientInformationEffect, []);
  useEffect(bmiEffect, [weight, height]);
  return (
    <>
      <TitleWithStep
        title="Tambah Pemeriksaan"
        stepTitle="Tahap 1"
        description="kunjungan dan keluhan awal"
      />
      <Card title="Detail Pasien">
        <Descriptions
          className="cus-description"
          bordered
          column={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3}}
        >
          <Descriptions.Item label="Nama lengkap">
            {anamnesisDatas.queue.anamnesis.patient.fullname} -{" "}
            {localStorage.getItem("activeQueue") &&
              JSON.parse(localStorage.getItem("activeQueue")!).queueNum}{" "}
            - {anamnesisDatas.queue.anamnesis.patient.no_identity}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal lahir">
            {moment(
              anamnesisDatas.queue.anamnesis.patient.date_of_birth
            ).format("DD/MM/YYYY")}{" "}
            - {age}
          </Descriptions.Item>
          <Descriptions.Item label="Tanggal">
            <div style={{display: "flex"}}>
              <p style={{color: "#219BD4", padding: "0 2% 0 0", margin: 0}}>
                {moment(anamnesisDatas.queue.anamnesis.created).format("ll")}
              </p>
              <p style={{color: "#21D47E", padding: 0, margin: 0}}>
                {moment(anamnesisDatas.queue.anamnesis.created).format("HH:mm")}
              </p>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="Penanggung jawab">
            {anamnesisDatas.queue.anamnesis.patient.person_in_charge}
          </Descriptions.Item>
          <Descriptions.Item label="Jenis Kunjungan">
            {anamnesisDatas.queue.anamnesis.patient.bpjs !== ""
              ? `BPJS - ${
                  anamnesisDatas.queue.anamnesis.patient.bpjs &&
                  anamnesisDatas.queue.anamnesis.patient.bpjs
                }`
              : "UMUM"}{" "}
          </Descriptions.Item>
          <Descriptions.Item label="Alamat">
            {anamnesisDatas.queue.anamnesis.patient.address}
          </Descriptions.Item>
          <Descriptions.Item label="PRB">Ya - Sariwawan</Descriptions.Item>
          <Descriptions.Item label="Catatan">-</Descriptions.Item>
        </Descriptions>
        <div style={{display: "flex", justifyContent: "end"}}>
          <Button
            className="btn-primary"
            onClick={() => history.replace("/patients/1")}
          >
            Edit
          </Button>
          <Button
            onClick={() => setVisible(true)}
            className="btn-secondary"
            style={{marginLeft: "1%"}}
          >
            Lihat Riwayat
          </Button>
        </div>
      </Card>
      <StepNavigation activeSteps={1} />
      <Form form={initalCheckupForm} layout="vertical" onFinish={onFinish}>
        <Row gutter={15}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Card title="Form Pemeriksaan">
              <Textarea name="complaint" label="Keluhan utama" />
              <Textarea
                name="complaint_detail"
                label="Riwayat Penyakit Sekarang"
                autoFocus
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Card title="Pemeriksaan badan & Vital sign">
              <Row gutter={15}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputWithUnit
                    label="Tinggi badan"
                    name="height"
                    value={height}
                    onValueChange={setHeight}
                    unit="cm"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputWithUnit
                    label="Berat badan"
                    name="weight"
                    value={weight}
                    onValueChange={setWeight}
                    unit="kg"
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                  <InputWithUnit
                    label="Berat Ideal"
                    name="ideal_weight"
                    value={idealWeight}
                    disabled={true}
                    unit="kg"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={7}>
                  <InputWithUnit
                    label="BMI"
                    value={bmi}
                    name="bmi"
                    disabled={true}
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={10}>
                  <Input
                    name="bmi_desc"
                    label="Keterangan BMI"
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={12} sm={12} md={6} lg={12} xl={8}>
                  <InputWithUnit
                    label="Sistole"
                    name="sistole"
                    value={sistole}
                    onValueChange={setSistole}
                    unit="mmHg"
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={12} xl={8}>
                  <InputWithUnit
                    label="Diastole"
                    name="diastole"
                    value={diastole}
                    onValueChange={setDiastole}
                    unit="mmHg"
                  />
                </Col>
                <Col xl={8}>
                  <InputWithUnit
                    label="spo2"
                    name="spo2"
                    value={spo2}
                    onValueChange={setSpo2}
                    unit="%"
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={12} sm={12} md={6} lg={12} xl={8}>
                  <InputWithUnit
                    label="Suhu badan"
                    name="temperature"
                    value={temperature}
                    onValueChange={setTemperature}
                    unit="°c"
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={12} xl={8}>
                  <InputWithUnit
                    label="Respiratory rate"
                    name="respiratory_rate"
                    value={respiratory}
                    onValueChange={setRespiratory}
                    unit="mnt"
                  />
                </Col>
                <Col xs={12} sm={12} md={6} lg={12} xl={8}>
                  <InputWithUnit
                    label="Heart rate"
                    name="heart_rate"
                    value={heart}
                    onValueChange={setHeart}
                    unit="bpm"
                  />
                </Col>
              </Row>
              <Row style={{marginBottom: "2%"}}>
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={8}
                  xl={8}
                  style={{display: "relative"}}
                >
                  <Button
                    className="btn-primary"
                    style={{
                      display: "absolute",
                      top: "40%",
                      fontSize: ".9em",
                    }}
                    htmlType="submit"
                  >
                    {" "}
                    Next
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
      <Form
        form={initalCheckupFormInformation}
        layout="vertical"
        onFinish={onFinishInformation}
      >
        <Card title="Informasi Penunjang Pasien">
          <Row gutter={15}>
            <Col xs={24} sm={12} md={7} lg={7} xl={7}>
              <Select
                name="type_disease"
                label="Jenis"
                items={TYPE_DISEASE}
                onValueChange={(v) => setTypeDisease(v as string)}
              />
            </Col>
            <Col xs={24} sm={12} md={15} lg={15} xl={15}>
              <Input
                name="disease_desc"
                label="Keterangan"
                value={diseaseDesc}
                onValueChange={(v) => setDiseaseDesc(v as string)}
              />
            </Col>
            <Col
              xs={24}
              sm={24}
              md={2}
              lg={2}
              xl={2}
              style={{display: "relative"}}
            >
              <Button
                className="btn-primary"
                htmlType="submit"
                type="primary"
                style={{display: "absolute", top: "40%", fontSize: ".9em"}}
                block
              >
                Add
              </Button>
            </Col>
          </Row>
          {/* <Table bordered dataSource={information} key="id">
            <Table.Column
              title="No"
              key="index"
              render={(value, item, index) => index + 1}
            />
            <Table.Column
              title="Jenis"
              key="index"
              render={(_: InformationAnamnesis) => _.type}
            />
            <Table.Column
              title="Keterangan"
              key="index"
              render={(_: InformationAnamnesis) => _.disease}
            />
            <Table.Column
              title="AKSI"
              key="index"
              render={(_: InformationAnamnesis, item, index) => (
                <>
                  {" "}
                  <Button onClick={(_) => deleteInformation(index)} danger>
                    <DeleteOutlined />
                  </Button>{" "}
                </>
              )}
            />
          </Table> */}
        </Card>
      </Form>
      <PatientHistory visible={visible} setVisible={setVisible} />
    </>
  );
};
