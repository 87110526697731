import { baseURL } from "../baseurl"
import { PaginationData } from "../utility"
import { InventoryLocation } from "./inventory.location"

export interface Order {
    inc_id? : number
    id?: string
    code: string
    date: Date
    inventory_location: InventoryLocation // Location inventory
    status: number //0. Process 1. Finish
    clinic: number
    created? : Date
    updated?: Date
}

const orderClient = baseURL

const useOrder = () => ({
    async create(order: Order): Promise<Order>{
        const {data} = await orderClient.post(`/pharmacy/orders`, order)
        return data
    },
    async update(id: string, order: Order): Promise<Order>{
        const {data} = await orderClient.patch(`/pharmacy/orders/${id}`, order)
        return data
    },
    async delete(id: string): Promise<void>{
        const {data} = await orderClient.delete(`/pharmacy/orders/${id}`)
        return data
    },
    async get(id:string): Promise<Order>{
        const {data} = await orderClient.get(`/pharmacy/orders/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Order[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await orderClient.get(`/pharmacy/orders/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useOrder