import React, { FC } from 'react'
import FormPartner from '../../../components/management/partner_company/_form'


const PartnershipsFormPage: FC = () => {
    return <div>
       <FormPartner/>
    </div>
}

export default PartnershipsFormPage