import { Col, Row, Form, Input,  Button, Space } from 'antd'
import  { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from '../../layout/title';
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Notification, successNotification } from '../../layout/notification'
import _ from 'lodash'
import Select, { SelectItem } from '../../layout/select';
import useICDInterface, { ICDInterface, StatusNumber } from '../../../services/configurations/type.diseases';
import TextArea from 'antd/lib/input/TextArea';


const IcdForm: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const typeDiseaseService = useICDInterface()
    const history = useHistory()
    const params = useParams() as any

    const [selectedICDType, setSelectedICDType] = useState("ICD 10");
    const [typeDisease, setTypeDisease ] = useState<ICDInterface>({
        code: '',
        name: '',
        general_name: '',
        info: '',
        type: '',
        status: StatusNumber.TRUE,
    })

    const STATUS: SelectItem[] = [
        {
            display: "AKTIF",
            value: StatusNumber.TRUE
        },
        {
            display: "TIDAK AKTIF",
            value: StatusNumber.FALSE
        }
    ]

    const ICDTYPE: SelectItem[] = [
        {
            display: "ICD 10",
            value: "ICD 10"
        },
        {
            display: "ICD 9",
            value: "ICD 9"
        },
        {
            display: "ICD 11",
            value: "ICD 11"
        }
    ]

    



    const loadtypeDisease = () => {
        setLoader(true)
        typeDiseaseService
            .get(params.id)
            .then(typeDisease => {
                    console.log(typeDisease)
                    setTypeDisease(typeDisease)
                    // console.log(typeDisease)
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
        params.id !== 'new' && loadtypeDisease()
     }, [])

     const submit = () => {
        console.log(typeDisease)
        params.id === 'new' && typeDiseaseService
            .create(typeDisease)
            .then(typeDisease => {
                    console.log(typeDisease)
                    successNotification('tambah')
                    setTypeDisease({
                        code: '',
                        name: '',
                        general_name: '',
                        info: '',
                        type: '',
                        status: StatusNumber.TRUE,
                    })
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== 'new' && typeDiseaseService
            .update(params.id, typeDisease)
            .then(typeDisease => {
                setTypeDisease(typeDisease)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
   

    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="ICD 10" description="form pengisian data icd 10" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                    <Card title="Pendataaan ICD 10" >
                        <Row justify='space-between'>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                <Form.Item label="code icd"  className='lable-form-input'>
                                    <Input  value={typeDisease.code} onChange={({target : {value: code}}) => setTypeDisease({...typeDisease, code} as any)} className='form-control' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                 <Select
                                    name="type"
                                    label="Tipe ICD"
                                    items={ICDTYPE}
                                    value={selectedICDType}
                                    onValueChange={(v) => setSelectedICDType(v as string)}
                                />
                            </Col>

                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                <Select name="status" label="status" items={STATUS} value={StatusNumber.TRUE} onValueChange={ (status) => setTypeDisease({ ...typeDisease, status: status as StatusNumber })}/>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="nama icd" className='lable-form-input'>
                                    <TextArea  value={typeDisease.name} onChange={({target : {value: name}}) => setTypeDisease({...typeDisease, name})} className='form-control' rows={2} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="nama umum" className='lable-form-input'>
                                    <TextArea rows={2}  value={typeDisease.general_name} onChange={({target : {value: general_name}}) => setTypeDisease({...typeDisease, general_name} as any)} className='form-control'  />
                                </Form.Item>
                            </Col>

                            
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="keterangan" className='lable-form-input'>
                                    <Input  value={typeDisease.info} onChange={({target : {value: info}}) => setTypeDisease({...typeDisease, info} as any)} className='form-control'  />
                                </Form.Item>
                            </Col>

                        </Row>
                        <Space>
                            <Button type="primary" onClick={submit}>
                                Simpan
                            </Button>
                            <Button type="default" title="Kembali" onClick={ () => history.push(`/icd`) }>Kembali</Button> 
                        </Space>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default IcdForm