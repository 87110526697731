
import { baseURL } from "../baseurl";
import { City } from "./cities";
import { PaginationData } from "../utility";

export interface District {
  id: string;
  name: string;
  city: City;
}

const DistrictClient = baseURL;

const useDistrict = () => ({
  async create(District: District): Promise<District> {
    const { data } = await DistrictClient.post(`/config/locations/district`, District);
    return data;
  },
  async update(id: string, District: District): Promise<District> {
    const { data } = await DistrictClient.patch(
      `/config/locations/district/${id}`,
      District
    );
    return data;
  },
  async delete(id: string): Promise<void> {
    const { data } = await DistrictClient.delete(`/config/locations/district/${id}`);
    return data;
  },
  async get(id: string): Promise<District> {
    const { data } = await DistrictClient.get(`/config/locations/district/${id}`);
    return data;
  },
  async fetch(
    search: string,
    cityId?: number | string,
    size?: number, row?: number
  ): Promise<PaginationData<District[]>> {
    let searching = search ? `name=${search}` : "";
    let city = cityId ? `cityId=${cityId}` : "";
    let sizes = size ? `size=${size}` : ''
    let rows = row ? `page=${row}` : ''
    const { data } = await DistrictClient.get(
      `/config/locations/district/?${searching}&${city}&${sizes}&${rows}`
    );
    return data;
  },
});

export default useDistrict;
