
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface Company {
  inc_id: number;
  id: string;
  name: string;
}

const partnershipClient = baseURL;

const useCompany = () => ({
  async fetch(search: string): Promise<PaginationData<Company[]>> {
    let searching = search ? `?name=${search}` : "";
    const { data } = await partnershipClient.get(
      `/config/partnerships${searching}`
    );

    return data;
  },
  async get(id: number): Promise<Company> {
    const { data } = await partnershipClient.get(`/config/partnerships/${id}`);

    return data;
  },
  async create(company: Company): Promise<Company> {
    const { data } = await partnershipClient.post(
      `/config/partnerships`,
      company
    );

    return data;
  },
  async delete(id: number): Promise<void> {
    const { data } = await partnershipClient.delete(
      `/config/partnerships/prbs${id}`
    );

    return data;
  },
});

export default useCompany;
