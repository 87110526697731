import { baseURL } from "../baseurl";
import { Clinic } from "../configuration/clinic";
import { PaginationData } from "../utility";

export interface Job {
  inc_id?: number;
  id?: string;
  name: string;
  clinics: Clinic;
}

const JobClient = baseURL;
const useJob = () => ({
  async create(Job: Job): Promise<Job> {
    const { data } = await JobClient.post(`/job`, Job);
    return data;
  },
  async update(id: string, Job: Job): Promise<Job> {
    await JobClient.patch(`/job/${id}`, Job);
    return Job;
  },
  async delete(id: string): Promise<void> {
    const { data } = await JobClient.delete(`/job/${id}`);
    return data;
  },
  async get(id: string): Promise<Job> {
    const { data } = await JobClient.get(`/job/${id}`);
    return data;
  },
  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<Job[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";

    const { data } = await JobClient.get(
      `/config/jobs?${searching}&${sizes}&${rows}`
    );

    return data;
  },
});

export default useJob;
