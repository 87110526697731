import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavConfigPharmacy: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['7']} className="menu">
                <Menu.Item key="7" disabled={location.pathname.includes('/medicine')}>
                    <RouterLink to="/medicine" key="7" >Obat</RouterLink>
                </Menu.Item>
                <Menu.Item key="1" disabled={location.pathname.includes('/unit')}>
                    <RouterLink to="/unit" key="1">Satuan</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/category')}>
                    <RouterLink to="/category" key="2" >Kategori</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/group')}>
                    <RouterLink to="/group" key="3" >Golongan</RouterLink>
                </Menu.Item>
                <Menu.Item key="4" disabled={location.pathname.includes('/chest')}>
                    <RouterLink to="/chest" key="4" >Lemari</RouterLink>
                </Menu.Item>
                <Menu.Item key="5" disabled={location.pathname.includes('/supplier')}>
                    <RouterLink to="/supplier" key="5" >PBF</RouterLink>
                </Menu.Item>
                {/* <Menu.Item key="6" disabled={location.pathname.includes('/location')}>
                    <RouterLink to="/location" key="6" >Lokasi</RouterLink>
                </Menu.Item> */}
                
            </Menu>
        </>
    )
}