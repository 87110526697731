import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Space } from 'antd';
import { useHistory} from 'react-router-dom';
import { DeleteOutlined, FormOutlined, BranchesOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from '../../layout/title';
import { PaginationData } from '../../../services/utility';
import { Notification } from '../../layout/notification'

import DataTable from 'react-data-table-component';
import {FilterComponent} from '../../layout/fiterDataTable'
import useUser, { User } from '../../../services/configurations/user';
const { confirm } = Modal

const DataUser: FC = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
      if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadusers();
      }
      };

      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);


  const [users, setUsers] = useState<PaginationData<User[]>>({data: [], pagination: {}})
  const filteredItems = users.data.filter(item => item.fullname && item.fullname.toLowerCase().includes(filterText.toLowerCase()));

  const [loader, setLoader] = useState<boolean>(false)
  const usersServices = useUser()
  const history = useHistory()

 
  const loadusers = () => {
      setLoader(true)
      usersServices
          .fetch('', perPage, page)
          .then(users => {
            console.log(users)
            setUsers(users)
              let total = (users.pagination.total_page as number)
              setTotalRows(total)
            }
          )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
      }
  
  
  const filterData = (searching: string) => {

      setFilterText(searching)
      usersServices
          .fetch(searching, perPage, 1)
          .then(users => {
              setUsers(users)
              let total = (users.pagination.total_page as number)
              setTotalRows(total)
              }
              )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
      }
      
  const handlePageChange = (pages:number) => {
      setPage(pages)
      console.log(pages)
      setLoader(true)
      usersServices
          .fetch('', perPage, pages)
          .then(users => {
              setUsers(users)
              let total =  (users.pagination.total_page as number)
              setTotalRows(total)
              }
              )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
      setPerPage(newPerPage)
      setPage(pages)
      setLoader(true)
      usersServices
          .fetch('', newPerPage, pages)
          .then(users => {
              setUsers(users)
              let total = (users.pagination.total_page as number)
              setTotalRows(total)
              }
          )
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
  }


  const deleteData = (id: string) => {
      confirm({
        title: 'Serius Ingin Hapus Data ini?',
        icon: <ExclamationCircleOutlined />,
        content: `Data dengan ID : ${id}`,
        onOk() {
            setLoader(true)
            usersServices
                .delete(id)
                .then(() => {
                    loadusers()
                    Notification('Delete User Berhasil!')
                  })
                .catch(e => console.log(e))
                .finally(()=> setLoader(false))
        },
        onCancel() {
          console.log('Cancel');
        },
      });
  }


  useEffect(() => {
      loadusers();
      
  }, [])


    
  const columns = [
    {
      name: 'Username',
      cell: (p: User) => p.username
    },
    {
        name: 'Nama Lengkap',
        cell: (p: User) => p.fullname
    },
    {
        name: 'No HP',
        cell: (p: User) => p.handphone
    },

    {
        name: 'Alamat',
        cell: (p: User) => p.address
    },

   
    {
        name: 'Aksi',
        cell: (c: User) => {
            return ( 
                <>  
                    <Button id="cy-edit" type="text" onClick={ () => history.push(`/employee/${c.id}`) } title="Edit User"><FormOutlined /></Button>
                    <Button id="cy-delete" danger type="text" onClick={ () => deleteData(c.id as string) }><DeleteOutlined /></Button>
                </>
            )
        }
    }
  ];


    return <div>
        <Title title="Karyawan" description="data semua karyawan" />
        <Row>
           
            <Col span={24}>
               
                <Card title="Data Karyawan Klinik" addButton={true}>
                <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      striped={true}
                      highlightOnHover={true}
                      noHeader={true}
                      className='data-table'
                  />
                </Card>
            </Col>
        </Row>
    </div>
}

export default DataUser

