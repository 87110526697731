import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavMobile: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/mobiles-polies')}>
                    <RouterLink to="/mobile-polies" key="1">Poli Mobile</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/mobile-close')}>
                    <RouterLink to="/mobile-close" key="2" >Tutup Poli</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/mobile-schedule')}>
                    <RouterLink to="/mobile-schedule" key="3" >Jadwal Mobile</RouterLink>
                </Menu.Item>
                <Menu.Item key="4" disabled={location.pathname.includes('/mobile-report')}>
                    <RouterLink to="/mobile-report" key="4" >Laporan</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}