import Text from 'antd/lib/typography/Text'
import moment from 'moment'
import Title from '../../layout/title'
import Card from '../../layout/card';
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { EnterIconButton } from '../../form/icon-button/EnterIconButton'
import { DataTable, limit } from '../../layout/dataTable/dataTable'

export const DataVisit: React.FC = () => {
    const pathname = window.location.pathname + "/1"
    const history = useHistory()

    const [sizePerPage, setSizePerPage] = useState(limit[0].value)
    const [page, setPage] = useState<number>(0)
    const datas = [
        {
            date: '10/11/2021',
            number: '2019070000012',
            queue_num: 'A001-POLI UMUM',
            name: 'Sonia Nevermind',
            gender: 'Perempuan',
            age: '26',
            status: 'UMUM',
            address: 'Kp. Rarahan, Kel. Cimacan, Kec. Cipanas, Kab. Cianjur JAWA BARAT'
        },
        {
            date: '10/11/2021',
            number: '2019070000012',
            queue_num: 'A001-POLI GIGI',
            name: 'Kirigiri',
            gender: 'Perempuan',
            age: '26',
            status: 'UMUM',
            address: 'Kp. Ciwalen, Kel. Cimacan, Kec. Cipanas, Kab. Cianjur JAWA BARAT'
        },
        {
            date: '10/11/2021',
            number: '2019070000012',
            queue_num: 'A001-POLI GIGI',
            name: 'Hajime Hinata',
            gender: 'Laki - Laki',
            age: '29',
            status: 'UMUM',
            address: 'Kp. Cibadak, Kel. Sindanglaya, Kec. Cipanas, Kab. Cianjur JAWA BARAT'
        },
    ]

    const columns = [
        {
            name: 'Tanggal',
            cell: (q: any) => <Text style={{ color: '#21D47E', padding: 0, margin: 0 }} strong> {moment(q.created).format('DD-MM-YYYY')}</Text>,
            width: '10%'
        },
        {
            name: 'Nomor',
            cell: (a: any) => <Text strong>{a.number}</Text>,
            width: '5%'
        },
        {
            name: 'No Antrian',
            cell: (a: any) => <> <Text style={{ padding: 0, margin: '0 5px 0 0' }} strong>{a.queue_num}</Text> </>,
            // - <Text style={{ color: '#21D47E', padding: 0, margin: '0 0 0 5px' }} strong>{a.bpjs}</Text>
            width: '15%'
        },
        {
            name: 'Nama',
            cell: (a: any) => <Text strong>{a.name}</Text>,
            width: '20%'
        },
        {
            name: 'Jenis Kelamin',
            cell: (a: any) => <Text strong>{a.gender}</Text>,
            width: '10%'
        },
        {
            name: 'Umur',
            cell: (a: any) => <Text strong>{a.age}</Text>,
            width: '5%'
        },
        {
            name: 'Status',
            cell: (a: any) => <Text strong>{a.status}</Text>,
            width: '10%'
        },
        {
            name: 'Alamat',
            cell: (a: any) => <Text strong>{a.address}</Text>,
            width: '20%'
        },
        {
            name: '',
            cell: (a: any) => <EnterIconButton onClick={() => (history.push('visit/1'))} style={{ cursor: 'pointer' }} />,
            width: '5%'
        },
    ]

    return (
        <>
            <Title title="Daftar Kunjungan Pasien" description="data semua kunjungan pasien yang terekap dalam sistem" />
            <Card title="Data Pasien" search={true}>
                <DataTable
                    datas={datas}
                    columns={columns}
                    setPage={setPage}
                    sizePerPage={sizePerPage}
                    setSizePerPage={setSizePerPage}
                    limit={limit}
                />
            </Card>
        </>
    )
}
