import {
  Col,
  Row,
  Form,
  Input,
  Button,
  Divider,
  Select as SelectTheme,
} from "antd";
import {FC, useEffect, useState} from "react";
import Card from "../../layout/card";
import Title from "../../layout/title";
import {useParams} from "react-router";
import {useHistory} from "react-router-dom";
import {Notification, successNotification} from "../../layout/notification";
import _ from "lodash";
import useUser, {RolesUser, User} from "../../../services/configurations/user";
import moment from "moment";
import Select, {SelectItem} from "../../layout/select";
import TextArea from "antd/lib/input/TextArea";
import useCity from "../../../services/global/cities";

const {Option} = SelectTheme;

const FormUser: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const userServices = useUser();
  const history = useHistory();
  const params = useParams() as any;
  const [user, setUsers] = useState<User>({
    username: "",
    fullname: "",
    password: "",
    identity_number: "",
    practice_number: "",
    nickname: "",
    handphone: "",
    email: "",
    city: 0,
    address: "",
    work_entry_date: moment(new Date()),
    doctor_code: "",
    str_number: "",
    roles: [
      {
        name: "",
      },
    ],
  });

  const cityService = useCity();
  const [city, setCity] = useState<SelectItem[]>([]);
  const getCity = async (value: string, provinceId: number) => {
    const city: SelectItem[] = [];
    const result = await cityService
      .fetch(value, provinceId)
      .then((res) => res.data);
    await Promise.all(
      _.map(
        result,
        async (val) =>
          await city.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setCity(city);
  };

  const ROLESUSER: SelectItem[] = [
    {
      display: "MANAJEMEN",
      value: RolesUser.MANAGEMENT,
    },
    {
      display: "ADMIN",
      value: RolesUser.ADMIN,
    },
    {
      display: "DOKTER",
      value: RolesUser.DOCTOR,
    },
  ];

  const loadUser = () => {
    setLoader(true);

    userServices
      .get(params.id)
      .then((user) => {
        setUsers(user);
        console.log(user);
      })
      .catch((e) => console.log(e))
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    if (params.id !== "new") {
      setUsers({
        ...user,
        id: params.id,
      });
    }
    params.id !== "new" && loadUser();
  }, []);

  const submit = () => {
    console.log(user);
    params.id == "new" &&
      userServices
        .create(user)
        .then((user) => {
          setUsers(user);
          successNotification("tambah");
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(false));
    params.id !== "new" &&
      userServices
        .update(params.id, user)
        .then((user) => {
          setUsers(user);
          successNotification("perbaharui");
        })
        .catch((e) => console.log(e))
        .finally(() => setLoader(false));
  };

  return (
    <>
      {loader && <div>Loading .... </div>}
      <Title title="Pengguna" description="data semua pengguna" />
      <Form layout="vertical">
        <Row>
          <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <Card title="Pendaftaran Klinik">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Username" className="lable-form-input">
                    <Input
                      value={user.username}
                      onChange={({target: {value: username}}) =>
                        setUsers({...user, username})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item label="Password" className="lable-form-input">
                    <Input.Password
                      value={user.password}
                      onChange={({target: {value: password}}) =>
                        setUsers({...user, password})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={23}
                  lg={23}
                  xl={23}
                  style={{marginLeft: "1%"}}
                >
                  <SelectTheme
                    mode="multiple"
                    style={{width: "100%"}}
                    placeholder="select one roles"
                    // value={['admin']}
                    defaultValue={["admin"]}
                    optionLabelProp="label"
                    className="form-control"
                    onChange={(value: any) => {
                      const roles = value.map((role: any) => ({
                        name: role,
                      }));
                      setUsers({
                        ...user,
                        roles,
                      });

                      // console.log(user)
                    }}
                  >
                    <Option value="admin" label="Admin">
                      <div className="demo-option-label-item">Admin</div>
                    </Option>
                    <Option value="manajemen" label="Manajemen">
                      <div className="demo-option-label-item">Manajemen</div>
                    </Option>
                    <Option value="dokter" label="Dokter">
                      <div className="demo-option-label-item">Dokter</div>
                    </Option>
                    <Option value="apotek" label="Apotek">
                      <div className="demo-option-label-item">Apotek</div>
                    </Option>
                    <Option value="gudang" label="Gudang">
                      <div className="demo-option-label-item">Gudang</div>
                    </Option>
                    <Option value="kasir" label="Kasir">
                      <div className="demo-option-label-item">Kasir</div>
                    </Option>
                    <Option value="lab" label="Laboratorium">
                      <div className="demo-option-label-item">Laboratorium</div>
                    </Option>
                    <Option value="bidan" label="Bidan">
                      <div className="demo-option-label-item">Bidan</div>
                    </Option>
                    <Option value="perawat" label="Perawat">
                      <div className="demo-option-label-item">Perawat</div>
                    </Option>
                  </SelectTheme>
                </Col>
                <Divider></Divider>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Nama Lengkap" className="lable-form-input">
                    <Input
                      value={user.fullname}
                      onChange={({target: {value: fullname}}) =>
                        setUsers({...user, fullname})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item
                    label="Nama Panggilan"
                    className="lable-form-input"
                  >
                    <Input
                      value={user.nickname}
                      onChange={({target: {value: nickname}}) =>
                        setUsers({...user, nickname})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="No Identitas" className="lable-form-input">
                    <Input
                      value={user.identity_number}
                      onChange={({target: {value: identity_number}}) =>
                        setUsers({...user, identity_number})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item label="No. Handphone" className="lable-form-input">
                    <Input
                      value={user.handphone}
                      onChange={({target: {value: handphone}}) =>
                        setUsers({...user, handphone})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Email" className="lable-form-input">
                    <Input
                      value={user.email}
                      onChange={({target: {value: email}}) =>
                        setUsers({...user, email})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Select
                    name="city"
                    label="kota / kabupaten"
                    value={user.city}
                    items={city}
                    onValueChange={(city) =>
                      setUsers({...user, city: city as any})
                    }
                    showSearch
                    filterOption={false}
                    onSearch={(q) => getCity(q, 12)}
                  />
                </Col>
              </Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label="Alamat" className="lable-form-input">
                  <TextArea
                    value={user.address}
                    rows={5}
                    onChange={({target: {value: address}}) =>
                      setUsers({...user, address})
                    }
                    className="form-control"
                  />
                </Form.Item>
              </Col>
            </Card>
          </Col>
          <Col span={1}></Col>
          <Col xs={24} sm={24} md={12} lg={11} xl={11}>
            <Card title="Tenaga Medis">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Tanggal Masuk" className="lable-form-input">
                    <Input
                      type="date"
                      onChange={({target: {value: work_entry_date}}) =>
                        setUsers({...user, work_entry_date} as any)
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item
                    label="No Ijin Prakter"
                    className="lable-form-input"
                  >
                    <Input
                      value={user.practice_number}
                      onChange={({target: {value: practice_number}}) =>
                        setUsers({...user, practice_number})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Form.Item label="Kode Dokter" className="lable-form-input">
                    <Input
                      value={user.doctor_code}
                      onChange={({target: {value: doctor_code}}) =>
                        setUsers({...user, doctor_code})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Col
                  xs={24}
                  sm={24}
                  md={11}
                  lg={11}
                  xl={11}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item label="Nomor STR" className="lable-form-input">
                    <Input
                      value={user.str_number}
                      onChange={({target: {value: str_number}}) =>
                        setUsers({...user, str_number})
                      }
                      className="form-control"
                    />
                  </Form.Item>
                </Col>

                <Divider></Divider>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  style={{marginLeft: "1%"}}
                >
                  <Form.Item>
                    <Button
                      type="primary"
                      onClick={submit}
                      style={{marginRight: "8px"}}
                    >
                      Simpan
                    </Button>

                    <Button
                      type="default"
                      title="Kembali"
                      onClick={() => history.push(`/employee`)}
                    >
                      Kembali
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FormUser;
