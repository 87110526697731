import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Row, Typography } from 'antd';
import Icon from '@ant-design/icons'
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import { GlobalStatus } from '../../../context/context';
import { NextButton } from '../../icons/nextButton';
import { FilterComponent2 } from '../../layout/fiterDataTable';
import { PaginationData } from '../../../services/utility';
import useQueue, { Queue } from '../../../services/queue';
import { useHistory, useParams } from 'react-router-dom';

const { Text } = Typography

const HistoryCheckUp: FC = () => { // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
                loadQueue();
            }
        };

        return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [Queue, setQueue] = useState<PaginationData<Queue[]>>({ data: [], pagination: {} })
    const filteredItems = Queue.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));

    const [loader, setLoader] = useState<boolean>(false)
    const QueueService = useQueue()
    const history = useHistory()
    const params = useParams() as any

    const loadQueue = () => {
        setLoader(true)
        QueueService
            .fetch('', '', perPage, page)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        QueueService
            .fetch(searching, '', perPage, 1)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }

    const handlePageChange = (pages: number) => {
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', perPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        QueueService
            .fetch('', '', newPerPage, pages)
            .then(Queue => {
                setQueue(Queue)
                let total = (Queue.pagination.total_page as number)
                setTotalRows(total)
            }
            )
            .catch(e => console.log(e))
            .finally(() => setLoader(false))
    }


    const deleteQueue = (id: string) => {
        const c = window.confirm(`Are You Sure Delete ?`)
        if (c) {
            setLoader(true)
            QueueService
                .delete(id)
                .then(() => {
                    loadQueue()
                })
                .catch(e => console.log(e))
                .finally(() => setLoader(false))
        }
    }

    useEffect(loadQueue, [])

    const columnss = [
        {
            name: 'Tanggal',
            cell: (p: Queue) => p.date
        },
        {
            name: 'No',
            cell: (p: Queue) => p.nomor
        },
        {
            name: 'Layanan',
            cell: (p: Queue) => p.poly
        },

        {
            name: 'Nama',
            cell: (p: Queue) => p.name
        },


        {
            name: 'Status',
            cell: (p: Queue) => GlobalStatus(parseInt(p.status as any))
        },
        {
            name: 'No. Hp',
            cell: (p: Queue) => p.handphone
        },
        {
            name: 'Alamat',
            cell: (p: Queue) => p.address
        },


        {
            name: 'Prioritas',
            cell: (p: Queue) => p.priority
        },
        {
            name: 'Aksi',
            cell: (c: Queue) => {
                return (
                    <>
                        <Button type="text" title="Update" onClick={() => history.push(`/visit/${c.id}`)}><NextButton /></Button>
                    </>
                )
            }
        }
    ]

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            poly: 'Poli Kecantikan',
            doctor: 'Doctor',
            diagnosa: ['p.20 Demam', 'xx Pilek'],
            medicine: ['Pct', 'ambroxol'],
            operator: 'Melly Lugina'
        }
    ]


    const columns = [
        {
            name: 'Tanggal',
            width: '10%',
            selector: (row: any) => <Text strong>  {row.date} </Text>
        },

        {
            name: 'Layanan',
            width: '20%',
            selector: (row: any) => <Text strong>  <span >{row.poly} - {row.doctor}</span></Text>
        },

        {
            name: 'Diagnosa',
            width: '25%',
            selector: (row: any) => <Text strong>  {row.diagnosa[0]} </Text>
        },

        {
            name: 'Medicine',
            width: '25%',
            selector: (row: any) => <Text strong>  {row.medicine[0]} </Text>
        },

        {
            name: 'Petugas',
            width: '10%',
            selector: (row: any) => <Text strong>  {row.operator} </Text>
        },
        {
            name: '',
            width: '7%',
            selector: (row: any) => <Button type="text" size="large" title="detail" onClick={() => history.push(`/visit/${row.id}`)}><NextButton /></Button>
        }
    ]


    return <div>
        <Title title="Rekam Medis" description="daftar rekam medis / riwayat pemeriksaan pasien" />
        <Row>

            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                {/* Data Pasien */}
                <Card title="Riwayat Pemeriksaan Pasien" addButton={true}>
                    <DataTable
                        columns={columns}
                        data={data}
                        className="data-table"
                        highlightOnHover
                        pointerOnHover
                        subHeader
                        // subHeaderComponent={subHeaderComponentMemo}
                        noHeader={true}
                        striped={true}
                    />
                </Card>
            </Col>
        </Row>
    </div>
}

export default HistoryCheckUp