import { Form, Radio as RadioForm } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { FC } from "react";

export interface OnRadioValueChange {
  (value: RadioChangeEvent): void;
}

export interface RadioItem {
  value: number | string;
  display: string;
}

export interface RadioProps {
  name: string;
  value: string | number;
  label: string;
  items: RadioItem[];
  onValueChange?: OnRadioValueChange;
  disabled?: boolean;
}

const Radio: FC<RadioProps> = (props) => {
  const { label, onValueChange, items, name, value } = props;

  const onChange: OnRadioValueChange = (e: RadioChangeEvent) => {
    onValueChange && onValueChange(e.target.value as any);
  };

  return (
    <div>
      <Form.Item label={label} name={name} style={{ fontWeight: "bold" }}>
        <RadioForm.Group
          disabled={!props.disabled ? false : true}
          size="small"
          name={name}
          defaultValue={value}
          onChange={onChange}
        >
          {items.map((v: RadioItem, i: number) => (
            <RadioForm.Button value={v.value}>{v.display}</RadioForm.Button>
          ))}
        </RadioForm.Group>
      </Form.Item>
    </div>
  );
};

export default Radio;
