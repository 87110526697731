
import React, { FC } from 'react'
import DataLocation from '../../components/pharmacy/location'

const Location: FC = () => {
    return <div>
        <DataLocation/>
    </div>
}

export default Location