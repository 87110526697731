import React, { useCallback, useContext, useEffect, useState } from "react";
import Title from "../../layout/title";
import Card from "../../layout/card";
import { EnterIconButton } from "../../form/icon-button/EnterIconButton";
import { useHistory } from "react-router";
import { DataTable, limit } from "../../layout/dataTable/dataTable";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import useQueue, { Queue } from "../../../services/queue";
import { PaginationData } from "../../../services/utility";
import { handleQueueNumber } from "../../queue";
import { useRecoilState } from "recoil";
import { triggerActiveRoom } from "../../layout/mainLayout";
import usePatient, { Patient } from "../../../services/Registration/Patients";
import { Link } from "react-router-dom";
import { SocketContext } from "../../../context/SocketContext";
import { getEnv } from "../../../config";

export const Anamnesa: React.FC = () => {
  const pathname = window.location.pathname + "/new";
  const history = useHistory();
  const queueService = useQueue();
  const patientService = usePatient();
  const socket = useContext(SocketContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [sizePerPage, setSizePerPage] = useState(limit[3].value);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [anamnesisList, setAnamnesisList] = useState<PaginationData<Queue[]>>({
    data: [],
    pagination: {},
  });
  const [anamnesisListFromSocket, setAnamnesisListFromSocket] = useState({
    inc_id: 0,
    position: "",
    status: false,
    created: "",
    updated: "",
  });
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);

  useEffect(() => {
    queueService
      .fetch(searchQuery, "ANAMNESA", sizePerPage, page)
      .then((res) => setAnamnesisList(res))
      .catch((err) => console.error(err));
  }, [anamnesisListFromSocket, sizePerPage, page, sizePerPage, totalPage]);

  // ===== Start of Socket io section =====
  const handleReceiveQueue = useCallback((args: any) => {
    setAnamnesisListFromSocket(args);
  }, []);

  useEffect(() => {
    socket.on(
      `QUEUE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
      handleReceiveQueue
    );

    socket.on(
      `QUEUE ACTIVE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
      handleReceiveQueue
    );

    socket.on(
      `QUEUE DEACTIVE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
      handleReceiveQueue
    );

    return () => {
      socket.off(
        `QUEUE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );

      socket.off(
        `QUEUE ACTIVE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );

      socket.off(
        `QUEUE DEACTIVE ANAMNESA ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );
    };
  }, []);

  // ===== End of socket io section =====

  // ===== handle enter button =====
  const handleEnterButton = (id: string) => {
    queueService.enableQueueRoom(id).then(() => {
      queueService.getQueueRoomDetail(id).then((res: any) => {
        let queueNum = handleQueueNumber(
          res.queue.shift.clinic_poly.code,
          res.queue.number,
          3
        );
        let activeQueue = {
          idRoom: res.id,
          idQueue: res.queue.id,
          queueNum: queueNum,
        };
        localStorage.setItem("activeQueue", JSON.stringify(activeQueue));
        setTriggerQueueNum(!triggerQueueNum);
        history.push(pathname);
      });
    });
  };

  const columns = [
    {
      name: "Tanggal",
      cell: (q: any) => (
        <Text style={{ color: "#21D47E", padding: 0, margin: 0 }} strong>
          {" "}
          {moment(q.created).format("DD-MM-YYYY")}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "Nomor",
      cell: (a: any) => (
        <Text strong>
          {handleQueueNumber(a.queue.shift.clinic_poly.code, a.queue.number, 3)}
        </Text>
      ),
      width: "5%",
    },
    {
      name: "Layanan",
      cell: (a: any) => (
        <>
          {" "}
          <Text style={{ padding: 0, margin: "0 5px 0 0" }} strong>
            {a.queue.shift.clinic_poly.poly.name}
          </Text>{" "}
          -{" "}
          <Text
            style={{ color: "#21D47E", padding: 0, margin: "0 0 0 5px" }}
            strong
          >
            {/* Data belum dapat ditampilkan */}
            {a.queue.anamnesis.patient.bpjs ? "BPJS" : "UMUM"}
          </Text>
        </>
      ),
      width: "10%",
    },
    {
      name: "Nama",
      cell: (a: any) => (
        <Link to={`patients/${1}`}>
          {" "}
          {/* Data belum dapat ditampilkan */}
          <Text strong>{a.queue.anamnesis.patient.fullname}</Text>
        </Link>
      ),
      width: "20%",
    },
    {
      name: "No Telp",
      cell: (a: any) => (
        <Text strong>
          {/* Data belum dapat ditampilkan */}
          {a.queue.anamnesis.patient.phone_number}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "Alamat",
      cell: (a: any) => (
        <Text strong>
          {/* Data belum dapat ditampilkan */}
          {a.queue.anamnesis.patient.address}{" "}
          {a.queue.anamnesis.patient.village.name}{" "}
          {a.queue.anamnesis.patient.village.district.name}{" "}
          {a.queue.anamnesis.patient.village.district.city.name}
          {a.queue.anamnesis.patient.village.district.city.province.name}
        </Text>
      ),
      width: "25%",
    },
    {
      name: "Prioritas",
      cell: (a: any) => (
        <Text
          style={{
            backgroundColor: `${a.queue.priority ? "#f0ad4e" : "#21D47E"}`,
            color: "#FFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            marginTop: "10px",
          }}
          strong
        >
          {a.queue.priority ? 2 : 1}
        </Text>
      ),
      width: "5%",
    },
    {
      name: "Status",
      cell: (a: any) => (
        <Text style={{ color: "#0A7BCD", marginTop: "10px" }} strong>
          {a.queue.status}
        </Text>
      ),
      width: "10%",
    },
    {
      name: "",
      cell: (a: any) => (
        <EnterIconButton
          onClick={() => handleEnterButton(a.id)}
          style={{ cursor: "pointer" }}
        />
      ),
      width: "5%",
    },
  ];

  return (
    <>
      <Title
        title="Daftar Anamnesa Pasien"
        description="list antrian daftar tunggu anamnesa"
      />
      <Card title="Data Pasien" search={true}>
        {/* {JSON.stringify(anamnesisList)} */}
        <DataTable
          datas={anamnesisList.data}
          columns={columns}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          limit={limit}
        />
      </Card>
    </>
  );
};
