import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";

import { successNotification } from '../../layout/notification'
import { PaginationData } from '../../../services/utility';
import usePartnerCompany, { PartnerCompany } from '../../../services/configurations/partner.company';

import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';

const { confirm } = Modal

const DataPartnerCompany: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            loadPartnership();
        }
        };

        return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    
    const [partnerships, setPartnership] = useState<PaginationData<PartnerCompany[]>>({data: [], pagination: {}})
    const filteredItems = partnerships.data.filter(item => 
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.pic && item.pic.toLowerCase().includes(filterText.toLowerCase()) || 
        item.phone && item.phone.toLowerCase().includes(filterText.toLowerCase())
        );
    
    const [loader, setLoader] = useState<boolean>(false)
    const partnershipServices = usePartnerCompany()
    const history = useHistory()

    
    const loadPartnership = () => {
        setLoader(true)
        partnershipServices
            .fetch('', perPage, page)
            .then(partnerships => {
                setPartnership(partnerships)
                let total = (partnerships.pagination.total_page as number)
                setTotalRows(total)
                }
            )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const filterData = (searching: string) => {

        setFilterText(searching)
        partnershipServices
            .fetch(searching, perPage, 1)
            .then(partnerships => {
                setPartnership(partnerships)
                let total = (partnerships.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        }
        
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        partnershipServices
            .fetch('', perPage, pages)
            .then(partnerships => {
                setPartnership(partnerships)
                let total =  (partnerships.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        partnershipServices
            .fetch('', newPerPage, pages)
            .then(partnerships => {
                setPartnership(partnerships)
                let total = (partnerships.pagination.total_page as number)
                setTotalRows(total)
                }
            )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }


    const deletePartnership = (id: string) => {
       
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: `Data dengan ID : ${id}`,
            onOk() {
                setLoader(true)
                partnershipServices
                    .delete(id)
                    .then(() => {
                        loadPartnership()
                        successNotification('hapus')
                    })
                    .catch(e => console.log(e))
                    .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }


    useEffect(() => {
        loadPartnership();
        
    }, [])
   
   
    const columns = [
        {
            name:  <Typography.Text strong>nama</Typography.Text>,
            cell: (p: PartnerCompany) => p.name
        },
        {
            name:  <Typography.Text strong>email</Typography.Text>,
            cell: (p: PartnerCompany) => p.email
        },
        {
            name:  <Typography.Text strong>penanggung jawab</Typography.Text>,
            cell: (p: PartnerCompany) => p.pic
        },

        {
            name:  <Typography.Text strong>telepon</Typography.Text>,
            cell: (p: PartnerCompany) => p.phone
        },

        {
            name: <Typography.Text strong>alamat</Typography.Text>,
            cell: (p: PartnerCompany) => p.address
        },
        {
            name:  <Typography.Text strong>aksi</Typography.Text>,
            cell: (c: PartnerCompany) => {
                return ( 
                    <>
                        <Button data-cy="btn-edit" type="text" title="Update" onClick={ () => history.push(`/partnerships/${c.id}`) }><FormOutlined /></Button>
                        <Button id="btn-delete" danger type="text" title="Detele" onClick={ () => deletePartnership(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Perusahaan Mitra" description="data semua perusahaan yang terekap dalam sistem" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
               
                    <Card title="data perusahaan" addButton={true}>
                        <DataTable
                            columns={columns}
                            data={filteredItems}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            striped={true}
                            highlightOnHover={true}
                            noHeader={true}
                            className='data-table'
                        />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataPartnerCompany