import React from 'react'
import { IconButton } from '../../../services/iconButton'

export const PreviousIconButton: React.FC<IconButton> = ({ onClick, style }) => {
    return (
        <svg onClick={onClick} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chevron_left_24px">
                <path id="icon/navigation/chevron_left_24px" d="M15.705 7.41L14.295 6L8.29504 12L14.295 18L15.705 16.59L11.125 12L15.705 7.41Z" fill="black" fillOpacity="0.54" />
            </g>
        </svg>

    )
}
