import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavInventoryPharmacy: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['4']} className="menu">
                <Menu.Item key="4" disabled={location.pathname.includes('/moving')}>
                    <RouterLink to="/inventory-stock" key="4" >Penyimpanan</RouterLink>
                </Menu.Item>
                <Menu.Item key="1" disabled={location.pathname.includes('/order')}>
                    <RouterLink to="/order" key="1">Permintaan</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/receive')}>
                    <RouterLink to="/receive" key="2" >Penerimaan</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/release')}>
                    <RouterLink to="/release" key="3" >Pengeluaran</RouterLink>
                </Menu.Item>
                <Menu.Item key="5" disabled={location.pathname.includes('/stockopname')}>
                    <RouterLink to="/stock-opname" key="5" >Penyesuaian</RouterLink>
                </Menu.Item>

                <Menu.Item key="6" disabled={location.pathname.includes('/stockopname')}>
                    <RouterLink to="/stock-opname" key="6" >Open PO</RouterLink>
                </Menu.Item>
                
            </Menu>
        </>
    )
}