import {FC, useContext, useEffect} from "react";
import {Switch, useHistory} from "react-router-dom";
import {PageNotFound} from "../pages/404";
import {RouteWithLayout} from "../components/router/route-with-layout";
import {AuthContext} from "../context/AuthContext";
import {parseJwt} from "../helper";
import { DoctorRoute } from "./routes/doctor.route";
import { AdminRoute } from "./routes/admin.route";
import { PharmacyRoute } from "./routes/pharmacy.route";
import { CashierRoute } from "./routes/cashier.route";
import { WarehouseRoute } from "./routes/warehouse.route";
import { ManagementRoute } from "./routes/management.route";
import { NurseRoute } from "./routes/nurse.route";
import { RolesUser } from "../services/configurations/user";
import { PublicRoute } from "./routes/public.route";
import { RoutesDataInterface } from "./router.interface";

const Routes: FC<{history: any}> = () => {
  const { state, dispatch } = useContext(AuthContext);
  const role = localStorage.getItem("role_name_1")?.toLowerCase();
  const history = useHistory();
  const isAuthenticated = state.isAuthenticated
  const index: string = isAuthenticated && role ? role : 'public'

  const routes: RoutesDataInterface = {
    [RolesUser.ADMIN]: AdminRoute,
    [RolesUser.CASHIER]: CashierRoute,
    [RolesUser.DOCTOR]: DoctorRoute,
    [RolesUser.MANAGEMENT]: ManagementRoute,
    [RolesUser.NURSE]: NurseRoute,
    [RolesUser.PHARMACY]: PharmacyRoute,
    [RolesUser.WAREHOUSE]: WarehouseRoute,
    public: PublicRoute
  }

  useEffect(() => {
    const listenHistory = history.listen(() => {
      const token = localStorage.getItem('token') || '';
      if (token) {
        const decodedTokenJwt = parseJwt(token);
        if (decodedTokenJwt.exp * 1000 < Date.now()) {
          dispatch({
            type: 'LOGOUT',
          });
        }
      }
    });
    return listenHistory;
  });

  return (
    <Switch>
      { routes[index]?.map(route => <RouteWithLayout
          component={route.component}
          layout={route.layout}
          path={route.path}
          exact={route.exact}
        />
      ) }
      <RouteWithLayout component={PageNotFound} />
    </Switch>
  )
};

export default Routes;
