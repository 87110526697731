import { Button, Col, Form, Row, Space } from "antd";
import { FC } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import Input from "../../form/Input";
import Select, { SelectItem } from "../../form/Select";
import Card from "../../layout/card";
import Title from "../../layout/title";

export const PackageMedicine: FC = () => {
    const history = useHistory()

    

    const PACKAGE_TYPE: SelectItem[] = [
        {
            display: "PAKET BIASA",
            value: "BIASA"
        },
        {
            display: "PAKET RACIKAN",
            value: "RACIK"
        }
    ]

    const LOOK_OTHER: SelectItem[] = [
        {
            display: "TIDAK",
            value: "0"
        },
        {
            display: "YA",
            value: "1"
        }
    ]


    const rows = [
        {
            id: 1,
            name: 'Demam',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        },
        {
            id: 2,
            name: 'Covid',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        },
        {
            id: 3,
            name: 'Maag',
            type: 'Paket Biasa',
            look_other: 'YA',
            status: 'Aktif'
        }
    ]

    const columns = [
        {
            name: 'Nama',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Jenis Paket',
            selector: 'type'
        },
        {
            name: 'Untuk Dokter lain',
            selector: 'look_other'
        },
        {
            name: 'Status',
            selector: 'status'
        },
        {
          name: 'Aksi',
          button: true,
          cell: (row: any) => {
              return ( 
                  <>
                      <Button type="primary"  onClick={ () => { history.push(`/package-medicine/${row.id}`);  window.location.reload() } }>Detail</Button>
                  </>
              )
          }
        },
      ];
    return (
        <>
            <Title title={"Konfigurasi Paket Obat"} description="pengaturan untuk konfigurasi obat di dokter"></Title>
            <Row justify="space-around">
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    <Card title="tambah paket">
                        <Form layout="vertical">
                            <Input name={"name"} label={"nama paket"}></Input>

                            <Select label={"jenis paket"} name={"type_package"} items={PACKAGE_TYPE}></Select>
                            <Select label={"Tampilkan di dokter lain?"} name={"look_other"} items={LOOK_OTHER}></Select>
                            <Space>
                                <Button type="primary">Simpan</Button>
                                <Button type="default">Batal</Button>
                            </Space>
                        </Form>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <Card title="data paket obat">
                        <DataTable
                            columns={columns}
                            data={rows}
                            pagination
                            paginationServer
                            // paginationTotalRows={totalRows}
                            // onChangeRowsPerPage={handlePerRowsChange}
                            // onChangePage={handlePageChange}
                            // paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            subHeader
                            // subHeaderComponent={subHeaderComponentMemo}
                            striped={true}
                            highlightOnHover={true}
                            noHeader={true}
                            className="data-table"                   
                            
                        />
                    </Card>
                </Col>
            </Row>
        </>
    )
}