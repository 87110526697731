import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavLetter: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/statement-letter')}>
                    <RouterLink to="/statement-letter" key="1">Keterangan Berobat</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/sick-letter')}>
                    <RouterLink to="/sick-letter" key="2" >Surat Sakit</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/kir-letter')}>
                    <RouterLink to="/kir-letter" key="3" >KIR</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}