
import React, { FC } from 'react'
import DataTreatment from '../../../components/management/treatment'

const TreatmentPage: FC = () => {
    return <div>
       <DataTreatment/>
    </div>
}

export default TreatmentPage