import React, { FC } from 'react'
import { Form, DatePicker } from 'antd'
import moment, { Moment } from 'moment'

export interface OnDateValueChange {
    (value: any): any
}

export interface DateProps {
    name: string
    label: string
    display?: 'Hour' | 'Date' | 'All'
    onValueChange?: OnDateValueChange
    disabled?: boolean
}

const Date: FC<DateProps> = (props) => {
    const onChange = (value: Moment) => {
        props.onValueChange && props.onValueChange(value)
    }

    return (
        <Form.Item label={props.label} name={props.name} style={{ fontWeight: 'bold' }}>
            <DatePicker name={props.name} disabled={!props.disabled ? false : true} defaultValue={moment()} onChange={(v) => onChange(v as Moment)} style={{ width: '100%', border: `${props.disabled ? '1px solid #d9d9d9' : '1px solid rgba(20, 155, 212, 0.5)'}`, borderRadius: 7 }}
                format={(props.display === 'Hour') ? 'HH:mm' : (props.display === 'Date') ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'} />
        </Form.Item>
    )
}

export default Date