import { IconButton } from "../../services/iconButton"

export const NextButton: React.FC<IconButton> = ({
    onClick,
    style,
    color,
  }) => {
    return (
        <svg style={style} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.7549 13.498L14.2007 10.0525C14.7543 9.49809 14.7543 8.60035 14.2007 8.04596L10.7549 4.60012C10.2008 4.04573 9.30235 4.04573 8.74832 4.60012C8.4713 4.87714 8.33281 5.24026 8.33281 5.60302C8.33281 5.96578 8.4713 6.3289 8.74832 6.60628L9.77175 7.6297H1.41952C0.636106 7.6297 0 8.26545 0 9.04849C0 9.8319 0.636106 10.4676 1.41952 10.4676H9.77175L8.74832 11.4914C8.4713 11.7684 8.33281 12.1312 8.33281 12.494C8.33281 12.8571 8.4713 13.2202 8.74832 13.4972C9.30272 14.0523 10.2012 14.0524 10.7549 13.498Z" fill="#219BD4"/>
        <path d="M10.9513 18.0965C7.76818 18.0965 4.87675 16.4718 3.21649 13.7511C2.8519 13.1535 3.0406 12.3737 3.63787 12.0091C4.23441 11.6442 5.01563 11.8332 5.37948 12.4305C6.57659 14.3916 8.65932 15.562 10.9513 15.562C14.5433 15.562 17.4652 12.6397 17.4652 9.04806C17.4652 5.45639 14.543 2.53418 10.9513 2.53418C8.65712 2.53418 6.57475 3.70599 5.38058 5.66818C5.01709 6.26618 4.23734 6.45709 3.63934 6.09213C3.04133 5.72864 2.8519 4.9489 3.21576 4.35126C4.87309 1.62618 7.76488 0 10.9513 0C15.9405 0 19.9997 4.05923 19.9997 9.04843C19.9997 14.0376 15.9405 18.0965 10.9513 18.0965Z" fill="#219BD4"/>
        </svg>
    )
  }