import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavConfigClinic: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/treatment')}>
                    <RouterLink to="/treatment" key="1">Tindakan</RouterLink>
                </Menu.Item>
               
                <Menu.Item key="2" disabled={location.pathname.includes('/polies')}>
                    <RouterLink to="/polies" key="2" >Poli</RouterLink>
                </Menu.Item>

                <Menu.Item key="3" disabled={location.pathname.includes('/cost-administration')}>
                    <RouterLink to="/cost-administration" key="3" >Administrasi</RouterLink>
                </Menu.Item>
                
            </Menu>
        </>
    )
}