import { FC, useState } from 'react'
import { Badge,  Col,  Modal,  Row,Typography, Card as CardTheme, Input, Descriptions, Space, Button} from 'antd';
import { useHistory } from 'react-router-dom';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import { orderDetail } from '../../../services/pharmacy/order.detail';
import ModalDetailMedicine from '../modal/_detail_medicine';
import { MedicineDetail } from '../../../services/pharmacy/medicine.detail';

const { Text, Link } = Typography;

const DetailReceivePharmacy: FC = () => { 
    // Data Table
    const history = useHistory()
    const [loader, setLoader] = useState(false)
    const [medicineModal, setMedicineModal] = useState(false)
    const [obat, setObat] = useState<MedicineDetail>()

    const [form, setForm] = useState<orderDetail>({
        qty: 0,
        detail_medicine : {
            inc_id : 0 
        } as MedicineDetail
        
    } as any)

    const data = [
        {
            id: 1,
            name: 'Paracetamol',
            unit: 'Box',
            qty_order: '4',
            qty_receive: '3',
        }
    ]


    const columns = [
        {
            name: 'No',
            width: '10%',
            selector: (row: any) =>  <Text strong> {row.id} </Text>
        },
        {
            name: 'Nama Obat',
            width: '40%',
           selector: (row: any) =>  <Text strong> { row.name} </Text>
        },
        {
            name: 'Qty Order',
            width: '20%',
            selector: (row: any) =>  <Text strong> {row.qty_order} {row.unit}</Text>
        },
        {
            name: 'Qty Penerimaan',
            width: '20%',
            selector: (row: any) =>  <Input size="large" placeholder={row.qty_receive} addonAfter={row.unit} className='form-control' />
        }
    ]

    const onChangeObat = (m: MedicineDetail) => {
        setObat(m)

        setForm({
            ...form,
            detail_medicine : {
                inc_id : m.inc_id
            } as MedicineDetail
        })

        setMedicineModal(false)
    }


    return <div>
        <Title title="Penerimaan Obat" description="form dan daftar detail penerimaan obat" />
        <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                {
                    loader && <div>Loading .... </div>
                }
                    <Card>
                        <Descriptions
                            title="Detail Permintaan"
                            bordered={true}
                            column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
                            >
                            <Descriptions.Item label="nomor permintaan"><Typography.Text strong>OOA202103-001</Typography.Text></Descriptions.Item>
                            <Descriptions.Item label="nomor penerimaan"><Typography.Text strong>OTA202103-001</Typography.Text></Descriptions.Item>
                            <Descriptions.Item label="tanggal permintaan"><Typography.Text strong>01/01/2022</Typography.Text></Descriptions.Item>
                            <Descriptions.Item label="tanggal pengeluaran"><Typography.Text strong>02/01/2022</Typography.Text></Descriptions.Item>
                            <Descriptions.Item label="tujuan lokasi"><Typography.Text strong>Gudang Klinik Keluarga</Typography.Text></Descriptions.Item>
                            <Descriptions.Item label="untuk lokasi"><Typography.Text strong>Apotek Kesturi</Typography.Text></Descriptions.Item>
                        </Descriptions>

                        <Space>
                            <Button style={{ marginTop: 10}} onClick={()=> history.push(`/receive`)}>Kembali</Button>
                        </Space>
                    </Card>
                    <Card title="Daftar Obat Yang Diterima">
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination={false}
                            style={{ marginTop: '-6vh'}}
                        />
                        <Modal
                            title="Data Obat"
                            style={{ top: 20 }}
                            visible={medicineModal}
                            onOk={() => setMedicineModal(false)}
                            onCancel={() => setMedicineModal(false)}
                            width={'50%'}
                        >
                        <ModalDetailMedicine 
                            onSelectedObat={onChangeObat} />
                        </Modal>
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DetailReceivePharmacy