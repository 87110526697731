import React from "react";
import {IconButton} from "../../../services/iconButton";

export const AddIconButton: React.FC<IconButton> = ({onClick, style}) => {
  return (
    <svg
      onClick={onClick}
      style={style ? style : {cursor: "pointer"}}
      width="45"
      height="45"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_359:280)">
        <circle cx="16.5" cy="14.5" r="12.5" fill="#27AAE1" />
      </g>
      <path
        d="M20.921 14.104C21.2293 14.104 21.4862 14.2068 21.6917 14.4123C21.8972 14.605 22 14.8555 22 15.1638C22 15.4721 21.8972 15.729 21.6917 15.9345C21.4862 16.1272 21.2293 16.2235 20.921 16.2235H18.2235V18.7476C18.2235 19.1073 18.1015 19.4027 17.8574 19.6339C17.6262 19.8651 17.3308 19.9807 16.9711 19.9807C16.6114 19.9807 16.3096 19.8651 16.0655 19.6339C15.8343 19.4027 15.7187 19.1073 15.7187 18.7476V16.2235H13.079C12.7707 16.2235 12.5138 16.1207 12.3083 15.9152C12.1028 15.7097 12 15.4528 12 15.1445C12 14.8362 12.1028 14.5857 12.3083 14.3931C12.5138 14.2004 12.7707 14.104 13.079 14.104H15.7187V11.2331C15.7187 10.8735 15.8343 10.578 16.0655 10.3468C16.3096 10.1156 16.6179 10 16.9904 10C17.35 10 17.6455 10.1156 17.8767 10.3468C18.1079 10.578 18.2235 10.8735 18.2235 11.2331V14.104H20.921Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_359:280"
          x="0"
          y="0"
          width="33"
          height="33"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_359:280"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_359:280"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
