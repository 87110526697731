import React, { useRef } from 'react'
interface IProps {
    isOpen: boolean
    children: React.ReactNode
}
export const Collapsible: React.FC<IProps> = ({ children, isOpen }) => {
    const collapsibleRef = useRef<HTMLDivElement | null>(null)
    return (
        <>
            <div ref={collapsibleRef} style={{
                width: '100%',
                height: `${isOpen ? collapsibleRef.current?.scrollHeight + 'px' : '0px'}`,
                background: 'rgb(255, 255, 255)',
                borderRadius: '10px',
                overflow: 'hidden',
                transition: 'height ease-in-out 300ms'
            }}>
                {children}
            </div>
        </>
    )
}
