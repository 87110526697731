import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavConfigAdmin: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="2" disabled={location.pathname.includes('/prb')}>
                    <RouterLink to="/prb" key="2" >PRB</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/polies')}>
                    <RouterLink to="/polies" key="3" >Poli</RouterLink>
                </Menu.Item>
                <Menu.Item key="6" disabled={location.pathname.includes('/public-health')}>
                    <RouterLink to="/public-health" key="6" >Puskesmas</RouterLink>
                </Menu.Item>
                <Menu.Item key="4" disabled={location.pathname.includes('/bridging')}>
                    <RouterLink to="/bridging" key="4" >Bridging Pcare</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}