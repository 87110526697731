import React, { ReactElement, createContext, useRef, useEffect, useReducer, Dispatch, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { DispachtStates, initialStates } from './constant'
import { isEqual } from 'lodash'
import accounting from 'accounting'


const GlobalContext = createContext(initialStates)
const DispactContext = createContext(DispachtStates)

export const GLOBAL_LOADER = 'global_loader'
export interface GlobalStateProvideProps {
    children: ReactElement
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

export interface ObjectValue<T> {
    [key: string]: T
}


export const useDeepEffect = (func: Function, depedencies: Array<Object>) => {
    const isFirst = useRef(true)
    const prevDeps = useRef(depedencies)

    useEffect(() => {
        const isSame = prevDeps.current.every((obj, index) => isEqual(obj, depedencies[index]))

        if (isFirst.current || !isSame) {
            func()
        }
        isFirst.current = false
        prevDeps.current = depedencies
    }, depedencies)
}

export const GlobalStateProvider = (props: GlobalStateProvideProps) => {
    const { children } = props
    const [state, dispatch] = useReducer(
        (state: any, newValue: any) => {
            return ({ ...state, ...newValue })
        },
        initialStates
    )

    return (
        <GlobalContext.Provider value={state}>
            <DispactContext.Provider value={dispatch}>
                {children}
            </DispactContext.Provider>
        </GlobalContext.Provider>
    )
}

export function useGlobalState<Type>(k: string, v?: Type): [Type, Dispatch<Type>] {
    const [state, dispatch] = [
        useContext(GlobalContext),
        useContext(DispactContext)
    ]

    const setState = (key: string, value: Type) => {
        dispatch && dispatch({
            ...state, [key]: value
        })
    }

    const val: Type = (state as any)[k] || v
    const [localState, setLocalState] = useState(val)
    useDeepEffect(() => {
        setState(k, localState)
    }, [localState])

    return [
        val,
        setLocalState
    ]
}

export const GlobalCurrency = (rupiah: number) => {
    return accounting.formatMoney(rupiah, "Rp ", 0, ".", ",")

}

export const GlobalStatus = (status: number | boolean) => {
    let active = ''
    if (status == 1 || status == true) {
        active = 'AKTIF'
    } else {
        active = 'TIDAK AKTIF'
    }

    return active
}