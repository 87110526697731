import { Badge, Col, Row, Typography } from "antd";
import { FC } from "react";

const { Text } = Typography

const ResultProfile: FC = () => {
    return <>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} style={{ paddingLeft: 10}}>
            <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Status Berobat</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Badge color="green">
                        <Text strong>BPJS</Text>
                    </Badge>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Nama lengkap</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>Mahardiaz Prima Setiawan - A018 - 20180100548</Text>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Tanggal</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong style={{ color: '#219BD4'}}>06/11/2021</Text> <Text strong type="success">11:30</Text>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Status asuransi</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>BPJS - 00021312314112</Text>
                </Col>
                
            </Row>
        </Col>
        <Col span="2"/>
        {/* Right Column */}
        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: '5px 10px'}}>
            <Row>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Tanggal lahir</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>29/06/1990</Text>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Umur</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>29 Tahun 6 Bulan 21 Hari</Text>
                </Col>
                
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Layanan</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>Poli umum</Text>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5}}>
                    <Text type="secondary">Dokter</Text>
                </Col>
                <Col span={1} />
                <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5}}>
                    <Text strong>dr. Yusuf Wibisono</Text>
                </Col>
                
            </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0 10px'}}>
            <Row>
                <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                    <Text type="secondary">Alamat</Text>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ marginLeft: '-10px', marginBottom: 5}}>
                    <Text strong>Kp Tungturunan 3/3 Hegarmanah Sukaluyu KAB. CIANJUR</Text>
                </Col>
            </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: '0 10px'}}>
            <Row>
                <Col xs={24} sm={24} md={6} lg={3} xl={3}>
                    <Text type="secondary">Diagnosa</Text>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={18} style={{ marginLeft: '-10px'}}>
                    <Text strong>-</Text>
                </Col>
            </Row>
        </Col>

    </>
}

export default ResultProfile