import { Tooltip } from "antd";
import React from "react";
import { IconButton } from "../../../services/iconButton";

export const AddPeopleIconButton: React.FC<IconButton> = ({
  onClick,
  style,
}) => {
  return (
    <Tooltip placement="topLeft" title={"Tambah Data"}>
      <svg
        onClick={onClick}
        style={style}
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#219BD4"
        />
        <path
          d="M25.5982 20.1653C25.0082 19.8532 24.2772 20.0779 23.965 20.6675C23.4975 21.5517 22.5857 22.0998 21.5858 22.0998C20.5863 22.0998 19.6745 21.5506 19.2066 20.6675C18.8941 20.0779 18.1634 19.8532 17.5735 20.1653C16.9839 20.4771 16.7587 21.2082 17.0705 21.7981C17.9584 23.4742 19.6886 24.5163 21.5854 24.5163C23.4826 24.5163 25.2125 23.4742 26.1004 21.7981C26.4133 21.2085 26.1881 20.4775 25.5982 20.1653Z"
          fill="white"
        />
        <path
          d="M21.5856 10C16.3175 10 12.0308 14.2864 12.0308 19.5548C12.0308 23.1393 14.0134 26.2645 16.9407 27.8999C15.3576 28.7811 14.028 30.1103 13.1434 31.7756C12.8335 32.3656 13.0576 33.0974 13.6475 33.4073C13.8263 33.5044 14.0209 33.5492 14.2113 33.5492C14.6444 33.5492 15.0626 33.314 15.2792 32.907C16.3396 30.9019 18.2662 29.5465 20.458 29.1993C20.5849 28.2845 20.9169 27.4332 21.4064 26.6902C17.5531 26.5968 14.4462 23.4305 14.4462 19.5548C14.4462 15.6194 17.6498 12.4158 21.5852 12.4158C25.5207 12.4158 28.7243 15.6194 28.7243 19.5548C28.7243 21.2127 28.1567 22.7398 27.2046 23.9495C28.0746 24.0392 28.8927 24.3155 29.6129 24.7336C30.5763 23.2438 31.1401 21.4628 31.1401 19.5548C31.1404 14.2864 26.8544 10 21.5856 10Z"
          fill="white"
        />
        <path
          d="M26.6418 24.8469C23.5069 24.8469 20.9556 27.3971 20.9556 30.532C20.9556 33.6669 23.5061 36.2182 26.6418 36.2182C29.7763 36.2182 32.3269 33.6673 32.3269 30.532C32.3269 27.3974 29.7763 24.8469 26.6418 24.8469ZM26.6418 34.4136C24.5008 34.4136 22.7597 32.6726 22.7597 30.532C22.7597 28.3921 24.5008 26.6507 26.6418 26.6507C28.7813 26.6507 30.5231 28.3921 30.5231 30.532C30.5231 32.6718 28.7816 34.4136 26.6418 34.4136Z"
          fill="white"
        />
        <path
          d="M28.5692 29.6303H27.5443V28.6046C27.5443 28.1061 27.1407 27.7025 26.6426 27.7025C26.1437 27.7025 25.7401 28.1061 25.7401 28.6046V29.6303H24.7152C24.2171 29.6303 23.8135 30.0339 23.8135 30.532C23.8135 31.0304 24.2171 31.4341 24.7152 31.4341H25.7401V32.4594C25.7401 32.9575 26.1437 33.3611 26.6426 33.3611C27.1407 33.3611 27.5443 32.9578 27.5443 32.4594V31.4341H28.5692C29.0677 31.4341 29.4709 31.0304 29.4709 30.532C29.4709 30.0339 29.0673 29.6303 28.5692 29.6303Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
};
