import { Button, Col, Divider, Form, Input, Modal, Row, Select, Table, Typography } from "antd"
import { FC, useState } from "react"
import { useParams } from "react-router"
import { Concotion, ConcotionMedicine } from "../../../services/pharmacy/concotion"
import { InventoryLocation } from "../../../services/pharmacy/inventory.location"
import { Medicine } from "../../../services/pharmacy/medicine"
import { MedicineDetail } from "../../../services/pharmacy/medicine.detail"
import { Unit } from "../../../services/pharmacy/units"
import DataMedicine from "./medicine"

const { Option } = Select
const { TextArea } = Input

const ConcotionPage: FC = () => {
    const params = useParams() as any
    const [concotionModal, setConcotionModal] = useState(false)
    const [obat, setObat] = useState<InventoryLocation>()
    const [concotionMedicine, setConcotionMedicine] = useState<ConcotionMedicine[]>([])
    const [formDetail, setFormDetail ] = useState<ConcotionMedicine> ({

        concotion: {
            inc_id: 1
        } as any,
        inventory_location: {
            inc_id: 0
        } as InventoryLocation,
        used: 1,
        total: 1,
        note: '',
    })

    const [form, setForm] = useState<Concotion> ({
        visit: {
            inc_id: params.id
        } as any,
        pocket: 1,
        time: 1,
        note: '' 
    })

    const columns = [
        {
          title: 'Nama Obat',
          render: (c: ConcotionMedicine) => c.inventory_location.detail_medicine.unit.medicine.name,
          width: '30%'
        },
        {
          title: 'Dosis',
          render: (c: ConcotionMedicine) => `${c.used} hari` 
        },
        {
            title: 'Total',
            render: (c: ConcotionMedicine) => c.total

        },
        {
            title: 'Catatan',
            render: (c: ConcotionMedicine) => c.total * c.inventory_location.detail_medicine.price_purchase
          },
          {
            title: '',
            render: (c: ConcotionMedicine) => {
                return ( 
                    <> 
                        <Button type="link" danger title="Get Data" onClick={ () => deleteData(c.id as any) }>Hapus</Button>
                    </>
                 )
            }
            
          },
      ];

      const deleteData = (id: string) => {

      }

      const onChangeObat = (m: InventoryLocation) => {
        setObat(m)

        setFormDetail({
            ...formDetail,
            inventory_location: {
                inc_id: m.inc_id
            } as InventoryLocation
        })

        setConcotionModal(false)
    }
    

   
    const submitConcotionMed = () => {
        console.log(form)
        console.log(formDetail)
    }

    const submit = () => {
    
    }
    


    return <>
        <Form layout="vertical" id="racikan">
            <Row>
                <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                    <Form.Item label="Nama Obat" style={{ fontWeight: 'bold' }}>
                        <Input type="text" onClick={()=> setConcotionModal(true)} className="form-control" value={obat?.detail_medicine.unit.medicine.name}  onChange={({target : {value: inventory_location}}) => setFormDetail({...formDetail, inventory_location: {inc_id: inventory_location} as any})} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}/>
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item label="Dosis" style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control"  value={formDetail.used}  onChange={({target : {value: used}}) => setFormDetail({...formDetail, used} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} />
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={4} lg={3} xl={3}>
                    <Form.Item label="Total" style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control"  value={formDetail.total}  onChange={({target : {value: total}}) => setFormDetail({...formDetail, total} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} />
                    </Form.Item>
                </Col>
                <Col span="1" />
                <Col xs={24} sm={24} md={22} lg={9} xl={9}>
                    <Form.Item label="Catatan" style={{ fontWeight: 'bold' }}>
                        <Input type="text" className="form-control"  value={formDetail.note}  onChange={({target : {value: note}}) => setFormDetail({...formDetail, note} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}/>
                    </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={4} lg={2} xl={2} style={{ marginTop: '-2%'}}>
                    <Form.Item label=" ">
                        <Button type="primary" onClick={submitConcotionMed} size="middle"> Simpan</Button>
                    </Form.Item>
                </Col>
            </Row>

            <Modal
                title="Data Racikan Obat"
                style={{ top: 20 }}
                visible={concotionModal}
                onOk={() => setConcotionModal(false)}
                onCancel={() => setConcotionModal(false)}
                width={'80%'}
            >
               <DataMedicine 
                onSelectedObat={onChangeObat}
               />

            </Modal>
        </Form>
        <Divider />

        <Table
            columns={columns}
            dataSource={concotionMedicine}
            pagination={false}
            bordered
            summary={pageData => {
                return (
                <>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Berapa Bungkus ?</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                            <Select defaultValue="1" className="form-control"   onChange={(pocket: any ) => setForm({...form, pocket} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }}>
                                <Option value="1">1 bungkus</Option>
                                <Option value="2">2 bungkus</Option>
                                <Option value="3">3 bungkus</Option>
                            </Select>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Waktu Makan ?</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                            <Select defaultValue="1" className="form-control" onChange={(time: any ) => setForm({...form, time} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }}>
                                <Option value="1">1x</Option>
                                <Option value="2">2x</Option>
                            </Select>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>
                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Catatan</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} colSpan={2}>
                            <Form.Item>
                                <TextArea rows={5} className="form-control"   value={form.note}   onChange={({target : {value: note} } ) => setForm({...form, note} as any)} style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} />
                            </Form.Item>
                        </Table.Summary.Cell>
                    </Table.Summary.Row>

                    <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={3}>
                            <Button type="primary" style={{ float: 'right'}} onClick={submit}>Simpan</Button>
                        </Table.Summary.Cell>
                        
                    </Table.Summary.Row>

                </>
                );
            }}
        />
    </>
}

export default ConcotionPage