import {data} from "cypress/types/jquery";
import {baseURL} from "../baseurl";
import {Treatment} from "../configurations/treatment";
import {ICDInterface} from "../configurations/type.diseases";
import {IAnamnesis} from "../Registration/Anamnesis";
import {ArrayData, PaginationData} from "../utility";

const CheckupClient = baseURL;

export interface IPhysicalCheckup {
  eye: number | string;
  verbal: number | string;
  motorik: number | string;
  type_of_consciousness: string;
  info?: string;
  kir_condition?: string;
  anamnesis?: IAnamnesis;
}

export interface ICreateDiagnosis {
  disease: string;
  type_diagnosis: string;
  info: string;
}

export interface IDiagnosis {
  id?: string;
  disease: ICDInterface;
  type_diagnosis: string;
  info: string;
  anamnesis?: IAnamnesis;
}
export interface ITreatment {
  id?: string;
  treatment: {
    name: string;
  };
  nurse: {
    fullname: string;
  };
  info: string;
}

export interface IPostTreatment {
  id?: string;
  treatment?: string;
  nurse: string;
  info: string;
}

export interface ICheckupPlan {
  id?: string;
  name?: string;
  status?: boolean;
  anamnesis?: IAnamnesis;
}

export interface DestroyCheckupPlan {
  id?: string;
  name?: string;
  status?: boolean;
  anamnesis: IAnamnesis;
}

export interface ISelectedMedicine {
  inc_id: number;
  id: string;
  min_stock: number;
  max_stock: number;
  safety: number;
  qty_smallest_unit: number;
  created: string | Date;
  updated: string | Date;
  deleted: string | Date;
  medicine: IMedicine;
  location: ILocation;
}

export interface IMedicine {
  inc_id: number;
  id: string;
  clinic: number;
  code: string;
  barcode: string;
  name: string;
  bpjs: boolean;
  mix: boolean;
  active_substance: string;
  status: boolean;
  created: string | Date;
  updated: string | Date;
  deleted: string | Date;
  units: IUnits;
}

export interface IUnits {
  inc_id: number;
  id: string;
  smallest_unit: number;
  primary: boolean;
  price_selling: number;
  price_purchase: number;
  created: string | Date;
  updated: string | Date;
  unit: {
    inc_id: number;
    id: string;
    clinic: number;
    name: string;
    created: string | Date;
    updated: string | Date;
    deleted: string | Date;
  };
}

export interface ILocation {
  inc_id: number;
  id: string;
  clinic: number;
  name: string;
  info: string;
  created: string | Date;
  updated: string | Date;
  deleted: string | Date;
}

export interface IDirectMedicine {
  medicine: string;
  use: string;
  qty: number;
  note: string;
  status: string;
}

const useCheckup = () => ({
  async submitPhysicalCheck(
    anamnesisId: string,
    physicalCheckData: IPhysicalCheckup
  ): Promise<IPhysicalCheckup> {
    const {data} = await CheckupClient.post(
      `checkup/general/anamnesis/${anamnesisId}`,
      physicalCheckData
    );
    return data;
  },

  async getPhysicalCheck(anamnesisId: string): Promise<IPhysicalCheckup> {
    const {data} = await CheckupClient.get(
      `checkup/general/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async submitDiagnosis(
    anamnesisId: string,
    diagnosis: ICreateDiagnosis
  ): Promise<IDiagnosis> {
    const {data} = await CheckupClient.post(
      `checkup/diagnosis/anamnesis/${anamnesisId}`,
      diagnosis
    );
    return data;
  },

  async getDiagnosis(anamnesisId: string): Promise<ArrayData<IDiagnosis>> {
    const {data} = await CheckupClient.get(
      `checkup/diagnosis/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async deleteDiagnosis(
    diagnosisId: string,
    anamnesisId: string
  ): Promise<void> {
    const {data} = await CheckupClient.delete(
      `checkup/diagnosis/${diagnosisId}/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async submitSelectedTreatments(
    anamnesisId: string,
    treatment: IPostTreatment
  ): Promise<IPostTreatment> {
    const {data} = await CheckupClient.post(
      `checkup/treatments/anamnesis/${anamnesisId}`,
      treatment
    );
    return data;
  },

  async getSelectedTreatments(anamnesisId: string): Promise<ITreatment[]> {
    const {data} = await CheckupClient.get(
      `checkup/treatments/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async deleteSelectedTreatments(treatmentId: string): Promise<void> {
    const {data} = await CheckupClient.delete(
      `checkup/treatments/${treatmentId}/anamnesis`
    );
    return data;
  },

  async postCheckupPlan(
    anamnesisId: string,
    checkupPlan: ICheckupPlan
  ): Promise<ICheckupPlan> {
    const {data} = await CheckupClient.post(
      `checkup/plan/anamnesis/${anamnesisId}`,
      checkupPlan
    );
    return data;
  },

  async getCheckupPlan(anamnesisId: string): Promise<ArrayData<ICheckupPlan>> {
    const {data} = await CheckupClient.get(
      `checkup/plan/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async updateCheckupPlan(
    planId: string,
    anamnesisId: string
  ): Promise<ICheckupPlan> {
    const {data} = await CheckupClient.patch(
      `checkup/plan/${planId}/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async deleteCheckupPlane(planId: string, anamnesisId: string): Promise<void> {
    const {data} = await CheckupClient.delete(
      `checkup/plan/${planId}/anamnesis/${anamnesisId}`
    );
    return data;
  },

  async postDirectMedicine(
    anamnesisId: string,
    directMedicine: IDirectMedicine
  ): Promise<IDirectMedicine> {
    const {data} = await CheckupClient.post(
      `checkup/medicine-recipe/anamnesis/${anamnesisId}`,
      directMedicine
    );
    return data;
  },
  async getDirectMedicine(anamnesisId: string): Promise<IDirectMedicine[]> {
    const {data} = await CheckupClient.get(
      `checkup/medicine-recipe/anamnesis/${anamnesisId}`
    );
    return data;
  },
  // Temporary Medicine
  async fetchMedicine(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<ISelectedMedicine[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";
    const {data} = await CheckupClient.get(
      `medicine/location/87769b6f-b697-4549-8b70-61b9f5cc1ed6/stock?${searching}&${sizes}&${rows}`
    );
    return data;
  },
});

export default useCheckup;
