import { ThirdStepForm } from "../../../components/doctor/checkup/thirdStepForm";

const ThirdStepPage = () => {
  return (
    <>
      <ThirdStepForm />
    </>
  );
};

export default ThirdStepPage;
