import { Col, Row, Table, Typography } from "antd"
import Column from "antd/lib/table/Column";
import { FC } from "react"
import DataTable from "react-data-table-component";

const { Text } = Typography

const ResultPhysicalCheck: FC = () => {
    const customStyles = {
        rows: {
            style: {
                border: '1px solid #edf0f2'
            }
        },
        headCells: {
            style: {
                backgroundColor: '#089ED4',
                color: '#fff',
                border: '1px solid #edf0f2'

            },
        },
        cells: {
            style: {
                border: '1px solid #edf0f2'
            }
        }
    };

    const columns = [
        {
            name: 'Nama Pemeriksaan',
            selector: (row: any) => <Text strong>{row.name}</Text>,
        },
        {
            name: 'Keterangan',
            selector: (row: any) => <Text strong>{row.info}</Text>,
        }
    ]
    const data = [
        {
            key: '1',
            name: 'Odontogram',
            info: 'bla-bla'

        }
    ];


    return <>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 10, paddingBottom: 20 }}>
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 10 }}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} style={{ marginBottom: 5 }}>
                            <Text type="secondary">Pemeriksaan Fisik 1</Text>
                        </Col>
                        <Col span={2} />
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} style={{ marginBottom: 5 }}>
                            <Text> - </Text>
                        </Col>
                    </Row>
                </Col>
                {/* Right Column */}
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ padding: 10 }}>
                    <Row>
                        <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginBottom: 5 }}>
                            <Text type="secondary">Pemeriksaan Fisik 2</Text>
                        </Col>
                        <Col span={1} />
                        <Col xs={24} sm={24} md={17} lg={17} xl={17} style={{ marginBottom: 5 }}>
                            <Text> - </Text>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 10 }}>
                    <DataTable
                        data={data}
                        columns={columns}
                        pagination={false}
                        style={{ marginTop: '-4em' }}
                        customStyles={customStyles}
                    />

                </Col>

            </Row>
        </Col>
    </>
}

export default ResultPhysicalCheck