import { Button,  Divider, Row, Typography } from "antd";
import { useHistory } from "react-router";
import { EditButton } from "../../form/EditButton";
import Card from "../../layout/card";
import Consultant from "../result/consultant";
import ResultDiagnosis from "../result/diagnosis";
import ResultFirstCheck from "../result/first_check";
import ResultPhysicalCheck from "../result/physical_check";
import ResultProfile from "../result/profile";
import ResultRecipe from "../result/recipe";
import ResultTreatment from "../result/treatment";
import TitleWithStep from "../../layout/stepTitle";

export const FifthStep = () => {
  const history = useHistory();
  return (
    <>
      <TitleWithStep
        title="Hasil Pemeriksaan"
        stepTitle="Tahap 5"
        description="data hasil pemeriksaan riwayat pasien"
      />
      <Card title="Data Pemeriksaan Pasien">
        <Row align="middle" style={{ padding: "2%" }}>
          <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
            Data Pembayaran
          </Typography.Title>
          <Divider
            dashed
            style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
          />
          <Consultant />
          <Row justify="end" style={{ width: "100%", margin: "1% 0" }}>
            <Button
              onClick={() => history.replace("/checkup")}
              className="btn-primary"
            >
              Selesai
            </Button>
          </Row>
          <Row>
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              1. Profile Pasien
            </Typography.Title>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultProfile />
            {/* Pemeriksaan Awal */}
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              2. Pemeriksaan awal
            </Typography.Title>{" "}
            <EditButton onClick={() => console.log("2. Pemeriksaan Awal")}>
              Edit
            </EditButton>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultFirstCheck />
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              3. Pemeriksaan fisik & penunjang
            </Typography.Title>{" "}
            <EditButton
              onClick={() => console.log("3. Pemeriksaan fisik & penunjang")}
            >
              Edit
            </EditButton>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultPhysicalCheck />
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              4. Diagnosa
            </Typography.Title>{" "}
            <EditButton onClick={() => console.log("4. Diagnosa")}>
              Edit
            </EditButton>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultDiagnosis />
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              5. Tindakan & Perlengkapan
            </Typography.Title>{" "}
            <EditButton
              onClick={() => console.log("5. Tindakan & Perlengkapan")}
            >
              Edit
            </EditButton>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultTreatment />
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              6. Resep Obat
            </Typography.Title>{" "}
            <EditButton onClick={() => console.log("6. Resep Obat")}>
              Edit
            </EditButton>
            <Divider
              dashed
              style={{ marginTop: "1%", borderColor: "rgba(0, 0, 0, 0.25)" }}
            />
            <ResultRecipe />
            <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>
              7. Keterangan
            </Typography.Title>{" "}
            <EditButton onClick={() => console.log("7. Keterangan")}>
              Edit
            </EditButton>
          </Row>
        </Row>
      </Card>
    </>
  );
};
