import React from 'react'
import { DataCoba } from '../../components/coba'


export const Coba: React.FC = () => {
    return (
        <>
            <DataCoba />
        </>
    )
}
