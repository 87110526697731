import { Tooltip } from "antd";
import React from "react";

interface IProps {
  style?: any;
  className?: string;
}

export const HomeMenuIcon: React.FC<IProps> = ({ style, className }) => {
  return (
    <Tooltip placement="right" title={"Dashboard"}>
      <svg
        style={style}
        className={className}
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="22.5" cy="22.5" r="22.5" fill="#219BD4" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.8567 13.3753C22.6466 13.2183 22.3534 13.2183 22.1433 13.3753L14.56 19.0448C14.417 19.1517 14.3333 19.3166 14.3333 19.4914V29.1781C14.3333 29.4898 14.5945 29.7425 14.9167 29.7425H16.9318C17.254 29.7425 17.5152 29.4898 17.5152 29.1781V23.8421C17.5152 22.2836 18.821 21.0202 20.4318 21.0202H24.5682C26.179 21.0202 27.4848 22.2836 27.4848 23.8421V29.1781C27.4848 29.4898 27.746 29.7425 28.0682 29.7425H30.0833C30.4055 29.7425 30.6667 29.4898 30.6667 29.1781V19.4914C30.6667 19.3166 30.583 19.1517 30.44 19.0448L22.8567 13.3753ZM20.7166 11.589C21.767 10.8037 23.233 10.8037 24.2834 11.589L31.8667 17.2585C32.5815 17.7928 33 18.6175 33 19.4914V29.1781C33 30.7366 31.6942 32 30.0833 32H28.0682C26.4573 32 25.1515 30.7366 25.1515 29.1781V23.8421C25.1515 23.5304 24.8903 23.2777 24.5682 23.2777H20.4318C20.1097 23.2777 19.8485 23.5304 19.8485 23.8421V29.1781C19.8485 30.7366 18.5426 32 16.9318 32H14.9167C13.3058 32 12 30.7366 12 29.1781V19.4914C12 18.6175 12.4185 17.7928 13.1333 17.2585L20.7166 11.589Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
};
