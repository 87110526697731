import { Col, Row, Card, Typography, Progress} from "antd"
const { Title } = Typography

const DashboardPelayanan = () => {
    return <>
         
        <Row justify="space-around">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Row style={{ marginBottom: 10}}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Row>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">
                                    <Title level={5} disabled>pendaftaran hari ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>4000</Typography.Text>
                                        <span className="mock-block"> orang</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">

                                    <Title level={5} disabled>kunjungan hari ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>4000</Typography.Text>
                                        <span className="mock-block"> orang</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">

                                    <Title level={5} disabled>pendaftaran bulan ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>8</Typography.Text>
                                        <span className="mock-block"> menit</span>
                                    </div>
                                </Card>
                            </Col> 
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Card className="card-display">

                                    <Title level={5} disabled>kunjungan bulan ini</Title>
                                    <div style={{ margin: 10, textAlign: 'right'}}>
                                        <Typography.Text style={{ fontSize: '2.4em'}} strong>10</Typography.Text>
                                        <span className="mock-block"> menit</span>
                                    </div>
                                </Card>
                            </Col> 

                            
                        </Row>
                    </Col>
                </Row>
                <Row justify="space-around">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card className="card-display" style={{ minHeight: '450px', marginBottom: 10 }}>
                            <Title level={5} disabled>Kunjungan Pasien</Title>
                        </Card>
                    </Col>
                </Row>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="card-display" style={{ marginBottom: 20, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>pasien umum</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>4000</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Orang</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="card-display" style={{ marginBottom: 20, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>pasien bpjs</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>2000</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Orang</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>rata-rata tunggu pendaftaran</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>8</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Menit</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                            <Col span={24}>
                                <Title level={5} disabled>rata-rata tunggu anamnesa</Title>
                            </Col>
                            <div style={{ width: '90%', textAlign: 'right',}}>
                                <div>
                                    <Typography.Text style={{ fontSize: '2.4em'}} strong>10</Typography.Text>
                                    <span style={{ fontSize: '1.3em'}}> Menit</span>
                                </div>
                            </div>
                        </Card>
                    </Col>

                </Row>
                
            </Col>

            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                    <Col  span={16} offset={4}>
                        <Title level={5} disabled>pasien by poli</Title>
                    </Col>
                    <div style={{ width: '90%'}}>
                    <Typography.Text>Poli Umum</Typography.Text>
                    <Progress percent={1000} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Poli Gigi</Typography.Text>
                    <Progress percent={23} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Poli KIA</Typography.Text>
                    <Progress percent={5} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Laboratorium</Typography.Text>
                    <Progress percent={90} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Poli USG</Typography.Text>
                    <Progress percent={50} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Poli Kecantikan</Typography.Text>
                    <Progress percent={4} status="active" format={percent=> `${percent}`} />
                    </div>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <Card className="card-display" style={{ marginBottom: 10, marginRight: 6}}>
                    <Col  span={16} offset={4}>
                        <Title level={5} disabled>pasien by source</Title>
                    </Col>
                    <div style={{ width: '90%'}}>
                    <Typography.Text>Tahu Sendiri</Typography.Text>
                    <Progress percent={90} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Website</Typography.Text>
                    <Progress percent={23} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Instagram</Typography.Text>
                    <Progress percent={5} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Facebook</Typography.Text>
                    <Progress percent={90} status="active" format={percent=> `${percent}`} />
                    <Typography.Text>Dari Orang lain</Typography.Text>
                    <Progress percent={50} status="active" format={percent=> `${percent}`} />
                    </div>
                </Card>
            </Col>
        </Row>
    </>
}

export default DashboardPelayanan