import React, { FC, useEffect, useState } from 'react'
import { Button, Col,  Row, Tabs, Typography, Form, Input, Modal, Select,  Badge, Table, Divider} from 'antd';
import {ExclamationCircleOutlined, CloseCircleFilled} from '@ant-design/icons'
import Card from '../../layout/card'
import Title from "../../layout/title";
import Recipe from '../form/recipe';
import DataTreatment from './_treatment';
import ConcotionPage from '../form/concotion';
import useRecipe, { RecipeMedicine } from '../../../services/pharmacy/recipe';
import { useParams } from 'react-router';
import { successNotification } from '../../layout/notification';
import DataTable from 'react-data-table-component';
import ProfilePasien from './_profile';

const { Text } = Typography;
const { TabPane } = Tabs;

const { confirm } = Modal


const DetailQueue: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)
    const recipeServices = useRecipe()
    const params = useParams<any>()
    const [RecipeMedicine, setRecipeMedicine] = useState<RecipeMedicine[]>([])


    const columns = [
        {
          title: 'Nama Obat',
          render: (c: RecipeMedicine) => c.inventory_location.detail_medicine.unit.medicine.name,
          width: '30%'
        },
        {
          title: 'Keterangan',
          render: (c: RecipeMedicine) => `${c.used} x ${c.time} hari` 
        },
        {
          title: 'Harga',
          render: (c: RecipeMedicine) => c.inventory_location.detail_medicine.price_purchase
        },
        {
            title: 'Total',
            render: (c: RecipeMedicine) => c.total

        },
        {
            title: 'Total Harga',
            render: (c: RecipeMedicine) => c.total * c.inventory_location.detail_medicine.price_purchase
          },
          {
            title: '',
            render: (c: RecipeMedicine) => {
                return ( 
                    <> 
                        <Button type="link" danger title="Get Data" onClick={ () => deleteRecipe(c.id as any) }>Hapus</Button>
                    </>
                 )
            }
            
          },
      ];

      const deleteRecipe = (id: string) => {
          console.log(id)
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: ``,
            onOk() {
              setLoader(true)
              recipeServices
                  .delete(id)
                  .then(() => {
                      loadRecipe()
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
      }

      const loadRecipe = () => {
        setLoader(true)
        recipeServices
        .get(params.id)
        .then(RecipeMedicine => {
            console.log(RecipeMedicine)
            setRecipeMedicine(RecipeMedicine)
           
            }
        )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }

        const col = [
            {
                name: 'Nama Obat',
                selector: (row: any) => <Input value={row.name} style={{ width: '100%', minWidth: 250, border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} ></Input>,
                width : '25%',
            },
            {
                name: 'Signa',
                selector: (row: any) => <Input value={row.used} disabled style={{ border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} ></Input>,
                width: '20%'
            }, 
            {
                name: 'Total',
                selector: (row: any) => <Input value={row.qty} onKeyUp={() => console.log('test')} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} ></Input>,
                width: '15%'
            }, 
            {
                name: 'Info',
                selector: (row: any) => <Input value={row.info} disabled style={{ width: '100%', minWidth: 250, border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} ></Input>,
                width: '25%'
            },
            {
                name: '',
                selector: (row: any) => <Button type='link' danger onClick={() => deleteData(row.id as any)}> <CloseCircleFilled style={{ fontSize: '1.5em'}}/> </Button>,
                width : '10%'
            }
        ]

        const rows = [
            {
                id: 1,
                name: 'Paracetamol',
                qty: 10,
                info: 'Setelah Makan',
                used: '3x1 hari',
            },
            {
                id: 1,
                name: 'Caviplex',
                qty: 10,
                info: 'Setelah Makan',
                used: '1x1 hari',
            }
        ]


    const deleteData = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              successNotification('hapus')
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadRecipe,[])


    return <div>
        <Title title="Kunjungan Pasien" description="detail data kunjungan pasien" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                    {/* Data Pasien */}
                        <ProfilePasien />
                    {/* Data Tindakan */}
                        <DataTreatment />
                    {/* Data Pasien */}
                    <Card title="Tambah obat & pembayaran">
                        <Tabs defaultActiveKey="1">
                            <TabPane tab="Obat" key="1">
                                <Recipe 
                                setRecipe={setRecipeMedicine!} />
                            </TabPane>
                            <TabPane tab="Obat Racikan" key="2">
                                <ConcotionPage/>
                            </TabPane>
                            <TabPane tab="Biaya Lain" key="3">
                            Content of Tab Pane 3
                            </TabPane>
                        </Tabs>
                    </Card>

                    
                    {/* Detail Biaya Pasien */}
                    <Card title="Detail obat pasien">
                        {/* <Table
                            columns={columns}
                            dataSource={RecipeMedicine}
                            pagination={false}
                            scroll={{ x: 500, y: 500 }}
                            bordered
                            summary={pageData => {
                                let grandtotal = 0;
                                console.log(pageData)
                                // // // let totalRepayment = 0;
                                
                                pageData.forEach(({ total, inventory_location }) => {
                                    console.log(inventory_location)
                                    grandtotal += (inventory_location.detail_medicine.price_purchase * total) ;
                                });

                                return (
                                <>
                                    <Table.Summary.Row>
                                        <Table.Summary.Cell index={0} colSpan={4}>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell index={1}>
                                            <Text type="danger">{grandtotal}</Text>
                                        </Table.Summary.Cell>
                                        <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                    </Table.Summary.Row>
                                </>
                                );
                            }}
                        /> */}
                        <Typography.Title level={5}>Resep Obat</Typography.Title>
                        <Divider dashed></Divider>
                        <DataTable
                            columns={col}
                            data={rows}
                            pagination={false}
                            style={{ marginTop : '-5em'}}
                        />

                        <Divider dashed></Divider>
                        <Typography.Title level={5}>Racik Obat</Typography.Title>
                        <Divider dashed></Divider>


                        <Button type="primary" style={{ marginTop: 20}}>Selesai</Button>
                    </Card>
                    
            </Col>
        </Row>
    </div>
}

export default DetailQueue