import React, { FC } from "react";
import { DataDisplayTV } from "../../../components/administration/display_tv";

const DisplayTV: FC = () => {
  return (
    <div>
      <DataDisplayTV />
    </div>
  );
};

export default DisplayTV