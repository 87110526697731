import Axios from "axios";
import { getEnv } from "../config";

export enum StatusNumber {
  TRUE = 1,
  FALSE = 0,
}

// Create instance
export const baseURL = Axios.create({
  baseURL: getEnv(`REACT_APP_BASE_URL`),
  headers: {
    "Content-Type": "application/json",
    "x-type": "user",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept",
  },
});

baseURL.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

// export const id = 'klinik.keluarga'
// export const id = localStorage.getItem('clinic_id') && localStorage.getItem('clinic_id')
export const id = 1; //CLINIC ID
