import React from "react";
import { DataRegQueue } from "../../../components/administration/reg_queue";

export const RegQueue: React.FC = () => {
  return (
    <>
      <DataRegQueue />
    </>
  );
};
