import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import { successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';

import useChest, {Chest} from '../../../services/pharmacy/chest';
import { FilterComponent2 } from '../../layout/fiterDataTable';

const { confirm } = Modal


const DataChest = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadChest();
      }
    };

    return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()

  const params = useParams<any>()
  const [Chest, setChest] = useState<PaginationData<Chest[]>>({data: [], pagination: {}})
  const filteredItems = Chest.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const ChestServices = useChest()
  const [loader, setLoader] = useState<boolean>(false)

  //Form Variabel
  const [ form, setForm ] = useState<Chest>({
    name: ''
  })

  const resetField = () => {
    setForm({
      name: '', 
    } as any)
  }
 
  const columns = [
    {
      name: 'Nama',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Aksi',
      button: true,
      cell: (row: any) => {
          return ( 
              <>
              
                  <Button id="chest-edit" type="text" onClick={ () => { history.push(`/chest/${row.id}`);  window.location.reload() } }><FormOutlined /></Button>
                  <Button id="chest-delete" danger type="text" onClick={ () => deleteData(row.id) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getChest = () => {
    setLoader(true)
    ChestServices
        .get(params.id)
        .then(form => {
            setForm({
              name: form.name, 
            })
            console.log(form)
        }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }


  const loadChest = () => {
    setLoader(true)
    ChestServices
        .fetch('', perPage, page)
        .then(Chest => {
            setChest(Chest)
            let total = (Chest.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    ChestServices
        .fetch(searhing, perPage, 1)
        .then(Chest => {
            setChest(Chest)
            let total = (Chest.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    ChestServices
        .fetch('', perPage, pages)
        .then(Chest => {
            setChest(Chest)
            let total =  (Chest.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    ChestServices
        .fetch('', newPerPage, pages)
        .then(Chest => {
            setChest(Chest)
            let total = (Chest.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoader(true)
        ChestServices
            .delete(id)
            .then(() => {
                loadChest()
                successNotification('hapus')
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  }

  const submit = () => {
    // console.log( !!params.id)
    // console.log(form)
          
      !!params.id === false  && ChestServices
      .create(form)
      .then(() => { 
          loadChest() 
          successNotification('tambah')
          resetField()
      })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      !!params.id === true && ChestServices
          .update(params.id, form)
          .then(() => { 
              loadChest() 
              successNotification('perbaharui')
              window.location.href = "/chest"
          })
          .catch(e => console.log(e))
          .finally(()=> setLoader(false))
  }


    useEffect(() => {
        loadChest();
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getChest()
    }, [])


  return (<>
    <Title title="Lemari" description="daftar data lemari obat" />
    <Row>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <Card title="tambah lemari" >
                <Form layout="vertical">
                    <Form.Item label="nama" className='lable-form-input'>
                        <Input data-cy="name_chest" value={form.name}  onChange={({target : {value: name}}) => setForm({...form, name})} className="form-control" />
                    </Form.Item>    
                </Form>
                <Form.Item>
                    <Button className='btn_submit' type="primary" onClick={submit}>Simpan</Button>
                </Form.Item>
            </Card>
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1}>
        </Col>
        <Col xs={24} sm={24} md={15} lg={15} xl={15}>
            {
                loader && <div>Loading .... </div>
            }
            <Card title="data lemari">
              <DataTable
                  columns={columns}
                  data={filteredItems}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                  paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  striped={true}
                  highlightOnHover={true}
                  noHeader={true}
                  className='data-table'
              />
            </Card>
        </Col>
    </Row>
    </>
  );
};

export default DataChest 