
import { FC } from 'react'
import Online from '../../components/administration/online'

const OnlinePage: FC = () => {
    return <div>
        <Online/>
    </div>
}

export default OnlinePage