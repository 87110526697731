import React, { FC } from 'react'
import { Input as InputForm, Form } from 'antd'

export interface OnInputValueChange {
    (value: string): void
}

export interface InputProps {
    name: string
    label: string
    value?: string
    onValueChange?: OnInputValueChange
    className?: string
    placeholder?: string
    addonAfter?: string
    addonBefore?: string
}

const Input: FC<InputProps> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onValueChange && props.onValueChange(e.target.value)
    }

    return (
        <Form.Item label={props.label} name={props.name} style={{ fontWeight: 'bold', fontSize: '1em', marginBottom: 10 }}>
            <InputForm name={props.name} placeholder={props.placeholder} style={{ border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} value={props.value} onChange={onChange} className={props.className} addonAfter={props.addonAfter}/>
        </Form.Item>
    )
}

export default Input
