import { Tooltip } from "antd";
import React from "react";

export const ReportMenuIcon = () => {
  return (
    <Tooltip placement="right" title={"Laporan"}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#21D47E"
        />
        <path
          d="M29 13V13C30.6569 13 32 14.3432 32 16L32 18.1429C32 18.477 32 18.644 31.9234 18.766C31.8834 18.8296 31.8296 18.8834 31.766 18.9234C31.644 19 31.477 19 31.1429 19L26 19M29 13V13C27.3431 13 26 14.3432 26 16L26 19M29 13L18 13C16.1144 13 15.1716 13 14.5858 13.5858C14 14.1716 14 15.1144 14 17L14 31L17 30L20 31L23 30L26 31L26 19"
          stroke="white"
          stroke-width="2"
        />
        <path
          d="M18 17L22 17"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M19 21H18"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
        <path
          d="M18 25L21 25"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </Tooltip>
  );
};
