import Unit from "../../pages/pharmacy/unit";
import Category from "../../pages/pharmacy/category";
import Chest from "../../pages/pharmacy/chest";
import Location from "../../pages/pharmacy/location";
import Group from "../../pages/pharmacy/group";
import Supplier from "../../pages/pharmacy/supplier";
import FormSupplier from "../../components/pharmacy/supplier/_form";
import QueuePharmacy from "../../pages/pharmacy/queue";
import QueueDetailPharmacy from "../../pages/pharmacy/queue.detail";
import VisitDetailPharmacy from "../../pages/pharmacy/visit.detail";
import VisitPharmacy from "../../pages/pharmacy/visit";
import MedicinePage from "../../pages/pharmacy/medicine";
import FormMedicine from "../../components/pharmacy/medicine/_form";
import FormMedicineDetail from "../../components/pharmacy/medicine/_form_detail";
import OrderPharmacyPage from "../../pages/pharmacy/order";
import OrderDetailPharmacyPage from "../../pages/pharmacy/order.detail";
import ReceivePharmacyPage from "../../pages/pharmacy/receive";
import ReceiveDetailPharmacyPage from "../../pages/pharmacy/receive.detail";
import ReleaseDetailPharmacyPage from "../../pages/pharmacy/release.detail";
import ReleasePharmacyPage from "../../pages/pharmacy/release";
import DashboardPharmacyPage from "../../pages/pharmacy/dashboard";
import InventoryPharmacyPage from "../../pages/pharmacy/inventory";
import StockopnamePharmacyPage from "../../pages/pharmacy/stock.opname";
import StockODetailPharmacyPage from "../../pages/pharmacy/stock.opname.detail";
import StockOResultPharmacyPage from "../../pages/pharmacy/stock.opname.result";
import InventoryDetailPharmacyPage from "../../pages/pharmacy/inventory.detail";
import OrderViewharmacyPage from "../../pages/pharmacy/order.view";
import ReceiveViewPharmacyPage from "../../pages/pharmacy/receive.view";
import ReleaseViewPharmacyPage from "../../pages/pharmacy/release.view";
import MainLayout from "../../components/layout/mainLayout";
import { RouterInterface } from "../router.interface";

export const PharmacyRoute: RouterInterface[] = [
  {
    component: DashboardPharmacyPage,
    layout: MainLayout,
    path: '/',
    exact: true
  },
  {
    component: QueueDetailPharmacy,
    layout: MainLayout,
    path: '/queue/:id',
    exact: true
  },
  {
    component: QueuePharmacy,
    layout: MainLayout,
    path: '/queue',
    exact: true
  },
  {
    component: VisitDetailPharmacy,
    layout: MainLayout,
    path: '/visit/:id',
    exact: true
  },
  {
    component: VisitPharmacy,
    layout: MainLayout,
    path: '/visit',
    exact: true
  },
  {
    component: OrderViewharmacyPage,
    layout: MainLayout,
    path: '/order/view/:id',
    exact: true
  },
  {
    component: OrderDetailPharmacyPage,
    layout: MainLayout,
    path: '/order/:id',
    exact: true
  },
  {
    component: OrderPharmacyPage,
    layout: MainLayout,
    path: '/order',
    exact: true
  },
  {
    component: ReceiveViewPharmacyPage,
    layout: MainLayout,
    path: '/receive/view/:id',
    exact: true
  },
  {
    component: ReceiveDetailPharmacyPage,
    layout: MainLayout,
    path: '/receive/:id',
    exact: true
  },
  {
    component: ReceivePharmacyPage,
    layout: MainLayout,
    path: '/receive',
    exact: true
  },
  {
    component: ReleaseViewPharmacyPage,
    layout: MainLayout,
    path: '/release/view/:id',
    exact: true
  },
  {
    component: ReleaseDetailPharmacyPage,
    layout: MainLayout,
    path: '/release/:id',
    exact: true
  },
  {
    component: ReleasePharmacyPage,
    layout: MainLayout,
    path: '/release',
    exact: true
  },
  {
    component: InventoryDetailPharmacyPage,
    layout: MainLayout,
    path: '/inventory-stock/:id',
    exact: true
  },
  {
    component: InventoryPharmacyPage,
    layout: MainLayout,
    path: '/inventory-stock',
    exact: true
  },
  {
    component: StockOResultPharmacyPage,
    layout: MainLayout,
    path: '/stock-opname/:id',
    exact: true
  },
  {
    component: StockopnamePharmacyPage,
    layout: MainLayout,
    path: '/stock-opname',
    exact: true
  },
  {
    component: StockODetailPharmacyPage,
    layout: MainLayout,
    path: '/adjustment/:id',
    exact: true
  },
  {
    component: Unit,
    layout: MainLayout,
    path: '/unit/:id',
    exact: true
  },
  {
    component: Unit,
    layout: MainLayout,
    path: '/unit',
    exact: true
  },
  {
    component: Category,
    layout: MainLayout,
    path: '/category/:id',
    exact: true
  },
  {
    component: Category,
    layout: MainLayout,
    path: '/category',
    exact: true
  },
  {
    component: Chest,
    layout: MainLayout,
    path: '/chest/:id',
    exact: true
  },
  {
    component: Chest,
    layout: MainLayout,
    path: '/chest',
    exact: true
  },
  {
    component: Location,
    layout: MainLayout,
    path: '/location/:id',
    exact: true
  },
  {
    component: Location,
    layout: MainLayout,
    path: '/location',
    exact: true
  },
  {
    component: Group,
    layout: MainLayout,
    path: '/group/:id',
    exact: true
  },
  {
    component: Group,
    layout: MainLayout,
    path: '/group',
    exact: true
  },
  {
    component: FormSupplier,
    layout: MainLayout,
    path: '/supplier/:id',
    exact: true
  },
  {
    component: Supplier,
    layout: MainLayout,
    path: '/supplier',
    exact: true
  },
  {
    component: FormMedicineDetail,
    layout: MainLayout,
    path: '/medicine/:id/detail',
    exact: true
  },
  {
    component: FormMedicine,
    layout: MainLayout,
    path: '/medicine/:id',
    exact: true
  },
  {
    component: MedicinePage,
    layout: MainLayout,
    path: '/medicine',
    exact: true
  },
]