import { Button, Divider, Dropdown, Menu, Row, Space, Typography } from 'antd'
import Card from '../layout/card'
import ResultDiagnosis from '../administration/result/diagnosis'
import ResultFirstCheck from '../administration/result/first_check'
import ResultPhysicalCheck from '../administration/result/physical_check'
import ResultProfile from '../administration/result/profile'
import ResultRecipe from '../administration/result/recipe'
import ResultTreatment from '../administration/result/treatment'

export const ResultVisit = () => {

    const menu = (
        <Menu>
          <Menu.Item key="1">Rekam Medis</Menu.Item>
          <Menu.Item key="2">Surat Sakit</Menu.Item>
          <Menu.Item key="3">Keterangan Berobat</Menu.Item>
        </Menu>
      );
      
    return (
        <Card title="Data Pemeriksaan Pasien">
            <Row align="middle" style={{ padding: '2%' }}>
                <Typography.Title level={5} style={{ padding: 0, margin: 0 }} >1. Profile Pasien</Typography.Title>

                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultProfile />

                {/* Pemeriksaan Awal */}

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>2. Pemeriksaan awal</Typography.Title>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultFirstCheck />

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>3. Pemeriksaan fisik & penunjang</Typography.Title>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultPhysicalCheck />

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>4. Diagnosa</Typography.Title>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultDiagnosis />

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>5. Tindakan & Perlengkapan</Typography.Title>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultTreatment />


                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>6. Resep Obat</Typography.Title>
                <Divider dashed style={{ marginTop: '1%', borderColor: 'rgba(0, 0, 0, 0.25)' }} />

                <ResultRecipe />

                <Typography.Title level={5} style={{ padding: 0, margin: 0 }}>7. Keterangan</Typography.Title>

                <Divider></Divider>
                <Space>
                    <Button type='primary'>Tambah Pemeriksaan</Button>
                    <Dropdown.Button overlay={menu}>Cetak Dokumen</Dropdown.Button>
                </Space>

            </Row>
        </Card>
    )
}
