import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, InputNumber, Modal, Row } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import {  successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';
import useCategory, { Category } from '../../../services/pharmacy/category';

import { id as clinicId } from '../../../services/baseurl';
import { FilterComponent, FilterComponent2 } from '../../layout/fiterDataTable';

const { confirm } = Modal

const DataCategory = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadCategorys();
      }
    };

    return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()

  const params = useParams<any>()
  const [Categorys, setCategorys] = useState<PaginationData<Category[]>>({data: [], pagination: {}})
  const filteredItems = Categorys.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const CategoryServices = useCategory()
  const [loader, setLoader] = useState<boolean>(false)

  //Form Variabel
  const [ form, setForm ] = useState<Category>({
    name: '', 
    percentage: 0,
  })

  const resetField = () => {
    setForm({
      name: '', 
      percentage: 1,
    } as any)
  }


 
  const columns = [
    {
      name: 'Nama',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Persentase (%)',
      selector: 'percentage',
    },
    {
      name: 'Aksi',
      button: true,
      cell: (row: any) => {
          return ( 
              <>
                  <Button id="category-edit" type="text" onClick={ () => { history.push(`/category/${row.id}`);  window.location.reload() } }><FormOutlined /></Button>
                  <Button id="category-delete" danger type="text" onClick={ () => deleteData(row.id) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getCategorys = () => {
    setLoader(true)
    CategoryServices
        .get(params.id)
        .then(form => {
            setForm({
              name: form.name,
              percentage: form.percentage
            })
            console.log(form)
        }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
}


  const loadCategorys = () => {
    setLoader(true)
    CategoryServices
        .fetch('', perPage, page)
        .then(Category => {
            setCategorys(Category)
            let total = (Category.pagination.total_page as number)
            setTotalRows(total)
           
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    CategoryServices
        .fetch(searhing, perPage, 1)
        .then(Category => {
            setCategorys(Category)
            let total = (Category.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    CategoryServices
        .fetch('', perPage, pages)
        .then(Category => {
            setCategorys(Category)
            let total =  (Category.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    CategoryServices
        .fetch('', newPerPage, pages)
        .then(Category => {
            setCategorys(Category)
            let total = (Category.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      content: `Data dengan ID : ${id}`,
      onOk() {
        setLoader(true)
        CategoryServices
            .delete(id)
            .then(() => {
                loadCategorys()
                successNotification('hapus')
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  }

  const submit = () => {
    // console.log(params.id)
    // console.log(form)
          
      !!params.id === false  && CategoryServices
      .create(form)
      .then(() => { 
          loadCategorys() 
          successNotification('tambah')
          resetField()
      })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      !!params.id === true && CategoryServices
          .update(params.id, form)
          .then(() => { 
              loadCategorys() 
              successNotification('perbaharui')
              resetField()
              window.location.href = "/category"
          })
          .catch(e => console.log(e))
          .finally(()=> {
            setLoader(false)
          })
  }


    useEffect(() => {
        loadCategorys();
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getCategorys()
    }, [])


  return (<>
    <Title title="Kategori" description="daftar data kategori" />
    <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="tambah kategori" >
                    <Form layout="vertical" id="form-input">
                        <Form.Item label="kategori" className='lable-form-input'>
                            <Input data-cy="name_category" value={form.name}  onChange={({target : {value: name}}) => setForm({...form, name})} className="form-control" />
                        </Form.Item>

                        <Form.Item label="persentase" className='lable-form-input'>
                            <InputNumber data-cy="percentage_category" type={'number'} value={form.percentage} min={0}  onChange={(percentage: number) => setForm({...form, percentage} as any)} className="form-control" style={{ width: '100%'}} />
                        </Form.Item>
                        
                    </Form>
                    <Form.Item>
                        <Button className='btn_submit' type="primary" onClick={submit}>
                            Simpan
                        </Button>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="data kategori">
                  <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      striped={true}
                      highlightOnHover={true}
                      noHeader={true}
                      className='data-table'
                  />
                </Card>
            </Col>
        </Row>
    </>
  );
};

export default DataCategory 