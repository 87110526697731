import React, {useCallback, useContext, useEffect, useState} from "react";
import Title from "../layout/title";
import Card from "../layout/card";
import styles from "./index.module.css";
import {EnterIconButton} from "../form/icon-button/EnterIconButton";
import {useHistory} from "react-router";
import {DeleteIconButton} from "../form/icon-button/DeleteIconButton";
import moment from "moment";
import Text from "antd/lib/typography/Text";
import {DataTable, limit} from "../layout/dataTable/dataTable";
import useQueue, {Queue} from "../../services/queue";
import {PaginationData} from "../../services/utility";
import {message, Popconfirm} from "antd";
import {RoomEntryConfirmationModal} from "../form/modal/RoomEntryConfirmationModal";
import {atom, useRecoilState} from "recoil";
import {triggerActiveRoom} from "../layout/mainLayout";
import {SocketContext} from "../../context/SocketContext";
import {getEnv} from "../../config";

// const CLINICID = process.env.REACT_APP_REACT_APP_CLINIC_ID
export const newIdActiveRoomAtom = atom({
  key: "new_id_active_room",
  default: "",
});

export const prevActiveRoomAtom = atom({
  key: "prev_active_atom",
  default: "",
});

export const handleQueueNumber = (code: string, num: number, size: number) => {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return code + s;
};
interface QueueFromSocket {
  created: string;
  updated: string;
  id: string;
  position: string;
  status: boolean;
  queue: {
    id: string;
    poly: {
      name: string;
      code: string;
    };
  };
}

export const DataQueueList: React.FC = () => {
  const history = useHistory();
  const queueService = useQueue();
  const socket = useContext(SocketContext);

  const [searchQuery, setSearchQuery] = useState("");
  const [sizePerPage, setSizePerPage] = useState(limit[2].value);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<any>(0);
  const [visible, setVisible] = useState<boolean>(false); //visibility for modal
  const [newIdActiveRoom, setNewIdActivateRoom] =
    useRecoilState(newIdActiveRoomAtom);

  const [queue, setQueue] = useState<PaginationData<Queue[]>>({
    data: [],
    pagination: {},
  });

  const [queueFromSocket, setQueueFromSocket] = useState([
    {
      created: "",
      updated: "",
      id: "",
      position: "",
      status: false,
      queue: {
        id: "",
        poly: {
          name: "",
          code: "",
        },
      },
    },
  ]);
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);
  const [, setPrevIdRoom] = useRecoilState(prevActiveRoomAtom);

  // ===== Start of enter action =====
  const handlePressEnter = (id: string) => {
    // check the local storage if there is a queue number
    const activateQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    if (activateQueue) {
      setNewIdActivateRoom(id);
      setPrevIdRoom(activateQueue.idRoom);
      setVisible(true);
    } else {
      queueRoomHandle(id);
    }
  };

  const queueRoomHandle = (id: string) => {
    queueService
      .enableQueueRoom(id)
      .then(() => {
        queueService
          .getQueueRoomDetail(id)
          .then((res: any) => {
            let queueNum = handleQueueNumber(
              res.queue.shift.clinic_poly.code,
              res.queue.number,
              3
            );
            let activeQueue = {
              idRoom: res.id,
              idQueue: res.queue.id,
              queueNum: queueNum,
            };
            localStorage.setItem("activeQueue", JSON.stringify(activeQueue));
            setTriggerQueueNum(!triggerQueueNum);
            history.push("/patients");
          })
          .catch((err: any) =>
            console.log(err, "ini response adalah get queue room")
          );
      })
      .catch((err) => console.log(err));
  };
  // ===== End of enter action =====

  // ===== Start of delete action =====
  const confirmDelete = (id: string) => {
    queueService
      .delete(id)
      .then((res: any) => {
        message.success(res.message);
        setQueue({
          ...queue,
          data: queue.data.filter((q: any) => q.queue.id !== id),
        });
      })
      .catch((err) => console.error(err));
  };
  // ===== End of delete action =====

  // ===== Start of Socket io section =====
  const handleReceiveQueue = useCallback((args) => {
    setQueueFromSocket((prev) => [...prev, args]);
  }, []);

  useEffect(() => {
    socket.on(`QUEUE REGISTRATION ${getEnv("REACT_APP_CLINIC_ID")}`, handleReceiveQueue);
    socket.on(
      `QUEUE DEACTIVE PENDAFTARAN ${getEnv("REACT_APP_CLINIC_ID")}`,
      handleReceiveQueue
    );
    return () => {
      socket.off(
        `QUEUE REGISTRATION ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );
      socket.off(
        `QUEUE DEACTIVE PENDAFTARAN ${getEnv("REACT_APP_CLINIC_ID")}`,
        handleReceiveQueue
      );
    };
  }, []);

  // ===== End of socket io section =====

  // ===== Start of datatable section =====
  useEffect(() => {
    queueService
      .fetch(searchQuery, "PENDAFTARAN", sizePerPage, page)
      .then((res) => {
        setQueue(res);
        setTotalPage(res.pagination.total_page);
      })
      .catch((err) =>
        console.error("Gagal Mendapatkan Data Antrian", err.response)
      );
  }, [queueFromSocket, page, sizePerPage, totalPage]);

  const column = [
    {
      name: "Tanggal",
      cell: (q: any) => (
        <div>
          <p style={{color: "#21D47E", padding: 0, margin: 0}}>
            {moment(q.created).format("DD-MM-YYYY")}
          </p>
          <p style={{color: "#0A7BCD", padding: 0, margin: 0}}>
            {moment(q.created).format("HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      name: "Layanan",
      cell: (q: any) => (
        <Text strong>{q.queue.shift.clinic_poly.poly.name}</Text>
        // <Text strong>Belum dapat ditampilkan</Text>
      ),
    },
    {
      name: "Nomor Antrian",
      cell: (q: any) => (
        <Text strong>
          {handleQueueNumber(q.queue.shift.clinic_poly.code, q.queue.number, 3)}
        </Text>
        // <Text strong>Belum dapat ditampilkan</Text>
      ),
    },
    {
      name: "Status",
      cell: (q: any) => <Text strong>{q.queue.status}</Text>,
      // cell: (q: any) => <Text strong>Belum dapat ditampilkan</Text>,
    },
    {
      name: "Prioritas",
      cell: (q: any) => (
        <p
          style={{
            backgroundColor: `${q.queue.priority ? "#f0ad4e" : "#21D47E"}`,
            color: "#FFFF",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            width: "20px",
            height: "20px",
            marginTop: "10px",
          }}
        >
          {q.queue.priority ? "2" : "1"}
        </p>
      ),
    },
    {
      name: "",
      cell: (q: any) => (
        <div
          className={styles.td_body_style}
          style={{display: "flex", justifyContent: "center"}}
        >
          <EnterIconButton
            onClick={() => handlePressEnter(q.id)}
            style={{cursor: "pointer", width: "40px"}}
          />
          <Popconfirm
            placement="topLeft"
            title={"Apakah anda yakin ingin menghapus data ini?"}
            onConfirm={() => confirmDelete(q.queue.id)}
            okText="Ya"
            cancelText="Tidak"
          >
            <DeleteIconButton
              style={{
                cursor: "pointer",
                fontWeight: "bold",
                marginLeft: "5%",
              }}
            />
          </Popconfirm>
        </div>
      ),
      width: "50px",
    },
  ];
  // ===== End of datatable section =====

  return (
    <>
      <Title
        title="Daftar Antrian Pasien"
        description="list antrian daftar tunggu pasien"
      />
      <Card title="Data Pasien" search={true}>
        <DataTable
          datas={queue.data}
          columns={column}
          setPage={setPage}
          sizePerPage={sizePerPage}
          setSizePerPage={setSizePerPage}
          limit={limit}
          totalPage={totalPage}
        />
      </Card>
      <RoomEntryConfirmationModal
        visible={visible}
        setVisible={setVisible}
        onConfirm={() => queueRoomHandle(newIdActiveRoom)}
      />
    </>
  );
};
