import axios from "axios";
import { baseURL, id } from "./baseurl";
import { PaginationData } from "./utility";

export interface Queue {
  id?: string;
  date?: string;
  nomor?: string;
  name?: string; //parmasi
  clinic_poly: string;
  poly?: { id: string };
  status?: number | string;
  address?: string; //parmasi
  handphone?: string; //parmasi
  priority?: boolean;
  clinic?: {
    id: string;
  };
  queue?: {
    number: number;
    poly: {
      name: string;
      code: string;
    }; //parmasi
  };
  bookdated?: string;
}

export interface QueueActive {
  id: string
  queue_room: {
    id: string
    queue: {
      id: string
      number: number
      shift: {
        clinic_poly: {
          code: string
        }
      }
    }
  }
}

interface IToAnamnesa {
  anamnesis: {
    patient: string | undefined;
    insurance_type: null;
    insurance_no: string;
    prb: null;
    partnership: null;
    partnership_info: null;
  };
}

const QueueClient = baseURL;
const clinicId = id;

const useQueue = () => ({
  async create(Queue: Queue): Promise<Queue> {
    const { data } = await QueueClient.post(`/queue`, Queue);
    return data;
  },
  async update(id: string, Queue: Queue): Promise<Queue> {
    const { data } = await QueueClient.patch(
      `/clinics/${clinicId}/Queues/${id}`,
      Queue
    );
    return Queue;
  },
  async delete(id: string): Promise<void> {
    const { data } = await QueueClient.delete(`/queue/${id}`);
    return data;
  },
  async fetch(
    search: string,
    position: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<Queue[]>> {
    let searching = search ? `&name=${search}` : "";
    let sizes = size ? `&size=${size}` : "";
    let rows = row ? `&page=${row}` : "";
    let positions = position ? `position=${position}` : "";
    const { data } = await QueueClient.get(
      `queue/detail/room?${positions}${rows}${searching}${sizes}`
    );
    return data;
  },
  async enableQueueRoom(id: string): Promise<Queue> {
    const { data } = await QueueClient.post(`/queue/detail/room/${id}/active`);
    return data;
  },
  async getQueueRoomDetail(id: string): Promise<Queue> {
    const { data } = await QueueClient.get(`/queue/detail/room/${id}`);
    return data;
  },
  async getQueueRoomActive(): Promise<QueueActive> {
    const { data } = await QueueClient.get(`/queue/check/room/active`);
    return data;
  },
  async disableQueueRoom(id: string): Promise<Queue> {
    const { data } = await QueueClient.delete(
      `/queue/detail/room/${id}/active`
    );
    return data;
  },
  async toAnamnesis(idRoom: string, datas: IToAnamnesa): Promise<Queue> {
    const { data } = await QueueClient.patch(
      `queue/detail/room/${idRoom}`,
      datas
    );
    return data;
  },
  async createPatientToAnamnesis(
    activeQueueIdRoom: string,
    datas: any
  ): Promise<Queue> {
    const { data } = await QueueClient.patch(
      `queue/detail/room/${activeQueueIdRoom}/patient`,
      datas
    );
    return data;
  },
});

export default useQueue;
