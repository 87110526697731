import React, { FC, useEffect, useState } from 'react'
import { Button, Col,  Modal,  Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from '../../layout/title';
import { PaginationData } from '../../../services/utility';
import useTreatment, { Treatment } from '../../../services/configurations/treatment';
import { Notification } from '../../layout/notification'
import { GlobalCurrency, GlobalStatus } from '../../../context/context';

import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';

const { confirm } = Modal

const DataTreatment: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
            loadTreatment();
        }
        };

        return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    const [treatment, setTreatment] = useState<PaginationData<Treatment[]>>({data: [], pagination: {}})
    const filteredItems = treatment.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
    const [loader, setLoader] = useState<boolean>(false)
    const treatmentService = useTreatment()
    const history = useHistory()
    const params = useParams() as any

    const loadTreatment = () => {
        setLoader(true)
        treatmentService
            .fetch('', perPage, page)
            .then(treatment => {
                setTreatment(treatment)
                let total = (treatment.pagination.total_page as number)
                setTotalRows(total)
              }
              )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

   
    const filterData = (searching: string) => {
        
        setFilterText(searching)
        treatmentService
            .fetch(searching, perPage, 1)
            .then(treatment => {
                setTreatment(treatment)
                let total = (treatment.pagination.total_page as number)
                setTotalRows(total)
              }
              )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
      }
      
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        treatmentService
            .fetch('', perPage, pages)
            .then(treatment => {
                setTreatment(treatment)
                let total =  (treatment.pagination.total_page as number)
                setTotalRows(total)
              }
              )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        treatmentService
            .fetch('', newPerPage, pages)
            .then(treatment => {
                setTreatment(treatment)
                let total = (treatment.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const deleteTreatment = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: `Data dengan ID : ${id}`,
            onOk() {
                setLoader(true)
                treatmentService
                    .delete(id)
                    .then(() => { 
                        loadTreatment()
                        Notification('Transaksi Hapus Berhasil')
                    })
                    .catch(e => console.log(e))
                    .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    

    useEffect(loadTreatment, [])

    const columns = [
        {
            name: 'Nama',
            cell: (p: Treatment) => p.name,
            sortable: true,
        },
        {
            name: 'Harga',
            cell: (p: Treatment) => GlobalCurrency(p.price as any) 
        },
        {
            name: 'Poli',
            cell: (p: Treatment) => p.clinic_poly.poly.name
        },

        {
            name: 'BPJS',
            cell: (p: Treatment) => GlobalStatus(p.bpjs)
        },

        {
            name: 'Status',
            cell: (p: Treatment) => GlobalStatus(p.status)
        },


        {
            name: 'Keterangan',
            cell: (p: Treatment) => p.info
        },
        {
            name: 'Aksi',
            cell: (c: Treatment) => {
                return ( 
                    <>
                        <Button type="text" title="Update" onClick={ () => history.push(`/treatment/${c.id}`) }><FormOutlined /></Button>
                        <Button danger type="text" title="Detele" onClick={ () => deleteTreatment(c.id as string) }><DeleteOutlined /></Button>                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Tindakan" description="data semua tindakan yang terekap dalam sistem klinik" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                
                <Card title="Data Tindakan" addButton={true}>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    striped={true}
                    highlightOnHover={true}
                    noHeader={true}
                    className='data-table'
                />
                </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataTreatment