export enum IdentityInsurance {
    GENERAL = "UMUM",
    BPJS = "BPJS",
    INSURANCE = "ASURANSI"
}

export enum IdentityPRBType {
    DIABETES = "DIABETES",
    HYPERTENTION = "HIPERTENSI",
    BOTH = "DIABETES & HIPERTENSI",
    NON = ""
}

export enum IdentityParnersType {
    EMPLOYEE = "KARYAWAN",
    FAMILY = "KELUARGA KARYAWAN",
    NON = ""
}

export enum IdentityDecition {
    TRUE = "YA",
    FALSE = "TIDAK"
}

export interface Identity {
    insurance: IdentityInsurance,
    nomor: string
    prb: IdentityDecition,
    prb_type: IdentityPRBType,
    partners: IdentityDecition,
    partners_type: IdentityParnersType,
    company: string
}