import moment, { Moment } from "moment";
import { baseURL } from "../baseurl";
import { City } from "../global/cities";
import { District } from "../global/district";
import { Province } from "../global/province";
import { PaginationData } from "../utility";
import { Village } from "../global/village";

const PatientClient = baseURL;

export enum PatientGender {
  MALE = "Laki - Laki",
  FEMALE = "Perempuan",
}

export enum PatientEducation {
  NON = "TIDAK SEKOLAH",
  SD = "SD",
  SMP = "SMP",
  SMA = "SMA",
  D1 = "D1",
  D2 = "D2",
  D3 = "D3",
  D4 = "D4",
  S1 = "S1",
  S2 = "S2",
  S3 = "S3",
}

export enum PatientRelationship {
  PARENT = "ORANG TUA",
  SPOUSE = "SUAMI / ISTRI",
  CHILD = "ANAK",
  LITTLE_BROTHER = "ADIK",
  BROTHER = "KAKAK",
  SIBLING = "SAUDARA",
  OTHER = "LAIN - LAIN",
}

export enum PatientSource {
  SOSMED = "FACEBOOK & INSTAGRAM",
  COUNSELING = "PENYULUHAN",
  REFERENCE = "REFERENSI",
  PHONE = "SMS & TELEPON",
  KNOWED = "SUDAH TAHU SENDIRI",
  MESSENGER = "WHATSAPP & LINE",
}

export enum PatientIdentityType {
  ID_CARD = "KTP",
  DRIVING_LICENSE = "SIM",
  PASSPORT = "PASSPORT",
  STUDENT_CARD = "KARTU PELAJAR",
}

export enum Profession {
  CIVIL_SERVANT = "PNS",
  PRIVATE_EMPLOYEE = "SWASTA",
  FARMER = "TANI",
  TRADER = "PEDAGANG",
}

export enum Marital_Status {
  MARRIED = "MENIKAH",
  UNMARRIED = "BELUM MENIKAH",
}

export interface Patient {
  inc_id?: number;
  id?: string;
  no_registration?: string;
  // regisration_number?: string;
  type_identity: string | number;
  no_identity: string;
  fullname: string;
  place_of_birth: string;
  date_of_birth: string | Moment;
  gender: string | number;
  blood_type: string | number | null;
  last_education: PatientEducation | string | number;
  job: string | number;
  marital_status?: string | number;
  phone_number: string;
  email: string;
  address: string;
  // province?: string;
  // city: string;
  // districts?: string;
  village: number | string;
  // insurance: string;
  bpjs?: string;
  // prb?: string;
  // prb_desc?: string;
  // company: string;
  // company_desc: string;
  person_in_charge: string;
  relationship: PatientRelationship;
  person_in_charge_phone_number: string;
  source: PatientSource;
  created?: Date;
  updated?: Date;
  deleted?: Date;
}

const usePatient = () => ({
  async create(patient: Patient): Promise<Patient> {
    const { data } = await PatientClient.post(`/register/patients`, patient);
    return data;
  },
  async update(id: string, patient: Patient): Promise<Patient> {
    const { data } = await PatientClient.patch(
      `/register/patients/${id}`,
      patient
    );
    return data;
  },
  async delete(id: string): Promise<void> {
    const { data } = await PatientClient.delete(`/register/patients/${id}`);
    return data;
  },
  async get(id: string) {
    const { data } = await PatientClient.get(`/register/patients/${id}`);
    return data;
  },

  async check(
    typeIdentity: string | number,
    noIdentity: string,
    dateOfBirth: string,
    email: string
  ) {
    // console.log(typeIdentity, noIdentity, dateOfBirth, email);
    let type_identity = typeIdentity ? `type_identity=${typeIdentity}` : "";
    let no_identitity = noIdentity ? `no_identity=${noIdentity}` : "";
    let date_of_birth = dateOfBirth ? `date_of_birth=${dateOfBirth}` : "";
    let e_mail = email ? `email=${email}` : "";

    const { data } = await PatientClient.get(
      `/register/patients/check?${type_identity}&${no_identitity}&${date_of_birth}&${e_mail}`
    );
    return data;
  },

  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<Patient[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";
    const { data } = await PatientClient.get(
      `/register/patients?${searching}&${sizes}&${rows}`
    );
    return data;
  },

  async updateAnamnesis(id: string, anamnesisData: any): Promise<any> {
    const { data } = await PatientClient.patch(
      `/checkup/anamnesis/${id}`,
      anamnesisData
    );
    return data;
  },
});

export default usePatient;
