import { FC } from 'react'
import { Route, RouteComponentProps, RouteProps } from 'react-router'

export interface IPrivateRouteWithLayoutProps extends RouteProps {
    layout?: FC
    component: FC<RouteComponentProps>
    path?: string | string[]

}

export const RouteWithLayout: React.FC<IPrivateRouteWithLayoutProps> = (props) => {
    const { layout: Layout, component: Component, ...rest } = props;
    return <Route {...rest} render={matchProps => (Layout && Component) ? (
        <Layout>
            <Component {...matchProps}/>
        </Layout>
    ): <Component {...matchProps}/>} />
}
