import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavSellingCashier: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['2']} className="menu">
                
                <Menu.Item key="1" disabled={location.pathname.includes('/list-selling')}>
                    <RouterLink to="/list-selling" key="1">Daftar Penjualan</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/selling')}>
                    <RouterLink to="/selling" key="2" >Penjualan</RouterLink>
                </Menu.Item>
                
            </Menu>
        </>
    )
}