import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import {Notification, successNotification} from "../..//layout/notification";
import { PaginationData } from '../../../services/utility';
import usePoly, { ClinicPoly } from '../../../services/configurations/poly';

import DataTable from 'react-data-table-component'
import { FilterComponent } from '../../layout/fiterDataTable';

const { confirm } = Modal

const DataPoly: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadPoly();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [poly, setPoly] = useState<PaginationData<ClinicPoly[]>>({data: [], pagination: {}})
    const filteredItems = poly.data.filter(item => item.poly.name && item.poly.name.toLowerCase().includes(filterText.toLowerCase()));


    const [loader, setLoader] = useState<boolean>(false)
    const PolyService = usePoly()
    const history = useHistory()
    const params = useParams() as any

 
    const loadPoly = () => {
        setLoader(true)
        PolyService
        .fetch('', perPage, page)
        .then(poly => {
            setPoly(poly)
            let total = (poly.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }
    
    const filterData = (searching: string) => {
        setLoader(false)
        setFilterText(searching)
        PolyService
            .fetch(searching, perPage, 1)
            .then(poly => {
                setPoly(poly)
                let total = (poly.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
    
    const handlePageChange = (pages:number) => {
        setPage(pages)
        setLoader(true)
        PolyService
            .fetch('', perPage, pages)
            .then(poly => {
                setPoly(poly)
                let total =  (poly.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    };
    
    const handlePerRowsChange = (newPerPage: number, pages: number) => {
        setPerPage(newPerPage)
        setPage(pages)
        setLoader(true)
        PolyService
            .fetch('', newPerPage, pages)
            .then(poly => {
                setPoly(poly)
                let total = (poly.pagination.total_page as number)
                setTotalRows(total)
                }
                )
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    const deletePoly = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: `Data dengan ID : ${id}`,
            onOk() {
                setLoader(true)
                PolyService
                    .delete(id)
                    .then(() => {
                        loadPoly()
                        successNotification('hapus')
                    })
                    .catch(e => console.log(e))
                    .finally(()=> setLoader(false))
                    .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadPoly, [])

    const columns = [
        {
            name: 'Nama',
            cell: (p: ClinicPoly) => p.poly.name
        },
        {
            name: 'Kode Bpjs',
            cell: (p: ClinicPoly) => p.poly.code_bpjs
        },
        {
            name: 'Status',
            cell: (p: ClinicPoly) => p.status ? 'Aktif' : 'Tidak Aktif'
        },

        {
            name: 'Kode',
            cell: (p: ClinicPoly) => p.code
        },

        {
            name: 'Nomor',
            cell: (p: ClinicPoly) => p.code
        },
        {
            name: 'Aksi',
            cell: (c: ClinicPoly) => {
                return ( 
                    <>
                        <Button type="text" onClick={ () => history.push(`/poly/${c.id}`) }><FormOutlined /></Button>
                        <Button danger type="text" onClick={ () => deletePoly(c.id as string) }><DeleteOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Poli / Layanan" description="data poli / layanan di klinik" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                
                <Card title="data poli layanan" addButton={true}>
                <DataTable
                     columns={columns}
                     data={filteredItems}
                     pagination
                     paginationServer
                     paginationTotalRows={totalRows}
                     onChangeRowsPerPage={handlePerRowsChange}
                     onChangePage={handlePageChange}
                     paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                     subHeader
                     subHeaderComponent={subHeaderComponentMemo}
                     striped={true}
                     highlightOnHover={true}
                     noHeader={true}
                     className='data-table'
                />
                </Card>
            </Col>
        </Row>
    </div>
}

export default DataPoly