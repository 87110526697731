
import { FC } from 'react'
import Selling from '../../components/cashier/selling/_selling'

const SellingCashierPage: FC = () => {
    return <div>
        <Selling/>
    </div>
}

export default SellingCashierPage