import React, { useEffect, useState } from "react";
import Title from "../../layout/title";
import Card from "../../layout/card";
import { Col, Row, Form, Descriptions, Button, Table, message } from "antd";
import Select, { SelectItem } from "../../form/Select";
import Textarea from "../../form/TextArea";
import moment from "moment";
import Input from "../../form/Input";
import {
  InformationAnamnesis,
  TypeInformationAnamnesis,
} from "../../../services/Registration/InformationAnamnesis";
import { useForm } from "antd/lib/form/Form";
import { DeleteOutlined } from "@ant-design/icons";
import InputNumber from "../../form/InputNumber";
import { Collapse } from "antd";
import usePatient, { Patient } from "../../../services/Registration/Patients";
import { Collapsible } from "../../form/Collapsible";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { InputWithUnit } from "../../form/InputWithUnit";
import "moment/locale/id";
import useQueue from "../../../services/queue";
import { handleQueueNumber } from "../../queue";
import { PaginationData } from "../../../services/utility";
import usePoly from "../../../services/configuration/poly";
import _ from "lodash";
import { DeleteIconButton } from "../../form/icon-button/DeleteIconButton";
import { useRecoilState } from "recoil";
import { triggerActiveRoom } from "../../layout/mainLayout";

const { Panel } = Collapse;

const DOCTOR: SelectItem[] = [
  {
    display: "DR. ADIT",
    value: "dr Aditiya",
  },
  {
    display: "DR. FAHRI",
    value: "dr Fahri",
  },
];
const POLI: SelectItem[] = [
  {
    display: "Kecantikan",
    value: "Kecantikan",
  },
  {
    display: "Kegantengan",
    value: "Kegantengan",
  },
];

const TYPE_DISEASE: SelectItem[] = [
  {
    display: "Alergi Obat",
    value: "Alergi Obat",
  },
  {
    display: "Alergi Makanan",
    value: "Alergi Makanan",
  },
  {
    display: "Penyakit Lama",
    value: "Penyakit Lama",
  },
  {
    display: "Penyakit Keluarga",
    value: "Penyakit Keluarga",
  },
];

const PRIORITY_PATIENT: SelectItem[] = [
  {
    display: "TIDAK",
    value: "TIDAK",
  },
  {
    display: "YA",
    value: "YA",
  },
];

export const FormAnamnesa: React.FC = () => {
  const params = useParams() as any;
  const history = useHistory();
  const [formAnamnesis] = useForm();
  const polyService = usePoly();
  const queueService = useQueue();
  const patientService = usePatient();

  const [anamnesisDatas, setAnamnesisDatas] = useState({
    id: "",
    position: "",
    status: false,
    created: "",
    updated: "",
    queue: {
      id: "",
      priority: true,
      number: 0,
      status: "",
      bookdated: "",
      shift: {
        id: 0,
        name: "",
        startTime: "",
        endTime: "",
        clinic_poly: {
          inc_id: 0,
          id: "",
          order: 0,
          status: true,
          code: "",
          max_online_registration: 30,
          is_doctor: true,
          created: "",
          updated: "",
          poly: {
            inc_id: 1,
            id: "",
            name: "",
            code_bpjs: "",
            created: "",
            updated: "",
          },
        },
      },
      anamnesis: {
        id: "",
        patient: {
          inc_id: 0,
          id: "",
          no_registration: "",
          bpjs: "",
          type_identity: "",
          no_identity: "",
          fullname: "",
          place_of_birth: "",
          date_of_birth: "",
          gender: "",
          blood_type: null,
          last_education: null,
          marital_status: "",
          job: "",
          phone_number: "",
          email: "",
          village: {
            id: 0,
            name: "",
            district: {
              id: 0,
              name: "",
              city: {
                id: 0,
                name: "",
                province: {
                  id: 0,
                  name: "",
                  country: {
                    id: 0,
                    name: "",
                  },
                },
              },
            },
          },
          address: "",
          person_in_charge: null,
          relationship: null,
          person_in_charge_phone_number: null,
          clinic: 0,
          source: null,
          created: "",
          updated: "",
          deleted: null,
        },
        insurance_type: null,
        insurance_no: "",
        prb: null,
        partnership: null,
        partnership_info: null,
        doctor: null,
        complaint: null,
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        temperature: null,
        respiratory: null,
        heart: null,
        spo2: null,
        gdp: null,
        gds: null,
        hba1c: null,
        priority_info: null,
        created: "",
        updated: "",
      },
    },
  });

  const [age, setAge] = useState("");
  const [diseaseDesc, setDiseaseDesc] = useState("");
  const [typeDisease, setTypeDisease] = useState("");
  const [information, setInformation] = useState<InformationAnamnesis[]>([]);

  const [polies, setPolies] = useState<SelectItem[]>([]);
  const [selectedPolies, setSelectedPolies] = useState({
    id: "",
    name: anamnesisDatas.queue.shift.clinic_poly.poly.name,
  });
  const [doctor, setDoctor] = useState<SelectItem[]>([]);
  const [complaint, setComplaint] = useState("");

  const [height, setHeight] = useState(0);
  const [weight, setWeight] = useState(0);
  const [sistole, setSistole] = useState(0);
  const [diastole, setDiastole] = useState(0);
  const [spo2, setSpo2] = useState(0);
  const [temperature, setTemperature] = useState(0);
  const [respiratory, setRespiratory] = useState(0);
  const [heart, setHeart] = useState(0);

  const [gdp, setGdp] = useState(0);
  const [gds, setGds] = useState(0);
  const [hba1c, setHba1c] = useState(0);

  const [priority, setPriority] = useState(PRIORITY_PATIENT[0].value);
  const [togglePosyandu, setTogglePosyandu] = useState(false);
  const [disablePriorityInfo, setdisablePriorityInfo] = useState(false);
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);

  const deleteInformation = (_: number) => {
    setInformation((prev) => {
      let newArray = [...prev];
      newArray.splice(_, 1);
      return newArray;
    });
  };

  // const addInformation = () => {
  //   typeDisease &&
  //     diseaseDesc &&
  //     setInformation((prev) => {
  //       return [
  //         ...prev,
  //         {
  //           type: typeDisease as TypeInformationAnamnesis,
  //           disease: diseaseDesc,
  //         },
  //       ];
  //     });

  //   setDiseaseDesc("");
  // };

  const getPolies = async (value: string) => {
    const polies: SelectItem[] = [];
    const result = await polyService.fetch(value).then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await polies.push({
            display: val.poly!.name,
            value: val.id!,
          })
      )
    );

    setPolies(polies);
  };

  useEffect(() => {
    if (priority !== "YA") {
      setdisablePriorityInfo(true);
    } else {
      setdisablePriorityInfo(false);
    }
  }, [priority]);

  useEffect(() => {
    formAnamnesis.setFieldsValue({
      priority_patient: PRIORITY_PATIENT[0].display,
      kir_patient: PRIORITY_PATIENT[0].display,
    });

    // ===== ANAMNESIS DATA SECTION =====
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .getQueueRoomDetail(activeQueue.idRoom)
      .then((res: any) => {
        let diff = moment.duration(
          moment().diff(moment(res.queue.anamnesis.patient.date_of_birth))
        );
        setAge(
          `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
        );
        setAnamnesisDatas(res);
      })
      .catch((err) => console.log(err));

    // ===== EDIT AND ADD PATIENT =====
    if (params.id !== "new") {
      console.log("Edit Anamnesa");
    } else {
      console.log("New Anamnesa");
    }
  }, []);

  const onFinishAnamnesis = (val: any) => {
    const anamnesisData = {
      insurance_type: null,
      insurance_no: "",
      prb: null,
      partnership: null,
      partnership_info: null,
      doctor: "becb11c0-2713-42d1-81d8-5f8fcb98e250",
      complaint: val.complaint,
      height: height,
      weight: weight,
      sistole: sistole,
      diastole: diastole,
      temperature: temperature,
      respiratory: respiratory,
      heart: heart,
      spo2: spo2,
      gdp: gdp,
      gds: gds,
      hba1c: hba1c,
      priority_info: val.priority_info,
    };

    // console.log(anamnesisData);
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    patientService
      .updateAnamnesis(anamnesisDatas.queue.anamnesis.id, anamnesisData)
      .then((res) =>
        queueService
          .disableQueueRoom(activeQueue.idRoom)
          .then((res) => {
            localStorage.removeItem("activeQueue");
            setTriggerQueueNum(!triggerQueueNum);
            message.success("Berhasil menambahkan data ke antrian dokter");
            history.push("/anamnesis");
          })
          .catch((err) => console.error(err))
      )
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .disableQueueRoom(activeQueue.idRoom)
      .then((res) => {
        localStorage.removeItem("activeQueue");
        setTriggerQueueNum(!triggerQueueNum);
        history.push("/anamnesis");
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <Title title="Anamnesa" description="form anamnesa pasien" />
      <Row>
        <Col xs={24} sm={24} md={24} xl={24}>
          <Card title="Data Pasien">
            <Descriptions
              className="cus-description"
              bordered
              column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
            >
              <Descriptions.Item label="Nama lengkap">
                {anamnesisDatas.queue.anamnesis.patient.fullname} -{" "}
                {localStorage.getItem("activeQueue") &&
                  JSON.parse(localStorage.getItem("activeQueue")!)
                    .queueNum}{" "}
                - {anamnesisDatas.queue.anamnesis.patient.no_identity}
              </Descriptions.Item>
              <Descriptions.Item label="Tanggal lahir">
                {moment(
                  anamnesisDatas.queue.anamnesis.patient.date_of_birth
                ).format("DD/MM/YYYY")}{" "}
                - {age}
              </Descriptions.Item>
              <Descriptions.Item label="Tanggal">
                <div style={{ display: "flex" }}>
                  <p
                    style={{ color: "#219BD4", padding: "0 2% 0 0", margin: 0 }}
                  >
                    {moment(anamnesisDatas.queue.anamnesis.created).format(
                      "ll"
                    )}
                  </p>
                  <p style={{ color: "#21D47E", padding: 0, margin: 0 }}>
                    {moment(anamnesisDatas.queue.anamnesis.created).format(
                      "HH:mm"
                    )}
                  </p>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label="Penanggung jawab">
                {anamnesisDatas.queue.anamnesis.patient.person_in_charge}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Kunjungan">
                {anamnesisDatas.queue.anamnesis.patient.bpjs !== ""
                  ? `BPJS - ${
                      anamnesisDatas.queue.anamnesis.patient.bpjs &&
                      anamnesisDatas.queue.anamnesis.patient.bpjs
                    }`
                  : "UMUM"}{" "}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat">
                {anamnesisDatas.queue.anamnesis.patient.address}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Form form={formAnamnesis} layout="vertical" onFinish={onFinishAnamnesis}>
        <Row gutter={15}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10}>
            <Card title="Form Pemeriksaan">
              <Row gutter={15}>
                <Col xs={24} sm={12} md={12} xl={12}>
                  <Select
                    name="poli"
                    label="Pelayanan"
                    items={polies}
                    value={selectedPolies.id}
                    onSearch={(q) => getPolies(q)}
                    onValueChange={(v) =>
                      setSelectedPolies({
                        ...selectedPolies,
                        id: v as string,
                        name: "",
                      })
                    }
                    showSearch
                    filterOption={false}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} xl={12}>
                  <Select
                    name="doctor"
                    label="Dokter"
                    items={DOCTOR}
                    showSearch
                    filterOption={false}
                  />
                </Col>
              </Row>
              <Textarea
                name="complaint"
                label="Keluhan utama"
                value={complaint}
                onValueChange={setComplaint}
              />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14}>
            <Card title="Pemeriksaan badan & Vital sign">
              <Row gutter={15}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputWithUnit
                    label="Tinggi badan"
                    name="body_height"
                    value={height}
                    onValueChange={setHeight}
                    unit="cm"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputWithUnit
                    label="Berat badan"
                    name="body_width"
                    value={weight}
                    onValueChange={setWeight}
                    unit="kg"
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                  <InputWithUnit
                    label="Sistole"
                    name="sistole"
                    value={sistole}
                    onValueChange={setSistole}
                    unit="mmHg"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={8}>
                  <InputWithUnit
                    label="Diastole"
                    name="diastole"
                    value={diastole}
                    onValueChange={setDiastole}
                    unit="mmHg"
                  />
                </Col>
                <Col md={12} xl={8}>
                  <InputWithUnit
                    label="Spo2"
                    name="spO2"
                    value={spo2}
                    onValueChange={setSpo2}
                    unit="%"
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                  <InputWithUnit
                    label="Suhu badan"
                    name="body_temperature"
                    value={temperature}
                    onValueChange={setTemperature}
                    unit="°c"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                  <InputWithUnit
                    label="Respiratory rate"
                    name="respiratory_rate"
                    value={respiratory}
                    onValueChange={setRespiratory}
                    unit="mnt"
                  />
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={6}>
                  <InputWithUnit
                    label="Heart rate"
                    name="heart_rate"
                    value={heart}
                    onValueChange={setHeart}
                    unit="bpm"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={6}
                  style={{ display: "relative" }}
                >
                  <Button
                    className="btn-primary"
                    onClick={() => setTogglePosyandu(!togglePosyandu)}
                    style={{
                      display: "absolute",
                      top: "40%",
                      fontSize: ".75em",
                      marginBottom: "35px",
                    }}
                    block
                  >
                    + Form Lab Posyandu
                  </Button>
                </Col>
              </Row>
              {/* Posyandu */}
              <Collapsible isOpen={togglePosyandu}>
                <Row gutter={15}>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <InputWithUnit
                      label="GDP"
                      name="gdp"
                      value={gdp}
                      onValueChange={setGdp}
                      unit="mg/dl"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <InputWithUnit
                      label="GDS"
                      name="gds"
                      value={gds}
                      onValueChange={setGds}
                      unit="mg/dl"
                    />
                  </Col>
                  <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                    <InputWithUnit
                      label="HbA1c"
                      name="hba1c"
                      value={hba1c}
                      onValueChange={setHba1c}
                      unit="%"
                    />
                  </Col>
                </Row>
              </Collapsible>
              {/* End of Posyandu */}
              <Row gutter={15}>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Select
                    name="priority_patient"
                    label="Pasien Prioritas?"
                    items={PRIORITY_PATIENT}
                    value={priority}
                    onValueChange={(v) => setPriority(v as string)}
                  />
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Input
                    name="priority_info"
                    label="Ket. Prioritas"
                    disabled={disablePriorityInfo}
                  />
                </Col>
                <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Select
                    name="kir_patient"
                    label="Pasien KIR?"
                    items={PRIORITY_PATIENT}
                  />
                </Col>
              </Row>
              <Row gutter={15} justify="end" style={{ marginBottom: "2%" }}>
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={8}
                  xl={6}
                  style={{ display: "relative" }}
                >
                  <Button
                    onClick={() => console.log("kamu klik aku")}
                    htmlType="submit"
                    className="btn-primary"
                    style={{
                      display: "absolute",
                      top: "40%",
                      fontSize: ".9em",
                    }}
                    block
                  >
                    {" "}
                    Next
                  </Button>
                </Col>
                <Col
                  xs={12}
                  sm={12}
                  md={7}
                  lg={8}
                  xl={6}
                  style={{ display: "relative" }}
                >
                  <Button
                    className="btn-light"
                    style={{
                      display: "absolute",
                      top: "40%",
                      fontSize: ".9em",
                      marginBottom: "10px",
                    }}
                    block
                    onClick={() => handleCancel()}
                  >
                    {" "}
                    Cancel
                  </Button>
                </Col>
                {params.id !== "new" && (
                  <Col
                    xs={12}
                    sm={12}
                    md={8}
                    lg={8}
                    xl={6}
                    style={{ display: "relative" }}
                  >
                    <Button
                      className="btn-danger"
                      style={{
                        display: "absolute",
                        top: "40%",
                        fontSize: ".75em",
                      }}
                      block
                    >
                      {" "}
                      Hapus
                    </Button>
                  </Col>
                )}

                {anamnesisDatas.queue.anamnesis.patient.bpjs === "" ? (
                  <></>
                ) : (
                  <Col
                    xs={12}
                    sm={12}
                    md={10}
                    lg={8}
                    xl={6}
                    style={{ display: "relative" }}
                  >
                    <Button
                      className="btn-secondary"
                      style={{
                        display: "absolute",
                        top: "40%",
                        fontSize: ".75em",
                      }}
                      block
                    >
                      {" "}
                      Daftar ke PCare
                    </Button>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Row>
        <Card title="Informasi Penunjang Pasien">
          <Row>
            <Col xs={24} sm={12} md={7} lg={7}>
              <Select
                name="type_disease"
                label="Jenis"
                items={TYPE_DISEASE}
                onValueChange={(v) => setTypeDisease(v as string)}
              />
            </Col>
            <Col xs={24} sm={12} md={14} lg={15} style={{ padding: "0 1%" }}>
              <Input
                name="disease_desc"
                label="Keterangan"
                onValueChange={(v) => setDiseaseDesc(v as string)}
              />
            </Col>
            <Col xs={24} sm={24} md={2} lg={2} style={{ display: "relative" }}>
              <Button
                className="btn-primary"
                // onClick={addInformation}
                style={{ display: "absolute", top: "40%", fontSize: ".9em" }}
                type="primary"
              >
                Add
              </Button>
            </Col>
          </Row>

          {/* <Table bordered dataSource={information} key="id">
            <Table.Column
              title="No"
              key="index"
              render={(value, item, index) => index + 1}
            />
            <Table.Column
              title="Jenis"
              key="index"
              render={(_: InformationAnamnesis) => _.type}
            />
            <Table.Column
              title="Keterangan"
              key="index"
              render={(_: InformationAnamnesis) => _.disease}
            />
            <Table.Column
              title="AKSI"
              key="index"
              render={(_: InformationAnamnesis, item, index) => (
                <>
                  {" "}
                  <DeleteIconButton
                    onClick={(_: React.MouseEvent<HTMLElement, MouseEvent>) =>
                      deleteInformation(index)
                    }
                  />
                </>
              )}
            />
          </Table> */}
        </Card>
      </Form>
    </div>
  );
};
