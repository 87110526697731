import {baseURL, id} from "../baseurl";
import {ClinicPoly} from "./poly";
import {PaginationData} from "../utility";

export interface Treatment {
  id?: string;
  name: string;
  price: number;
  doctor_fee: number;
  nurse_fee: number;
  bhp: number;
  cost_net: number;
  status: number; //1. Active 0. Non-active
  info: string;
  clinic_poly: ClinicPoly;
  bpjs: number; //1. Yes 0. No
}

export enum StatusTreatment {
  TRUE = 1,
  FALSE = 0,
}

const TreatmentClient = baseURL;
const clinicId = id;

const useTreatment = () => ({
  async create(Treatment: Treatment): Promise<Treatment> {
    const {data} = await TreatmentClient.post(`/config/treatments/`, Treatment);
    return data;
  },
  async update(id: string, Treatment: Treatment): Promise<Treatment> {
    await TreatmentClient.patch(`/config/treatments/${id}`, Treatment);
    return Treatment;
  },
  async delete(id: string): Promise<void> {
    const {data} = await TreatmentClient.delete(`/config/treatments/${id}`);
    return data;
  },
  async get(id: string): Promise<Treatment> {
    const {data} = await TreatmentClient.get(`/config/treatments/${id}`);
    return data;
  },
  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<Treatment[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";

    const {data} = await TreatmentClient.get(
      `/config/treatments/?${searching}&${sizes}&${rows}`
    );
    return data;
  },
});

export default useTreatment;
