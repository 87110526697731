import {FC, useEffect, useState} from "react";
import {Layout} from "antd";

import "antd/dist/antd.css";
import "./main.css";
import HeaderLayout from "../header/header";
import TopHeader from "../header/top.header";
import {Header} from "antd/lib/layout/layout";
import {Drawer} from "antd";
import {HomeMenuIcon} from "../header/icons/HomeMenuIcon";
import {QueueMenuIcon} from "../header/icons/QueueMenuIcon";
import {PatientMenuIcon} from "../header/icons/PatientMenuIcon";
import {BPJSMenuIcon} from "../header/icons/BPJSMenuIcon";
import {MobileSidebar} from "./mobileSidebar";
import {MessageMenuIcon} from "../header/icons/MessageMenuIcon";
import {ReportMenuIcon} from "../header/icons/ReportMenuIcon";
import {atom, useRecoilValue} from "recoil";
import {QueueNumberModal} from "../form/modal/QueueNumberModal";
import {ConfigMenuIcon} from "../header/icons/ConfigMenuIcon";
import {InventorySvg} from "../icons/inventory";
const {Content, Footer, Sider} = Layout;

export const SidebarAdmin = [
  {
    id: "1",
    icon: <HomeMenuIcon />,
    title: "Dashboard",
    path: "/",
    exact: true
  },
  {
    id: "2",
    icon: <QueueMenuIcon />,
    title: "Antrian",
    path: "/queue-list",
  },
  {
    id: "3",
    icon: <PatientMenuIcon />,
    title: "Pasien",
    path: "/patients",
  },
  {
    id: "4",
    icon: <BPJSMenuIcon />,
    title: "BPJS",
    path: "/register-bpjs",
  },
  {
    id: "5",
    icon: <MessageMenuIcon />,
    title: "Surat",
    path: "/statement-letter",
  },
  // {
  //   id: "6",
  //   icon: <MobileMenuIcon />,
  //   title: "Mobile",
  //   path: "/mobile-polies",
  // },
  {
    id: "7",
    icon: <ConfigMenuIcon />,
    title: "Extras",
    path: "/treatment",
  },
  {
    id: "8",
    icon: <ReportMenuIcon />,
    title: "Laporan",
    path: "/report",
  },
];

export const SidebarDoctor = [
  {
    id: "1",
    icon: <HomeMenuIcon />,
    title: "Dashboard",
    path: "/",
    exact: true,
  },
  {
    id: "2",
    icon: <QueueMenuIcon />,
    title: "Kunjungan",
    path: "/visit",
  },
  {
    id: "3",
    icon: <PatientMenuIcon />,
    title: "Pemeriksaan",
    path: "/checkup",
  },
  {
    id: "4",
    icon: <ReportMenuIcon />,
    title: "Laporan",
    path: "/report",
  },
];

export const triggerActiveRoom = atom({
  key: "triggerActiveRoom",
  default: false,
});

export const SidebarManagement = [
  {
    id: "1",
    icon: <HomeMenuIcon />,
    title: "Dashboard",
    path: "/",
    exact: true,
  },
  {
    id: "2",
    icon: <PatientMenuIcon />,
    title: "Karyawan",
    path: "/employee",
  },
  {
    id: "3",
    icon: <ConfigMenuIcon />,
    title: "Klinik",
    path: "/icd",
  },
  {
    id: "4",
    icon: <ConfigMenuIcon />,
    title: "Klinik",
    path: "/treatment",
  },
  {
    id: "5",
    icon: <ReportMenuIcon />,
    title: "Laporan",
    path: "/report",
  },
];

export const SidebarApotek = [
  {
    id: "1",
    icon: <HomeMenuIcon />,
    title: "Dashboard",
    path: "/",
    exact: true,
  },

  {
    id: "2",
    icon: <QueueMenuIcon />,
    title: "Antrian",
    path: "/queue",
  },
  {
    id: "3",
    icon: <PatientMenuIcon />,
    title: "Kunjungan",
    path: "/visit",
  },
  {
    id: "4",
    icon: <ConfigMenuIcon />,
    title: "Master",
    path: "/medicine",
  },
  {
    id: "6",
    icon: <InventorySvg />,
    title: "Persediaan",
    path: "/inventory-stock",
  },
  {
    id: "5",
    icon: <ReportMenuIcon />,
    title: "Laporan",
    path: "/report",
  },
];

export const SidebarKasir = [
  {
    id: "1",
    icon: <HomeMenuIcon />,
    title: "Dashboard",
    path: "/",
    exact: true,
  },

  {
    id: "2",
    icon: <QueueMenuIcon />,
    title: "Antrian",
    path: "/queue",
  },
  {
    id: "3",
    icon: <PatientMenuIcon />,
    title: "Kunjungan",
    path: "/visit",
  },
  {
    id: "4",
    icon: <InventorySvg />,
    title: "Penjualan",
    path: "/selling",
  },
  {
    id: "5",
    icon: <ReportMenuIcon />,
    title: "Laporan",
    path: "/report",
  },
];


const MainLayout: FC = ({children}) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleQueueRoom, setVisibleQueueRoom] = useState<boolean>(false);
  const triggerQueueNum = useRecoilValue(triggerActiveRoom);
  const [activeRoom, setActiveRoom] = useState({
    idRoom: "",
    queueNum: "",
  });

  useEffect(() => {
    const activateQueue =
      localStorage.getItem("activeQueue") &&
      JSON.parse(localStorage.getItem("activeQueue")!);

    if (activateQueue) {
      setActiveRoom({
        idRoom: activateQueue.idRoom,
        queueNum: activateQueue.queueNum,
      });
    } else {
      setActiveRoom({idRoom: "", queueNum: ""});
    }
  }, [triggerQueueNum]);

  const roles = localStorage.getItem("role_name_1");
  let dataSidebar;

  if (roles === "dokter") {
    dataSidebar = SidebarDoctor;
  } else if (roles === "perawat" || roles === "admin") {
    dataSidebar = SidebarAdmin;
  } else if (roles === "manajemen") {
    dataSidebar = SidebarManagement;
  } else if (roles === "apotek" || roles === "gudang") {
    dataSidebar = SidebarApotek;
  } else {
    dataSidebar = SidebarKasir;
  }

  return (
    <Layout style={{minHeight: "100vh"}}>
      <Sider
        theme="light"
        trigger={null}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        style={{overflow: "hidden", paddingLeft: "15px"}}
      >
        <HeaderLayout dataSidebar={dataSidebar} />
      </Sider>
      {/* {roles} */}
      <Drawer
        placement="left"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
        key="left"
      >
        {dataSidebar.map((data: any) => (
          <MobileSidebar
            icon={data.icon}
            title={data.title}
            id={data.id}
            path={data.path}
          />
        ))}
      </Drawer>
      <Layout className="site-layout">
        <Header style={{backgroundColor: "#fff", padding: 0}}>
          <div className="menu-top">
            <TopHeader
              collapsed={collapsed}
              setCollapsed={setCollapsed}
              showDrawer={setVisible}
            />
          </div>
        </Header>
        <Content style={{margin: "0 16px", textAlign: "left"}}>
          <div style={{padding: 24, minHeight: 360}}>{children}</div>
        </Content>
        {activeRoom.idRoom !== "" && activeRoom.queueNum !== "" ? (
          <strong
            onClick={() => setVisibleQueueRoom(true)}
            style={{
              position: "absolute",
              top: "12.5%",
              right: "2.5%",
              cursor: "pointer",
              padding: "5px 10px",
              background: "#ffcc00",
              borderRadius: "10px",
              boxShadow: "0px 2px 10px rgba(0,0,0,.2)",
              zIndex: 10,
            }}
          >
            No. Antrian: {activeRoom.queueNum}
          </strong>
        ) : (
          <></>
        )}
        <Footer style={{textAlign: "center"}}>&copy; Medisy 2021</Footer>
      </Layout>
      <QueueNumberModal
        visible={visibleQueueRoom}
        setVisible={setVisibleQueueRoom}
      />
    </Layout>
  );
};

export default MainLayout;
