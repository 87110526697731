
import { baseURL } from "../baseurl";

export interface BridgingClinicInterface {
    id?: string
    username: string
    password: string
    provider_code: string
}

const ClinicBpjsClient = baseURL


const useClinicBpjs = () => ({
    async create(ClinicBpjs: BridgingClinicInterface): Promise<BridgingClinicInterface>{
        await ClinicBpjsClient.post(`/clinics/bpjs`, ClinicBpjs)
        return ClinicBpjs
    },
    async update(id: string, ClinicBpjs: BridgingClinicInterface): Promise<BridgingClinicInterface>{
        const {data} = await ClinicBpjsClient.patch(`/clinics/bpjs/${id}`, ClinicBpjs)
        return data
    },
    async get(clinicId:string): Promise<BridgingClinicInterface>{
        const {data} = await ClinicBpjsClient.get(`/clinics/bpjs`)
        return data
    },

})

export default useClinicBpjs