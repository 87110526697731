import { Button, Modal, Form, Col, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import { IModal } from "./PatientMedicalRecord";
import Select, { SelectItem } from "../../form/Select";
import { useForm } from "antd/lib/form/Form";
import { Collapsible } from "../Collapsible";
import { Patient } from "../../../services/Registration/Patients";
import useQueue from "../../../services/queue";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { triggerActiveRoom } from "../../layout/mainLayout";

interface IPreAnamnesisModal extends IModal {
  // insuranceType: any;
  visitType: SelectItem[];
  selectedPatient: Patient | undefined;
}

const TREATMENT: SelectItem[] = [
  {
    display: "Rawat Jalan",
    value: "r4w4tj4l4n",
  },
  {
    display: "Rawat Inap",
    value: "r4w4t1n4p",
  },
];

const INSURANCE_TYPE: SelectItem[] = [
  {
    display: "AKSA",
    value: "4KS4",
  },
  {
    display: "JIWASRAYA",
    value: "J1W45R4Y4",
  },
];

export const PreAnamnesisModal: React.FC<IPreAnamnesisModal> = ({
  visible,
  setVisible,
  visitType,
  selectedPatient,
  // insuranceType,
}) => {
  const [form] = useForm();
  const queueService = useQueue();
  const history = useHistory();

  const [showTreatment, setTreatment] = useState(false);
  const [showInsuranceType, setShowInsuranceType] = useState(false);

  const [selectedVisitType, setSelectedVisitInsuranceType] = useState("BPJS");
  const showModal = () => {
    setVisible(true);
  };
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);

  const handleSubmit = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    const datas = {
      anamnesis: {
        patient: selectedPatient?.id,
        insurance_type: null, //bpjs di DB kita
        insurance_no: "320320320",
        prb: null, //asma di db kita
        partnership: null, //faris tech di db kita
        partnership_info: null,
      },
    };
    if (activeQueue) {
      queueService
        .toAnamnesis(activeQueue.idRoom, datas)
        .then((res) => {
          message.success("Berhasil menambahkan data ke anamnesa");
          localStorage.removeItem("activeQueue");
          setTriggerQueueNum(!triggerQueueNum);
          history.push("/anamnesis");
        })
        .catch((err) => message.error(err));
      setVisible(false);
    } else {
      console.log("Data antrian tidak  ada");
      console.log(activeQueue);
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (selectedVisitType === "BPJS") {
      setTreatment(true);
      setShowInsuranceType(false);
    } else if (selectedVisitType === "ASURANSI") {
      setTreatment(false);
      setShowInsuranceType(true);
    } else {
      setTreatment(false);
      setShowInsuranceType(false);
    }
  }, [selectedVisitType]);

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="submit" className="btn-primary" onClick={handleSubmit}>
            Ya
          </Button>,
          <Button key="back" className="btn-light" onClick={handleCancel}>
            Tidak
          </Button>,
        ]}
      >
        <h3 style={{ fontWeight: "bold" }}>Masukan Pasien dengan data diri:</h3>
        <table style={{ margin: "5% 0" }} cellPadding={10}>
          <thead>
            <tr>
              <td width={"30%"}>
                <strong>No Pendaftaran</strong>
              </td>
              <td>:</td>
              <td>{selectedPatient?.no_registration}</td>
            </tr>
            <tr>
              <td width={"30%"}>
                <strong>Nama</strong>
              </td>
              <td>:</td>
              <td>{selectedPatient?.fullname}</td>
            </tr>
            <tr>
              <td width={"30%"}>
                <strong>Jenis Kelamin</strong>
              </td>
              <td>:</td>
              <td>{selectedPatient?.gender}</td>
            </tr>
            <tr>
              <td width={"30%"}>
                <strong>Alamat</strong>
              </td>
              <td>:</td>
              <td>{selectedPatient?.address}</td>
            </tr>
            <tr>
              <td colSpan={3}>
                <Form form={form} layout="vertical">
                  <Row>
                    <Col sm={24} md={24} lg={24} xl={24}>
                      <Select
                        name="insurance"
                        label="Jenis Kunjungan"
                        items={visitType}
                        onValueChange={(v) =>
                          setSelectedVisitInsuranceType(v as string)
                        }
                      />
                    </Col>
                  </Row>
                  <Collapsible isOpen={showTreatment}>
                    <Row>
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <Select
                          name="treatment"
                          label="Perawatan"
                          items={TREATMENT}
                        />
                      </Col>
                    </Row>
                  </Collapsible>
                  <Collapsible isOpen={showInsuranceType}>
                    <Row>
                      <Col sm={24} md={24} lg={24} xl={24}>
                        <Select
                          name="insurance_type"
                          label="Jenis Asuransi"
                          items={INSURANCE_TYPE}
                        />
                      </Col>
                    </Row>
                  </Collapsible>
                </Form>
              </td>
            </tr>
          </thead>
        </table>
      </Modal>
    </div>
  );
};
