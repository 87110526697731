import { baseURL } from "../baseurl";

export interface Clinic {
  id?: string;
  name: string;
}

const ClinicClient = baseURL;
const useClinic = () => ({
  async create(clinic: number): Promise<Clinic> {
    const { data } = await ClinicClient.post(`config/clinics`);
    return data;
  },
  async get(): Promise<Clinic> {
    const { data } = await ClinicClient.get(`config/clinics/detail/info`);
    return data;
  },
});

export default useClinic;
