import {AxiosRequestConfig} from "axios";
import {AuthClient} from "./Services";

export interface IAuth {
  username: string;
  nickname: string;
  password: string;
  access_token: string;
  roles: {
    name: string;
  }[];
  clinic: {
    id: string;
  };
}

interface ILogin {
  username: string;
  password: string;
}

export const useAuth = () => ({
  async create(
    authData: ILogin,
    config?: AxiosRequestConfig | undefined
  ): Promise<IAuth> {
    const {data} = await AuthClient.post(
      `auth/v2/user/login`,
      authData,
      config
    );
    return data;
  },
});

/**
 * User Role
 *
 * -    Management  :   Username => management
 *                      Password => string
 * - Oke
 */
