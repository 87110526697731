import {Button, Descriptions} from "antd";
import React from "react";
import Card from "../../layout/card";

interface IDetailAnamnesisPatient {
  anamnesisDatas: any;
  age: string;
  setPatientHistoryModal: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 *   Detail Anamnesis Patient Card :
 **  anamnesisDatas : data pemeriksaan yang sedang berjalan
 **  age : perhitungan untuk menghitung usia pasien
 **  setPatientHistoryModal: trigger untuk modal riwayat pemeriksaan pasien
 */

export const DetailAnamnesisPatient: React.FC<IDetailAnamnesisPatient> = ({
  anamnesisDatas,
  age,
  setPatientHistoryModal,
}) => {
  return (
    <Card title="Detail Pasien">
      <Descriptions
        className="cus-description"
        bordered
        column={{xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3}}
        labelStyle={{display: "flex", alignItems: "start"}}
        contentStyle={{verticalAlign: "top"}}
      >
        <Descriptions.Item label="Nama lengkap">
          {anamnesisDatas.queue.anamnesis.patient.fullname} <br /> {age}
        </Descriptions.Item>
        <Descriptions.Item
          label="Riwayat Penyakit Sekarang"
          contentStyle={{width: "35%"}}
        >
          {anamnesisDatas.queue.anamnesis.complaint_detail}
        </Descriptions.Item>
        <Descriptions.Item label="Keluhan Utama">
          {anamnesisDatas.queue.anamnesis.complaint}
        </Descriptions.Item>
        <Descriptions.Item label="Anamnesa">
          <Descriptions
            className="cus-description"
            bordered
            column={{xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 2}}
          >
            <Descriptions.Item label="Tinggi Badan">
              {" "}
              : {anamnesisDatas.queue.anamnesis.height}
            </Descriptions.Item>
            <Descriptions.Item label="Respiratory Rate">
              : {anamnesisDatas.queue.anamnesis.respiratory}
            </Descriptions.Item>
            <Descriptions.Item label="Berat Badan">
              : {anamnesisDatas.queue.anamnesis.weight}
            </Descriptions.Item>
            <Descriptions.Item label="Heart Rate">
              : {anamnesisDatas.queue.anamnesis.heart}
            </Descriptions.Item>
            <Descriptions.Item label="Sistole">
              : {anamnesisDatas.queue.anamnesis.sistole}
            </Descriptions.Item>
            <Descriptions.Item label="Suhu Badan">
              : {anamnesisDatas.queue.anamnesis.temperature}
            </Descriptions.Item>
            <Descriptions.Item label="Diastole">
              : {anamnesisDatas.queue.anamnesis.diastole}
            </Descriptions.Item>
          </Descriptions>
        </Descriptions.Item>
        <Descriptions.Item label="Golongan Darah">
          {anamnesisDatas.queue.anamnesis.patient.blood_type}
        </Descriptions.Item>
        <Descriptions.Item label="Catatan">-</Descriptions.Item>
      </Descriptions>
      <div style={{display: "flex", justifyContent: "end"}}>
        <Button
          onClick={() => setPatientHistoryModal(true)}
          className="btn-secondary"
        >
          Lihat Riwayat
        </Button>
      </div>
    </Card>
  );
};
