import DisplayTV from "../../pages/administration/display_tv";
import { Login } from "../../pages/login";
import { RouterInterface } from "../router.interface";

export const PublicRoute: RouterInterface[] = [
  {
    component: Login,
    path: '/',
    exact: true
  },
  {
    component: DisplayTV,
    path: '/display-tv',
    exact: true
  }
]