import React, {createContext, ReactNode, useReducer} from "react";
import {AuthReducer, IAction, IState} from "./AuthReducer";

const initialState = {
  username: localStorage.getItem("username")
    ? localStorage.getItem("username")
    : null,
  nickname: localStorage.getItem("nickname")
    ? localStorage.getItem("nickname")
    : null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
  roles: [
    {
      name: localStorage.getItem("role_name")
        ? localStorage.getItem("role_name")
        : null,
    },
  ],
  isAuthenticated:
    localStorage.getItem("isAuthenticated") === "true" ? true : false,
};

interface IAuthProvider {
  children: ReactNode;
}

export const AuthContext = createContext<{
  state: IState;
  dispatch: React.Dispatch<IAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const AuthProvider: React.FC<IAuthProvider> = ({children}) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      {children}
    </AuthContext.Provider>
  );
};
