import {Modal, Typography} from "antd";
import {filter} from "lodash";
import React, {useEffect, useMemo, useState} from "react";
import DataTable from "react-data-table-component";
import useTreatment, {
  Treatment,
} from "../../../services/configurations/treatment";
import {PaginationData} from "../../../services/utility";
import {FilterComponent} from "../../layout/fiterDataTable";
import {AddIconButton} from "../icon-button/AddIconButton";
import {IModal} from "./PatientMedicalRecord";

interface ITreatmentProps extends IModal {
  setSelectedTreatment: React.Dispatch<React.SetStateAction<any>>;
  // setSelectedTreatment: React.Dispatch<React.SetStateAction<Treatment>>;
}

export const TreatmentModal: React.FC<ITreatmentProps> = ({
  visible,
  setVisible,
  setSelectedTreatment,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (searchQuery) {
        setResetPaginationToggle(!resetPaginationToggle);
        setSearchQuery("");
        loadTreatment();
      }
    };
    return (
      <FilterComponent
        onFilter={(e: any) => filterData(e.target.value)}
        onClear={handleClear}
        filterText={searchQuery}
      />
    );
  }, [searchQuery, resetPaginationToggle]);
  const [treatments, setTreatments] = useState<PaginationData<Treatment[]>>({
    data: [],
    pagination: {},
  });

  const filteredData = treatments.data.filter((treatment) => {
    (treatment.name &&
      treatment.name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (treatment.info &&
        treatment.info.toLowerCase().includes(searchQuery.toLowerCase()));
  });

  const treatmentService = useTreatment();

  const loadTreatment = () => {
    setIsLoading(true);
    treatmentService
      .fetch(searchQuery, sizePerPage, currentPage)
      .then((res) => {
        setTreatments(res);
        // console.log(res);
        setTotalPage(res.pagination.total_page as number);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const filterData = (searchQuery: string) => {
    setIsLoading(false);
    setSearchQuery(searchQuery);
    treatmentService
      .fetch(searchQuery, sizePerPage, currentPage)
      .then((res) => {
        setTreatments(res);
        setTotalPage(res.pagination.total_page as number);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handlePageChange = (pages: number) => {
    setCurrentPage(pages);
    setIsLoading(true);
    treatmentService
      .fetch(searchQuery, sizePerPage, currentPage)
      .then((res) => {
        setTreatments(res);
        setTotalPage(res.pagination.total_page as number);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const handlePerRowsChange = (size: number, pages: number) => {
    setSizePerPage(size);
    setCurrentPage(pages);
    setIsLoading(true);
    treatmentService
      .fetch(searchQuery, sizePerPage, currentPage)
      .then((res) => {
        setTreatments(res);
        setTotalPage(res.pagination.total_page as number);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(loadTreatment, []);

  const columns = [
    {
      name: <Typography.Text strong>Nama</Typography.Text>,
      cell: (t: Treatment) => t.name,
      sortable: true,
    },
    {
      name: <Typography.Text strong>Keterangan</Typography.Text>,
      cell: (t: Treatment) => t.info,
      sortable: true,
    },
    {
      name: <Typography.Text strong></Typography.Text>,
      cell: (t: Treatment) => (
        <>
          <AddIconButton onClick={() => onAddIconButtonClick(t.id!)} />
        </>
      ),
    },
  ];

  const onAddIconButtonClick = (id: string) => {
    setSelectedTreatment(treatments.data.find((t) => t.id === id));
    setVisible(false);
  };

  return (
    <Modal
      title="Daftar Tindakan"
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      width={1000}
    >
      {isLoading && <div>Loading...</div>}
      <DataTable
        columns={columns}
        data={treatments.data}
        pagination
        paginationServer
        paginationTotalRows={totalPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        striped={true}
        highlightOnHover={true}
        noHeader={true}
        className="data-table"
      />
    </Modal>
  );
};
