
import React, { FC } from 'react'
import DataVisit from '../../components/pharmacy/visit'

const VisitPharmacy: FC = () => {
    return <div>
        <DataVisit/>
    </div>
}

export default VisitPharmacy