import { Col, Row, Form,  Button, Space, TimePicker, Select as SelectTheme } from 'antd'
import  React, { FC, useEffect, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import Card from '../../layout/card'
import Title from "../../layout/title";
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { successNotification } from '../../layout/notification'
import usePoly, { ClinicPoly, Shift, StatusClinicPoly } from '../../../services/configurations/poly';
import _ from 'lodash'
import Select, { SelectItem } from '../../layout/select';
import Input from '../../form/Input';
import usePolies from '../../../services/configurations/polies';
import QRCode from 'react-qr-code';

const { Option } = SelectTheme

const STATUS: SelectItem[] = [
    {
        display: "AKTIF",
        value: "true"
    },
    {
        display: "TIDAK AKTIF",
        value: "false"
    }
]

const DOKTER: SelectItem[] = [
    {
        display: "YA",
        value: "true"
    },
    {
        display: "TIDAK",
        value: "false"
    }
]


const FormPoly: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const polyService = usePoly()
    const history = useHistory()
    const params = useParams<{ id: string }>();

    const [polyName, setPolyName] = useState("")
    const [order, setOrder] = useState("") 
    const [code, setCode] = useState("") 
    const [codeBpjs, setCodeBpjs] = useState("") 
    const [maxOnline, setMaxOnline] = useState("") 
    const [polies, setPolies] = useState<SelectItem[]>([]);
    const [selectedPoly, setSelectedPoly] = useState({ id: "", name: "" });
    const [selectedStatus, setSelectedStatus] = useState("AKTIF");
    const [selectedDoctor, setSelectedDoctor] = useState("YA");

    const poliesService = usePolies()

    const getPoly = async (value: string) => {
        const polies: SelectItem[] = [];
        const result = await poliesService.fetch(value).then((res) => res.data);
    
        await Promise.all(
          _.map(
            result,
            async (val) =>
              await polies.push({
                display: val.name,
                value: val.id!,
              })
          )
        );
    
        setPolies(polies);
      };


    const stringToBoolean = (status: string ) => {
        switch(status.toLowerCase().trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(status);
        }
    }

    const onFinish = (val: any) => {
        const shifts = val.shift.map((shift: any) => {
            return {
                name: shift.shift_name,
                startTime: shift.startTime.format('hh:mm'),
                endTime: shift.endTime.format('hh:mm'),
            }
        })

        const datas: ClinicPoly = {
            poly: {
                id: selectedPoly.id,
            } as any,
            order: val.order,
            code: val.code,
            is_doctor: true,
            max_online_registration: val.max_online,
            status: true,
            shift: shifts
        } 
        console.log(val)
        // ===== POST EDIT & ADD PATIENT DATA =====
      if (params.id !== "new") {
        // POST EDIT PATIENT DATA
        polyService
          .update(params.id, datas)
          .then((res: any) => {
            successNotification('edit')
            history.push("/polies");
          })
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
      } else {
        // POST ADD PATIENT DATA
        polyService
          .create(datas)
          .then((res: any) => {
            successNotification('tambah')
            history.push("/polies");
          })
          .catch(e => console.log(e))   
          .finally(() => setLoader(false))
      }
    }

    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Poli / Layanan" description="form pengaturan poli / layanan" />
        
            <Row>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Form layout="vertical" onFinish={onFinish}>
                    <Card title="pengaturan poli / layanan" >
                        <Row justify='space-between'>
                         <Col xs={24} sm={24} md={10} xl={10}>
                            <Select
                                name="poly"
                                label="Pilih Poli / Layanan"
                                items={polies}
                                showSearch
                                onSearch={(q) => getPoly(q)}
                                filterOption={false}
                                onValueChange={(v) =>
                                    setSelectedPoly({
                                        ...selectedPoly,
                                        id: v as string,
                                        name: "",
                                      })
                                }
                            />
                            </Col>

                            <Col xs={24} sm={24} md={6} xl={6}>
                                <Select
                                    name="is_doctor"
                                    label="Perlu Dokter"
                                    items={DOKTER}
                                    value={selectedDoctor}
                                    onValueChange={(v) => setSelectedDoctor(v as string)}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                                    <Input name='order' className="form-control"
                                    value={order }
                                    onValueChange={(v) => setOrder(v)} label={'order'}/>
                            </Col>
                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                            <Select
                                name="status"
                                label="Status"
                                items={STATUS}
                                value={selectedStatus}
                                onValueChange={(v) => setSelectedStatus(v as string)}
                            />
                           
                            </Col>

                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                    <Input name='code' className="form-control"
                                    value={code}
                                    onValueChange={(v) => setCode(v)} label={'kode'}/>
                            </Col>

                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                    <Input name='code_bpjs' className="form-control"
                                    value={codeBpjs}
                                    onValueChange={(v) => setCodeBpjs(v)} label={'kode bpjs'}/>
                            </Col>

                            <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                                    <Input name='max_online' className="form-control"
                                    value={maxOnline}
                                    onValueChange={(v) => setMaxOnline(v)} label={'batas daftar online'}/>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Form.List name="shift">
                                {(fields, { add, remove }) => (
                                <>
                                    {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ marginBottom: 4 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'shift_name']}
                                            rules={[{ required: true, message: 'shift is required' }]}
                                            label="Pilih Shift" className='lable-form-input'
                                        >
                                            <SelectTheme placeholder="Pilih shift" className='form-control' style={{ width: '200px'}} >
                                                <Option value="PAGI">Pagi</Option>
                                                <Option value="SIANG">Siang</Option>
                                            </SelectTheme>
                                        </Form.Item>
                                    
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'startTime']}
                                            label="waktu mulai" className='lable-form-input'>
                                            <TimePicker  className="form-control" style={{ width: '200px'}} format={'HH:mm'} />
                                        </Form.Item>
                                    
                                        <Form.Item 
                                        {...restField}
                                        name={[name, 'endTime']}
                                        label="waktu selesai" className='lable-form-input'>
                                            <TimePicker className="form-control" style={{ width: '200px'}} format={'HH:mm'} />
                                        </Form.Item>
                                            
                                      
                                        <Form.Item label=" ">
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Form.Item>
                                    </Space>
                                    ))}
                                    <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Tambah Shift
                                    </Button>
                                    </Form.Item>
                                </>
                                )}
                            </Form.List>
                            
                            </Col>
                        </Row>
                        <Space>
                            <Button type="primary" htmlType='submit'>
                                Simpan
                            </Button>

                            <Button type="default" title="Kembali" onClick={ () => history.push(`/polies`) }>Kembali</Button> 
                        </Space>
                    </Card> 
                </Form>
                </Col>
                
            </Row>
       
        </>
    )
}

export default FormPoly