import { baseURL, id } from "../baseurl";
import { Clinic } from "./clinic";
import { PaginationData } from "../utility";

export interface Poly {
  inc_id?: number;
  id?: string;
  name: string;
  order: number;
  status: string;
  code: string;
  nomor: number;
  code_bpjs: string;
  cloudId?: string;
  clinics?: Clinic;
  poly?: {
    name: string;
    code_bpjs: string;
  };
  shift: {
    id: number;
    name: string;
    startTime: string;
    endTime: string;
  }[];
}

export enum StatusPoly {
  TRUE = "AKTIF",
  FALSE = "TIDAK AKTIF",
}

const PolyClient = baseURL;
const clinicId = id;

const usePoly = () => ({
  async create(Poly: Poly): Promise<Poly> {
    const { data } = await PolyClient.post(`/clinics/${clinicId}/polies`, Poly);
    return data;
  },
  async update(id: string, Poly: Poly): Promise<Poly> {
    await PolyClient.patch(`/clinics/${clinicId}/polies/${id}`, Poly);
    return Poly;
  },
  async delete(id: string): Promise<void> {
    const { data } = await PolyClient.delete(
      `/clinics/${clinicId}/polies/${id}`
    );
    return data;
  },
  async get(id: string): Promise<Poly> {
    const { data } = await PolyClient.get(`/clinics/${clinicId}/polies/${id}`);
    return data;
  },
  async fetch(
    search: string,
    size?: number | string,
    row?: number | string
  ): Promise<PaginationData<Poly[]>> {
    let searching = search ? `name=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";
    // const { data } = await PolyClient.get(`/clinics/poly/?${searching}&${sizes}&${rows}`, config)
    const { data } = await PolyClient.get(
      `/config/poly/clinic?${searching}&${sizes}&${rows}`
    );
    // const { data } = await PolyClient.get(`/poly/poly`)
    return data;
  },
});

export default usePoly;
