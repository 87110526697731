import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { DeleteOutlined, FormOutlined, ExportOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from '../../layout/title';
import { PaginationData } from '../../../services/utility';
import usePublicHealth, { PublicHealth } from '../../../services/configurations/public.health';
import { successNotification } from '../../layout/notification'
import DataTable from 'react-data-table-component';
import { FilterComponent } from '../../layout/fiterDataTable';
const { confirm } = Modal

const DataPublicHealth: FC = () => {
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
          loadPublicHealth();
        }
      };
  
      return <FilterComponent onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const [publicHealth, setPublicHealth] = useState<PaginationData<PublicHealth[]>>({data: [], pagination: {}})
    // multiple filter
    const filteredItems = publicHealth.data.filter(item => 
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase()) ||
        item.pic && item.pic.toLowerCase().includes(filterText.toLowerCase()) 
    );
 
    const [loader, setLoader] = useState<boolean>(false)
    const PublicHealthService = usePublicHealth()
    const history = useHistory()
    const params = useParams() as any

    const loadPublicHealth = () => {
    setLoader(true)
    PublicHealthService
    .fetch('', perPage, page)
    .then(publicHealth => {
        setPublicHealth(publicHealth)
        let total = (publicHealth.pagination.total_page as number)
        setTotalRows(total)
        }
        )
    .catch(e => console.log(e))   
    .finally(() => setLoader(false))
    }

    const filterData = (searching: string) => {
    setLoader(false)
    setFilterText(searching)
    PublicHealthService
        .fetch(searching, perPage, 1)
        .then(publicHealth => {
            setPublicHealth(publicHealth)
            let total = (publicHealth.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

    const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    PublicHealthService
        .fetch('', perPage, pages)
        .then(publicHealth => {
            setPublicHealth(publicHealth)
            let total =  (publicHealth.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    };

    const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    PublicHealthService
        .fetch('', newPerPage, pages)
        .then(publicHealth => {
            setPublicHealth(publicHealth)
            let total = (publicHealth.pagination.total_page as number)
            setTotalRows(total)
            }
            )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

    const deletePublicHealth = (id: string) => {

        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setLoader(true)
                PublicHealthService
                    .delete(id)
                    .then(() => {
                        loadPublicHealth()
                        successNotification('hapus')
                    })
                    .catch(e => console.log(e))
                    .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    useEffect(loadPublicHealth, [])

    const columns = [
        {
            name:  <Typography.Text strong>nama</Typography.Text>,
            cell: (p: PublicHealth) => p.name,
            sortable: true,
        },
        {
            name:  <Typography.Text strong>email</Typography.Text>,
            cell: (p: PublicHealth) => p.email,
            sortable: true
        },
        {
            name:  <Typography.Text strong>penanggung jawab</Typography.Text>,
            cell: (p: PublicHealth) => p.pic
        },

        {
            name:  <Typography.Text strong>telepon</Typography.Text>,
            cell: (p: PublicHealth) => p.phone
        },

        {
            name:  <Typography.Text strong>alamat</Typography.Text>,
            cell: (p: PublicHealth) => p.address
        },
        {
            name:  <Typography.Text strong>aksi</Typography.Text>,
            cell: (c: PublicHealth) => {
                return ( 
                    <>
                        <Button data-cy="btn-edit" type="text" title="Update" onClick={ () => history.push(`/public-health/${c.id}`) }><FormOutlined /></Button>
                        <Button id="btn-delete" danger type="text" onClick={ () => deletePublicHealth(c.id as string) }><DeleteOutlined /></Button>
                        <Button type="text" title="Data Desa" onClick={ () => history.push(`/public-health/${c.id}/village`) }><ExportOutlined /></Button>
                    </>
                 )
            }
        }
    ]

    return <div>
        <Title title="Puskesmas" description="data semua puskesmas di dekat klinik" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="Data Puskesmas" addButton={true}>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    striped={true}
                    highlightOnHover={true}
                    noHeader={true}
                    className='data-table'
                />
                </Card>

            </Col>
        </Row>
    </div>
}

export default DataPublicHealth