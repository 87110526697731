import { FC, useEffect, useState } from 'react'
import { Button, Col,  Row, Form, Input, Modal, Select, Space, Table} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons'
import Card from '../../layout/card'
import Title from "../../layout/title";
import useRecipe, { RecipeMedicine } from '../../../services/pharmacy/recipe';
import { useParams } from 'react-router';
import { successNotification } from '../../layout/notification';
import DataTable from 'react-data-table-component';
import ProfilePasien from '../../pharmacy/queue/_profile';
import NumberFormat from 'react-number-format';


const { confirm } = Modal
const { Option } = Select


const DetailQueue: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)
    const recipeServices = useRecipe()
    const params = useParams<any>()
    const [RecipeMedicine, setRecipeMedicine] = useState<RecipeMedicine[]>([])


      const deleteRecipe = (id: string) => {
          console.log(id)
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            content: ``,
            onOk() {
              setLoader(true)
              recipeServices
                  .delete(id)
                  .then(() => {
                      loadRecipe()
                      successNotification('hapus')
                    })
                  .catch(e => console.log(e))
                  .finally(()=> setLoader(false))
            },
            onCancel() {
              console.log('Cancel');
            },
          });
      }

      const loadRecipe = () => {
        setLoader(true)
        recipeServices
        .get(params.id)
        .then(RecipeMedicine => {
            console.log(RecipeMedicine)
            setRecipeMedicine(RecipeMedicine)
           
            }
        )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
        }



    const deleteData = (id: string) => {
        confirm({
            title: 'Serius Ingin Hapus Data ini?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
              successNotification('hapus')
            },
            onCancel() {
              console.log('Cancel');
            },
          });
    }

    const moneyChanges = () => {
      Modal.success({
        title: 'Kembalian Pembayaran',
        content:(
          <div>
            <span style={{ fontWeight: 'bold', fontSize: '30pt', textAlign: 'right'}}>Rp. 100.000</span>
          </div>
        )
      });
    }

    const columns = [
      {
          name: 'keterangan biaya',
          width: '40%',
          selector: (row : any) => row.name,
      },
      {
          name: 'jumlah',
          selector: (row : any) => row.qty,
      },
      {
        name: 'harga',
        selector: (row : any) => row.price,
      },
      {
        name: 'total',
        selector: (row : any) => row.qty*row.price,
      },
  ];
  
  const data = [
      {
          id: 1,
          name: 'Administrasi',
          qty: 1,
          price: 15000
      },
      {
          id: 2,
          name: 'Konsultasi Dokter',
          qty: 1,
          price: 25000
      },
      {
        id: 3,
        name: 'Obat - PCT',
        qty: 5,
        price: 2000
      },
      {
        id: 4,
        name: 'Obat - Bicnat',
        qty: 10,
        price: 2000
      },
      {
        id: 5,
        name: 'Obat - Yusimox',
        qty: 10,
        price: 2000
      },
      {
        id: 6,
        name: 'Tindakan - Penolong Persalinan',
        qty: 10,
        price: 2000
      },
  ]


    useEffect(loadRecipe,[])


    return <div>
        <Title title="Antrian Pasien" description="detail data antrian  pasien" />
        <Row>
           
            <Col span={24}>
                {
                    loader && <div>Loading .... </div>
                }
                    {/* Data Pasien */}
                        <ProfilePasien />
                    {/* Data Tindakan */}
                    
                    {/* Detail Biaya Pasien */}
                    <Row justify='space-between'>

                    <Col xs={24} sm={24} md={15} lg={15}>

                        <Card title="Rincian Transaksi">
                        <DataTable
                          columns={columns}
                          data={data}
                          selectableRows
                          noHeader={false}
                        />
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={8} lg={8}>      
                        <Card title="Pembayaran">
                            <Form layout='vertical'>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item label="total biaya (Rp.)" className='lable-form-input'>
                                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item label="diskon (Rp.)" className='lable-form-input'>
                                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item label="total (Rp.)" className='lable-form-input'>
                                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                                  </Form.Item>
                              </Col>
                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                  <Form.Item label="bayar (Rp.)" className='lable-form-input'>
                                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                                  </Form.Item>
                              </Col>

                              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="bayar" className='lable-form-input'>
                                  <Select defaultValue="Tunai" className='form-control' allowClear>
                                    <Option value="0">Tunai</Option>
                                    <Option value="1">EDC BNI</Option>
                                    <Option value="2">Debit BNI</Option>
                                    <Option value="3">Debit Mandiri</Option>
                                    <Option value="4">Transfer</Option>
                                  </Select>
                                </Form.Item>

                                <Form.Item label="bank" className='lable-form-input'>
                                  <Select defaultValue="BNI" className='form-control' allowClear>
                                    <Option value="0">Mandiri</Option>
                                    <Option value="1">BNI</Option>
                                  </Select>
                                </Form.Item>

                                <Form.Item label="Nomor Rekening" className='lable-form-input'>
                                  <Input type={'text'} className='form-control'></Input>
                                </Form.Item>

                                <Form.Item label="Keterangan" className='lable-form-input'>
                                  <Input type={'text'} className='form-control'></Input>
                                </Form.Item>
                              </Col>

                              <Space>
                                <Button type='link' >Batal</Button>
                                <Button type='primary' style={{ backgroundColor: '#21D47E' }} onClick={moneyChanges}>Proses Bayar</Button>
                                <Button type='default' onClick={moneyChanges}>Bayar Nanti</Button>
                              </Space>
                              
                            </Form>
                        </Card>  
                    </Col>
                    </Row>
            </Col>  
        </Row>
    </div>
}

export default DetailQueue