import React from 'react'
import { Data404 } from '../../components/404'

export const PageNotFound = () => {
    return (
        <div>
            <Data404 />
        </div>
    )
}
