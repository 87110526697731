export const handleQueueNumberHelper = (
  code: string,
  num: number,
  size: number
) => {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return code + s;
};

export function honorifics(
  fullname: string,
  maritalStatus: string,
  gender: string,
  age?: number | string
) {
  if (gender === "Laki - Laki") {
    return `Tn. ${fullname}`;
  } else if (maritalStatus === "MENIKAH" && gender === "Perempuan") {
    return `Ny. ${fullname}`;
  } else if (maritalStatus === "BELUM MENIKAH" && gender === "Perempuan") {
    return `Nn. ${fullname}`;
  } else {
    return fullname;
  }
}

export function bmiHelper(weight: number, height: number, gender: string) {
  const heightInMeter = height / 100;
  let bmi = Number((weight / Math.pow(heightInMeter, 2)).toFixed(2));

  let idealWeight = 0;
  if (gender == "Laki - Laki") {
    idealWeight = height - 100 - (height - 100) * 0.1;
  } else if (gender == "Perempuan") {
    idealWeight = height - 100 - (height - 100) * 0.15;
  }

  let description = "";
  if (bmi < 18.5) {
    description = "Kurang";
  } else if (bmi >= 18.5 && bmi < 23) {
    description = "Normal";
  } else if (bmi >= 23 && bmi < 25) {
    description = "Kelebihan";
  } else if (bmi >= 25 && bmi < 30) {
    description = "Obesitas 1";
  } else if (bmi > 30) {
    description = "Obesitas 2";
  }

  return {idealWeight, bmi, description};
}

export const gcsValHelper = (eye:number, verbal:number, motoric:number) => {
  let gcs = eye + verbal + motoric;
  let gcsDesc:string;
  if(gcs === 15 || gcs === 14){
      gcsDesc = 'Composmentis';
  } else if (gcs === 13 || gcs === 12){
      gcsDesc = 'Apatis';
  } else if (gcs === 11|| gcs === 10){
      gcsDesc = 'Delirium';
  } else if (7 <= gcs && gcs <= 9){
      gcsDesc = 'Somnolen';
  } else if (gcs === 6 || gcs ===5){
      gcsDesc = 'Sopor';
  } else if (gcs === 4){
      gcsDesc = 'Semi-coma';
  } else if (gcs === 3){
      gcsDesc = 'Coma';
  } else {
      gcsDesc = 'Nilai tidak terdefinisi'
  }
  return {gcs, gcsDesc};
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (err) {
    return null;
  }
};
