import React from 'react'
import { ISidebar } from './mobileSidebar'
import './main.css'
import { NavLink } from 'react-router-dom'

export const DataSidebar: React.FC<ISidebar> = ({ id, icon, title, path, exact }) => {
    return (
        <NavLink exact={exact} to={path} className="data_sidebar_container" activeClassName="data_sidebar_container_active">
            <div className="data_sidebar_container__icon">
                {icon}
            </div>
            <strong style={{ color: '#000' }}>{title}</strong>
        </NavLink>

    )
}
