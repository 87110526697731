import React, {useEffect, useState} from "react";
import Title from "../../layout/title";
import Card from "../../layout/card";
import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Row,
  Dropdown,
  Menu,
  Popconfirm,
} from "antd";
import {useForm} from "antd/lib/form/Form";
import Input from "../../form/Input";
import Date from "../../form/Date";
import Radio from "../../form/Radio";
import Select from "../../form/Select";
import {SelectItem} from "../../form/Select";
import usePatient, {
  Marital_Status,
  Patient,
  PatientEducation,
  PatientGender,
  PatientIdentityType,
  PatientRelationship,
  PatientSource,
} from "../../../services/Registration/Patients";
import useProvince from "../../../services/global/province";
import useCity from "../../../services/global/cities";
import useDistrict from "../../../services/global/district";
import useVillage from "../../../services/global/village";
import {useHistory, useParams} from "react-router";
import useInsurance, {
  Insurance,
} from "../../../services/configuration/Insurance";
import usePRBType from "../../../services/global/prbType";
import useCompany from "../../../services/configuration/company";
import useJob from "../../../services/global/job";
import _ from "lodash";
import Textarea from "../../form/TextArea";
import {IdentityParnersType} from "../../../services/global/Identity";
import {Collapsible} from "../../form/Collapsible";
import moment from "moment";
import useQueue from "../../../services/queue";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {atom, useRecoilState} from "recoil";
import {triggerActiveRoom} from "../../layout/mainLayout";
import {CreatePatientToAnamnesisModal} from "../../form/modal/CreatePatientToAnamnesisModal";
import {PaginationData} from "../../../services/utility";
import Password from "antd/lib/input/Password";

export interface onPatientChangeFunction {
  (patient: Patient): void;
}

export interface patientProps {
  onPatientChanged: onPatientChangeFunction;
}

export const activeQueueAtom = atom({
  key: "active_queue",
  default: false,
});

const GENDER: SelectItem[] = [
  {
    display: "L",
    value: PatientGender.MALE,
  },
  {
    display: "P",
    value: PatientGender.FEMALE,
  },
];

const MARITAL_STATUS: SelectItem[] = [
  {
    display: "BELUM MENIKAH",
    value: Marital_Status.UNMARRIED,
  },
  {
    display: "MENIKAH",
    value: Marital_Status.MARRIED,
  },
];

const IDENTITY_TYPE: SelectItem[] = [
  {
    display: "KTP",
    value: PatientIdentityType.ID_CARD,
  },
  {
    display: "SIM",
    value: PatientIdentityType.DRIVING_LICENSE,
  },
  {
    display: "PASSPORT",
    value: PatientIdentityType.PASSPORT,
  },
  {
    display: "KARTU PELAJAR",
    value: PatientIdentityType.STUDENT_CARD,
  },
];

const LAST_EDUCATION: SelectItem[] = [
  {
    display: "-",
    value: PatientEducation.NON,
  },
  {
    display: "SD",
    value: PatientEducation.SD,
  },
  {
    display: "SMP",
    value: PatientEducation.SMP,
  },
  {
    display: "SMA",
    value: PatientEducation.SMA,
  },
  {
    display: "D1",
    value: PatientEducation.D1,
  },
  {
    display: "D2",
    value: PatientEducation.D2,
  },
  {
    display: "D3",
    value: PatientEducation.D3,
  },
  {
    display: "D4",
    value: PatientEducation.D4,
  },
  {
    display: "S1",
    value: PatientEducation.S1,
  },
  {
    display: "S2",
    value: PatientEducation.S2,
  },
  {
    display: "S3",
    value: PatientEducation.S3,
  },
];

const SOURCE: SelectItem[] = [
  {
    display: "FB & INSTAGRAM",
    value: PatientSource.SOSMED,
  },
  {
    display: "PENYULUHAN",
    value: PatientSource.COUNSELING,
  },
  {
    display: "REFERENSI",
    value: PatientSource.REFERENCE,
  },
  {
    display: "SMS & TELEPON",
    value: PatientSource.PHONE,
  },
  {
    display: "SUDAH TAHU SENDIRI",
    value: PatientSource.KNOWED,
  },
  {
    display: "WHATSAPP & LINE",
    value: PatientSource.MESSENGER,
  },
];

const PARTNERS: SelectItem[] = [
  {
    display: "Keluarga",
    value: IdentityParnersType.FAMILY,
  },
  {
    display: "Karyawan",
    value: IdentityParnersType.EMPLOYEE,
  },
];

const RELATIONSHIP: SelectItem[] = [
  {
    display: "ORANG TUA",
    value: PatientRelationship.PARENT,
  },
  {
    display: "SUAMI / ISTRI",
    value: PatientRelationship.SPOUSE,
  },
  {
    display: "ANAK",
    value: PatientRelationship.CHILD,
  },
  {
    display: "ADIK",
    value: PatientRelationship.LITTLE_BROTHER,
  },
  {
    display: "KAKAK",
    value: PatientRelationship.BROTHER,
  },
  {
    display: "SAUDARA",
    value: PatientRelationship.SIBLING,
  },
  {
    display: "LAIN - LAIN",
    value: PatientRelationship.OTHER,
  },
];

const PRB: SelectItem[] = [
  {
    display: "YA",
    value: "YA",
  },
  {
    display: "TIDAK",
    value: "TIDAK",
  },
];

const INSURANCE: SelectItem[] = [
  {
    display: "BPJS",
    value: "BPJS",
  },
  {
    display: "UMUM",
    value: "UMUM",
  },
  {
    display: "ASURANSI",
    value: "ASURANSI",
  },
];

const BLOOD_GROUP: SelectItem[] = [
  {
    display: "-",
    value: "",
  },
  {
    display: "O",
    value: "O",
  },
  {
    display: "A",
    value: "A",
  },
  {
    display: "B",
    value: "B",
  },
  {
    display: "AB",
    value: "AB",
  },
];

export const FormPatient: React.FC = () => {
  const [disableForm, setDisableForm] = useState(false);
  const [disableInsuranceNumber, setDisableInsuranceNumber] = useState(false);

  const [triggerActiveQueue, setTriggerActiveQueue] =
    useRecoilState(activeQueueAtom);

  const [patient, setPatient] = useState<undefined | string>();
  const [showPRB, setShowPRB] = useState(false);
  const [
    showCreatePatientToAnamnesisModal,
    setShowCreatePatientToAnamnesisModal,
  ] = useState(false);

  // const [identityType, setIdentityType] = useState<SelectItem[]>([])

  const [selectedIdentityType, setSelectedIdentityType] = useState(
    IDENTITY_TYPE[0].value
  );
  const [selectedIdentityNumber, setSelectedIdentityNumber] = useState("");
  const [selectedDOB, setSelectedDOB] = useState(moment());
  const [age, setAge] = useState("");
  const [selectedGender, setSelectedGender] = useState(GENDER[0].value);
  const [selectedBloodGroup, setSelectedBloodGroup] = useState(
    BLOOD_GROUP[0].value
  );
  const [selectedLastEducation, setSelectedLastEducation] = useState(
    LAST_EDUCATION[0].value
  );
  const [job, setJob] = useState<SelectItem[]>([]);
  const [selectedJob, setSelectedJob] = useState({id: "", name: ""});
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(
    MARITAL_STATUS[0].value
  );
  const [selectedEmail, setSelectedEmail] = useState("");
  const [province, setProvince] = useState<SelectItem[]>([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [city, setCity] = useState<SelectItem[]>([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [district, setDistrict] = useState<SelectItem[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [village, setVillage] = useState<SelectItem[]>([]);
  const [selectedVillage, setSelectedVillage] = useState({id: "", name: ""});
  const [prbType, setPRBType] = useState<SelectItem[]>([]);
  const [selectedPrbType, setSelectedPRBType] = useState("");
  const [company, setCompany] = useState<SelectItem[]>([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [showInsuranceName, setShowInsuranceName] = useState(false);
  const [insurance, setInsurance] = useState<SelectItem[]>([]);
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [selectedVisitType, setSelectedVisitType] = useState(
    INSURANCE[1].value
  );

  const [selectedPrb, setSelectedPrb] = useState("TIDAK");
  const [disablePrbType, setDisablePrbType] = useState(false);
  const [patientInformationConfirmation, setPatientInformationConfirmation] =
    useState({
      no_registration: "",
      fullname: "",
      age: "",
      address: "",
    });
  const [activeQueue, setActiveQueue] = useState<any>();
  const [triggerReservationButton, setTriggerReservationButton] =
    useState(false);

  const patientService = usePatient();
  const insuranceClient = useInsurance();
  const provinceService = useProvince();
  const cityService = useCity();
  const districtService = useDistrict();
  const villageService = useVillage();
  const prbTypeService = usePRBType();
  const companyService = useCompany();
  const jobService = useJob();
  const queueService = useQueue();

  const params = useParams<{id: string}>();
  const [form] = Form.useForm();
  const history = useHistory();

  // ===== START OF GET SELECTION FIELD SECTION =====
  const getInsurance = async () => {
    const insurance: SelectItem[] = [];
    const result = await insuranceClient.fetch().then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await insurance.push({
            display: val.name as string,
            value: val.id!,
          })
      )
    );
    setInsurance(insurance);
  };

  const getJob = async (value: string) => {
    const job: SelectItem[] = [];
    const result = await jobService.fetch(value).then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await job.push({
            display: val.name,
            value: val.id!,
          })
      )
    );

    setJob(job);
  };

  const getProvince = async (value: string, idCountry: number) => {
    const province: SelectItem[] = [];
    const result = await provinceService
      .fetch(value, idCountry)
      .then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await province.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setProvince(province);
  };

  const getCity = async (value: string, provinceId: number | string) => {
    const city: SelectItem[] = [];
    const result = await cityService
      .fetch(value, provinceId)
      .then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await city.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setCity(city);
  };

  const getDistrict = async (value: string, cityId: number | string) => {
    const district: SelectItem[] = [];
    const result = await districtService
      .fetch(value, cityId)
      .then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await district.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setDistrict(district);
  };

  const getVillage = async (value: string, districtId: string | number) => {
    const village: SelectItem[] = [];
    const result = await villageService
      .fetch(value, districtId)
      .then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await village.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setVillage(village);
  };

  const getPRBType = async (value: string) => {
    const prb: SelectItem[] = [];
    const result = await prbTypeService.fetch(value).then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await prb.push({
            display: val.name,
            value: val.id,
          })
      )
    );

    setPRBType(prb);
  };

  const getCompany = async (value: string) => {
    const company: SelectItem[] = [];
    const result = await companyService.fetch(value).then((res) => res.data);

    await Promise.all(
      _.map(
        result,
        async (val) =>
          await company.push({
            display: val.name,
            value: val.id,
          })
      )
    );
    setCompany(company);
  };

  useEffect(() => {
    if (selectedVisitType === "BPJS") {
      setShowPRB(true);
      setDisableInsuranceNumber(false);
      setShowInsuranceName(false);
      if (selectedPrb === "TIDAK") {
        setDisablePrbType(true);
      } else {
        setDisablePrbType(false);
      }
    } else if (selectedVisitType === "UMUM") {
      setShowPRB(false);
      setDisableInsuranceNumber(true);
      setShowInsuranceName(false);
    } else if (selectedVisitType === "ASURANSI") {
      setShowPRB(false);
      setDisableInsuranceNumber(false);
      setShowInsuranceName(true);
    }
  }, [selectedVisitType, selectedProvince, selectedCity, selectedPrb]);

  // ===== END OF GET SELECTION FIELD SECTION =====

  // ===== EFFECT FOR GENERATE AGE ======
  useEffect(() => {
    let diff = moment.duration(moment().diff(moment(selectedDOB)));
    if (diff) {
      setAge(
        `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
      );
    } else {
      setAge(`0 Tahun 0 Bulan 0 Hari`);
    }

    console.log(diff);
  }, [selectedDOB]);
  // ===== END EFFECT FOR GENERATE AGE ======

  // ===== START OF ADD AND EDIT PATIENT SIDE EFFECT =====
  useEffect(() => {
    getJob("");
    getProvince("", 1);
    getCity("", selectedProvince);
    getDistrict("", selectedCity);
    getVillage("", selectedDistrict);
    getCompany("");

    // ===== GET INSURANCE =====
    getInsurance();

    setActiveQueue(localStorage.getItem("activeQueue"));

    // form.setFieldsValue({
    //   insurance: INSURANCE[1].display,
    // });

    // ===== START EDIT PATIENT SECTION ====
    // ===== SET VALUE WITH SELECTED PATIENT DATA =====
    if (params.id !== "new") {
      setDisableForm(true);
      patientService
        .get(params.id)
        .then((res) => {
          form.setFieldsValue({
            identity_type: res.type_identity,
            no_identity: res.no_identity,
            reg_number: res.no_registration,
            fullname: res.fullname.toUpperCase(),
            place_of_birth: res.place_of_birth,
            date_of_birth: moment(res.date_of_birth),
            gender: res.gender,
            blood_type: res.blood_type,
            last_education: res.last_education,
            job: res.job.id,
            marital_status: res.marital_status,
            phone_number: res.phone_number,
            email: res.email,
            address: res.address.toUpperCase(),
            village: res.village.name,
            district: res.village.district.name,
            city: res.village.district.city.name,
            province: res.village.district.city.province.name,
            insurance: res.bpjs ? INSURANCE[0].display : INSURANCE[1].display,
            insurance_number: res.bpjs,
            person_in_charge: res.person_in_charge,
            relationship: res.relationship,
            person_in_charge_phone_number: res.person_in_charge_phone_number,
            source: res.source,
          });
          res.bpjs !== ("" || null)
            ? setSelectedVisitType("UMUM")
            : setSelectedVisitType("BPJS");
          setSelectedDOB(res.date_of_birth);
          setSelectedJob({
            ...selectedJob,
            id: res.job.id,
            name: res.job.name,
          });

          setSelectedVillage({
            ...selectedVillage,
            id: res.village.id,
            name: res.village.name,
          });
        })
        .catch((err) => console.error(err, "get patient"));
    }
    // ===== END OF SET VALUE WITH SELECTED PATIENT DATA =====
    // *** EDIT PATIENT (POST EDITED PATIENT DATA) CONTINUED TO ON FINISH FUNCTION ***
    // ===== END OF EDIT PATIENT SECTION ====
  }, []);

  // ===== EFFECT FOR RESET CITY, DISTRICT AND VILLAGE WHEN PROVINCE IS CHANGED ======
  useEffect(() => {
    form.setFieldsValue({
      city: "",
      district: "",
      village: "",
    });
  }, [selectedProvince]);

  useEffect(() => {
    form.setFieldsValue({
      district: "",
      village: "",
    });
  }, [selectedCity]);

  useEffect(() => {
    form.setFieldsValue({
      village: "",
    });
  }, [selectedDistrict]);

  useEffect(() => {
    if (activeQueue) {
      setTriggerReservationButton(false);
    } else {
      setTriggerReservationButton(true);
    }
  }, [triggerActiveQueue]);

  //===== Effect for button reservation trigger =====

  // ===== START DELETE PATIENT SECTION =====
  const handleDelete = (id: string) => {
    patientService
      .delete(id)
      .then((res: any) => message.success(res.message))
      .catch((e) => console.log(e));

    history.push("/patients");
  };
  // ===== END OF DELETE PATIENT SECTION =====
  const onFinish = (val: any) => {
    // ===== ADD PATIENT WITHOUT QUEUE NUMBER =====
    if (!localStorage.getItem("activeQueue")) {
      const datas: Patient = {
        type_identity: selectedIdentityType as string,
        no_identity: val.no_identity,
        fullname: val.fullname.toUpperCase(),
        place_of_birth: val.place_of_birth,
        date_of_birth: moment(selectedDOB).format("YYYY-MM-DD"),
        gender: selectedGender,
        blood_type: selectedBloodGroup || null,
        last_education: selectedLastEducation,
        job: selectedJob.id,
        marital_status: selectedMaritalStatus,
        phone_number: val.phone_number,
        email: val.email,
        address: val.address.toUpperCase(),
        village: selectedVillage.id,
        bpjs: val.insurance_number,
        person_in_charge: val.person_in_charge,
        relationship: val.relationship,
        person_in_charge_phone_number: val.person_in_charge_phone_number,
        source: val.source,
      };

      // ===== POST EDIT & ADD PATIENT DATA =====
      if (params.id !== "new") {
        // POST EDIT PATIENT DATA
        setDisableForm(!disableForm);
        patientService
          .update(params.id, datas)
          .then((res: any) => {
            message.success(res.message);
            history.push("/patients");
          })
          .catch((e) => message.error(e.response.data.message));
      } else {
        // POST ADD PATIENT DATA
        patientService
          .create(datas)
          .then((res: any) => {
            message.success(res.message);
            history.push("/patients");
          })
          .catch((e) => message.error(e.response.data.message));
      }
    }

    // ===== ADD PATIENT WITH QUEUE NUMBER =====
    else if (localStorage.getItem("activeQueue")) {
      const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
      const patientToAnamnesis = {
        anamnesis: {
          registration: {
            patient: {
              id: "",
              type_identity: selectedIdentityType,
              no_identity: val.no_identity,
              fullname: val.fullname.toUpperCase(),
              place_of_birth: val.place_of_birth,
              date_of_birth: selectedDOB.format("YYYY-MM-DD"),
              gender: selectedGender,
              blood_type: val.blood_type || null,
              last_education: val.last_education,
              job: {
                id: selectedJob.id,
              },
              marital_status: selectedMaritalStatus,
              phone_number: val.phone_number,
              email: val.email,
              address: val.address.toUpperCase(),
              village: {
                // id: Number(val.village.id),
                id: selectedVillage.id,
              },
              bpjs: val.insurance_number,
              person_in_charge: val.person_in_charge,
              relationship: val.relationship,
              person_in_charge_phone_number: val.person_in_charge_phone_number,
            },
            source: val.source,
          },
          insurance_type:
            selectedVisitType === "UMUM" ? null : {id: val.insurance_type},
          insurance_no: val.insurance_number,
          prb: val.prb_desc !== undefined ? {id: val.prb_desc} : null,
          partnership: val.company !== undefined ? {id: val.company} : null,
          partnership_info: val.company_desc !== "" ? val.company_desc : null,
        },
      };

      queueService
        .createPatientToAnamnesis(activeQueue.idRoom, patientToAnamnesis)
        .then((res: any) => {
          setPatientInformationConfirmation({
            no_registration: res.no_registration,
            fullname: val.fullname.toUpperCase(),
            age: age,
            address: val.address,
          });
          setShowCreatePatientToAnamnesisModal(true);
        })
        .catch((e) => message.error(e.response.data.message));
    }
  };

  // ===== Check Identity Type to to check whether patient data is available or not  section =====
  const handleOnBlur = () => {
    patientService
      .check(
        selectedIdentityType,
        selectedIdentityNumber,
        selectedDOB.format("YYYY-MM-DD"),
        selectedEmail
      )
      .then((res) => {
        Modal.confirm({
          title: "Peringatan",
          icon: <ExclamationCircleOutlined />,
          content: `Data pasien ini sudah terakap dalam sistem, apakah anda ingin memakai data ini?`,
          okText: "Ya",
          onOk: () => handleOnOkConfirm(res),
          cancelText: "Tidak",
          onCancel: () => handleOnCancel(),
        });
      })
      .catch((err) => console.log(err));
  };

  const handleOnOkConfirm = async (res: any) => {
    console.log(res);
    setSelectedVillage({
      ...selectedVillage,
      id: res.village.id,
      name: res.village.name,
    });

    setSelectedJob({
      ...selectedJob,
      id: res.job.id,
      name: res.job.name,
    });

    form.setFieldsValue({
      identity_type: res.type_identity,
      no_identity: res.no_identity,
      reg_number: "",
      fullname: res.fullname.toUpperCase(),
      place_of_birth: res.place_of_birth,
      date_of_birth: moment(res.date_of_birth),
      gender: res.gender,
      blood_type: res.blood_type,
      last_education: res.last_education,
      job: res.job.name,
      marital_status: res.marital_status,
      phone_number: res.phone_number,
      email: res.email,
      address: res.address.toUpperCase(),
      village: res.village.name,
      district: res.village.district.name,
      city: res.village.district.city.name,
      province: res.village.district.city.province.name,
      insurance: res.bpjs ? INSURANCE[0].display : INSURANCE[1].display,
      insurance_number: res.bpjs,
      person_in_charge: res.person_in_charge,
      relationship: res.relationship,
      person_in_charge_phone_number: res.person_in_charge_phone_number,
      source: res.source,
    });
  };
  const handleOnCancel = () => {
    console.log("Handle tydac");
  };
  // ===== End of Check Identity Type to to check whether patient data is available or not  section=====
  const handleMenuClick = (e: any) => {};
  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1">Tambah Kunjungan</Menu.Item>
      <Menu.Item key="2">Reservasi</Menu.Item>
    </Menu>
  );
  return (
    <>
      <Title title="Pasien" description="form pendaftaran pasien baru" />
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Row>
          <Col xs={24} sm={24} md={12}>
            <Card title="profil pasien">
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="identity_type"
                    label="Tipe Identitas"
                    items={IDENTITY_TYPE}
                    value={selectedIdentityType}
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedIdentityType(v as string)}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Input
                    name="no_identity"
                    label="No Identitas"
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedIdentityNumber(v)}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Input
                    name="phone_number"
                    label="Nomor Telepon"
                    disabled={disableForm}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Input
                    name="fullname"
                    label="Nama Lengkap"
                    disabled={disableForm}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12}>
                  <Input
                    name="place_of_birth"
                    label="Kota Lahir"
                    disabled={disableForm}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <Date
                    name="date_of_birth"
                    label="Tanggal Lahir"
                    display="Date"
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedDOB(v)}
                  />
                </Col>
                <Col md={8}>
                  <div style={{margin: "0 10px"}}>
                    <label style={{fontWeight: "bold", fontSize: ".85em"}}>
                      Usia
                    </label>
                    <p
                      style={{
                        color: "#21D47E",
                        fontWeight: "bold",
                        margin: 0,
                        fontSize: "1.1em",
                        padding: "12.5px 0 0 0",
                      }}
                    >
                      {age}
                    </p>
                  </div>
                </Col>
                <Col md={8}>
                  <Radio
                    name="gender"
                    label="Jenis Kelamin"
                    items={GENDER}
                    value={selectedGender}
                    onValueChange={(v) => setSelectedGender(v.toString())}
                    disabled={disableForm}
                  />
                </Col>
                <Col md={8}>
                  <Radio
                    name="blood_type"
                    label="Golongan Darah"
                    items={BLOOD_GROUP}
                    value={selectedBloodGroup}
                    onValueChange={(v) => setSelectedBloodGroup(v.toString())}
                    disabled={disableForm}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="last_education"
                    label="Pendidikan"
                    items={LAST_EDUCATION}
                    value={selectedLastEducation}
                    onValueChange={(v) => setSelectedLastEducation(v as string)}
                    disabled={disableForm}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="job"
                    label="Pekerjaan"
                    items={job}
                    showSearch
                    onSearch={(q) => getJob(q)}
                    filterOption={false}
                    disabled={disableForm}
                    onValueChange={(v) =>
                      setSelectedJob({
                        ...selectedJob,
                        id: v as string,
                        name: "",
                      })
                    }
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="marital_status"
                    label="Status Menikah"
                    items={MARITAL_STATUS}
                    value={selectedMaritalStatus}
                    onValueChange={(v) => setSelectedMaritalStatus(v)}
                    disabled={disableForm}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Input
                    name="email"
                    label="Email"
                    value={selectedEmail}
                    onValueChange={(v) => setSelectedEmail(v)}
                    disabled={disableForm}
                    onBlur={handleOnBlur}
                  />
                </Col>
              </Row>

              <Textarea name="address" label="Alamat" disabled={disableForm} />
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="province"
                    label="Provinsi"
                    items={province}
                    value={selectedProvince}
                    showSearch
                    onSearch={(q) => getProvince(q, 1)}
                    filterOption={false}
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedProvince(v as string)}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="city"
                    label="Kota"
                    items={city}
                    value={selectedCity}
                    showSearch
                    onSearch={(q) => getCity(q, selectedProvince)}
                    filterOption={false}
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedCity(v as string)}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="district"
                    label="Kecamatan"
                    items={district}
                    value={selectedDistrict}
                    showSearch
                    onSearch={(q) => getDistrict(q, selectedCity)}
                    filterOption={false}
                    disabled={disableForm}
                    onValueChange={(v) => setSelectedDistrict(v as string)}
                  />
                </Col>
                <Col xs={24} sm={24} md={12} xl={12}>
                  <Select
                    name="village"
                    label="Desa"
                    items={village}
                    value={selectedVillage.id}
                    showSearch
                    onSearch={(q) => getVillage(q, selectedDistrict)}
                    filterOption={false}
                    disabled={disableForm}
                    onValueChange={(v) =>
                      setSelectedVillage({
                        ...selectedVillage,
                        id: v as string,
                        name: "",
                      })
                    }
                  />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={1}></Col>
          {/* end of right side */}
          {/* start of left side */}
          <Col xs={24} sm={24} md={11}>
            {JSON.parse(localStorage.getItem("activeQueue")!) ||
            params.id !== "new" ? (
              <Card title="data pendukung">
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Select
                      name="visit"
                      label="Jenis Kunjungan"
                      items={INSURANCE}
                      value={selectedVisitType}
                      disabled={disableForm}
                      onValueChange={(v) => setSelectedVisitType(v as string)}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Input
                      name="insurance_number"
                      label="Nomor"
                      disabled={disableForm || disableInsuranceNumber}
                    />
                  </Col>
                </Row>
                <Collapsible isOpen={showInsuranceName}>
                  <Row gutter={15}>
                    <Col xs={24} sm={24} md={24} xl={24}>
                      <Select
                        name="insurance"
                        label="Nama Asuransi"
                        items={insurance}
                        disabled={disableForm}
                      />
                    </Col>
                  </Row>
                </Collapsible>
                <Collapsible isOpen={showPRB}>
                  <Row gutter={15}>
                    <Col xs={24} sm={24} md={12}>
                      <Select
                        name="prb"
                        label="Pasien Rujuk Balik"
                        items={PRB}
                        value={selectedPrb}
                        onValueChange={(v) => setSelectedPrb(v as string)}
                        disabled={disableForm}
                      />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                      <Select
                        name="prb_desc"
                        label="Keterangan PRB"
                        items={prbType}
                        showSearch
                        filterOption={false}
                        // onSearch={getPRBType}
                        disabled={disableForm || disablePrbType}
                        onSearch={(q) => getPRBType(q)}
                        onValueChange={(v) => setSelectedPRBType(v as string)}
                      />
                    </Col>
                  </Row>
                </Collapsible>
                <Row gutter={15}>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Select
                      name="company"
                      label="Tempat Bekerja"
                      items={company}
                      showSearch
                      filterOption={false}
                      onSearch={getCompany}
                      disabled={disableForm}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={12} xl={12}>
                    <Select
                      name="partners_type"
                      label="Keterangan"
                      items={PARTNERS}
                      showSearch
                      disabled={disableForm}
                    />
                  </Col>
                </Row>
              </Card>
            ) : (
              <></>
            )}

            <Card title="penanggung jawab">
              <Col>
                <Input
                  name="person_in_charge"
                  label="Nama"
                  disabled={disableForm}
                />
              </Col>
              <Row gutter={15}>
                <Col xs={24} sm={12} md={12} xl={12}>
                  <Select
                    name="relationship"
                    label="Status Hubungan"
                    items={RELATIONSHIP}
                    disabled={disableForm}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} xl={12}>
                  <Input
                    name="person_in_charge_phone_number"
                    label="No Telp"
                    disabled={disableForm}
                  />
                </Col>
              </Row>
              <Select
                name="source"
                label="Mengetahui Klinik"
                items={SOURCE}
                disabled={disableForm}
              />
            </Card>
            {params.id === "new" ? (
              <>
                <Button className="btn-primary" htmlType="submit">
                  Submit
                </Button>
                <Button
                  onClick={() => history.replace("/patients")}
                  style={{marginLeft: "1%"}}
                  className="btn-light"
                  htmlType="submit"
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                {disableForm && (
                  <Button
                    className="btn-primary"
                    onClick={() => setDisableForm(!disableForm)}
                  >
                    Edit
                  </Button>
                )}
                {!disableForm && (
                  <Button className="btn-primary" htmlType="submit">
                    Perbaharui
                  </Button>
                )}
                <Popconfirm
                  placement="topLeft"
                  title={"Apakah anda yakin ingin menghapus data ini?"}
                  onConfirm={() => handleDelete(params.id)}
                  okText="Ya"
                  cancelText="Tidak"
                >
                  <Button className="btn-danger" style={{margin: "0 1%"}}>
                    Hapus
                  </Button>
                  <Button
                    onClick={() => history.replace("/patients")}
                    style={{marginRight: "1%"}}
                    className="btn-light"
                    htmlType="submit"
                  >
                    Cancel
                  </Button>
                </Popconfirm>
                {triggerReservationButton ? (
                  <Dropdown overlay={menu} placement="bottomCenter">
                    <Button className="btn-secondary">Daftar Berobat</Button>
                  </Dropdown>
                ) : (
                  <Button
                    onClick={() => history.replace("/reservation")}
                    className="btn-secondary"
                  >
                    Reservasi
                  </Button>
                )}
              </>
            )}
          </Col>
          {/* end of left side */}
        </Row>
      </Form>

      <CreatePatientToAnamnesisModal
        visible={showCreatePatientToAnamnesisModal}
        setVisible={setShowCreatePatientToAnamnesisModal}
        patient={patientInformationConfirmation}
      />
    </>
  );
};
