
import React, { FC } from 'react'
import DataMedicine from '../../components/pharmacy/medicine'

const MedicinePage: FC = () => {
    return <div>
        <DataMedicine/>
    </div>
}

export default MedicinePage