
import { baseURL } from "../baseurl";
import { Province } from "./province";
import { PaginationData } from "../utility";

export interface City {
  id: string;
  name: string;
  province: Province;
}

// 12 = Jawa Barat
// 163 = Cianjur

const CityClient = baseURL

const useCity = () => ({
  async create(City: City): Promise<City> {
    const { data } = await CityClient.post(`/config/locations/city`, City);
    return data;
  },
  async update(id: string, City: City): Promise<City> {
    const { data } = await CityClient.patch(`/config/locations/city/${id}`, City);
    return data;
  },
  async delete(id: string): Promise<void> {
    const { data } = await CityClient.delete(`/config/locations/city/${id}`);
    return data;
  },
  async get(id: string): Promise<City> {
    const { data } = await CityClient.get(`/config/locations/city/${id}`);
    return data;
  },

  async fetch(
    search: string,
    provinceId?: number | string,
    size?: number, row?: number
  ): Promise<PaginationData<City[]>> {
    let searching = search ? `name=${search}` : "";
    let province = provinceId ? `provinceId=${provinceId}` : "";
    let sizes = size ? `size=${size}` : ''
    let rows = row ? `page=${row}` : ''
    const { data } = await CityClient.get(
      `/config/locations/city/?${searching}&${province}&${sizes}&${rows}`
    );

    return data;
  },
});

export default useCity;
