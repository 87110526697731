import { Col, Row, Form, Input,  Button } from 'antd'
import  React, { FC, useEffect, useState } from 'react'
import Card from '../../layout/card'
import Title from '../../layout/title';
import { useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Notification, successNotification } from '../../layout/notification'
import _ from 'lodash'
import usePRB, { PRB } from '../../../services/configurations/prb';
import { id as clinicId } from '../../../services/baseurl';



const FormPRB: FC = () => {

    const [loader, setLoader] = useState<boolean>(false)
    const prbService = usePRB()
    const history = useHistory()
    const params = useParams() as any
    const [ prb, setPRB ] = useState<PRB>({
        name: ''
    })

    const loadPRB = () => {
        setLoader(true)
        prbService
            .get(params.id)
            .then(prb => {
                setPRB({
                    name: prb.name
                })
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }

    useEffect(() => {
        if(params.id !== 'new'){ 
            setPRB({
                ...prb,
                id: params.id
            })
        }
         params.id !== 'new' && loadPRB()
     }, [])

     const submit = () => {
        // console.log(clinicId)
        params.id == 'new' && prbService
            .create(prb)
            .then(prb => {
                    setPRB(prb)
                    successNotification('tambah')
                })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
        params.id !== 'new' && prbService
            .update(params.id, prb)
            .then(prb => {
                setPRB(prb)
                successNotification('perbaharui')
            })
            .catch(e => console.log(e))   
            .finally(() => setLoader(false))
    }
   

    return (
        <>  
         {
            loader && <div>Loading .... </div>
        }
        <Title title="Pasien Rujuk Balik" description="form pengisian data pasien rujuk balik" />
        <Form layout="vertical">
            <Row>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Card title="Form PRB" >
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="nama PRB" className='lable-form-input'>
                                    <Input data-cy="prb_name"  value={prb.name} onChange={({target : {value: name}}) => setPRB({...prb, name})} className='form-control' />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" data-cy="btn-submit" onClick={submit} style={{  marginRight:'8px' }}>
                                Simpan
                            </Button>

                            <Button type="default" data-cy="btn-back" title="Kembali" onClick={ () => history.push(`/prb`) }>Kembali</Button> 
                        </Form.Item>
                    </Card> 
                    
                
                </Col>
            </Row>
        </Form>
       
        </>
    )
}

export default FormPRB