
import React, { FC } from 'react'
import DataQueue from '../../components/cashier/queue'

const QueueCashierPage: FC = () => {
    return <div>
        <DataQueue/>
    </div>
}

export default QueueCashierPage