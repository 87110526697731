import React from 'react'
import { IconButton } from '../../../services/iconButton'

export const NextIconButton: React.FC<IconButton> = ({ onClick, style }) => {
    return (
        <svg onClick={onClick} style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chevron_right_24px">
                <path id="icon/navigation/chevron_right_24px" d="M9.70504 6L8.29504 7.41L12.875 12L8.29504 16.59L9.70504 18L15.705 12L9.70504 6Z" fill="black" fillOpacity="0.54" />
            </g>
        </svg>
    )
}
