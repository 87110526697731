import React, { FC} from 'react'
import { Button, Typography,Table, Input, Select} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../layout/card'
import DataTable from 'react-data-table-component';

const { Text } = Typography;
const { Option } = Select

const DataTreatment: FC = () => { // Data Table
  
    const columns = [
        {
          name: 'Tindakan',
          selector : (row: any) => <Input value={row.name} disabled style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} className='form-control'></Input>
        },
        {
            name: 'QTY',
            selector : (row: any) => <Input value={row.qty}  style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} className='form-control'></Input>
          },
        {
          name: 'Perawat / Asisten',
          selector : (row: any) => <Input value={row.asistant}  style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} className='form-control'></Input>
        },
        {
          name: 'Keterangan',
          selector : (row: any) => <Input value={row.info} disabled  style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }} className='form-control'></Input>
        },
        {
          name: '',
          selector : (row: any) => <Button type='primary'>Ubah</Button>
        }
      ];
      
      const data = [
        {
          key: '1',
          name: 'Hecting',
          qty: 10,
          asistant: 'Nafla',
          info: "luka robek"
        },
        {
          key: '2',
          name: 'INJ Ranitidin',
          qty: 6,
          asistant: 'Nafla',
          info: ""
        }
      ];

  

    return <div>
       
        {/* Detail Biaya Pasien */}
        <Card title="Tindakan & peralatan">
            <DataTable
                columns={columns}
                data={data}
                pagination={false}
                style={{ marginTop: '-4em'}}
                

            />
        </Card>

    </div>
}

export default DataTreatment