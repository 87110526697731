import {BrowserRouter, Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import Routes from "./router/router";
import {AuthProvider} from "./context/AuthContext";
import {SocketProvider} from "./context/SocketContext";
import "./App.css";

function App() {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <AuthProvider>
        <SocketProvider>
          <BrowserRouter>
            {/* <Router history={history}> */}
            <Routes history={history} />
            {/* </Router> */}
          </BrowserRouter>
        </SocketProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
