
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface Chest {
    id? : string
    name: string
}


const ChestClient = baseURL;
const locationID = '87769b6f-b697-4549-8b70-61b9f5cc1ed6';

const useChest = () => ({
    async create(Chest: Chest): Promise<Chest>{
        const {data} = await ChestClient.post(`/medicine/location/${locationID}/cabinets`, Chest)
        return data
    },
    async update(id: string, Chest: Chest): Promise<Chest>{
        await ChestClient.patch(`/medicine/location/${locationID}/cabinets/${id}`, Chest)
        return Chest
    },
    async delete(id: string): Promise<void>{
        const {data} = await ChestClient.delete(`/medicine/location/${locationID}/cabinets/${id}`)
        return data
    },
    async get(id:string): Promise<Chest>{
        const {data} = await ChestClient.get(`/medicine/location/${locationID}/cabinets/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Chest[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await ChestClient.get(`/medicine/location/${locationID}/cabinets/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useChest