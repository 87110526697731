
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface Location {
    id? : string
    name: string
    info: string
}

const LocationClient = baseURL

const useLocation = () => ({
    async create(Location: Location): Promise<Location>{
        const {data} = await LocationClient.post(`/medicine/locations`, Location)
        return data
    },
    async update(id: string, Location: Location): Promise<Location>{
        await LocationClient.patch(`/medicine/locations/${id}`, Location)
        return Location
    },
    async delete(id: string): Promise<void>{
        const {data} = await LocationClient.delete(`/medicine/locations/${id}`)
        return data
    },
    async get(id:string): Promise<Location>{
        const {data} = await LocationClient.get(`/medicine/locations/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<Location[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const {data} = await LocationClient.get(`/medicine/locations/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default useLocation