import React, {useEffect, useState} from "react";
import {Button, Descriptions, Form, Row, Col, Divider} from "antd";
import Card from "../../layout/card";
import Input from "../../form/Input";
import Textarea from "../../form/TextArea";
import {useForm} from "antd/lib/form/Form";
import {AddIconButton} from "../../form/icon-button/AddIconButton";
import {DeleteIconButton} from "../../form/icon-button/DeleteIconButton";
import {IStepProps} from "./_form";
import {PatientHistory} from "../../form/modal/PatientHistory";
import {DataLab} from "../../form/modal/DataLab";
import {ReferLabModal} from "../../form/modal/ReferLabModal";
import {Tabs} from "antd";
import FloatingTextInput from "../../form/FloatingTextInput";
import TitleWithStep from "../../layout/stepTitle";
import "./Step/step.css";
import useQueue from "../../../services/queue";
import moment from "moment";
import InputNumber from "../../form/InputNumber";
import {gcsValHelper} from "../../../helper";
import useCheckup from "../../../services/doctor/checkup";
import {useHistory, useParams} from "react-router-dom";
import {DetailAnamnesisPatient} from "./DetailPasient";
import {StepNavigation} from "./StepNavigation";
import Select, {SelectItem} from "../../form/Select";

const {TabPane} = Tabs;

const KIRDoctor: SelectItem[] = [
  {
    display: "-",
    value: "-",
  },
  {
    display: "Sehat",
    value: "Sehat",
  },
  {
    display: "Sakit",
    value: "Sakit",
  },
];

const labPackage = [
  {
    key: "1",
    itemName: "Cholesterol HDL",
    result: "mg/dl*",
    value: "35-100 mg/fl",
    desc: "-",
  },
  {
    key: "2",
    itemName: "Chlorida",
    result: "mmol-L*",
    value: "97-108 mmol/L",
    desc: "-",
  },
  {
    key: "4",
    itemName: "Natrium Chlorida",
    result: "mmol-L*",
    value: "97-108 mmol/L",
    desc: "-",
  },
  {
    key: "5",
    itemName: "Carbon",
    result: "mmol-L*",
    value: "97-108 mmol/L",
    desc: "-",
  },
];

export const SecondStepForm = () => {
  const [toggle, setToggle] = useState(false);
  const [dataLab, setDataLab] = useState(false);
  const [patientHistoryModal, setPatientHistoryModal] = useState(false);
  const [referLabModal, setReferLabModal] = useState(false);
  const [age, setAge] = useState("");
  const [eye, setEye] = useState(4);
  const [verbal, setVerbal] = useState(5);
  const [motor, setMotor] = useState(6);
  const [gcsValue, setGcsValue] = useState("");
  const [typeOfConsciousness, setTypeofConsciousness] = useState("");
  const [kirDoctor, setKirDoctor] = useState();
  const [selectedKirDoctor, setSelectedKirDoctor] = useState({
    display: KIRDoctor[0].display,
    value: KIRDoctor[0].value,
  });

  const [anamnesisDatas, setAnamnesisDatas] = useState({
    id: "",
    position: "",
    status: false,
    created: "",
    updated: "",
    queue: {
      id: "",
      priority: true,
      number: 0,
      status: "",
      bookdated: "",
      shift: {
        id: 0,
        name: "",
        startTime: "",
        endTime: "",
        clinic_poly: {
          inc_id: 0,
          id: "",
          order: 0,
          status: true,
          code: "",
          max_online_registration: 30,
          is_doctor: true,
          created: "",
          updated: "",
          poly: {
            inc_id: 1,
            id: "",
            name: "",
            code_bpjs: "",
            created: "",
            updated: "",
          },
        },
      },
      anamnesis: {
        id: "",
        patient: {
          inc_id: 0,
          id: "",
          no_registration: "",
          bpjs: "",
          type_identity: "",
          no_identity: "",
          fullname: "",
          place_of_birth: "",
          date_of_birth: "",
          gender: "",
          blood_type: null,
          last_education: null,
          marital_status: "",
          job: "",
          phone_number: "",
          email: "",
          village: {
            id: 0,
            name: "",
            district: {
              id: 0,
              name: "",
              city: {
                id: 0,
                name: "",
                province: {
                  id: 0,
                  name: "",
                  country: {
                    id: 0,
                    name: "",
                  },
                },
              },
            },
          },
          address: "",
          person_in_charge: null,
          relationship: null,
          person_in_charge_phone_number: null,
          clinic: 0,
          source: null,
          created: "",
          updated: "",
          deleted: null,
        },
        insurance_type: null,
        insurance_no: "",
        prb: null,
        partnership: null,
        partnership_info: null,
        doctor: null,
        complaint: null,
        complaint_detail: null,
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        temperature: null,
        respiratory: null,
        heart: null,
        spo2: null,
        gdp: null,
        gds: null,
        hba1c: null,
        priority_info: null,
        created: "",
        updated: "",
      },
    },
  });

  const [form] = useForm();
  const queueService = useQueue();
  const checkupService = useCheckup();
  const history = useHistory();
  const params = useParams<{id: string}>();

  const initalField = (idAnamnesis: string) => {
    checkupService
      .getPhysicalCheck(idAnamnesis)
      .then((res) => {
        if (res) {
          setEye(res.eye as number);
          setVerbal(res.verbal as number);
          setMotor(res.motorik as number);
          form.setFieldsValue({
            gcs_value: +res.eye + +res.verbal + +res.motorik,
            typeof_consciousness: res.type_of_consciousness,
            info: res.info,
            kir: res.kir_condition,
          });
        }
      })
      .catch((err) => console.log(err.response, "from checkup service"));
  };

  const gcsEffect = () => {
    const result = gcsValHelper(eye, verbal, motor);
    form.setFieldsValue({
      gcs_value: result.gcs,
      typeof_consciousness: result.gcsDesc,
    });
  };
  useEffect(gcsEffect, [eye, verbal, motor]);

  const patientInformationEffect = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .getQueueRoomDetail(activeQueue.idRoom)
      .then((res: any) => {
        let diff = moment.duration(
          moment().diff(moment(res.queue.anamnesis.patient.date_of_birth))
        );
        setAge(
          `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
        );
        setAnamnesisDatas(res);
        initalField(res.queue.anamnesis.id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(patientInformationEffect, []);
  console.log(selectedKirDoctor, "from initial field");

  const onFinish = (val: any) => {
    const datas = {
      eye: eye,
      verbal: verbal,
      motorik: motor,
      type_of_consciousness: val.typeof_consciousness as string,
      info: val.info as string,
      kir_condition: selectedKirDoctor.value as string,
    };
    // console.log(datas);
    checkupService
      .submitPhysicalCheck(anamnesisDatas.queue.anamnesis.id, datas)
      .then((res) => {
        history.push(`/checkup/step3/${params.id}`);
      })
      .catch((res) => console.log(res));
  };

  return (
    <>
      <TitleWithStep
        title="Pemeriksaan Fisik"
        stepTitle="Tahap 2"
        description="pemeriksaan fisik dan penunjang"
      />
      <DetailAnamnesisPatient
        anamnesisDatas={anamnesisDatas}
        age={age}
        setPatientHistoryModal={setPatientHistoryModal}
      />
      <StepNavigation activeSteps={2} />
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={15}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title="Pemeriksaan fisik">
              <strong>GCS (Glasgow Cima Scale)</strong>
              <Row gutter={15} style={{marginTop: "10px"}}>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <InputNumber
                    min={1}
                    max={4}
                    name="eye"
                    label="Eye (E)"
                    value={eye}
                    onValueChange={setEye}
                  />
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <InputNumber
                    min={1}
                    max={5}
                    name="verbal"
                    label="Verbal (V)"
                    value={verbal}
                    onValueChange={setVerbal}
                  />
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <InputNumber
                    min={1}
                    max={6}
                    name="motor"
                    label="Motorik (M)"
                    value={motor}
                    onValueChange={setMotor}
                  />
                </Col>
                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                  <Input
                    name="gcs_value"
                    label="Nilai GCS"
                    value={"gcsValue"}
                    onValueChange={setGcsValue}
                    disabled
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Input
                    name="typeof_consciousness"
                    label="Jenis Kesadaran"
                    value={typeOfConsciousness}
                    onValueChange={setTypeofConsciousness}
                    disabled
                  />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Select
                    name="kir"
                    label="KIR Dokter"
                    items={KIRDoctor}
                    value={selectedKirDoctor.value}
                    onValueChange={(v) =>
                      setSelectedKirDoctor({
                        display: v as string,
                        value: v as string,
                      })
                    }
                    filterOption={false}
                  />
                </Col>
              </Row>
              <Textarea name="info" label="Deskripsi pemeriksaan fisik" />
              <Row justify={"end"}>
                <Button className="btn-primary" htmlType="submit">
                  {" "}
                  Next{" "}
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Card title="Pemeriksaan penunjang">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Data Lab" key="1">
                  <Row gutter={10} style={{marginBottom: "2%"}}>
                    <Col xl={7}>
                      <FloatingTextInput
                        name="lab_name"
                        label="Nama Lab"
                        onClick={() => setDataLab(true)}
                      />
                    </Col>
                    <Col xl={7}>
                      <Input name="category" label="Kategory" />
                    </Col>
                    <Col xl={7}>
                      <Input name="normal_value" label="Nilai Normal" />
                    </Col>
                    <Col style={{position: "relative"}}>
                      <AddIconButton
                        style={{
                          position: "absolute",
                          bottom: 3,
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                  <strong>Hasil Pemeriksaan Lanjutan : </strong>
                  <Row
                    style={{
                      margin: "2% 0",
                      padding: "1%",
                      background: "rgb(244,244,244)",
                      borderRadius: "5px",
                    }}
                  >
                    <Col xl={1}>
                      <strong>#</strong>
                    </Col>
                    <Col xl={6}>
                      <strong>Nama</strong>
                    </Col>
                    <Col xl={4}>
                      <strong>Hasil</strong>
                    </Col>
                    <Col xl={5}>
                      <strong>Nilai Normal</strong>
                    </Col>
                    <Col xl={6}>
                      <strong>Ket</strong>
                    </Col>
                  </Row>
                  <div style={{width: "100%"}}>
                    {labPackage.map((item) => (
                      <Row
                        gutter={20}
                        style={{
                          width: "100%",
                          marginTop: "1%",
                          padding: "1%",
                        }}
                        align="middle"
                      >
                        <Col xl={1}>
                          <strong> {item.key}. </strong>
                        </Col>
                        <Col xl={6}>
                          <strong>{item.itemName}</strong>
                        </Col>
                        <Col xl={4}>
                          <strong style={{color: "#27AAE1"}}>
                            {item.result}
                          </strong>
                        </Col>
                        <Col xl={5}>
                          <strong> {item.value}</strong>
                        </Col>
                        <Col xl={6}>
                          <strong>{item.desc}</strong>
                        </Col>
                        <Col xl={2}>
                          <DeleteIconButton
                            style={{cursor: "pointer", width: "30px"}}
                          />
                        </Col>
                      </Row>
                    ))}
                  </div>
                  <Divider />
                </TabPane>
                <TabPane tab="Paket Lab" key="2">
                  <Row gutter={10} style={{marginBottom: "2%"}}>
                    <Col xl={7}>
                      <FloatingTextInput
                        name="lab_package"
                        label="Paket Lab"
                        onClick={() => setDataLab(true)}
                      />
                    </Col>
                    <Col xl={7}>
                      <Input name="desc" label="Keterangan" />
                    </Col>
                    <Col style={{position: "relative"}}>
                      <AddIconButton
                        style={{
                          position: "absolute",
                          bottom: 3,
                          cursor: "pointer",
                        }}
                      />
                    </Col>
                  </Row>
                  <strong>Hasil Pemeriksaan Lanjutan : </strong>
                  <Row
                    style={{
                      margin: "1% 0",
                      padding: "1%",
                      background: "rgb(244,244,244)",
                      borderRadius: "5px",
                    }}
                  >
                    <Col xl={1}>
                      <strong>#</strong>
                    </Col>
                    <Col xl={6}>
                      <strong>Nama</strong>
                    </Col>
                    <Col xl={4}>
                      <strong>Hasil</strong>
                    </Col>
                    <Col xl={5}>
                      <strong>Nilai Normal</strong>
                    </Col>
                    <Col xl={6}>
                      <strong>Ket</strong>
                    </Col>
                  </Row>
                </TabPane>
              </Tabs>
              <Row>
                <Button
                  className="btn-secondary"
                  onClick={() => setReferLabModal(true)}
                >
                  Rujuk Lab
                </Button>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>

      <PatientHistory
        visible={patientHistoryModal}
        setVisible={setPatientHistoryModal}
      />
      <DataLab visible={dataLab} setVisible={setDataLab} />
      <ReferLabModal visible={referLabModal} setVisible={setReferLabModal} />
    </>
  );
};
