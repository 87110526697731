import { Button, message, Modal } from "antd";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import useQueue from "../../../services/queue";
import { activeQueueAtom } from "../../administration/patient/_form";
import { triggerActiveRoom } from "../../layout/mainLayout";

export interface IModal {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const QueueNumberModal: React.FC<IModal> = ({ visible, setVisible }) => {
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const queueService = useQueue();
  const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
  const [triggerQueueNum, setTriggerQueueNum] =
    useRecoilState(triggerActiveRoom);
  const [triggerActiveQueue, setTriggerActiveQueue] =
    useRecoilState(activeQueueAtom); //trigger for reservation button
  const onDelete = () => {
    queueService
      .delete(activeQueue.idQueue)
      .then((res: any) => {
        message.success(res.message);
        localStorage.removeItem("activeQueue");
        setTriggerQueueNum(!triggerQueueNum);
      })

      .catch((err) => console.error(err))
      .finally(() => setVisible(false));
  };

  const onSubmit = () => {
    queueService
      .disableQueueRoom(activeQueue.idRoom)
      .then((res: any) => {
        message.success(res.message);
        localStorage.removeItem("activeQueue");
        setTriggerQueueNum(!triggerQueueNum);
        setTriggerActiveQueue(!triggerActiveQueue); //trigger for reservation button
      })
      .catch((err) => console.error(err, "from queue disable"))
      .finally(() => {
        setVisible(false);
        console.log(params.id);
        if (params.id === "new") {
          history.goBack();
        }
      });
  };

  return (
    <Modal
      title="Pemberitahuan"
      centered
      visible={visible}
      onCancel={() => setVisible(false)}
      width={400}
      footer={[
        <Button onClick={onDelete} className="btn-danger">
          Hapus
        </Button>,
        <Button onClick={() => setVisible(false)} className="btn-light">
          Tidak
        </Button>,
        <Button onClick={onSubmit} className="btn-primary">
          Ya
        </Button>,
      ]}
    >
      Masukan ke daftar antrian kembali?
    </Modal>
  );
};
