import { FC, useState } from 'react'
import { Col,  Row, Menu, Form, Input, Button, Space, Divider, Table, Select, Modal} from 'antd';

import Card from '../../layout/card'
import Title from "../../layout/title";
import NumberFormat from 'react-number-format';

const { Option } = Select

const Selling: FC = () => { // Data Table
    const [loader, setLoader] = useState<boolean>(false)
    const [modalPemeriksaan, setModalPemeriksaan] = useState(false)

    const columns = [
        {
          title: 'nama obat',
          dataIndex: 'medicine',
        },
        {
            title: 'jumlah',
            dataIndex: 'qty',
          },
        {
          title: 'harga',
          dataIndex: 'price',
        },
        {
          title: 'total',
          dataIndex: 'total',
        },
        {
            title: '',
            dataIndex: ''
        }
      ];
      
      const data = [
        {
          key: '1',
          medicine: 'John Brown',
          qty: 10,
          price: 4000,
          total: 40000,
          
        }
      ];
    
      const moneyChanges = () => {
        Modal.success({
          title: 'Kembalian Pembayaran',
          content:(
            <div>
              <span style={{ fontWeight: 'bold', fontSize: '30pt', textAlign: 'right'}}>Rp. 100.000</span>
            </div>
          )
        });
      }
  

    return <div>
        <Title title="Penjualan" description="transaksi penjualan" />
        <Row justify='space-around'>
            <Col xs={24} sm={24} md={16} lg={16}>
              <Card title='penjualan obat/alkes'>
                <Form layout='vertical'>
                  <Row justify='space-between'>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <Form.Item label="nama obat" className='lable-form-input'>
                          <Input type={'text'} className='form-control'></Input>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <Form.Item label="jumlah" className='lable-form-input'>
                          <Input type={'text'} className='form-control'></Input>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6}>
                      <Form.Item label="harga obat/alkes" className='lable-form-input'>
                          <Input type={'text'} className='form-control'></Input>
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={5} lg={5}>
                      <Form.Item label=" " className='lable-form-input'>
                        <Space>

                          <Button type='primary'>Proses</Button>
                          <Button type='link'>Batal</Button>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>

              <Card>
                <Table
                  columns={columns}
                  dataSource={data}
                  pagination={false}
                >

                </Table>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={7} lg={7}>
              <Card title='pembayaran'>
                <Form layout='vertical'>
                  <Form.Item label="nomor penjualan" className='lable-form-input'>
                      <Input type={'text'} className='form-control'></Input>
                  </Form.Item>
                  <Form.Item label="nama pembeli" className='lable-form-input'>
                      <Input type={'text'} className='form-control'></Input>
                  </Form.Item>
                  <Form.Item label="total order (Rp.)" className='lable-form-input'>
                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                  </Form.Item>
                  <Form.Item label="diskon (Rp.)" className='lable-form-input'>
                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                  </Form.Item>
                  <Form.Item label="grand total (Rp.)" className='lable-form-input'>
                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                  </Form.Item>
                  <Form.Item label="bayar (Rp.)" className='lable-form-input'>
                      <NumberFormat thousandSeparator={true}  className='form-control-currency' />
                  </Form.Item>

                    <Form.Item label="bayar" className='lable-form-input'>
                      <Select defaultValue="Tunai" className='form-control' allowClear>
                        <Option value="0">Tunai</Option>
                        <Option value="1">EDC BNI</Option>
                        <Option value="2">Debit BNI</Option>
                        <Option value="3">Debit Mandiri</Option>
                        <Option value="4">Transfer</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item label="bank" className='lable-form-input'>
                      <Select defaultValue="BNI" className='form-control' allowClear>
                        <Option value="0">Mandiri</Option>
                        <Option value="1">BNI</Option>
                      </Select>
                    </Form.Item>

                    <Form.Item label="Nomor Rekening" className='lable-form-input'>
                      <Input type={'text'} className='form-control'></Input>
                    </Form.Item>

                    <Form.Item label="Keterangan" className='lable-form-input'>
                      <Input type={'text'} className='form-control'></Input>
                    </Form.Item>

                  <Space>
                    <Button type='link' >Batal</Button>
                    <Button type='primary' style={{ backgroundColor: '#21D47E' }} onClick={moneyChanges}>Proses Bayar</Button>
                  </Space>
                </Form>
              </Card>
            </Col>
        </Row>
    </div>
}

export default Selling