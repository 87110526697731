
import React, { FC } from 'react'
import { Card as CardDisplay, Typography } from 'antd'

export interface CardProps {
    title: string
    stepTitle: string
    description?: string
}

const TitleWithStep: FC<CardProps> = (props) => {

    const { title, description, stepTitle } = props

    return (
        <div style={{ marginBottom: 30 }}>
            <Typography.Title level={2} style={{ marginBottom: 0, fontWeight: 'bold' }}>{title}</Typography.Title>
            <div style={{ display: 'flex' }}>
                <strong style={{ color: '#21D47E', marginRight: '1%' }}>{stepTitle} - </strong>
                <p>{description}</p>
            </div>
        </div>
    )
}

export default TitleWithStep