
import { baseURL, id } from "../baseurl";
import { PaginationData } from "../utility";
import { Unit } from "./units";

export interface MedicineDetail {
    id?: string
    inc_id?: number
    unit: Unit
    smallest_unit: number
    primary: boolean
    price_selling: number
    price_purchase: number

}

const MedicineDetailClient = baseURL
const clinicId = id

const useMedicineDetail = (medicineId?: string) => ({
    async create(medicineId: string, MedicineDetail: MedicineDetail): Promise<MedicineDetail>{
        const {data} = await MedicineDetailClient.post(`medicine/${medicineId}/munit`, MedicineDetail)
        return data
    },
    async update(id: number, MedicineDetail: MedicineDetail): Promise<MedicineDetail>{
        await MedicineDetailClient.patch(`/medicine/${medicineId}/munit/${id}`, MedicineDetail)
        return MedicineDetail
    },
    async delete(id: number): Promise<void>{
        const {data} = await MedicineDetailClient.delete(`/medicine/${medicineId}/munit/${id}`)
        return data
    },
    async get(id: number): Promise<MedicineDetail>{
        const {data} = await MedicineDetailClient.get(`/medicine/${medicineId}/munit/${id}`)
        return data
    },
    async fetch(search: string, size: number, row: number): Promise<PaginationData<MedicineDetail[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        
        const { data } = await MedicineDetailClient.get(`/medicine/${medicineId}/munit?${searching}&${sizes}&${rows}`)
        return data
    }

})

export default useMedicineDetail