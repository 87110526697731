import React, { FC } from 'react'
import { Input as InputForm, Form } from 'antd'

export interface OnInputValueChange {
    (value: string): void
}

export interface InputProps {
    name: string
    label: string
    value?: string
    onValueChange?: OnInputValueChange
    className?: string
    disabled?: boolean
    styles?: React.CSSProperties | undefined
    autoFocus?: boolean
}

const Textarea: FC<InputProps> = (props) => {

    const onChange = (e: any) => {
        props.onValueChange && props.onValueChange(e.target.value)
    }

    return (
        <Form.Item label={props.label} name={props.name} style={{ fontWeight: 'bold' }}>
            {props.autoFocus
                ? <InputForm.TextArea autoFocus disabled={!props.disabled ? false : true} name={props.name} value={props.value} onChange={onChange} style={{ borderRadius: 5, border: `${props.disabled ? '1px solid #d9d9d9' : '1px solid rgba(20, 155, 212, 0.5)'}` }} className={props.className} />
                : <InputForm.TextArea disabled={!props.disabled ? false : true} name={props.name} value={props.value} onChange={onChange} style={{ borderRadius: 5, border: `${props.disabled ? '1px solid #d9d9d9' : '1px solid rgba(20, 155, 212, 0.5)'}` }} className={props.className} />
            }
        </Form.Item>
    )
}

export default Textarea