import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Modal, Row, Typography } from 'antd';
import Title from '../../layout/title'
import Card from '../../layout/card';
import { PaginationData } from '../../../services/utility';
import { successNotification } from '../../layout/notification'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { FormOutlined, DeleteOutlined } from '@ant-design/icons';
import DataTable from 'react-data-table-component';

import { FilterComponent2 } from '../../layout/fiterDataTable';
import useInsurance, { InsuranceInterface } from '../../../services/configurations/insurance';

const { confirm } = Modal


export const InsuranceComponent = () => {
  // Data Table
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
        loadInsurance();
      }
    };

    return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
  }, [filterText, resetPaginationToggle]);

  
  //Global Variable
  const history = useHistory()

  const params = useParams<any>()
  const [Insurance, setInsurance] = useState<PaginationData<InsuranceInterface[]>>({data: [], pagination: {}})
  const filteredItems = Insurance.data.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const InsuranceServices = useInsurance()
  const [loader, setLoader] = useState<boolean>(false)

  //Form Variabel
  const [ form, setForm ] = useState<InsuranceInterface>({
    name: ''
  })

  const resetField = () => {
    setForm({
      name: '', 
    } as any)
  }
 
  const columns = [
    {
      name: <Typography.Text strong>nama</Typography.Text>,
      selector: 'name',
      sortable: true,
    },
    {
      name:  <Typography.Text strong>aksi</Typography.Text>,
      button: true,
      cell: (row: any) => {
          return ( 
              <>
                  <Button id="insurance-edit" type="text" onClick={ () => { history.push(`/insurance/${row.id}`); window.location.reload()} }><FormOutlined /></Button>
                  <Button danger id='insurance-delete' type="text" onClick={ () => deleteData(row.id) }><DeleteOutlined /></Button>
              </>
          )
      }
    },
  ];

  const getInsurance = () => {
    setLoader(true)
    InsuranceServices
        .get(params.id)
        .then(form => {
            setForm(form)
        }
        )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
}


  const loadInsurance = () => {
    setLoader(true)
    InsuranceServices
        .fetch('', perPage, page)
        .then(Insurance => {
            setInsurance(Insurance)
            let total = (Insurance.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
    }

  
  
  const filterData = (searhing: string) => {
    setLoader(false)
    setFilterText(searhing)
    InsuranceServices
        .fetch(searhing, perPage, 1)
        .then(Insurance => {
            setInsurance(Insurance)
            let total = (Insurance.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }
  
  const handlePageChange = (pages:number) => {
    setPage(pages)
    setLoader(true)
    InsuranceServices
        .fetch('', perPage, pages)
        .then(Insurance => {
            setInsurance(Insurance)
            let total =  (Insurance.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  };

  const handlePerRowsChange = (newPerPage: number, pages: number) => {
    setPerPage(newPerPage)
    setPage(pages)
    setLoader(true)
    InsuranceServices
        .fetch('', newPerPage, pages)
        .then(Insurance => {
            setInsurance(Insurance)
            let total = (Insurance.pagination.total_page as number)
            setTotalRows(total)
          }
          )
        .catch(e => console.log(e))   
        .finally(() => setLoader(false))
  }

  const deleteData = (id: string) => {
    confirm({
      title: 'Serius Ingin Hapus Data ini?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        setLoader(true)
        InsuranceServices
            .delete(id)
            .then(() => {
                loadInsurance()
                successNotification('hapus')
              })
            .catch(e => console.log(e))
            .finally(()=> setLoader(false))
      },
      onCancel() {
        console.log('Cancel');
      },
    });
    
  }

  const submit = () => {
    // console.log(form)
          
      !!params.id === false  && InsuranceServices
      .create(form)
      .then(() => { 
          loadInsurance() 
          successNotification('tambah')
          resetField()
      })
      .catch(e => console.log(e))
      .finally(()=> setLoader(false))
      
      !!params.id === true && InsuranceServices
          .update(params.id, form)
          .then(() => { 
              loadInsurance() 
              successNotification('perbaharui')
              window.location.href = "/insurance"
          })
          .catch(e => console.log(e))
          .finally(()=> setLoader(false))
  }


    useEffect(() => {
        loadInsurance();
        console.log(params.id)
        console.log(!!params.id);
        !!params.id === true && 
            setForm(form)
        !!params.id === true && getInsurance()
    }, [])


  return (<>
    <Title title="Asuransi" description="daftar data type asuransi" />
    <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="tambah tipe asuransi" >
                    <Form layout="vertical">
                        <Form.Item label="nama" className='lable-form-input'>
                            <Input data-cy="name_insurance" value={form.name} name="name_insurance"  onChange={({target : {value: name}}) => setForm({...form, name})} className="form-control" />
                        </Form.Item>
                    </Form>
                    <Button type="primary" className='btn_submit' onClick={submit}>
                        Simpan
                    </Button>

                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                {
                    loader && <div>Loading .... </div>
                }
                <Card title="data tipe asuransi">
                  <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      striped={true}
                      highlightOnHover={true}
                      noHeader={true}
                      className='data-table'
                  />
                </Card>
            </Col>
        </Row>
    </>
  );
};
