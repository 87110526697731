import React from 'react'
import { DataQueueNumber } from '../../../components/queue/QueueNumber'

export const QueueNumber = () => {
    return (
        <div>
            <DataQueueNumber />
        </div>
    )
}
