import React, { FC } from 'react'
import DataPartnerCompany from '../../../components/management/partner_company'


const PartnershipsPage: FC = () => {
    return <div>
       <DataPartnerCompany/>
    </div>
}

export default PartnershipsPage