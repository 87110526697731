import { useHistory } from "react-router-dom";

export interface IState {
  username: string | null;
  nickname: string | null;
  token: string | null;
  roles: {
    name: string | null;
  }[];
  isAuthenticated: boolean;
}

export interface IAction {
  type: "LOGIN" | "LOGOUT";
  payload?: any;
}

export const AuthReducer = (state: IState, action: IAction) => {
  const history = useHistory();
  switch (action.type) {
    case "LOGIN":
      localStorage.setItem("username", action.payload.username);
      localStorage.setItem("nickname", action.payload.nickname);
      localStorage.setItem("token", action.payload.token);
      for (let i in action.payload.roles) {
        localStorage.setItem(
          `role_name_${parseInt(i) + 1}`,
          action.payload.roles[i].name
        );
      }
      localStorage.setItem(
        "isAuthenticated",
        JSON.stringify(action.payload.isAuthenticated)
      );
      return {
        ...state,
        isAuthenticated: true,
        username: action.payload.username,
        token: action.payload.token,
      };
    case "LOGOUT":
      localStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        username: null,
        token: null,
      };
    default:
      return state;
  }
};
