import React from "react";
import {Link as RouterLink, useLocation} from "react-router-dom";
import {Menu} from "antd";

export const NavQueue: React.FC = () => {
  const location = useLocation();
  return (
    <>
      <Menu mode="horizontal" defaultSelectedKeys={["1"]} className="menu">
        <Menu.Item key="1" disabled={location.pathname.includes("/queue-list")}>
          <RouterLink to="/queue-list" key="1">
            Daftar Antrian
          </RouterLink>
        </Menu.Item>
        <Menu.Item key="2" disabled={location.pathname.includes("/anamnesis")}>
          <RouterLink to="/anamnesis" key="2">
            Anamnesa
          </RouterLink>
        </Menu.Item>
        <Menu.Item
          key="3"
          disabled={location.pathname.includes("/checkup-queue")}
        >
          <RouterLink to="/checkup-queue" key="3">
            Daftar Pemeriksaan
          </RouterLink>
        </Menu.Item>
        <Menu.Item key="4" disabled={location.pathname.includes("/reg-queue")}>
          <RouterLink to="/reg-queue" key="4" target={"_blank"}>
            Antrian Display
          </RouterLink>
        </Menu.Item>
        <Menu.Item key="5" disabled={location.pathname.includes("/display-tv")}>
          <RouterLink to="/display-tv" key="5" target={"_blank"}>
            TV Display
          </RouterLink>
        </Menu.Item>
        <Menu.Item key="6" disabled={location.pathname.includes("/reservasi")}>
          <RouterLink to="/reg-queue" key="6" target={"_blank"}>
            Antrian Online
          </RouterLink>
        </Menu.Item>
      </Menu>
    </>
  );
};
