import { Data } from "@react-google-maps/api";
import { Spin } from "antd";

import React, { useState } from "react";
import { CurrentTime } from "./CurrentTime";

import styles from "./index.module.css";

export const DataDisplayTV: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Spin size="large" spinning={isLoading}>
      <div className={styles.layout}>
        <div className={styles.layout__left}>
          <div className={styles.layout__left__top}>
            <h2>Nomor Antrian</h2>
            <h1>A001</h1>
            <span>Neng Farida Nurasia</span>
          </div>
          <div className={styles.layout__left__middle}>
            <h3>Poli Umum</h3>
          </div>
          <div className={styles.layout__left__bottom}>
            <CurrentTime />
          </div>
        </div>
        <div className={styles.layout__right}>
          <div className={styles.layout__right__top}></div>
          <div className={styles.layout__right__bottom}>
            <div className={styles.layout__right__bottom__queue_container}>
              <div
                className={
                  styles.layout__right__bottom__queue_container__order_number
                }
              >
                1
              </div>
              <div
                className={
                  styles.layout__right__bottom__queue_container__queue_number
                }
              >
                A002
              </div>
              <div
                className={styles.layout__right__bottom__queue_container__name}
              >
                Opang Suropang
              </div>
            </div>
            <div className={styles.layout__right__bottom__queue_container}>
              <div
                className={
                  styles.layout__right__bottom__queue_container__order_number
                }
              >
                2
              </div>
              <div
                className={
                  styles.layout__right__bottom__queue_container__queue_number
                }
              >
                B001
              </div>
              <div
                className={styles.layout__right__bottom__queue_container__name}
              >
                Reksa Mulya Fitra Nanda
              </div>
            </div>
            <div className={styles.layout__right__bottom__queue_container}>
              <div
                className={
                  styles.layout__right__bottom__queue_container__order_number
                }
              >
                3
              </div>
              <div
                className={
                  styles.layout__right__bottom__queue_container__queue_number
                }
              >
                A003
              </div>
              <div
                className={styles.layout__right__bottom__queue_container__name}
              >
                Nanda Tri Multi
              </div>
            </div>
            <div className={styles.layout__right__bottom__queue_container}>
              <div
                className={
                  styles.layout__right__bottom__queue_container__order_number
                }
              >
                4
              </div>
              <div
                className={
                  styles.layout__right__bottom__queue_container__queue_number
                }
              >
                C001
              </div>
              <div
                className={styles.layout__right__bottom__queue_container__name}
              >
                Fiqri Rahardian
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
