import { Form, Select as SelectForm } from 'antd'
import React, { FC } from 'react'

const { Option } = SelectForm;

export interface OnSelectValueChange {
    (value: string | number): void
}

export interface OnSelectSearch {
    (value: string): void
}

export interface SelectItem {
    value: number | string
    display: string
}

export interface SelectProps {
    label: string
    name: string
    value?: string | number
    items: SelectItem[]
    onValueChange?: OnSelectValueChange
    showSearch?: boolean
    filterOption?: boolean
    onSearch?: OnSelectSearch
}

const Select: FC<SelectProps> = (props) => {
    const {
        label, items, onValueChange, showSearch, name, onSearch, filterOption, value
    } = props

    const onChange: OnSelectValueChange = (value) => {
        onValueChange && onValueChange(value)
    }

    return (
        <Form.Item label={label} name={name} style={{ marginBottom: 10, fontWeight: 'bold' }}>
            <SelectForm showSearch={showSearch} filterOption={filterOption} onSearch={onSearch} onChange={onChange} style={{ fontWeight: 'normal', border: '1px solid rgba(20, 155, 212, 0.5)', borderRadius: 7 }} defaultValue={value}>
                {
                    items.map((v: SelectItem, i: number) => (
                        <Option key={i} value={v.value}>{v.display}</Option>
                    ))
                }
            </SelectForm>
        </Form.Item>
    )
}

export default Select
