import {Patient} from "../../pages/administration/patient";
import {FormPatient} from "../../components/administration/patient/_form";
import {Coba} from "../../pages/coba";
import {FormAnamnesa} from "../../components/administration/anamnesa/_form";
import {RegQueue} from "../../pages/administration/reg_queue";
import {Anamnesa} from "../../components/administration/anamnesa";
import {CheckupQueue} from "../../pages/administration/queue/CheckupQueue";
import {Visit} from "../../pages/visit";
import {ResultVisit} from "../../components/visit/ResultVisit";
import {QueueNumber} from "../../pages/administration/queue/QueueNumber";
import {blankLayout} from "../../components/layout/blankLayout";
import QueueList from "../../pages/administration/queue";
import DashboardPage from "../../pages/administration/dashboard";
import DetailReferencePage from "../../pages/administration/reference.detail";
import ReferencePage from "../../pages/administration/reference";
import ReservationPage from "../../pages/administration/reservation/reservation";
import OnlinePage from "../../pages/administration/online";
import LetterStatementPage from "../../pages/administration/letter/letter.statement";
import SickLetterPage from "../../pages/administration/letter/letter.sick";
import KIRLetterPage from "../../pages/administration/letter/letter.kir";
import MobilePoliesPage from "../../pages/administration/mobile.polies";
import MobileClosePage from "../../pages/administration/mobile.close";
import MobileSchedulePage from "../../pages/administration/mobile.schedule";
import RegisBPJSPage from "../../pages/administration/bpjs/register";
import VisitBPJSPage from "../../pages/administration/bpjs/visit";
import HistoryVisitPage from "../../pages/administration/visit.history";
import TreatmentPage from "../../pages/management/treatment/treatment";
import TreatmentFormPage from "../../pages/management/treatment/_form";
import PrbPage from "../../pages/management/prb/prb";
import PrbFormPage from "../../pages/management/prb/prb.form";
import PolyPage from "../../pages/management/poly/poly";
import PolyFormPage from "../../pages/management/poly/poly.form";
import PublicHealthPage from "../../pages/management/public-health/public.health";
import PublicHealthFormPage from "../../pages/management/public-health/public.health.form";
import PublicHealthFormDetailPage from "../../pages/management/public-health/public.health.form.detail";
import PrintQueuePage from "../../pages/administration/queue/print.queue";
import PrintQueueOnlinePage from "../../pages/administration/queue/print.queue.online";
import PrintMedicalRecordPage from "../../pages/administration/visit/print.medical.record";
import MainLayout from "../../components/layout/mainLayout";
import { RouterInterface } from "../router.interface";

export const NurseRoute: RouterInterface[] = [
  {
    component: DashboardPage,
    layout: MainLayout,
    path: '/',
    exact: true
  },
  {
    component: RegQueue,
    layout: blankLayout,
    path: '/reg-queue',
    exact: true
  },
  {
    component: PrintQueuePage,
    layout: blankLayout,
    path: '/queue-print',
    exact: true
  },
  {
    component: PrintQueueOnlinePage,
    layout: blankLayout,
    path: '/queue-online-print',
    exact: true
  },
  {
    component: QueueNumber,
    layout: blankLayout,
    path: '/queue-number/:id',
    exact: true
  },
  {
    component: QueueList,
    layout: MainLayout,
    path: '/queue-list',
    exact: true
  },
  {
    component: CheckupQueue,
    layout: MainLayout,
    path: '/checkup-queue',
    exact: true
  },
  {
    component: Coba,
    layout: MainLayout,
    path: '/coba',
    exact: true
  },
  {
    component: FormPatient,
    layout: MainLayout,
    path: '/patients/:id',
    exact: true
  },
  {
    component: Patient,
    layout: MainLayout,
    path: '/patients',
    exact: true
  },
  {
    component: FormAnamnesa,
    layout: MainLayout,
    path: '/anamnesis/:id',
    exact: true
  },
  {
    component: Anamnesa,
    layout: MainLayout,
    path: '/anamnesis',
    exact: true
  },
  {
    component: ResultVisit,
    layout: MainLayout,
    path: '/visit/:id',
    exact: true
  },
  {
    component: PrintMedicalRecordPage,
    layout: blankLayout,
    path: '/visit-print/',
    exact: true
  },
  {
    component: Visit,
    layout: MainLayout,
    path: '/visit',
    exact: true
  },
  {
    component: HistoryVisitPage,
    layout: MainLayout,
    path: '/list-checkup',
    exact: true
  },
  {
    component: DetailReferencePage,
    layout: MainLayout,
    path: '/reference/:id',
    exact: true
  },
  {
    component: ReferencePage,
    layout: MainLayout,
    path: '/reference',
    exact: true
  },
  {
    component: ReservationPage,
    layout: MainLayout,
    path: '/reservation',
    exact: true
  },
  {
    component: OnlinePage,
    layout: MainLayout,
    path: '/register-online',
    exact: true
  },
  
  {
    component: LetterStatementPage,
    layout: MainLayout,
    path: '/statement-letter',
    exact: true
  },
  
  {
    component: SickLetterPage,
    layout: MainLayout,
    path: '/sick-letter',
    exact: true
  },
  {
    component: KIRLetterPage,
    layout: blankLayout,
    path: '/kir-letter/:id',
    exact: true
  },
  {
    component: KIRLetterPage,
    layout: MainLayout,
    path: '/kir-letter',
    exact: true
  },
  {
    component: MobilePoliesPage,
    layout: MainLayout,
    path: '/mobile-polies',
    exact: true
  },
  {
    component: MobileClosePage,
    layout: MainLayout,
    path: '/mobile-close',
    exact: true
  },
  {
    component: MobileSchedulePage,
    layout: MainLayout,
    path: '/mobile-schedule',
    exact: true
  },
  {
    component: RegisBPJSPage,
    layout: MainLayout,
    path: '/register-bpjs',
    exact: true
  },
  {
    component: VisitBPJSPage,
    layout: MainLayout,
    path: '/visit-bpjs',
    exact: true
  },
  {
    component: PrbFormPage,
    layout: MainLayout,
    path: '/prb/:id',
    exact: true
  },
  {
    component: PrbPage,
    layout: MainLayout,
    path: '/prb',
    exact: true
  },
  {
    component: TreatmentFormPage,
    layout: MainLayout,
    path: '/treatment/:id',
    exact: true
  },
  {
    component: TreatmentPage,
    layout: MainLayout,
    path: '/treatment',
    exact: true
  },
  {
    component: PolyFormPage,
    layout: MainLayout,
    path: '/polies/:id',
    exact: true
  },
  {
    component: PolyPage,
    layout: MainLayout,
    path: '/polies',
    exact: true
  },
  {
    component: PublicHealthFormDetailPage,
    layout: MainLayout,
    path: '/public-health/:id/village',
    exact: true
  },
  {
    component: PublicHealthFormPage,
    layout: MainLayout,
    path: '/public-health/:id',
    exact: true
  },
  {
    component: PublicHealthPage,
    layout: MainLayout,
    path: '/public-health',
    exact: true
  }
]