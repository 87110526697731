
import React, { FC } from 'react'
import DataUnit from '../../components/pharmacy/unit'

const Unit: FC = () => {
    return <div>
        <DataUnit/>
    </div>
}

export default Unit