import { Col, Row, Table, Typography } from "antd"
import { FC } from "react"
import DataTable from "react-data-table-component";

const { Text } = Typography


const ResultTreatment: FC = () => {
    const customStyles = {
      rows : {
          style : {
              border: '1px solid #edf0f2'
          }
      },
      headCells: {
          style: {
              backgroundColor: '#108ee9',
              color: '#fff',
              border: '1px solid #edf0f2'

          },
      },
      cells : {
          style : {
              border: '1px solid #edf0f2'
          }
      }
  };

  const columns = [
      {
        name: 'Nama Tindakan',
        selector: (row: any) => <Text strong>{row.name}</Text>,
      }, 
      {
        name: 'Keterangan',
        selector: (row: any) => <Text strong>{row.info}</Text>,
      }
    ]
  const data = [
    {
      key: '1',
      name: 'Hecting',
      info : 'bla-bla'
      
    }
  ];

    
    return <>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ padding: 10}}>
            <DataTable
                  data={data}
                  columns={columns}
                  pagination={false}
                  style={{ marginTop: '-4em'}}
                  customStyles={customStyles}
              />
          </Col>
    </>
}

export default ResultTreatment