import { Col, Input, Row, Table, Typography } from "antd";
import { FC } from "react";

const { Text } = Typography

const Consultant: FC = () => {
    const columns = [
        {
            title: 'Nama Biaya',
            render: (row: any) => <Text strong>{row.name}</Text>
        },
        {
            title: 'Jumlah',
            render: (row: any) => <Input value={row.price} disabled={(row.name === 'Total') ? true : false}></Input>
        },
    ]

    const rows = [
        {
            id: 1,
            name: 'Konsultasi Dokter',
            price: 30000
        },
        {
            id: 2,
            name: 'Biaya Administrasi',
            price: 10000
        },
        {
            name: 'Total',
            price: 40000
        }
    ]

    return <>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{ paddingLeft: 10, marginBottom: 30 }}>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Table
                        columns={columns}
                        dataSource={rows}
                        pagination={false}
                    ></Table>
                </Col>
            </Row>
        </Col>
    </>
}

export default Consultant