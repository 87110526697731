import React, { FC } from "react";
import { InputNumber as InputForm, Form } from "antd";

export interface OnInputValueChange {
  (value: number): void;
}

export interface InputProps {
  label: string;
  value?: number;
  name: string;
  onValueChange?: OnInputValueChange;
  className?: string;
  placeholder?: string;
  unit?: string;
  disabled?: boolean;
  step?: number;
}

export const InputWithUnit: FC<InputProps> = (props) => {
  const onChange = (value: any) => {
    props.onValueChange && props.onValueChange(value);
  };

  return (
    <Form.Item
      label={props.label}
      name={props.name}
      style={{ fontWeight: "bold", marginBottom: 10, position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          top: "10%",
          right: "5%",
          background: "#FFF",
          zIndex: 1,
          color: "#41404280",
          fontWeight: "normal",
          fontSize: "1em",
        }}
      >
        {props.unit}
      </div>

      <InputForm
        disabled={props.disabled}
        name={props.name}
        placeholder={props.placeholder}
        style={{
          width: "100%",
          border: `${
            props.disabled
              ? "1px solid #d9d9d9"
              : "1px solid rgba(20, 155, 212, 0.5)"
          }`,
          borderRadius: 7,
          fontSize: "1.1em",
        }}
        value={props.value}
        onChange={onChange}
        className={props.className}
        step={props.step ? props.step : 1}
      />
    </Form.Item>
  );
};
