import { Input } from "antd";

import './main.css'
import { SearchIconButton } from "../form/icon-button/SearchIconButton";

// full page 1 card
export const FilterComponent = ({ filterText, onFilter }: any) => (
  <>

    <div className="filter-data-table">
      <Input placeholder="Cari Data" value={filterText} onChange={onFilter} className="btn-search-full form-control" suffix={ <SearchIconButton style={{ color: '#1890ff'}} /> }  style={{ border: '1px solid rgba(20, 155, 212, 0.5)'  }}  />
      </div>
  </>
);

// 2 colom / 2 card
export const FilterComponent2 = ({ filterText, onFilter }: any) => (
  <>
      <div style={{ width: '40%'}}>
        <Input placeholder="Cari Data" value={filterText} onChange={onFilter} className="btn-search-full2 form-control" suffix={ <SearchIconButton style={{ color: '#1890ff' }} /> }   style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}  />
      </div>
  </>
);

export const FilterComponent3 = ({ filterText, onFilter }: any) => (
  <>
        <Input placeholder="Cari Data" value={filterText} onChange={onFilter} className="btn-search-full3 form-control" suffix={ <SearchIconButton style={{ color: '#1890ff' }} /> }   style={{ border: '1px solid rgba(20, 155, 212, 0.5)' }}  />
  </>
);


export const FilterComponent4 = ({ filterText, onFilter }: any) => (
  <>
        <Input placeholder="Cari Data" value={filterText} onChange={onFilter} className="form-control" suffix={ <SearchIconButton style={{ color: '#1890ff' }} /> }   style={{ border: '1px solid rgba(20, 155, 212, 0.5)'}}  />
  </>
);