import React from "react";
import Card from "../../layout/card";

interface IStepNavigation {
  activeSteps: number;
}

export const StepNavigation: React.FC<IStepNavigation> = ({activeSteps}) => {
  return (
    <Card customeMinHeight={"100px"}>
      {/* Step Navigation */}
      <div className="step_wrapper">
        <div className="divider"></div>
        <div className={`step_block ${activeSteps === 1 ? "selected" : ""}`}>
          <div className="circle_wrapper">
            <div className="circle">1</div>
          </div>
          <span>kunjungan dan keluhan awal</span>
        </div>
        <div className={`step_block ${activeSteps === 2 ? "selected" : ""}`}>
          <div className="circle_wrapper">
            <div className="circle">2</div>
          </div>
          <span>pemeriksaan fisik dan penunjang</span>
        </div>
        <div className={`step_block ${activeSteps === 3 ? "selected" : ""}`}>
          <div className="circle_wrapper">
            <div className="circle">3</div>
          </div>
          <span>diagnosa pasien</span>
        </div>
        <div className={`step_block ${activeSteps === 4 ? "selected" : ""}`}>
          <div className="circle_wrapper">
            <div className="circle">4</div>
          </div>
          <span>tindakan dan resep obat</span>
        </div>
        <div className={`step_block ${activeSteps === 5 ? "selected" : ""}`}>
          <div className="circle_wrapper">
            <div className="circle">5</div>
          </div>
          <span>Hasil Pemeriksaan</span>
        </div>
      </div>
      {/* End of Step Navigation */}
    </Card>
  );
};
