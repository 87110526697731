import React, { FC, useState } from "react"
import { useLocation } from 'react-router-dom'
import { MedisyIcon } from "../form/icon-button/MedisyIcon"
import { MenuIconButton } from "../form/icon-button/MenuIconButton"

import NavUser from "./nav.user"
import { QueueNumberModal } from "../form/modal/QueueNumberModal"
import { NavExtras } from "./top-header/administration/nav.extras"
import NavRegion from "./top-header/nav.region"
import { NavQueue } from "./top-header/administration/nav.queue"
import { NavRegistration } from "./top-header/administration/nav.registration"
import { NavLetter } from "./top-header/administration/nav.letter"
import { NavMobile } from "./top-header/administration/nav.mobile"
import { NavBpjs } from "./top-header/administration/nav.bpjs"
import { NavVisit } from "./top-header/administration/nav.visit"
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons'
import { NavQueueDoctor } from "./top-header/doctor/nav.queue"
import { NavReportDoctor } from "./top-header/doctor/nav.report"
import { NavManagement } from "./top-header/management/nav.management"
import { NavConfigClinic } from "./top-header/management/nav.clinic"
import { NavConfigAdmin } from "./top-header/administration/nav.configuration"
import { NavConfigPharmacy } from "./top-header/pharmacy/nav.config.pharmacy"
import { NavInventoryPharmacy } from "./top-header/pharmacy/nav.inventory.pharmacy"
import { NavSellingCashier } from "./top-header/cashier/nav.selling"
import { NavConfigGlobalClinic } from "./top-header/management/nav.config.global"


const usePathname = () => {
    const location = useLocation();
    return location.pathname;
}

interface INavbar {
    collapsed: boolean
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
    showDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const Navbar = ({ collapsed, setCollapsed, showDrawer }: INavbar) => {
    const [visible, setVisible] = useState(false)
    var topNav;

    if (usePathname() === "/province") topNav = <NavRegion />
    if (usePathname() === "/province/:id") topNav = <NavRegion />
    if (usePathname() === "/cities") topNav = <NavRegion />
    if (usePathname() === "/cities/:id") topNav = <NavRegion />
    if (usePathname() === "/village") topNav = <NavRegion />
    if (usePathname() === "/village/:id") topNav = <NavRegion />
    if (usePathname() === "/district") topNav = <NavRegion />
    if (usePathname() === "/district/:id") topNav = <NavRegion />

   

    // if (usePathname() === "/dashboard") topNav = <NavRegistration />

    const HeaderAdmin = () => {
    // menu antrian
        if (usePathname().includes("/queue-list")) topNav = <NavQueue />
        if (usePathname().includes("/anamnesis")) topNav = <NavQueue />
        if (usePathname().includes("/checkup-queue")) topNav = <NavQueue />

        if (usePathname().includes("/patients")) topNav = <NavRegistration />
        if (usePathname().includes("/patients/1")) topNav = <NavVisit />
        if (usePathname().includes("/reservation")) topNav = <NavRegistration />
        if (usePathname().includes("/reference")) topNav = <NavRegistration />
        if (usePathname().includes("/visit")) topNav = <NavRegistration />
        if (usePathname().includes("/visit/1")) topNav = <NavVisit />
        if (usePathname().includes("/list-checkup")) topNav = <NavVisit />
        if (usePathname().includes("/reg-queue")) topNav = <NavQueue />

        if (usePathname().includes("/statement-letter")) topNav = <NavLetter />
        if (usePathname().includes("/sick-letter")) topNav = <NavLetter />
        if (usePathname().includes("/kir-letter")) topNav = <NavLetter />


        if (usePathname().includes("/mobile-polies")) topNav = <NavMobile />
        if (usePathname().includes("/mobile-close")) topNav = <NavMobile />
        if (usePathname().includes("/mobile-schedule")) topNav = <NavMobile />
        if (usePathname().includes("/mobile-report")) topNav = <NavMobile />


        if (usePathname().includes("/register-bpjs")) topNav = <NavBpjs />
        if (usePathname().includes("/visit-bpjs")) topNav = <NavBpjs />


        if (usePathname().includes("/treatment")) topNav = <NavConfigAdmin />
        if (usePathname().includes("/prb")) topNav = <NavConfigAdmin />
        if (usePathname().includes("/polies")) topNav = <NavConfigAdmin />
        if (usePathname().includes("/public-health")) topNav = <NavConfigAdmin />
        if (usePathname().includes("/bridging-pcare")) topNav = <NavConfigAdmin />
    }



    const HeaderManagement = () => {
        if (usePathname().includes("/icd")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/icd/1")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/bridging-pcare")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/insurance")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/public-health")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/partnerships")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/prb")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/hospital")) topNav = <NavConfigGlobalClinic />
        if (usePathname().includes("/location")) topNav = <NavConfigGlobalClinic />
        
        if (usePathname().includes("/treatment")) topNav = <NavConfigClinic />
        if (usePathname().includes("/treatment/1")) topNav = <NavConfigClinic />
        if (usePathname().includes("/polies")) topNav = <NavConfigClinic />
        if (usePathname().includes("/cost-administration")) topNav = <NavConfigClinic />

    }


    const HeaderPharmacy = () => {
        if (usePathname().includes("/unit")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/group")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/category")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/chest")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/supplier")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/medicine")) topNav = <NavConfigPharmacy />
        if (usePathname().includes("/location")) topNav = <NavConfigPharmacy />

        if (usePathname().includes("/order")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/receive")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/moving")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/release")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/stock-opname")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/inventory-stock")) topNav = <NavInventoryPharmacy />
        if (usePathname().includes("/adjustment/1")) topNav = <NavInventoryPharmacy />
    }

    const HeaderDoctor = () => {}

    const HeaderCashier = () => {
        if (usePathname().includes("/selling")) topNav = <NavSellingCashier />
        if (usePathname().includes("/list-selling")) topNav = <NavSellingCashier />
    }

    const roles = localStorage.getItem('role_name_1')
    
    if(roles === 'Dokter') {
        HeaderDoctor()
    }else if(roles === 'perawat' || roles === 'admin'){
        HeaderAdmin()
    } else if (roles === 'manajemen'){
        HeaderManagement()
    } else if (roles === 'apotek' || roles === 'gudang') {
        HeaderPharmacy()
    }
    else {
        HeaderCashier()
    }

    //menu extras
    if (usePathname().includes("/tv-display")) topNav = <NavExtras />

    return (
        <div className="top_navbar_container">
            <div className="top_navbar_container__left">
                <MenuIconButton className="top_navbar_container__left_menu" onClick={() => showDrawer((prev: boolean) => !prev)} style={{ cursor: 'pointer', width: '50px' }} />
                <div style={{ cursor: 'pointer' }} onClick={() => setCollapsed(!collapsed)}>
                    {
                        collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />
                    }
                </div>
                {topNav}
            </div>
            <MedisyIcon />
            <QueueNumberModal visible={visible} setVisible={setVisible} />
        </div>
    )
}

interface ITopHeader {
    collapsed: boolean
    setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
    showDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

const TopHeader: FC<ITopHeader> = ({ collapsed, setCollapsed, showDrawer }) => {

    return (<>
        {Navbar({ collapsed, setCollapsed, showDrawer })}
    </>
    )
}

export default TopHeader