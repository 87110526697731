import { Tooltip } from "antd";

export const QueueMenuIcon = () => {
  return (
    <Tooltip placement="right" title={"Antrian"}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#219BD4"
        />
        <path
          d="M17.4997 19.9993C18.8802 19.9993 19.9993 18.8802 19.9993 17.4997C19.9993 16.1191 18.8802 15 17.4997 15C16.1191 15 15 16.1191 15 17.4997C15 18.8802 16.1191 19.9993 17.4997 19.9993Z"
          fill="white"
        />
        <path
          d="M27.5006 19.9993C28.8812 19.9993 30.0003 18.8802 30.0003 17.4997C30.0003 16.1191 28.8812 15 27.5006 15C26.1201 15 25.001 16.1191 25.001 17.4997C25.001 18.8802 26.1201 19.9993 27.5006 19.9993Z"
          fill="white"
        />
        <path
          d="M17.4997 30C18.8802 30 19.9993 28.8809 19.9993 27.5003C19.9993 26.1198 18.8802 25.0007 17.4997 25.0007C16.1191 25.0007 15 26.1198 15 27.5003C15 28.8809 16.1191 30 17.4997 30Z"
          fill="white"
        />
        <path
          d="M27.5006 30C28.8812 30 30.0003 28.8809 30.0003 27.5003C30.0003 26.1198 28.8812 25.0007 27.5006 25.0007C26.1201 25.0007 25.001 26.1198 25.001 27.5003C25.001 28.8809 26.1201 30 27.5006 30Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
};
