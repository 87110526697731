import React, { useState } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Col, Divider, Form, Input, Menu, Modal, Row, Select } from 'antd'

import Text from "antd/lib/typography/Text";

export const NavBpjs: React.FC = () => {
    const location = useLocation()
    const [visible, setVisible] = useState(false);
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1"> 
                    &nbsp; <a onClick={() => setVisible(true)}>Cek BPJS</a>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/register-bpjs')}>
                    <RouterLink to="/register-bpjs" key="2" >Pendaftaran</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/visit-bpjs')}>
                    <RouterLink to="/visit-bpjs" key="3" >Kunjungan</RouterLink>
                </Menu.Item>
            </Menu>

            <Modal
            title="Cek Status Pasien"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={600}
        >
        <Form layout="vertical">
            <Row justify="space-around">
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Form.Item
                        label="tipe nomor"
                        name="type"
                        className='lable-form-input'
                        >
                        <Select className='form-control'>
                            <Select.Option value="noBPJS">No. BPJS</Select.Option>
                            <Select.Option value="NIK">No. NIK</Select.Option>
                        </Select>
                    </Form.Item>

                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                        label="nomor kartu"
                        name="nomor"
                        className='lable-form-input'
                        rules={[{ required: true, message: 'Masukan NIK / BPJS' }]}
                        >
                        <Input className="form-control"/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>

        <Divider/>
        <Row gutter={[8, 8]}>
            <Col span={12}>
                <Text strong>Nama Pemeriksaan</Text>
            </Col>
            <Col span={12}>
                <Text strong>Hasil</Text>
            </Col>

            <Col span={12}>
                <Text strong>Nama Peserta</Text>
            </Col>
            <Col span={12}>
                <Text strong>-</Text>
            </Col>

            <Col span={12}>
                <Text strong>Jenis Kelamin</Text>
            </Col>
            <Col span={12}>
                <Text strong>-</Text>
            </Col>

            <Col span={12}>
                <Text strong>Tanggal Lahir</Text>
            </Col>
            <Col span={12}>
                <Text strong>-</Text>
            </Col>

            <Col span={12}>
                <Text strong>Tempat FASKES</Text>
            </Col>
            <Col span={12}>
                <Text strong>-</Text>
            </Col>

            <Col span={12}>
                <Text strong>Status Peserta</Text>
            </Col>
            <Col span={12}>
                <Text strong>-</Text>
            </Col>
        </Row>
      </Modal>
        </>
    )
}