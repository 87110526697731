import { Modal } from 'antd'
import React from 'react'
import { IModal } from './PatientMedicalRecord'

export const DataLab: React.FC<IModal> = ({ visible, setVisible }) => {
    return (
        <Modal
            title="Daftar List Lab"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={1000}
        >
            Data Belum Diinputkan...
        </Modal>
    )
}
