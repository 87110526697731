import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavVisit: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/visit')}>
                    <RouterLink to="/visit" key="1">Kunjungan</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/patients/1')}>
                    <RouterLink to="/patients/1" key="2" >Data Pribadi</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/list-checkup')}>
                    <RouterLink to="/list-checkup" key="3" >History Rekam Medis</RouterLink>
                </Menu.Item>
            </Menu>
        </>
    )
}