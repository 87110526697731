import React from 'react'
import { DataLogin } from '../../components/login'

export const Login: React.FC = () => {
    return (
        <>
            <DataLogin />
        </>
    )
}
