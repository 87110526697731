import { Tooltip } from "antd";
import React from "react";

export const BPJSMenuIcon = () => {
  return (
    <Tooltip placement="right" title={"BPJS"}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#21D47E"
        />
        <path
          d="M28.5071 32.5602C28.5071 32.5602 24.8096 35.0164 20.3696 33.5126C20.3696 33.5126 22.554 33.2913 24.3281 31.7987C25.5456 30.7741 26.8979 29.5646 28.0069 29.5646C28.0069 29.5646 27.2297 31.1005 28.5071 32.5602Z"
          fill="white"
        />
        <path
          d="M22.621 32.172C22.621 32.172 20.4822 34.2525 17.5059 32.4852C17.5059 32.4852 18.596 31.3371 17.9309 29.4776C17.9309 29.4776 19.0583 29.5754 20.2945 30.7809C21.6829 32.1343 22.621 32.172 22.621 32.172Z"
          fill="white"
        />
        <path
          d="M17.5059 13.4398C17.5059 13.4398 21.2034 10.9836 25.6434 12.4874C25.6434 12.4874 23.459 12.7087 21.6849 14.2013C20.4674 15.2259 19.1151 16.4354 18.0061 16.4354C18.0061 16.4354 18.7833 14.8995 17.5059 13.4398Z"
          fill="white"
        />
        <path
          d="M23.3921 13.828C23.3921 13.828 25.5308 11.7475 28.5072 13.5148C28.5072 13.5148 27.417 14.6629 28.0822 16.5224C28.0822 16.5224 26.9548 16.4246 25.7185 15.2191C24.3302 13.8657 23.3921 13.828 23.3921 13.828Z"
          fill="white"
        />
        <path
          d="M32.5634 17.4363C32.5634 17.4363 35.0147 21.1413 33.5139 25.5902C33.5139 25.5902 33.293 23.4014 31.8034 21.6237C30.7809 20.4039 29.5742 19.0488 29.5742 17.9376C29.5738 17.9376 31.1062 18.7164 32.5634 17.4363Z"
          fill="white"
        />
        <path
          d="M32.1753 23.3344C32.1753 23.3344 34.2516 25.4774 32.4879 28.4598C32.4879 28.4598 31.3421 27.3674 29.4863 28.0339C29.4863 28.0339 29.584 26.9042 30.787 25.6655C32.1377 24.274 32.1753 23.3344 32.1753 23.3344Z"
          fill="white"
        />
        <path
          d="M13.4369 28.5781C13.4369 28.5781 10.9856 24.8731 12.4864 20.4243C12.4864 20.4243 12.7073 22.613 14.1969 24.3907C15.2194 25.6106 16.4261 26.9656 16.4261 28.0768C16.4261 28.0768 14.8937 27.2981 13.4369 28.5781Z"
          fill="white"
        />
        <path
          d="M13.8246 22.68C13.8246 22.68 11.7483 20.537 13.5121 17.5546C13.5121 17.5546 14.6579 18.647 16.5137 17.9805C16.5137 17.9805 16.416 19.1102 15.213 20.3489C13.8619 21.74 13.8246 22.68 13.8246 22.68Z"
          fill="white"
        />
        <path
          d="M15.2851 33.0775C16.6038 33.0775 17.6728 32.0064 17.6728 30.6851C17.6728 29.3637 16.6038 28.2926 15.2851 28.2926C13.9665 28.2926 12.8975 29.3637 12.8975 30.6851C12.8975 32.0064 13.9665 33.0775 15.2851 33.0775Z"
          fill="white"
        />
        <path
          d="M30.6601 33.0775C31.9788 33.0775 33.0478 32.0064 33.0478 30.6851C33.0478 29.3637 31.9788 28.2926 30.6601 28.2926C29.3415 28.2926 28.2725 29.3637 28.2725 30.6851C28.2725 32.0064 29.3415 33.0775 30.6601 33.0775Z"
          fill="white"
        />
        <path
          d="M15.2851 17.705C16.6038 17.705 17.6728 16.6339 17.6728 15.3126C17.6728 13.9912 16.6038 12.9201 15.2851 12.9201C13.9665 12.9201 12.8975 13.9912 12.8975 15.3126C12.8975 16.6339 13.9665 17.705 15.2851 17.705Z"
          fill="white"
        />
        <path
          d="M30.6601 17.705C31.9788 17.705 33.0478 16.6339 33.0478 15.3126C33.0478 13.9912 31.9788 12.9201 30.6601 12.9201C29.3415 12.9201 28.2725 13.9912 28.2725 15.3126C28.2725 16.6339 29.3415 17.705 30.6601 17.705Z"
          fill="white"
        />
        <path
          d="M22.0505 21.9947L20.1562 20.2258V19.2565C20.1562 19.2565 20.3391 18.3041 21.2732 18.3041C22.2073 18.3041 24.0331 18.2917 24.0331 18.2917L24.0083 17.5021L25.7928 19.2565L24.0251 21.011L24.0087 20.2591L22.0577 20.2759L22.0505 21.9947Z"
          fill="white"
        />
        <path
          d="M24.0234 22.0151L25.7888 20.1171H26.7561C26.7561 20.1171 27.7066 20.3004 27.7066 21.2364C27.7066 22.1723 27.719 24.0014 27.719 24.0014L28.507 23.9765L26.7561 25.7646L25.0052 23.9933L25.7556 23.9765L25.7387 22.0216L24.0234 22.0151Z"
          fill="white"
        />
        <path
          d="M23.9807 23.9672L25.8745 25.7361V26.7054C25.8745 26.7054 25.6916 27.6578 24.7575 27.6578C23.8234 27.6578 21.998 27.6702 21.998 27.6702L22.0228 28.4598L20.2383 26.7054L22.0056 24.9509L22.0224 25.7028L23.9731 25.686L23.9807 23.9672Z"
          fill="white"
        />
        <path
          d="M21.9894 23.9921L20.2237 25.8897H19.2568C19.2568 25.8897 18.3063 25.7064 18.3063 24.7705C18.3063 23.8345 18.2939 22.0054 18.2939 22.0054L17.5059 22.0303L19.2568 20.2422L21.0077 22.0131L20.2573 22.0299L20.2737 23.9845L21.9894 23.9921Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
};
