import React, {useEffect, useState} from "react";
import {
  Descriptions,
  Button,
  Row,
  Col,
  Form,
  Divider,
  Space,
  message,
  Table,
  Popconfirm,
} from "antd";
import Card from "../../layout/card";
import Input from "../../form/Input";
import Textarea from "../../form/TextArea";
import {AddIconButton} from "../../form/icon-button/AddIconButton";
import {DeleteIconButton} from "../../form/icon-button/DeleteIconButton";
import {IStepProps} from "./_form";
import {PatientHistory} from "../../form/modal/PatientHistory";
import {TreatmentModal} from "../../form/modal/TreatmentModal";
import {ListMedicineModal} from "../../form/modal/ListMedicineModal";
import {Tabs} from "antd";
import FloatingTextInput from "../../form/FloatingTextInput";
import TextArea from "rc-textarea";
import {ListOfDrugPackagesModal} from "../../form/modal/ListOfDrugPackagesModal";
import TitleWithStep from "../../layout/stepTitle";
import {DetailAnamnesisPatient} from "./DetailPasient";
import {StepNavigation} from "./StepNavigation";
import useQueue from "../../../services/queue";
import useCheckup, {
  ICheckupPlan,
  DestroyCheckupPlan,
  ISelectedMedicine,
  ITreatment,
} from "../../../services/doctor/checkup";
import moment from "moment";
import {Treatment} from "../../../services/configurations/treatment";
import {useForm} from "antd/lib/form/Form";
import Select, {SelectItem} from "../../form/Select";
import {SelectionItem} from "antd/lib/table/interface";
import useUser from "../../../services/configurations/user";
import {ArrayData} from "../../../services/utility";
import {CheckIconButton} from "../../form/icon-button/CheckIconButton";

const {TabPane} = Tabs;

const actions = [
  {
    key: 1,
    action: "Hecting Perawat",
    // qty: "1",
    nurse: "Indri Rusmini, A.Md. Kep",
    description: "-",
  },
  {
    key: 2,
    action: "Hecting Perawat",
    // qty: "3",
    nurse: "Indri Rusmini, A.Md. Kep",
    description: "-",
  },
];

const recipe = [
  {
    key: 1,
    medicine_name: "DWALL1957 CETIRIZINE TAB ISI 100 - DOWA-",
    dose: "3.5 - 6x dalam 30 hari",
    notes: "Sebelum makan",
    unknown1: "423",
    unknown2: "76.200",
  },
  {
    key: 2,
    medicine_name: "DWALL1957 CETIRIZINE TAB ISI 100 - DOWA-",
    dose: "3.5 - 6x dalam 30 hari",
    notes: "Setelah makan",
    unknown1: "2.777",
    unknown2: "83.300",
  },
];

const initialStateSelectedMedicine = {
  inc_id: 1,
  id: "adfahdfgadkfa",
  clinic: 2,
  name: "PCT",
  bpjs: true,
  status: true,
  created: "2022-04-26T06:18:39.041Z",
  updated: "2022-04-26T06:18:39.041Z",
  deleted: null,
};

const initialStatesSelectedTreatment = {
  id: "",
  name: "",
  price: "",
  doctor_fee: "",
  nurse_fee: "",
  cost_net: "",
  bhp: 0,
  status: 0,
  bpjs: 0,
  info: "-",
  created: "",
  updated: "",
  clinic_poly: {
    inc_id: 0,
    id: "",
    order: 0,
    status: true,
    code: "",
    max_online_registration: 10,
    is_doctor: true,
    created: "",
    updated: "",
    poly: {
      inc_id: 2,
      id: "",
      name: "",
      code_bpjs: null,
      created: "",
      updated: "",
    },
  },
  poly: {
    name: "",
  },
};

const DOSE: SelectItem[] = [
  {
    display: "0",
    value: 0,
  },
  {
    display: "0.5",
    value: 0.5,
  },
  {
    display: "1",
    value: 1,
  },
  {
    display: "1.5",
    value: 1.5,
  },
  {
    display: "2",
    value: 2,
  },
  {
    display: "2.5",
    value: 2.5,
  },
  {
    display: "3",
    value: 3,
  },
  {
    display: "3.5",
    value: 3.5,
  },
];

const TIMES: SelectItem[] = [
  {
    display: "0",
    value: 0,
  },
  {
    display: "1 x",
    value: 1,
  },
  {
    display: "2 x",
    value: 2,
  },
  {
    display: "3 x",
    value: 3,
  },
  {
    display: "4 x",
    value: 4,
  },
  {
    display: "5 x",
    value: 5,
  },
  {
    display: "6 x",
    value: 6,
  },
];

const DAY: SelectItem[] = [
  {
    display: "0 ",
    value: 0,
  },
  {
    display: "1 Hari ",
    value: 1,
  },
  {
    display: "2 Hari ",
    value: 2,
  },
  {
    display: "3 Hari ",
    value: 3,
  },
  {
    display: "4 Hari ",
    value: 4,
  },
  {
    display: "5 Hari ",
    value: 5,
  },
  {
    display: "6 Hari ",
    value: 6,
  },
  {
    display: "7 Hari ",
    value: 7,
  },
  {
    display: "8 Hari ",
    value: 8,
  },
  {
    display: "9 Hari ",
    value: 9,
  },
  {
    display: "10 Hari ",
    value: 10,
  },
  {
    display: "11 Hari ",
    value: 11,
  },
  {
    display: "12 Hari ",
    value: 12,
  },
  {
    display: "13 Hari ",
    value: 13,
  },
  {
    display: "14 Hari ",
    value: 14,
  },
  {
    display: "15 Hari ",
    value: 15,
  },
  {
    display: "16 Hari ",
    value: 16,
  },
  {
    display: "17 Hari ",
    value: 17,
  },
  {
    display: "18 Hari ",
    value: 18,
  },
  {
    display: "19 Hari ",
    value: 19,
  },
  {
    display: "20 Hari ",
    value: 20,
  },
  {
    display: "21 Hari ",
    value: 21,
  },
  {
    display: "22 Hari ",
    value: 22,
  },
  {
    display: "23 Hari ",
    value: 23,
  },
  {
    display: "24 Hari ",
    value: 24,
  },
  {
    display: "25 Hari ",
    value: 25,
  },
  {
    display: "26 Hari ",
    value: 26,
  },
  {
    display: "27 Hari ",
    value: 27,
  },
  {
    display: "28 Hari ",
    value: 28,
  },
  {
    display: "29 Hari ",
    value: 29,
  },
  {
    display: "30 Hari ",
    value: 30,
  },
];

const NOTE: SelectItem[] = [
  {
    display: "Setelah Makan",
    value: "Setelah Makan",
  },
  {
    display: "Sebelum Makan",
    value: "Sebelum Makan",
  },
];

export const FourthStepForm = () => {
  const [anamnesisDatas, setAnamnesisDatas] = useState({
    id: "",
    position: "",
    status: false,
    created: "",
    updated: "",
    queue: {
      id: "",
      priority: true,
      number: 0,
      status: "",
      bookdated: "",
      shift: {
        id: 0,
        name: "",
        startTime: "",
        endTime: "",
        clinic_poly: {
          inc_id: 0,
          id: "",
          order: 0,
          status: true,
          code: "",
          max_online_registration: 30,
          is_doctor: true,
          created: "",
          updated: "",
          poly: {
            inc_id: 1,
            id: "",
            name: "",
            code_bpjs: "",
            created: "",
            updated: "",
          },
        },
      },
      anamnesis: {
        id: "",
        patient: {
          inc_id: 0,
          id: "",
          no_registration: "",
          bpjs: "",
          type_identity: "",
          no_identity: "",
          fullname: "",
          place_of_birth: "",
          date_of_birth: "",
          gender: "",
          blood_type: null,
          last_education: null,
          marital_status: "",
          job: "",
          phone_number: "",
          email: "",
          village: {
            id: 0,
            name: "",
            district: {
              id: 0,
              name: "",
              city: {
                id: 0,
                name: "",
                province: {
                  id: 0,
                  name: "",
                  country: {
                    id: 0,
                    name: "",
                  },
                },
              },
            },
          },
          address: "",
          person_in_charge: null,
          relationship: null,
          person_in_charge_phone_number: null,
          clinic: 0,
          source: null,
          created: "",
          updated: "",
          deleted: null,
        },
        insurance_type: null,
        insurance_no: "",
        prb: null,
        partnership: null,
        partnership_info: null,
        doctor: null,
        complaint: null,
        complaint_detail: null,
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        temperature: null,
        respiratory: null,
        heart: null,
        spo2: null,
        gdp: null,
        gds: null,
        hba1c: null,
        priority_info: null,
        created: "",
        updated: "",
      },
    },
  });
  const [age, setAge] = useState("");
  const [treatmentModal, setTreatmentModal] = useState(false);
  const [nurse, setNurse] = useState<SelectItem[]>([]);
  const [selectedNurse, setSelectedNurse] = useState({id: "", name: ""});
  const [treatmentInfo, setTreatmentInfo] = useState("");
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment>();
  const [selectedTreatments, setSelectedTreatments] = useState<ITreatment[]>(
    []
  );
  const [checkupPlan, setCheckupPlan] = useState<ICheckupPlan>({name: ""});
  const [checkupPlans, setCheckupPlans] = useState<ArrayData<ICheckupPlan>>({
    data: [],
  });
  const [checkupPlanLoader, setCheckupPlansLoader] = useState(false);
  const [selectedMedicine, setSelectedMedicine] = useState<ISelectedMedicine>();
  const [selectedDirectMedicines, setSelectedDirectMedicines] =
    useState<ISelectedMedicine[]>();
  const [selectedDose, setSelectedDose] = useState(DOSE[0].display);
  const [selectedTimes, setSelectedTimes] = useState(TIMES[0].display);
  const [selectedDay, setSelectedDay] = useState(DAY[0].display);
  const [selectedNote, setSelectedNote] = useState(NOTE[0].display);

  const [patientHistoryModal, setPatientHistoryModal] = useState(false);
  const [listMedicineModal, setListMedicineModal] = useState(false);
  const [listOfDrugPackagesModal, setListOfDrugPackagesModal] = useState(false);

  const queueService = useQueue();
  const checkupService = useCheckup();
  const userService = useUser();

  const patientInformationEffect = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .getQueueRoomDetail(activeQueue.idRoom)
      .then((res: any) => {
        let diff = moment.duration(
          moment().diff(moment(res.queue.anamnesis.patient.date_of_birth))
        );
        setAge(
          `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
        );
        setAnamnesisDatas(res);
        fetchTretments(res.queue.anamnesis.id);
        fetchCheckupPlan(res.queue.anamnesis.id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(patientInformationEffect, []);

  const [treatmentForm] = useForm();
  const [checkupPlanForm] = useForm();
  const [directMedicineForm] = useForm();

  const initialTreatmentFormValue = () => {
    treatmentForm.setFieldsValue({
      treatment: `${selectedTreatment ? selectedTreatment.name : ""}`,
    });
  };

  useEffect(initialTreatmentFormValue, [selectedTreatment]);

  const initialDirectMedicineFormValue = () => {
    directMedicineForm.setFieldsValue({
      medicine_name: `${
        selectedMedicine ? selectedMedicine.medicine.name.toUpperCase() : ""
      }`,
    });
  };

  useEffect(initialDirectMedicineFormValue, [selectedMedicine]);

  const getNurse = async (search: string) => {
    const nurse: SelectItem[] = [];
    const result = await userService.getNurse(search).then((res) => res.data);

    await Promise.all(
      result.map(
        async (val) =>
          await nurse.push({
            display: val.fullname,
            value: val.id,
          })
      )
    );

    setNurse(nurse);
  };

  const fetchTretments = (anamnesisId: string) => {
    checkupService
      .getSelectedTreatments(anamnesisId)
      .then((res) => setSelectedTreatments(res))
      .catch((res) => console.log(res));
  };
  const deleteSelectedtedTreatments = (treatmentId: string) => {
    console.log(`TreatmentId: ${treatmentId}`);
    checkupService
      .deleteSelectedTreatments(treatmentId)
      .then(() => {
        setSelectedTreatments(
          selectedTreatments.filter((st) => st.id !== treatmentId)
        );
        message.success("Data berhasil dihapus");
      })
      .catch((err) => console.log(err));
  };

  const fetchCheckupPlan = (anamnesisId: string) => {
    setCheckupPlansLoader(true);
    checkupService
      .getCheckupPlan(anamnesisId)
      .then((res) =>
        setCheckupPlans({
          ...checkupPlans,
          data: [...res.data.filter((cp) => !cp.status)],
        })
      )
      .catch((err) => console.log(err))
      .finally(() => setCheckupPlansLoader(false));
  };

  const checkCheckupPlanStatus = (planId: string, anamnesisId: string) => {
    checkupService
      .updateCheckupPlan(planId, anamnesisId)
      .then((res) => {
        const filteredCheckupPlans = checkupPlans.data.filter(
          (cp) => cp.id !== planId
        );
        setCheckupPlans({
          ...checkupPlans,
          data: filteredCheckupPlans,
        });
        message.success("Rencana pemeriksaan selesai dilakukan");
      })
      .catch((err) => console.log(err));
  };

  const deleteCheckupPlan = (planId: string, anamnesisId: string) => {
    console.log(`planId: ${planId}, anamnesisId: ${anamnesisId}`);
    checkupService
      .deleteCheckupPlane(planId, anamnesisId)
      .then(() => {
        const filteredCheckupPlans = checkupPlans.data.filter(
          (cp) => cp.id !== planId
        );
        setCheckupPlans({
          ...checkupPlans,
          data: filteredCheckupPlans,
        });

        message.success("Rencana pemeriksaan berhasil dihapus");
      })
      .catch((err) => console.log(err));
  };

  const onFinishTreatmentForm = () => {
    const treatment = {
      treatment: selectedTreatment!.id,
      nurse: selectedNurse.id,
      info: treatmentInfo,
    };

    checkupService
      .submitSelectedTreatments(anamnesisDatas.queue.anamnesis.id, treatment)
      .then((res) => {
        console.log(selectedNurse);
        const displayTreatment = {
          id: res.id,
          treatment: {
            name: selectedTreatment!.name,
          },
          nurse: {
            fullname: selectedNurse.name,
          },
          info: treatmentInfo,
        };
        setSelectedTreatments([...selectedTreatments, displayTreatment]);
        treatmentForm.setFieldsValue({
          treatment: "",
          info: "",
        });
        setTreatmentInfo("");
        message.success("Tindakan berhasil ditambahkan");
      })
      .catch((err) => console.log(err));
  };

  const onFinishCheckupPlanForm = () => {
    const checkupPlanPrePost = {
      name: checkupPlan.name,
    };
    checkupService
      .postCheckupPlan(anamnesisDatas.queue.anamnesis.id, checkupPlanPrePost)
      .then((res) => {
        setCheckupPlans({
          ...checkupPlans,
          data: [...checkupPlans.data, {...checkupPlanPrePost, id: res.id}],
        });

        checkupPlanForm.setFieldsValue({checkup_plan: ""});
        message.success("Rencana pemeriksaan berhasil ditambahkan");
      })
      .catch((err) => console.log(err))
      .finally(() => console.log(checkupPlans.data));
  };

  const onFinishDirectMedicineForm = () => {};
  return (
    <>
      <TitleWithStep
        title="Tindakan"
        stepTitle="Tahap 4"
        description="tindakan dan resep obat"
      />
      <DetailAnamnesisPatient
        anamnesisDatas={anamnesisDatas}
        age={age}
        setPatientHistoryModal={setPatientHistoryModal}
      />
      <StepNavigation activeSteps={4} />

      <Row gutter={15}>
        <Col xl={14}>
          <Form
            form={treatmentForm}
            onFinish={onFinishTreatmentForm}
            layout="vertical"
          >
            <Card title="Form Tindakan">
              <Row gutter={15}>
                <Col xl={6}>
                  <FloatingTextInput
                    name="treatment"
                    label="Tindakan"
                    onClick={() => setTreatmentModal(true)}
                  />
                </Col>
                <Col xl={5}>
                  <Select
                    name="nurse"
                    label="Perawat"
                    items={nurse}
                    showSearch
                    onSearch={(q) => getNurse(q)}
                    filterOption={false}
                    onValueChange={(v) =>
                      setSelectedNurse({
                        ...selectedNurse,
                        id: v as string,
                        name: "",
                      })
                    }
                  />
                </Col>
                <Col xl={7}>
                  <Input
                    name="info"
                    value={treatmentInfo}
                    onValueChange={(v) => setTreatmentInfo(v)}
                    label="Keterangan"
                  />
                </Col>
                <Col xl={3} style={{position: "relative"}}>
                  <AddIconButton
                    onClick={() => onFinishTreatmentForm()}
                    style={{position: "absolute", bottom: 6, cursor: "pointer"}}
                  />
                </Col>
              </Row>
              <Row>
                <Table
                  bordered
                  dataSource={selectedTreatments}
                  key="id"
                  style={{width: "100%"}}
                >
                  <Table.Column
                    title="No"
                    key="index"
                    width={2}
                    render={(value, record, index) => index + 1}
                  />
                  <Table.Column
                    title="Nama"
                    key="index"
                    render={(treatment: ITreatment) => treatment.treatment.name}
                  />
                  <Table.Column
                    title="Perawat"
                    key="index"
                    render={(treatment: ITreatment) => treatment.nurse.fullname}
                  />
                  <Table.Column
                    title="Keterangan"
                    key="index"
                    render={(treatment: ITreatment) => treatment.info}
                  />
                  <Table.Column
                    title="Aksi"
                    key="index"
                    render={(treatment: ITreatment) => (
                      <>
                        <Popconfirm
                          placement="topLeft"
                          title="Apakah anda yakin ingin menghapus data ini?"
                          onConfirm={() =>
                            deleteSelectedtedTreatments(treatment.id!)
                          }
                          okText="Ya"
                          cancelText="Tidak"
                        >
                          <DeleteIconButton
                            style={{cursor: "pointer", width: "30px"}}
                          />
                        </Popconfirm>
                      </>
                    )}
                  />
                </Table>
              </Row>
              <Divider style={{borderTop: "1px solid #CACACA"}} />
            </Card>
          </Form>
        </Col>
        <Col xl={10}>
          <Form
            form={checkupPlanForm}
            onFinish={onFinishCheckupPlanForm}
            layout="vertical"
          >
            <Card title="Form Rencana Pemeriksaan">
              <Row gutter={15}>
                <Col xl={20}>
                  <Input
                    name="checkup_plan"
                    value={checkupPlan?.name}
                    onValueChange={(v) =>
                      setCheckupPlan({...checkupPlan, name: v})
                    }
                    label="Rencana Pemeriksaan"
                  />
                </Col>
                <Col xl={4} style={{position: "relative"}}>
                  <AddIconButton
                    onClick={() => onFinishCheckupPlanForm()}
                    style={{position: "absolute", bottom: 6}}
                  />
                </Col>
              </Row>
              <Row>
                <Table
                  bordered
                  dataSource={checkupPlans.data}
                  key="id"
                  style={{width: "100%"}}
                >
                  <Table.Column
                    title="No"
                    key="index"
                    width={2}
                    render={(value, record, index) => index + 1}
                  />
                  <Table.Column
                    title="Jenis"
                    key="index"
                    render={(checkupPlan: ICheckupPlan) => checkupPlan.name}
                  />
                  <Table.Column
                    title="Aksi"
                    key="index"
                    render={(checkupPlan: DestroyCheckupPlan) => (
                      <>
                        <CheckIconButton
                          onClick={() =>
                            checkCheckupPlanStatus(
                              checkupPlan.id!,
                              anamnesisDatas.queue.anamnesis.id
                            )
                          }
                          style={{cursor: "pointer", width: "30px"}}
                        />
                        <Popconfirm
                          placement="topLeft"
                          title={"Apakah anda yakin ingin menghapus data ini?"}
                          onConfirm={() =>
                            deleteCheckupPlan(
                              checkupPlan.id!,
                              anamnesisDatas.queue.anamnesis.id
                            )
                          }
                          okText="Ya"
                          cancelText="Tidak"
                        >
                          <DeleteIconButton
                            style={{cursor: "pointer", width: "30px"}}
                          />
                        </Popconfirm>
                      </>
                    )}
                  />
                </Table>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
      <Card title="Resep Obat">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Obat Langsung" key="1">
            <Form
              form={directMedicineForm}
              onFinish={onFinishDirectMedicineForm}
              layout="vertical"
            >
              <div style={{padding: "1%"}}>
                <Row gutter={15} style={{paddingBottom: "1%"}}>
                  <Col xl={7}>
                    <FloatingTextInput
                      name="medicine_name"
                      label="Nama Obat"
                      onClick={() => setListMedicineModal(true)}
                    />
                  </Col>
                  <Col xl={4}>
                    {/* <Input name="dose" label="Dosis" /> */}
                    <Select
                      name="dose"
                      label="Jenis Diagnosa"
                      items={DOSE}
                      value={selectedDose}
                      onValueChange={(v) => setSelectedDose(v as string)}
                    />
                  </Col>
                  <Col xl={4}>
                    {/* <Input name="time" label="Waktu" /> */}
                    <Select
                      name="times"
                      label="Waktu"
                      items={TIMES}
                      value={selectedTimes}
                      onValueChange={(v) => setSelectedTimes(v as string)}
                    />
                  </Col>
                  <Col xl={4}>
                    {/* <Input name="use" label="Penggunaan" /> */}
                    <Select
                      name="day"
                      label="Penggunaan"
                      items={DAY}
                      value={selectedDay}
                      onValueChange={(v) => setSelectedDay(v as string)}
                    />
                  </Col>
                  <Col xl={4}>
                    {/* <Input name="notes" label="Catatan" /> */}
                    <Select
                      name="notes"
                      label="Catatan"
                      items={NOTE}
                      value={selectedNote}
                      onValueChange={(v) => setSelectedNote(v as string)}
                    />
                  </Col>
                  <Col xl={1} style={{position: "relative"}}>
                    <AddIconButton style={{position: "absolute", bottom: 5}} />
                  </Col>
                </Row>
                {/* Checkpoint table for direct medicine */}
                {/* <Row>
                  <Table
                    bordered
                    dataSource={selectedDirectMedicines}
                    key="id"
                    style={{width: "100%"}}
                    footer={() => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <strong>Total</strong>
                        <p>20000000</p>
                      </div>
                    )}
                  >
                    <Table.Column
                      title="No"
                      key="index"
                      width={2}
                      render={(value, record, index) => index + 1}
                    />
                    <Table.Column
                      title="Obat"
                      key="index"
                      render={(treatment: ITreatment) =>
                        treatment.treatment.name
                      }
                    />
                    <Table.Column
                      title="Penggunaan"
                      key="index"
                      render={(treatment: ITreatment) =>
                        treatment.nurse.fullname
                      }
                    />
                    <Table.Column
                      title="Catatan"
                      key="index"
                      render={(treatment: ITreatment) => treatment.info}
                    />
                    <Table.Column
                      title="Harga"
                      key="index"
                      render={(treatment: ITreatment) => treatment.info}
                    />
                    <Table.Column
                      title="Total"
                      key="index"
                      render={(treatment: ITreatment) => treatment.info}
                    />
                    <Table.Column
                      title="Aksi"
                      key="index"
                      render={(treatment: ITreatment) => (
                        <>
                          <Popconfirm
                            placement="topLeft"
                            title="Apakah anda yakin ingin menghapus data ini?"
                            onConfirm={() =>
                              deleteSelectedtedTreatments(treatment.id!)
                            }
                            okText="Ya"
                            cancelText="Tidak"
                          >
                            <DeleteIconButton
                              style={{cursor: "pointer", width: "30px"}}
                            />
                          </Popconfirm>
                        </>
                      )}
                    />
                  </Table>
                </Row> */}
                <Divider style={{borderTop: "1px solid #CACACA"}} />
                <Row justify="end">
                  <Col xl={2}>
                    <strong>Total</strong>
                  </Col>
                  <Col xl={3}>
                    <strong style={{color: "#27AAE1"}}>23.009</strong>
                  </Col>
                </Row>
              </div>
            </Form>
          </TabPane>
          <TabPane tab="Paket Racikan" key="2">
            <Row gutter={15} style={{paddingBottom: "1%"}}>
              <Col xl={7}>
                <FloatingTextInput
                  name="medicine_pack"
                  label="Nama Paket"
                  onClick={() => setListOfDrugPackagesModal(true)}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Racikan Obat" key="3">
            <Row gutter={15} style={{paddingBottom: "1%"}}>
              <Col xl={7}>
                <FloatingTextInput
                  name="medicine_name"
                  label="Nama Obat"
                  onClick={() => setListMedicineModal(true)}
                />
              </Col>
              <Col xl={4}>
                <Input name="dose" label="Dosis" />
              </Col>
              <Col xl={4}>
                <Input name="unit" label="Satuan" />
              </Col>
              <Col xl={4}>
                <Input name="note" label="Catatan" />
              </Col>
              <Col xl={1} style={{position: "relative"}}>
                <AddIconButton style={{position: "absolute", bottom: 5}} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Keterangan" key="4">
            <Row>
              <Col xl={12}>
                <Textarea name="recipe_description" label="Keterangan Resep" />
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </Card>
      <Card title="Form keterangan pasien">
        <Row justify="space-between" align="middle">
          <Col xl={24}>
            <Textarea name="description" label="Keterangan" />
          </Col>
          <Col>
            <Space>
              <Button
                onClick={() => console.log("menuju hasil")}
                className="btn-primary"
                style={{fontSize: ".9em"}}
              >
                Simpan
              </Button>
              <Button className="btn-secondary" style={{fontSize: ".9em"}}>
                Rujuk
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
      <PatientHistory
        visible={patientHistoryModal}
        setVisible={setPatientHistoryModal}
      />
      {treatmentModal && (
        <TreatmentModal
          visible={treatmentModal}
          setVisible={setTreatmentModal}
          setSelectedTreatment={setSelectedTreatment}
        />
      )}
      {listMedicineModal && (
        <ListMedicineModal
          visible={listMedicineModal}
          setVisible={setListMedicineModal}
          setSelectedMedicine={setSelectedMedicine}
        />
      )}
      <ListOfDrugPackagesModal
        visible={listOfDrugPackagesModal}
        setVisible={setListOfDrugPackagesModal}
      />
    </>
  );
};
