import { Button } from 'antd'
import React from 'react'
import { EditIconButton } from '../form/icon-button/EditIconButton'

interface IProps {
    onClick?: any
    className?: string
    children: React.ReactNode
}

export const EditButton: React.FC<IProps> = ({ onClick, children, className }) => {
    return (
        <Button onClick={onClick} className={className} style={{ display: 'flex', margin: '0 2%', cursor: 'pointer' }}><EditIconButton style={{ marginRight: '10px' }} width='18' /><p style={{ margin: 0, padding: 0 }}>{children}</p></Button>
    )
}
