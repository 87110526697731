import {baseURL} from "../baseurl";
import {PaginationData} from "../utility";

export interface ICDInterface {
  id?: string;
  code: string;
  name: string;
  general_name: string;
  type: string;
  status: number;
  info?: string;
}

export enum StatusNumber {
  TRUE = 1,
  FALSE = 0,
}

const IcdClient = baseURL;

const useICDInterface = () => ({
  async create(icd: ICDInterface): Promise<ICDInterface> {
    const {data} = await IcdClient.post(`/config/icds`, icd);
    return data;
  },
  async update(id: string, icd: ICDInterface): Promise<ICDInterface> {
    delete icd.id;
    await IcdClient.patch(`/config/icds/${id}`, icd);
    return icd;
  },
  async delete(id: string): Promise<void> {
    const {data} = await IcdClient.delete(`/config/icds/${id}`);
    return data;
  },
  async get(id: string): Promise<ICDInterface> {
    const {data} = await IcdClient.get(`/config/icds/${id}`);
    const datas = {
      code: data.code,
      name: data.name,
      general_name: data.general_name,
      info: data.info,
      status: data.status,
      type: data.type,
    };

    return datas;
  },
  async fetch(
    search: string,
    size?: number,
    row?: number
  ): Promise<PaginationData<ICDInterface[]>> {
    let searching = search ? `search=${search}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";

    const {data} = await IcdClient.get(
      `/config/icds/?${searching}&${sizes}&${rows}`
    );

    return data;
  },
});

export default useICDInterface;
