
import { baseURL } from "../baseurl";
import { PaginationData } from "../utility";

export interface PRB {
    id?:  string
    name: string
}


const PRBClient = baseURL

const usePRB = () => ({
    async create(PRB: PRB): Promise<PRB>{
        const {data} = await PRBClient.post(`/config/prbs`, PRB)
        return data
    },
    async update(id: string, PRB: PRB): Promise<PRB>{
        await PRBClient.patch(`/config/prbs/${id}`, PRB)
        return PRB
    },
    async delete(id: string): Promise<void>{
        const {data} = await PRBClient.delete(`/config/prbs/${id}`)
        return data
    },
    async get(id:string): Promise<PRB>{
        const {data} = await PRBClient.get(`/config/prbs/${id}`)
        return data
    },
    async fetch(search: string, size?: number, row?: number): Promise<PaginationData<PRB[]>>{
        let searching = search ? `name=${search}` : ''
        let sizes = size ? `size=${size}` : ''
        let rows = row ? `page=${row}` : ''
        const { data } = await PRBClient.get(`/config/prbs/?${searching}&${sizes}&${rows}`)
        return data
    }
})

export default usePRB
