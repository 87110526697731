import React from 'react'
import { CheckUpData } from '../../../components/doctor/checkup'

export const CheckupPage = () => {
    return (
        <>
            <CheckUpData />
        </>
    )
}
