import { FC } from 'react'
import { BridgingBpjs } from '../../components/management/bpjs'

const BridgingBPJSPage: FC = () => {
    return <div>
       <BridgingBpjs/>
    </div>
}

export default BridgingBPJSPage
