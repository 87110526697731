import { Descriptions, Typography } from "antd";
import { FC } from "react";

const { Text } = Typography

const ResultFirstCheck: FC = () => {
    return <>
        <Descriptions className="cus-description" bordered column={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}>
            <Descriptions.Item label="Tinggi Badan">170 Cm</Descriptions.Item>
            <Descriptions.Item label="Berat Badan">65 Kg</Descriptions.Item>
            <Descriptions.Item label="Respiratory rate">120 Menit</Descriptions.Item>
            <Descriptions.Item label="Heart rate">97 bpm</Descriptions.Item>
            <Descriptions.Item label="GDP">30 mg/dl</Descriptions.Item>
            <Descriptions.Item label="Sistole">- mmHg</Descriptions.Item>
            <Descriptions.Item label="Diastole">- mmHg</Descriptions.Item>
            <Descriptions.Item label="Suhu Badan">33 C</Descriptions.Item>
            <Descriptions.Item label="HbA1c">33 %</Descriptions.Item>
            <Descriptions.Item label="Keluhan Awal">Sakit disekitar mulut</Descriptions.Item>
            <Descriptions.Item label="Penyakit Sekarang">Barusuh</Descriptions.Item>
        </Descriptions>
        {/* <Descriptions className="cus-description" bordered column={{ xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}>
            <Descriptions.Item label="Tinggi Badan">170 <Text style={{ color: '#27AAE1' }} strong>Cm</Text></Descriptions.Item>
            <Descriptions.Item label="Berat Badan">65 <Text style={{ color: '#27AAE1' }} strong>Kg</Text></Descriptions.Item>
            <Descriptions.Item label="Respiratory rate">120 <Text style={{ color: '#27AAE1' }} strong>Menit</Text></Descriptions.Item>
            <Descriptions.Item label="Heart rate">97 <Text style={{ color: '#27AAE1' }} strong>bpm</Text></Descriptions.Item>
            <Descriptions.Item label="GDP">30 <Text style={{ color: '#27AAE1' }} strong>mg/dl</Text></Descriptions.Item>
            <Descriptions.Item label="Sistole">- <Text style={{ color: '#27AAE1' }} strong>mmHg</Text></Descriptions.Item>
            <Descriptions.Item label="Diastole">- <Text style={{ color: '#27AAE1' }} strong>mmHg</Text></Descriptions.Item>
            <Descriptions.Item label="Suhu Badan">33 <Text style={{ color: '#27AAE1' }} strong>C</Text></Descriptions.Item>
            <Descriptions.Item label="HbA1c">33 <Text style={{ color: '#27AAE1' }} strong>%</Text></Descriptions.Item>
            <Descriptions.Item label="Keluhan Awal">Sakit disekitar mulut</Descriptions.Item>
            <Descriptions.Item label="Penyakit Sekarang">Barusuh</Descriptions.Item>
        </Descriptions> */}

    </>
}

export default ResultFirstCheck