import { Tooltip } from "antd";

export const PatientMenuIcon = () => {
  return (
    <Tooltip placement="right" title={"Pasien"}>
      <svg
        width="45"
        height="45"
        viewBox="0 0 45 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
          fill="#219BD4"
        />
        <path
          d="M31.998 29.6601C31.4954 28.7065 30.8315 27.8774 30.0494 27.195C29.6711 27.8184 29.2212 28.3948 28.7154 28.915C29.2613 29.4107 29.7267 30.0028 30.0806 30.6788C30.3594 31.2118 31.0175 31.4139 31.5481 31.1339C32.0788 30.8503 32.2807 30.1924 31.998 29.6601ZM33.0001 18.6268C33.0001 13.8678 29.1465 10 24.4123 10C21.7191 10 19.3118 11.2496 17.7383 13.2038C18.9639 12.6459 20.3227 12.3313 21.7563 12.3313C22.1101 12.3313 22.4605 12.3502 22.8051 12.3875C23.3202 12.2534 23.86 12.1819 24.4123 12.1819C27.9333 12.1819 30.8036 15.0459 30.8283 18.5802C31.2502 19.6708 31.4801 20.8586 31.4801 22.0989C31.4801 22.6444 31.4366 23.1806 31.3498 23.7008C32.3891 22.2765 33.0001 20.5216 33.0001 18.6268Z"
          fill="white"
        />
        <path
          d="M25.1936 22.9169C24.6633 22.6351 24.0059 22.8379 23.7253 23.3702C23.3048 24.1683 22.4855 24.6636 21.5872 24.6636C20.6889 24.6636 19.8689 24.1676 19.4487 23.3702C19.1678 22.8379 18.5115 22.6351 17.9808 22.9169C17.4509 23.1987 17.2486 23.8584 17.5292 24.3914C18.3269 25.9051 19.882 26.8452 21.5869 26.8452C23.2914 26.8452 24.8464 25.9051 25.6445 24.3914C25.9261 23.8588 25.7232 23.1987 25.1936 22.9169Z"
          fill="white"
        />
        <path
          d="M25.7621 29.9003C28.3922 28.4243 30.175 25.6009 30.175 22.3654C30.175 17.6085 26.3228 13.739 21.5875 13.739C16.8526 13.739 13 17.6085 13 22.3654C13 25.6009 14.7832 28.424 17.413 29.9003C15.9897 30.6959 14.7938 31.8943 14.0003 33.3987C13.7201 33.931 13.9217 34.5914 14.452 34.8736C14.6139 34.9597 14.7878 34.9999 14.9592 34.9999C15.3492 34.9999 15.7254 34.7885 15.9199 34.4196C17.0343 32.3056 19.206 30.9919 21.5875 30.9919C23.9693 30.9919 26.1407 32.3053 27.2555 34.4196C27.536 34.9519 28.1931 35.1547 28.7234 34.8732C29.2533 34.5911 29.4552 33.931 29.175 33.3983C28.3805 31.8943 27.185 30.6963 25.7621 29.9003ZM15.1714 22.3658C15.1714 18.8122 18.0499 15.9212 21.5872 15.9212C25.1248 15.9212 28.0029 18.8119 28.0029 22.3658C28.0029 25.9194 25.1248 28.8108 21.5872 28.8108C18.0499 28.8108 15.1714 25.9194 15.1714 22.3658Z"
          fill="white"
        />
      </svg>
    </Tooltip>
  );
};
