import React, {useEffect, useState} from "react";
import Card from "../../layout/card";
import {
  Descriptions,
  Button,
  Row,
  Col,
  Form,
  Divider,
  Modal,
  Table,
  Popconfirm,
} from "antd";
import Input from "../../form/Input";
import Textarea from "../../form/TextArea";
import Select, {SelectItem} from "../../layout/select";
import {AddIconButton} from "../../form/icon-button/AddIconButton";
import {DeleteIconButton} from "../../form/icon-button/DeleteIconButton";
import {useForm} from "antd/lib/form/Form";

import {DiseaseCategoryList} from "../../form/modal/DiseaseCategoryList";
import {PatientHistory} from "../../form/modal/PatientHistory";
import FloatingTextInput from "../../form/FloatingTextInput";
import TitleWithStep from "../../layout/stepTitle";
import {useHistory, useParams} from "react-router-dom";
import useQueue from "../../../services/queue";
import useCheckup, {
  ICreateDiagnosis,
  IDiagnosis,
} from "../../../services/doctor/checkup";
import moment from "moment";
import {ICDInterface} from "../../../services/configurations/type.diseases";
import {ArrayData, PaginationData} from "../../../services/utility";
import {DetailAnamnesisPatient} from "./DetailPasient";
import {StepNavigation} from "./StepNavigation";

const DIAGNOSIS_TYPE: SelectItem[] = [
  {
    display: "DIAGNOSA UTAMA",
    value: "Diagnosa Utama",
  },
  {
    display: "DIAGNOSA TAMBAHAN",
    value: "Diagnosa Tambahan",
  },
  {
    display: "DIAGNOSA BANDING",
    value: "Diagnosa Banding",
  },
];

export const initialStateSelectedDiseaseType = {
  id: "",
  code: "",
  name: "",
  general_name: "",
  type: "",
  status: 0,
  info: "",
};

export const ThirdStepForm = () => {
  const history = useHistory();
  const [formDiagnosis] = useForm();
  const [formDisease] = useForm();
  const params = useParams<{id: string}>();
  const queueService = useQueue();
  const checkupService = useCheckup();

  const [visible, setVisible] = useState(false);
  const [patientHistoryModal, setPatientHistoryModal] = useState(false);
  const [anamnesisDatas, setAnamnesisDatas] = useState({
    id: "",
    position: "",
    status: false,
    created: "",
    updated: "",
    queue: {
      id: "",
      priority: true,
      number: 0,
      status: "",
      bookdated: "",
      shift: {
        id: 0,
        name: "",
        startTime: "",
        endTime: "",
        clinic_poly: {
          inc_id: 0,
          id: "",
          order: 0,
          status: true,
          code: "",
          max_online_registration: 30,
          is_doctor: true,
          created: "",
          updated: "",
          poly: {
            inc_id: 1,
            id: "",
            name: "",
            code_bpjs: "",
            created: "",
            updated: "",
          },
        },
      },
      anamnesis: {
        id: "",
        patient: {
          inc_id: 0,
          id: "",
          no_registration: "",
          bpjs: "",
          type_identity: "",
          no_identity: "",
          fullname: "",
          place_of_birth: "",
          date_of_birth: "",
          gender: "",
          blood_type: null,
          last_education: null,
          marital_status: "",
          job: "",
          phone_number: "",
          email: "",
          village: {
            id: 0,
            name: "",
            district: {
              id: 0,
              name: "",
              city: {
                id: 0,
                name: "",
                province: {
                  id: 0,
                  name: "",
                  country: {
                    id: 0,
                    name: "",
                  },
                },
              },
            },
          },
          address: "",
          person_in_charge: null,
          relationship: null,
          person_in_charge_phone_number: null,
          clinic: 0,
          source: null,
          created: "",
          updated: "",
          deleted: null,
        },
        insurance_type: null,
        insurance_no: "",
        prb: null,
        partnership: null,
        partnership_info: null,
        doctor: null,
        complaint: null,
        complaint_detail: null,
        height: null,
        weight: null,
        sistole: null,
        diastole: null,
        temperature: null,
        respiratory: null,
        heart: null,
        spo2: null,
        gdp: null,
        gds: null,
        hba1c: null,
        priority_info: null,
        created: "",
        updated: "",
      },
    },
  });
  const [age, setAge] = useState("");
  const [selectedDiagnosisType, setSelectedDiagnosisType] = useState(
    DIAGNOSIS_TYPE[0].value
  );
  const [diagnosis, setDiagnosis] = useState<ArrayData<IDiagnosis>>({
    data: [],
  });
  // const [diagnosis , setDiagnosis] = useState<ICDInterface[]>()
  const [selectedDiseaseType, setSelectedDiseaseType] = useState<ICDInterface>(
    initialStateSelectedDiseaseType
  );

  const fetchDiagnosis = (anamnesisId: string) => {
    checkupService
      .getDiagnosis(anamnesisId)
      .then((res) => setDiagnosis(res))
      .catch((err) => console.log(err));
  };
  const patientInformationEffect = () => {
    const activeQueue = JSON.parse(localStorage.getItem("activeQueue")!);
    queueService
      .getQueueRoomDetail(activeQueue.idRoom)
      .then((res: any) => {
        let diff = moment.duration(
          moment().diff(moment(res.queue.anamnesis.patient.date_of_birth))
        );
        setAge(
          `${diff.years()} Tahun ${diff.months()} Bulan ${diff.days()} Hari`
        );
        setAnamnesisDatas(res);
        fetchDiagnosis(res.queue.anamnesis.id);
      })
      .catch((err) => console.log(err));
  };

  useEffect(patientInformationEffect, []);
  // useEffect(fetchDiagnosis, [triggerDiagnosisFetch]);

  const initialFieldValue = () => {
    formDisease.setFieldsValue({
      disease: `${
        selectedDiseaseType.id
          ? `(${selectedDiseaseType.code}) ${selectedDiseaseType.name} / ${selectedDiseaseType.general_name}`
          : ""
      }`,
      type_diagnosis: DIAGNOSIS_TYPE[0],
    });
  };

  useEffect(initialFieldValue, [selectedDiseaseType]);

  const deleteSelectedDiseases = (diagnosisId: string, anamnesisId: string) => {
    checkupService.deleteDiagnosis(diagnosisId, anamnesisId).then((res) => {
      const filteredSelectedDiagnosis = diagnosis.data.filter(
        (diagnose) => diagnose.id !== diagnosisId
      );
      setDiagnosis({...diagnosis, data: filteredSelectedDiagnosis});
    });
  };

  const onFinishDisease = (val: any) => {
    const diagnosisDatas = {
      disease: selectedDiseaseType.id!,
      type_diagnosis: selectedDiagnosisType as string,
      info: val.info as string,
    };

    checkupService
      .submitDiagnosis(anamnesisDatas.queue.anamnesis.id, diagnosisDatas)
      .then((res) => {
        const selectedDiagnosis = {
          id: res.id,
          disease: selectedDiseaseType,
          type_diagnosis: selectedDiagnosisType as string,
          info: val.info as string,
        };
        console.log(selectedDiagnosis, "from selected diagnosis");
        // setDiagnosis((prev) => [...prev.data, selectedDiagnosis]);
        setDiagnosis({
          ...diagnosis,
          data: [...diagnosis.data, selectedDiagnosis],
        });
        formDisease.setFieldsValue({
          disease: "",
          info: "",
        });
      })
      .catch((err) => console.log(err, "submit disease"))
      .finally(() => console.log(diagnosis, "from finnaly"));
  };

  const onFinishDiagnosis = () => {
    if (diagnosis.data.length < 1) {
      alert("Harap isi data diagnosa!");
    }
    history.push(`/checkup/step4/${params.id}`);
  };

  useEffect(() => {
    setVisible(true);
  }, []);

  return (
    <>
      <TitleWithStep
        title="Diagnosa"
        stepTitle="Tahap 3"
        description="diagnosa pasien"
      />
      <DetailAnamnesisPatient
        anamnesisDatas={anamnesisDatas}
        age={age}
        setPatientHistoryModal={setPatientHistoryModal}
      />
      <StepNavigation activeSteps={3} />
      <Row gutter={15}>
        <Col xl={10}>
          <Form form={formDisease} layout="vertical" onFinish={onFinishDisease}>
            <Card title="Form Diagnosa">
              <Row gutter={15}>
                <Col xl={12}>
                  <FloatingTextInput
                    name="disease"
                    label="Diagnosa"
                    onClick={() => setVisible(true)}
                  />
                </Col>
                <Col xl={12}>
                  <Select
                    name="type_diagnosis"
                    label="Jenis Diagnosa"
                    items={DIAGNOSIS_TYPE}
                    value={selectedDiagnosisType}
                    onValueChange={(v) => setSelectedDiagnosisType(v as string)}
                    // onValueChange={(v) => console.log(v)}
                  />
                </Col>
              </Row>
              <Row gutter={15}>
                <Col xl={24}>
                  <Textarea name="info" label="Keterangan Diagnosa" />
                </Col>
              </Row>
              <Row>
                <Col xl={6}>
                  <Button htmlType="submit" className="btn-secondary">
                    Tambah Diagnosa
                  </Button>
                </Col>
              </Row>
            </Card>
          </Form>
        </Col>
        <Col xl={14}>
          <Form
            form={formDiagnosis}
            layout="vertical"
            onFinish={onFinishDiagnosis}
          >
            <Card title="Data Diagnosa">
              <Table
                bordered
                dataSource={diagnosis.data}
                key="id"
                style={{width: "100%"}}
              >
                <Table.Column
                  title="No"
                  key="index"
                  width={2}
                  render={(value, record, index) => index + 1}
                />
                <Table.Column
                  title="Nama"
                  key="index"
                  render={(diagnose: IDiagnosis) => (
                    <>
                      {diagnose.disease.code} {diagnose.disease.name}{" "}
                      {diagnose.disease.general_name}
                    </>
                  )}
                />
                <Table.Column
                  title="Jenis Diagnosa"
                  key="index"
                  render={(diagnose: IDiagnosis) => diagnose.type_diagnosis}
                />
                <Table.Column
                  title="Keterangan"
                  key="index"
                  render={(diagnose: IDiagnosis) => diagnose.info}
                />
                <Table.Column
                  title="Aksi"
                  key="index"
                  render={(diagnose: IDiagnosis) => (
                    <>
                      <Popconfirm
                        placement="topLeft"
                        title="Apakah anda yakin ingin menghapus data ini?"
                        onConfirm={() =>
                          deleteSelectedDiseases(
                            diagnose.id!,
                            anamnesisDatas.queue.anamnesis?.id!
                          )
                        }
                        okText="Ya"
                        cancelText="Tidak"
                      >
                        <DeleteIconButton
                          style={{cursor: "pointer", width: "30px"}}
                        />
                      </Popconfirm>
                    </>
                  )}
                />
              </Table>
              {/* <Row
                style={{
                  margin: "0 0",
                  padding: "1%",
                  background: "rgb(244,244,244)",
                  borderRadius: "5px",
                }}
              >
                <Col xl={2}>
                  <strong>#</strong>
                </Col>
                <Col xl={8}>
                  <strong>Nama</strong>
                </Col>
                <Col xl={5}>
                  <strong>Jenis Diagnosa</strong>
                </Col>
                <Col xl={6}>
                  <strong>Keterangan</strong>
                </Col>
              </Row> */}
              {/* <div
                style={{
                  width: "100%",
                  maxHeight: "150px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {diagnosis.data.map((item, i) => (
                  <Row
                    gutter={20}
                    style={{marginTop: "1%", padding: "0 1%"}}
                    align="middle"
                  >
                    <Col xl={2}>
                      <strong> {i + 1}. </strong>
                    </Col>
                    <Col xl={8}>
                      <strong>
                        {item.disease.code} {item.disease.name}
                        {item.disease.general_name}
                      </strong>
                    </Col>
                    <Col xl={5}>
                      <strong>{item.type_diagnosis}</strong>
                    </Col>
                    <Col xl={6}>
                      <strong> {item.info} </strong>
                    </Col>
                    <Col xl={2}>
                      <DeleteIconButton
                        style={{cursor: "pointer", width: "30px"}}
                        onClick={() =>
                          deleteSelectedDiseases(item.id!, item.anamnesis!.id)
                        }
                      />
                    </Col>
                  </Row>
                ))}
              </div> */}
              <Divider />
              <Row justify="end">
                <Button
                  className="btn-primary"
                  onClick={() => onFinishDiagnosis()}
                >
                  Next
                </Button>
              </Row>
            </Card>
          </Form>
        </Col>
      </Row>
      {visible && (
        <DiseaseCategoryList
          visible={visible}
          setVisible={setVisible}
          setSelectedDiseaseType={setSelectedDiseaseType}
        />
      )}
      {/* <DiseaseCategoryList
        visible={visible}
        setVisible={setVisible}
        setSelectedDiseaseType={setSelectedDiseaseType}
      /> */}
      <PatientHistory
        visible={patientHistoryModal}
        setVisible={setPatientHistoryModal}
      />
    </>
  );
};
