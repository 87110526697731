import React, { FC, useState } from 'react'
import { Button, Col,  DatePicker,  Form,  Input,  Row, Card as CardTheme, Tag, Typography, Space} from 'antd';
import { useHistory } from 'react-router-dom';
import {  EditOutlined, FileProtectOutlined, PrinterOutlined } from '@ant-design/icons';
import Card from '../../layout/card'
import Title from "../../layout/title";
import DataTable from 'react-data-table-component';
import { FilterComponent2} from '../../layout/fiterDataTable'
import { Order } from '../../../services/pharmacy/order';
import { InventoryLocation } from '../../../services/pharmacy/inventory.location';
import _ from 'lodash'

const { Text } = Typography;


const DataStockopnamePharmacy: FC = () => { 
    // Data Table
    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };
  
      return <FilterComponent2 onFilter={(e: any) => filterData(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);
    const history = useHistory()
    const [loader, setLoader] = useState(false)
   
    const [form, setForm] = useState<Order>({
        code: 0,
        date: 0,
        inventory_location: {
            inc_id : 0
        } as InventoryLocation,
    } as any)

    const data = [
        {
            id: 1,
            date: '11/11/2011',
            code: 'POA2022-001',
            status: 'proses'
        }
    ]

    const columns = [
        
        {
            name: 'tanggal',
            selector: (row: any) =>  <Text strong> {row.date} </Text>
        },
        {
            name: 'nomor stockopname',
           selector: (row: any) =>  <a onClick={()=> history.push(`/stock-opname/${row.id}`)} style={{ fontWeight: 'bold'}} > { row.code} </a>
        },
        {
            name: 'status',
           selector: (row: any) =>  {
            return (
                row.status == 'proses'
                ? <Tag color="#55acee">{row.status}</Tag>
                : <Tag color="#87d068">{row.status}</Tag>
            )
        }
     
        },
        {
            name: '',
            width: '25%',
            selector: (row: any) => ( 
                <Space>
                    <a title="isi stok" onClick={ () => history.push(`/adjustment/${row.id}`) } style={{ fontSize: '1.7em', color: '#08c' }}> <EditOutlined  /></a> 
                    <a title="selesai" onClick={ () => history.push(``) } style={{ fontSize: '1.7em', color: '#15d615' }}> <FileProtectOutlined/></a> 
                    <a title="selesai" onClick={ () => history.push(`/stock-opname/${row.id}`) } style={{ fontSize: '1.7em', color: '#08c' }}> <PrinterOutlined/></a> 
                </Space>
            )
        }
    ]

    const submit = () => {
        console.log(form)
    }

    return <div>
        <Title title="Penyesuaian Stock Opname Obat" description="data transaksi penyesuaian obat" />
        <Row>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                <Card title="tambah stok opname" >
                    <Form layout="vertical">
                        <Form.Item label="nomor stock opname" className='lable-form-input'>
                            <Input value={form.code}  onChange={({target : {value: code}}) => setForm({...form, code})} disabled className='form-control'/>
                        </Form.Item>
                        <Form.Item label="tanggal stockopname" className='lable-form-input'>
                            <DatePicker placeholder="pilih tanggal" className="form-control"  style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} />
                        </Form.Item>
                    </Form>
                    <Form.Item>
                        <Button type="primary" onClick={submit} style={{  marginRight:'8px' }}>
                            Simpan
                        </Button>
                    </Form.Item>
                </Card>
            </Col>
            <Col xs={24} sm={24} md={1} lg={1} xl={1}>
            </Col>
            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                    <CardTheme style={{ boxShadow: '0px 3px 10px #b6b6b6', borderRadius: 15, marginBottom: 10, minHeight: '80px' }}>
                        <Row>
                            <Col xs={24} sm={24} md={5} lg={5} xl={5} style={{ borderRight: '3px solid #ccc', marginRight: 40, marginTop: 10 }}>
                                <Typography.Title level={3} style={{ marginBottom: 0, fontWeight: 'bold', color: '#1890ff' }}>Pencarian </Typography.Title>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                                <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                            </Col>
                            <Col span={1} />
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} style={{ marginTop: 10}}>
                                <DatePicker style={{ border: '1px solid rgba(20, 155, 212, 0.5)', width: '100%' }} className='form-control' />
                            </Col>

                            <Col span={1} />
                            <Col xs={24} sm={24} md={2} lg={2} xl={2} style={{ marginTop: 10}}>
                                <Button type="primary">Cari Data</Button>
                            </Col>
                        </Row>
                    </CardTheme>
                {
                    loader && <div>Loading .... </div>
                }

                    <Card title="daftar pengajuan stokopname">
                        <DataTable
                            columns={columns}
                            data={data}
                            className="data-table"
                            highlightOnHover
                            pointerOnHover
                            subHeader
                            subHeaderComponent={subHeaderComponentMemo}
                            noHeader={true}
                            striped={true}
                        />
                    </Card>
                   
            </Col>
        </Row>
    </div>
}

export default DataStockopnamePharmacy

function filterData(value: any) {
    throw new Error('Function not implemented.');
}
