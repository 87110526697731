import { baseURL } from "../baseurl";
import { Country } from "./country";
import { PaginationData } from "../utility";

export interface Province {
  id: string;
  name: string;
  country: Country;
}

const ProvinceClient = baseURL;

const useProvince = () => ({
  async create(Province: Province): Promise<Province> {
    const { data } = await ProvinceClient.post(
      `/config/location/province`,
      Province
    );
    return data;
  },
  async update(id: string, Province: Province): Promise<Province> {
    const { data } = await ProvinceClient.patch(
      `/config/location/province/${id}`,
      Province
    );
    return data;
  },
  async delete(id: string): Promise<void> {
    const { data } = await ProvinceClient.delete(
      `/config/location/province/${id}`
    );
    return data;
  },
  async get(id: string): Promise<Province> {
    const { data } = await ProvinceClient.get(
      `/config/location/province/${id}`
    );
    return data;
  },
  async fetch(
    search: string,
    countryId: number,
    size?: number,
    row?: number
  ): Promise<PaginationData<Province[]>> {
    let searching = search ? `name=${search}` : "";
    let idCount = countryId ? `countryId=${countryId}` : "";
    let sizes = size ? `size=${size}` : "";
    let rows = row ? `page=${row}` : "";
    const { data } = await ProvinceClient.get(
      `/config/locations/province/?${searching}&${idCount}&${sizes}&${rows}`
    );

    return data;
  },
});

export default useProvince;
