import Location from "../../pages/pharmacy/location";
import DashboardManagementPage from "../../pages/management/dashboard";
import EmployeePage from "../../pages/management/employee/user";
import EmployeeFormPage from "../../pages/management/employee/form.employee";
import TreatmentPage from "../../pages/management/treatment/treatment";
import ICDPage from "../../pages/management/icd/type.disease";
import TreatmentFormPage from "../../pages/management/treatment/_form";
import IcdFormPage from "../../pages/management/icd/icd.form";
import PrbPage from "../../pages/management/prb/prb";
import PrbFormPage from "../../pages/management/prb/prb.form";
import PolyPage from "../../pages/management/poly/poly";
import PolyFormPage from "../../pages/management/poly/poly.form";
import BridgingBPJSPage from "../../pages/management/bpjs";
import PublicHealthPage from "../../pages/management/public-health/public.health";
import PublicHealthFormPage from "../../pages/management/public-health/public.health.form";
import PublicHealthFormDetailPage from "../../pages/management/public-health/public.health.form.detail";
import InsurancePage from "../../pages/management/insurance";
import PartnershipsFormPage from "../../pages/management/partnerships/partnership.form";
import PartnershipsPage from "../../pages/management/partnerships/partnership";
import HospitalFormPage from "../../pages/management/hospital/hospital.form";
import HospitalPage from "../../pages/management/hospital/hospital";
import MainLayout from "../../components/layout/mainLayout";
import { RouterInterface } from "../router.interface";

export const ManagementRoute: RouterInterface[] = [
  {
    component: DashboardManagementPage,
    layout: MainLayout,
    path: '/',
    exact: true
  },
  {
    component: EmployeeFormPage,
    layout: MainLayout,
    path: '/employee/:id',
    exact: true
  },
  {
    component: EmployeePage,
    layout: MainLayout,
    path: '/employee',
    exact: true
  },
  {
    component: TreatmentFormPage,
    layout: MainLayout,
    path: '/treatment/:id',
    exact: true
  },
  {
    component: TreatmentPage,
    layout: MainLayout,
    path: '/treatment',
    exact: true
  },
  {
    component: IcdFormPage,
    layout: MainLayout,
    path: '/icd/:id',
    exact: true
  },
  {
    component: ICDPage,
    layout: MainLayout,
    path: '/icd',
    exact: true
  },
  {
    component: HospitalFormPage,
    layout: MainLayout,
    path: '/hospital/:id',
    exact: true
  },
  {
    component: HospitalPage,
    layout: MainLayout,
    path: '/hospital',
    exact: true
  },
  {
    component: PrbFormPage,
    layout: MainLayout,
    path: '/prb/:id',
    exact: true
  },
  {
    component: PrbPage,
    layout: MainLayout,
    path: '/prb',
    exact: true
  },
  {
    component: PartnershipsFormPage,
    layout: MainLayout,
    path: '/partnerships/:id',
    exact: true
  },
  {
    component: PartnershipsPage,
    layout: MainLayout,
    path: '/partnerships',
    exact: true
  },
  {
    component: BridgingBPJSPage,
    layout: MainLayout,
    path: '/bridging-pcare',
    exact: true
  },
  {
    component: InsurancePage,
    layout: MainLayout,
    path: '/insurance/:id',
    exact: true
  },
  {
    component: InsurancePage,
    layout: MainLayout,
    path: '/insurance',
    exact: true
  },
  {
    component: PolyFormPage,
    layout: MainLayout,
    path: '/polies/:id',
    exact: true
  },
  {
    component: PolyPage,
    layout: MainLayout,
    path: '/polies',
    exact: true
  },
  {
    component: PublicHealthFormDetailPage,
    layout: MainLayout,
    path: '/public-health/:id/village',
    exact: true
  },
  {
    component: PublicHealthFormPage,
    layout: MainLayout,
    path: '/public-health/:id',
    exact: true
  },
  {
    component: PublicHealthPage,
    layout: MainLayout,
    path: '/public-health',
    exact: true
  },
  {
    component: Location,
    layout: MainLayout,
    path: '/location',
    exact: true
  },
  {
    component: Location,
    layout: MainLayout,
    path: '/location/:id',
    exact: true
  },
]