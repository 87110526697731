import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { Menu } from 'antd'

export const NavRegistration: React.FC = () => {
    const location = useLocation()
    return (
        <>
            <Menu mode="horizontal" defaultSelectedKeys={['1']} className="menu">
                <Menu.Item key="1" disabled={location.pathname.includes('/patients')}>
                    <RouterLink to="/patients" key="1">Pendaftaran</RouterLink>
                </Menu.Item>
                <Menu.Item key="2" disabled={location.pathname.includes('/visit')}>
                    <RouterLink to="/visit" key="2" >Kunjungan</RouterLink>
                </Menu.Item>
                <Menu.Item key="3" disabled={location.pathname.includes('/reference')}>
                    <RouterLink to="/reference" key="3" >Rujukan</RouterLink>
                </Menu.Item>
                <Menu.Item key="4" disabled={location.pathname.includes('/reservation')}>
                    <RouterLink to="/reservation" key="4" >Reservasi</RouterLink>
                </Menu.Item>
                <Menu.Item key="5" disabled={location.pathname.includes('/unmerger')}>
                    <RouterLink to="/unmerger" key="5" >Unmerger</RouterLink>
                </Menu.Item>
               
            </Menu>
        </>
    )
}