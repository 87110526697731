export const deleteButton = () => (
    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_351:36)">
<circle cx="11.5" cy="9.5" r="7.5" fill="#BE1E2D"/>
</g>
<path d="M13.8396 11.0817C13.9704 11.2125 14.0358 11.3651 14.0358 11.5395C14.0412 11.7084 13.9785 11.8583 13.8477 11.9891C13.7169 12.1199 13.5643 12.1853 13.39 12.1853C13.221 12.1799 13.0711 12.1117 12.9403 11.9809L11.7959 10.8365L10.725 11.9074C10.5724 12.06 10.3953 12.1335 10.1937 12.1281C9.99748 12.1281 9.82308 12.0518 9.67049 11.8992C9.5179 11.7466 9.43888 11.5695 9.43343 11.3678C9.43343 11.1716 9.50972 10.9973 9.66232 10.8447L10.7332 9.77378L9.61327 8.65385C9.48247 8.52306 9.41708 8.37047 9.41708 8.19607C9.41708 8.02168 9.48247 7.86909 9.61327 7.73829C9.74406 7.6075 9.89393 7.54483 10.0629 7.55028C10.2318 7.55573 10.3817 7.62385 10.5125 7.75464L11.6324 8.87457L12.8504 7.65655C13.003 7.50395 13.1774 7.42766 13.3736 7.42766C13.5752 7.43311 13.7551 7.51485 13.9131 7.6729C14.0657 7.82549 14.142 7.99988 14.142 8.19607C14.142 8.39227 14.0657 8.56666 13.9131 8.71925L12.6951 9.93727L13.8396 11.0817Z" fill="white"/>
<defs>
<filter id="filter0_d_351:36" x="0" y="0" width="23" height="23" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2"/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_351:36"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_351:36" result="shape"/>
</filter>
</defs>
</svg>

)