import { Button, Modal } from 'antd'
import React from 'react'

export interface IModal {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReferLabModal: React.FC<IModal> = ({ visible, setVisible }) => {
    return (
        <Modal
            title="Pemberitahuan"
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={400}
            footer={[
                <Button onClick={() => setVisible(false)} className="btn-light">Tidak</Button>,
                <Button onClick={() => setVisible(false)} className="btn-primary">Ya</Button>,
            ]}
        >
            Rujuk Pasien untuk Pemeriksaan lab?
        </Modal>
    )
}
