
import React, { FC } from 'react'
import DetailQueue from '../../components/pharmacy/queue/_detail'

const QueueDetailPharmacy: FC = () => {
    return <div>
        <DetailQueue/>
    </div>
}

export default QueueDetailPharmacy